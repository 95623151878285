import { createAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import * as api from '../../../../../api';
import * as type from '../../../../type';
import * as action from '../../../../action';

export const deletePharmaciesOrdersProductId = {
  delete: function (...props) {
    return (dispatch) => {
      dispatch(this.deletePending());

      api.pharmacie.orders
        .deletePharmaciesOrdersProductId(...props)
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          dispatch(this.deleteSuccess());
          toast.success('Produkt erfolgreich aus Bestellanfrage gelöscht');
          dispatch(action.request.pharmacie.orders.getPharmaciesOrders.get());
        })
        .catch((error) => {
          if (error.status === 403 || error.status === 404) {
            window.location = '/';
          }
          if (error.status === 401) {
            dispatch(action.request.auth.getRefresh.get(this.delete(...props)));
          } else {
            dispatch(this.deleteFailed(error.statusText));
            toast.error('Produkt konnte nicht aus Bestellanfrage gelöscht werden');
          }
        });
    };
  },

  deleteSuccess: createAction(type.request.pharmacie.orders.deletePharmaciesOrdersProductIdSuccess),
  deletePending: createAction(type.request.pharmacie.orders.deletePharmaciesOrdersProductIdPending),
  deleteFailed: createAction(type.request.pharmacie.orders.deletePharmaciesOrdersProductIdFailed),
};

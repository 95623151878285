export const getPharmacieInfo = (data) => {
  return {
    deliveryAddress: {
      PLZ: data?.deliveryAddress.zip,
      Stadt: data?.deliveryAddress.city,
      Straße: data?.deliveryAddress.street,
      Hausnummer: data?.deliveryAddress.house,
    },
    billingAddress: {
      PLZ: data?.billingAddress.zip,
      Stadt: data?.billingAddress.city,
      Straße: data?.billingAddress.street,
      Hausnummer: data?.billingAddress.house,
    },
    interlocutor: {
      Name: data?.contactName,
      'E-Mail': data?.contactEmail,
      'Telefon Nr.': data?.contactPhone,
      'Fax Nr.': data?.contactFax,
      Web: data?.contactWeb,
      Adresse: data?.contactAddress,
    },
    openingHours: {
      Montag: [
        data?.openingHours.monday?.mainHours?.open,
        data?.openingHours.monday?.mainHours?.close,
        data?.openingHours.monday?.additionalHours?.open,
        data?.openingHours.monday?.additionalHours?.close,
      ],
      Dienstag: [
        data?.openingHours.tuesday?.mainHours?.open,
        data?.openingHours.tuesday?.mainHours?.close,
        data?.openingHours.tuesday?.additionalHours?.open,
        data?.openingHours.tuesday?.additionalHours?.close,
      ],
      Mittwoch: [
        data?.openingHours.wednesday?.mainHours?.open,
        data?.openingHours.wednesday?.mainHours?.close,
        data?.openingHours.wednesday?.additionalHours?.open,
        data?.openingHours.wednesday?.additionalHours?.close,
      ],
      Donnerstag: [
        data?.openingHours.thursday?.mainHours?.open,
        data?.openingHours.thursday?.mainHours?.close,
        data?.openingHours.thursday?.additionalHours?.open,
        data?.openingHours.thursday?.additionalHours?.close,
      ],
      Freitag: [
        data?.openingHours.friday?.mainHours?.open,
        data?.openingHours.friday?.mainHours?.close,
        data?.openingHours.friday?.additionalHours?.open,
        data?.openingHours.friday?.additionalHours?.close,
      ],
      Samstag: [
        data?.openingHours.saturday?.mainHours?.open,
        data?.openingHours.saturday?.mainHours?.close,
        data?.openingHours.saturday?.additionalHours?.open,
        data?.openingHours.saturday?.additionalHours?.close,
      ],
      Sonntag: [
        data?.openingHours.sunday?.mainHours?.open,
        data?.openingHours.sunday?.mainHours?.close,
        data?.openingHours.sunday?.additionalHours?.open,
        data?.openingHours.sunday?.additionalHours?.close,
      ],
    },
  };
};

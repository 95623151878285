export const getProductInfo = (data) => {
  return {
    unternehmensname: data?.companyName,
    produktname: data?.name,
    genetik: data?.genetics||'N.A.',
    beschreibung: data?.description||'N.A.',
    'THC-Gehalt (%)': data?.thc.toFixed(2),
    'CBD-Gehalt (%)': data?.cbd.toFixed(2),
    'CBN-Gehalt (%)': data?.cbn.toFixed(2),
    kultivar: data?.cultivars.join(',')||'N.A.',
    terpene: data?.terpenes.map((item) => item.name).join(', ') || 'N.A.',
    symptome: data?.symptoms||'N.A.',
    'Exklusives Produkt': data?.exclusive ? 'Ja' : 'Nein',
  };
};

export const state = {
  getManufacturerOrders: {
    data: (state) => state.request.manufacturer.orders.getManufacturerOrders.data,
    status: (state) => state.request.manufacturer.orders.getManufacturerOrders.status,
    error: (state) => state.request.manufacturer.orders.getManufacturerOrders.error,
  },
  getManufacturerOrdersId: {
    data: (state) => state.request.manufacturer.orders.getManufacturerOrdersId.data,
    status: (state) => state.request.manufacturer.orders.getManufacturerOrdersId.status,
    error: (state) => state.request.manufacturer.orders.getManufacturerOrdersId.error,
  },
  putManufacturerOrdersId: {
    data: (state) => state.request.manufacturer.orders.putManufacturerOrdersId.data,
    status: (state) => state.request.manufacturer.orders.putManufacturerOrdersId.status,
    error: (state) => state.request.manufacturer.orders.putManufacturerOrdersId.error,
  },
};

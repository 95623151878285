import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./style.scss";

import * as action from "../../../store/action";

import COTLogo from "../../../assets/images/C_TRAX_bl.svg";

import ForgotPassword from "./forgotPassword";

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onForgotPassword = (email) => {
    dispatch(action.request.auth.postForgotPassword.post({ email }, navigate));
  };

  return (
    <main className="forgot-password-page">
      <div className="content">
        <p className="main-logo">
          <img src={COTLogo} alt="" />
        </p>

        <div className="forms">
          <ForgotPassword onEvent={onForgotPassword} />
        </div>
      </div>
    </main>
  );
};

export default ForgotPasswordPage;

import React from 'react';

import './style.scss';

import * as variable from '../../../variables';

import UserLogo from '../../../assets/images/user-logo.png';
import Dropdown from '../../../components/UI/dropdown';

const UserInfo = ({ logo, role, onEvent, handleLink, title }) => {
  return (
    <section className={`user-info`}>
      <p className="logo">
        <img src={logo ? `https://${variable.url}/uploads/${logo}` : UserLogo} alt="" />
      </p>

      <Dropdown
        title={title}
        onEvent={onEvent}
        disabled={role === 'disactivated_pharmacy' || role === 'doctor'}
        handleLink={handleLink}
      />
    </section>
  );
};

export default UserInfo;

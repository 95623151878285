import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../../../store/reducer';
import * as action from '../../../../../../store/action';
import * as util from '../../../../../../utils';

import { useChangeEffect } from '../../../../../../hooks';
import { TabContent } from '../../../../../../HOC';
import { variables } from './variables';

import TableBuilder from '../../../../../../components/tableBuilder';
import FieldSearch from '../../../../../../components/UI/fieldSearch';
import Dropdown2 from '../../../../../../components/UI/dropdown2';
import DropdownCount from '../../../../../../components/dropdownCount';
import Pagination from '../../../../../../components/UI/pagination';
import Selected from '../../../../../../components/selected';
import Loader from '../../../../../../components/popups/loader';
import DeletePopup from '../../../../../../components/popups/deletePopup';
import { useDefaultPerPage } from '../../../../../../hooks/useDefaultPerPage';

const List = ({ visible, isShowLoader }) => {
  const dispatch = useDispatch();

  const dataPharmacies = useSelector(reducer.request.admin.pharmacy.state.getPharmacies.data);

  const statusDeletePharmacies = useSelector(reducer.request.admin.pharmacy.state.deletePharmacies.status);
  const statusGetPharmacies = useSelector(reducer.request.admin.pharmacy.state.getPharmacies.status);

  const deletePharmacies = (data) => dispatch(action.request.admin.pharmacies.deletePharmacies.delete(data));
  const getPharmacies = (start, limit, archieved, search, active) =>
    dispatch(action.request.admin.pharmacies.getPharmacies.get(start, limit, archieved, search, active));

  const [valueSearch, setValueSearch] = useState('');
  const [valueActive, setValueActive] = useState({ id: 3, name: 'Alle' });

  const persistName = 'superuserPharmaciesMain';

  const { contentPerPage, setContentPerPage } = useDefaultPerPage(persistName);

  const [currentPage, setCurrentPage] = useState(1);

  const [isShowDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);

  const countSelectedRows = selectedIDs.length;
  const pageCount = Math.ceil(dataPharmacies?.total / contentPerPage) || 1;

  const tableTitles = ['№', 'Name', 'Telefon', 'Addresse', 'Auftrag', 'Aktiv'];
  const tableData = dataPharmacies?.pharmacies.reduce((acc, item, index) => {
    acc = [
      ...acc,
      {
        id: item.id,
        number: currentPage !== 1 ? (currentPage - 1) * contentPerPage + index + 1 : index + 1,
        name: item.name,
        contactPhone: item.contactPhone,
        deliveryAddress: `${item.deliveryAddress.street} ${item.deliveryAddress.house} ${item.deliveryAddress.zip} ${item.deliveryAddress.city}`,
        ordersCount: item.ordersCount,
        status: util.getStatusPharmacy(item.active),
      },
    ];

    return acc;
  }, []);

  // get new data after delete item
  useChangeEffect(() => {
    if (statusDeletePharmacies === 'success') {
      setShowDeletePopup(false);
      setSelectedIDs([]);
      getData();
    }
  }, [statusDeletePharmacies]);

  // get new data when change page or search
  useChangeEffect(() => {
    getData();
  }, [valueActive]);

  // get new data when change page or search
  useChangeEffect(() => {
    getData();
  }, [currentPage, contentPerPage]);

  const onGoSearch = () => {
    if (currentPage === 1) {
      getPharmacies(0, contentPerPage, false, valueSearch, valueActive);
    } else {
      setCurrentPage(1);
    }
  };

  const onClear = () => {
    setValueSearch('');

    if (currentPage === 1) {
      getPharmacies(0, contentPerPage, false, '', valueActive);
    } else {
      setCurrentPage(1);
    }
  };

  const onDelete = () => {
    deletePharmacies({
      pharmaciesIds: selectedIDs,
    });
  };

  const getData = () => {
    const firstContentIndex = (currentPage - 1) * contentPerPage;
    getPharmacies(firstContentIndex, contentPerPage, false, valueSearch, valueActive);
  };

  return (
    <>
      <TabContent visible={visible}>
        <TableBuilder
          className="pharmacy-table"
          path="profile"
          contentPerPage={contentPerPage}
          title={tableTitles}
          data={tableData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageCount={pageCount}
          selectedIDs={selectedIDs}
          setSelectedIDs={setSelectedIDs}
          valueSearch={valueSearch}>
          <div className="table-header">
            <ul className="left-side">
              <li className="elem">
                <FieldSearch
                  value={valueSearch}
                  setValue={(event) => setValueSearch(event.target.value)}
                  onClear={onClear}
                  onGoSearch={onGoSearch}
                />
              </li>

              <li className="elem">
                <Dropdown2 select={valueActive.name} setSelect={setValueActive} content={variables.dropdown2Content} />
              </li>
            </ul>

            <ul className="right-side">
              {!!countSelectedRows && (
                <li className="elem">
                  <Selected count={countSelectedRows} onTrash={() => setShowDeletePopup(true)} />
                </li>
              )}
              <li className="elem">
                <DropdownCount
                  contentPerPage={contentPerPage}
                  data={variables.dropdownContent}
                  onSetContentPerPage={setContentPerPage}
                  setCurrentPage={() => setCurrentPage(1)}
                  persistName={persistName}
                />
              </li>
              <li className="elem">
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
              </li>
            </ul>
          </div>
        </TableBuilder>
      </TabContent>

      {isShowDeletePopup && <DeletePopup title = 'Ausgewählte Apotheken löschen?' text = 'Ausgewählte Apotheken werden gelöscht.' onDelete={onDelete} onClose={() => setShowDeletePopup(false)} />}

      {!isShowLoader && (statusGetPharmacies === 'pending' || statusDeletePharmacies === 'pending') && (
        <Loader type="transparent" />
      )}
    </>
  );
};

export default List;

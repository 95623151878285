import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

import Button from '../../../../components/UI/button';
import Field from '../../../../components/UI/field';

const ForgotPassword = ({ onEvent }) => {
  const [email, setEmail] = useState('');

  return (
    <form className="forgot-password">
      <p className="title">Passwort zurücksetzen</p>
      <Field
        label="E-Mail"
        name="email"
        type="text"
        value={email}
        onEvent={(event) => setEmail(event.target.value)}
      />

      <Link className="login-link" to="/login">
        Ich kenne mein Passwort
      </Link>
      <Button title="Anmelden" width="209" color="green" onEvent={() => onEvent(email)} />
    </form>
  );
};

export default ForgotPassword;

import React from 'react';

import './style.scss';

import TemplatePopup from '../../../components/templatePopup';
import Button from '../../../components/UI/button';

const DeletePopup = ({
  onClose,
  onDelete,
  title = 'Ausgewählte Lieferanten löschen?',
  text = 'Ausgewählte Lieferanten werden gelöscht.',
  className = '',
}) => {
  return (
    <TemplatePopup onClose={onClose} title={title} className={`delete-popup ${className}`}>
      <div className="content">
        <p className="message">{text}</p>

        <div className="btns">
          <Button title="Abbrechen" width="137" color="gray" onEvent={onClose} />
          <Button title="Löschen" width="119" color="green" onEvent={onDelete} />
        </div>
      </div>
    </TemplatePopup>
  );
};

export default DeletePopup;

import { createAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import * as api from '../../../../api';
import * as type from '../../../type';
import * as action from '../../../action';

export const getRefresh = {
  get: function (retryAction) {
    return (dispatch) => {
      dispatch(this.getPending());

      api.auth
        .getRefresh()
        .then((response) => {
          response.json().then((json) => {
            Cookies.set('accessToken', json.accessToken);
            Cookies.set('refreshToken', json.refreshToken);
            Cookies.set('role', json.role);
            dispatch(this.getSuccess());
            dispatch(retryAction);
            dispatch(action.app.common.addRole(json.role));
          });
        })
        .catch((error) => {
          dispatch(this.getFailed(JSON.parse(error.message)));
        });
    };
  },

  getSuccess: createAction(type.request.auth.getRefreshSuccess),
  getPending: createAction(type.request.auth.getRefreshPending),
  getFailed: createAction(type.request.auth.getRefreshFailed),
};

import React, { useEffect, useRef, useState } from 'react';
import { useOnOutsideClick } from '../../../hooks';

import './style.scss';

const Dropdown2 = ({ label, className = '',btnClassName = '', title, select, setSelect, content, disabled }) => {
  const [isShow, setShow] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef();
  const { innerBorderRef } = useOnOutsideClick(() => setShow(false));

  const onSelect = (item) => {
    setShow(false);
    setSelect({ ...item });
  };

  useEffect(() => {
    setContentHeight(contentRef.current?.scrollHeight);
  }, [contentRef, isShow]);

  return (
    <section className={`dropdown2 ${className} `} ref={innerBorderRef}>
      {title && <h2 className="title">{title}</h2>}
      <button
        type="button"
        disabled={disabled}
        className={`button-choose ${btnClassName}`}
        onClick={() => !disabled && setShow(!isShow)}>
        {select?.name || select || label}
      </button>

      {isShow && !disabled && (
        <ul className="content" style={{ '--content-height': ` ${contentHeight}px` }} ref={contentRef}>
          {content?.map((item) => (
            <li className="item" key={item.id}>
              <button type="button" className="button-type" onClick={() => onSelect(item)}>
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};

export default Dropdown2;

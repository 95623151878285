import Cookies from 'js-cookie';

import * as variable from '../variables';

export const terpenes = {
  getTerpenesAll: () => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/terpenes/all`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getTerpeneId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/terpenes/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  putTerpeneId: (id, data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/terpenes/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteTerpeneId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/terpenes/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  postTerpenes: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/terpenes`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

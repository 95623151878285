import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import './style.scss';

const FieldPagination = ({ pageCount, currentPage, setCurrentPage }) => {
  const [countPages, setCountPages] = useState(10);
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="pagination-field">
      <p>Page</p>
      <input
        type="number"
        className="pagination-input"
        value={currentPage}
        onChange={(e) => {
          e.target.value >= pageCount
            ? setCurrentPage(pageCount)
            : e.target.value <= 1
            ? setCurrentPage(1)
            : setCurrentPage(e.target.value);
        }}
      />
      <p>of {pageCount}</p>
    </div>
  );
};

export default FieldPagination;

export const state = {
  getSettings: {
    data: (state) => state.request.settings.getSettings.data,
    status: (state) => state.request.settings.getSettings.status,
    error: (state) => state.request.settings.getSettings.error,
  },

  patchSettings: {
    data: (state) => state.request.settings.patchSettings.data,
    status: (state) => state.request.settings.patchSettings.status,
    error: (state) => state.request.settings.patchSettings.error,
  },
};

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../store/reducer';

import TemplatePopup from '../../../components/templatePopup';
import Field from '../../../components/UI/field';
import ButtonClear from '../../UI/buttonClear';
import ProductSelectAmount from './components/ProductSelectAmount';
import { toast } from 'react-toastify';

const ProductQuantityPopup = ({ data, setData, onClose }) => {
  const dataManufacturersAll = useSelector(reducer.request.admin.manufacture.state.getManufacturersAll.data);

  const [errorId, setErrorId] = useState([]);

  const onSetValue = (ID, event) => {
    const { name, value } = event.target;
    const chngedData = data.manufacturers.map((item) => {
      if (item.id === ID) {
        return { ...item, [name]: value.replace(/[^0-9\.]/, '') };
      }

      return item;
    });

    setData({ ...data, manufacturers: chngedData });
  };

  const onCloseHandler = () => {
    if (!!errorId.length) {
      toast.error(
        `Stellen Sie die ${
          dataManufacturersAll.find((currentItem) => currentItem.id === errorId[0]).name
        } auf die richtigen Werte ein`,
      );
    } else {
      onClose();
    }
  };

  return (
    <TemplatePopup onClose={onCloseHandler} title="Produktmengeeinstellungen" className="product-quantity-popup">
      <div className="content">
        <p className="message">Bitte fügen Sie die Mengenangaben hinzu</p>

        <ButtonClear onEvent={onCloseHandler} />

        <ul className="product-quantity">
          {data.manufacturers.map((item, index) => (
            <li className="product" key={item.id}>
              <div className="manufacturer">
                <Field
                  label="Lieferant"
                  type="text"
                  value={dataManufacturersAll.find((currentItem) => currentItem.id === item.id).name}
                  disabled={true}
                  onEvent={(event) => {}}
                />
              </div>

              <ProductSelectAmount {...item} onSetValue={onSetValue} setErrorId={setErrorId} errorId={errorId} />
            </li>
          ))}
        </ul>
      </div>
    </TemplatePopup>
  );
};

export default ProductQuantityPopup;

export const getStatusManufacture = (data) => {
  let status = '';

  switch (data) {
    case true:
      status = 'aktiv';
      break;
    case false:
      status = 'nein';
      break;
    default:
      status = '';
  }

  return status;
};

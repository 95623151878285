import { createAction } from '@reduxjs/toolkit';

import * as api from '../../../../api';
import * as type from '../../../type';
import * as action from '../../../action';

export const putCultivarId = {
  put: function (...props) {
    return (dispatch) => {
      dispatch(this.putPending());

      api.cultivars
        .putCultivarId(...props)
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          response.json().then((json) => dispatch(this.putSuccess(json)));
        })
        .catch((error) => {
          if (error.status === 403 || error.status === 404) {
            window.location = '/';
          }
          if (error.status === 401) {
            dispatch(action.request.auth.getRefresh.get(this.put(...props)));
          } else {
            dispatch(this.putFailed(error.statusText));
          }
        });
    };
  },

  putSuccess: createAction(type.request.cultivars.putCultivarIdSuccess),
  putPending: createAction(type.request.cultivars.putCultivarIdPending),
  putFailed: createAction(type.request.cultivars.putCultivarIdFailed),
};

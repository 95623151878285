import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      action.request.doctor.extracts.getDoctorsExtracts.getSuccess,
      ({ getDoctorsExtracts }, action) => {
        getDoctorsExtracts.status = 'success';
        getDoctorsExtracts.data = action.payload;
      },
    )
    .addCase(
      action.request.doctor.extracts.getDoctorsExtracts.getPending,
      ({ getDoctorsExtracts }, action) => {
        getDoctorsExtracts.status = 'pending';
      },
    )
    .addCase(action.request.doctor.extracts.getDoctorsExtracts.getFailed, ({ getDoctorsExtracts }, action) => {
      getDoctorsExtracts.status = 'failed';
      getDoctorsExtracts.error = action.payload;
      getDoctorsExtracts.data = null;
    });
  builder
    .addCase(
      action.request.doctor.extracts.getDoctorsExtractsId.getSuccess,
      ({ getDoctorsExtractsId }, action) => {
        getDoctorsExtractsId.status = 'success';
        getDoctorsExtractsId.data = action.payload;
      },
    )
    .addCase(
      action.request.doctor.extracts.getDoctorsExtractsId.getPending,
      ({ getDoctorsExtractsId }, action) => {
        getDoctorsExtractsId.status = 'pending';
      },
    )
    .addCase(
      action.request.doctor.extracts.getDoctorsExtractsId.getFailed,
      ({ getDoctorsExtractsId }, action) => {
        getDoctorsExtractsId.status = 'failed';
        getDoctorsExtractsId.error = action.payload;
        getDoctorsExtractsId.data = null;
      },
    );
});

import React from 'react';

import './style.scss';

import ButtonTrash from '../UI/buttonTrash';
import ButtonCopy from '../UI/buttonCopy';

const Selected = ({ count, onTrash }) => {
  return (
    <section className='custom-selected'>
      <p className='text'>{count} ausgewählt</p>
      <ButtonTrash onClick={onTrash} />
    </section>
  );
};

export default Selected;

import React, { forwardRef, useEffect } from 'react';
import { FormProvider, Resolver, useForm, UseFormReturn, ValidationMode } from 'react-hook-form';

const Form = ({
  onSubmit,
  defaultValues,
  resolver,
  children,
  className,
  onUnMount,
  onBlur,
  id,
  context,
  mode,
  onChange,
  formRef,
  innerRef,
  ...rest
}) => {
  const methods = useForm({ defaultValues, resolver, mode, context, ...rest });

  const isFunction = typeof children === 'function';

  useEffect(() => {
    return () => {
      onUnMount?.(methods.getValues(), methods);
    };
  }, []);

  useEffect(() => {
    if (onChange) {
      const subscription = methods.watch(() => onChange(methods.getValues()));

      return () => subscription.unsubscribe();
    }
  }, [methods.watch]);

  return (
    <FormProvider {...methods}>
      <form
        onBlur={() => onBlur?.(methods.getValues())}
        ref={(formRef, innerRef)}
        className={`custom-form ${className ? className : ''}`}
        id={id}
        onSubmit={methods.handleSubmit((data) => onSubmit?.(data, methods))}>
        {isFunction ? children(methods) : children}
      </form>
    </FormProvider>
  );
};
export default Form;

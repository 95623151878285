import React from 'react';

import './style.scss';

import TemplatePopup from '../../templatePopup';

import defaultBudImg from '../../../assets/images/default-bud-img.svg';
import defaultExtractImg from '../../../assets/images/default-extract-img.svg';

import * as variable from '../../../variables';

const ImagePopup = ({ img, onClose }) => {
  const location = window.location.href;
  const isBud = location.includes('buds');

  const defaultImg = isBud ? defaultBudImg : defaultExtractImg;
  return (
    <TemplatePopup onClose={onClose} className="content-image-popup-wrapper">
      <div className="content-image-popup">
        <img src={img ? `https://${variable.url}/uploads/${img}` : defaultImg} alt="" />
      </div>
    </TemplatePopup>
  );
};

export default ImagePopup;

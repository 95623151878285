export const getFormateTableDate = (date, time = true) => {
  const optionsWithTime = {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return date
    ? new Date(date)
        .toLocaleString('ru-RU', time ? optionsWithTime : options)
        .replace(',', ' ')
        .replace(new RegExp('/', 'g'), '.')
    : undefined;
};

import Cookies from 'js-cookie';

import * as variable from '../../variables';

export const products = {
  getPharmaciesProducts: (products) => {
    const accessToken = Cookies.get('accessToken');

    const productsParam = products ? `&${products}` : '';

    return fetch(`https://${variable.url}/pharmacies/products?${productsParam}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
};

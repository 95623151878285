import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useChangeEffect, useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import Loader from '../../../components/popups/loader';
import TableBuilder from '../../../components/tableBuilder';
import FieldDatePicker from '../../../components/fieldDatePicker/FieldDatePicker';
import FieldSearch from '../../../components/UI/fieldSearch';
import DropdownCount from '../../../components/dropdownCount';

import { getFormateTableDate, getStatusProductOrder } from '../../../utils';

import { variables } from './variables';
import { useDefaultPerPage } from '../../../hooks/useDefaultPerPage';

const ManufaturerOrders = () => {
  const dispatch = useDispatch();

  const isShow = useDelay(100);
  usePageUp();

  const persistName = 'manufaturerOrders';

  const { contentPerPage, setContentPerPage } = useDefaultPerPage(persistName);

  const [currentPage, setCurrentPage] = useState(1);
  const [valueSearch, setValueSearch] = useState('');
  const [dataSearch, setDataSearch] = useState();
  const [isShowLoader, setShowLoader] = useState(true);

  const ordersData = useSelector(reducer.request.manufacturer.orders.state.getManufacturerOrders.data);
  const statusOrders = useSelector(reducer.request.manufacturer.orders.state.getManufacturerOrders.status);

  const getManufacturerOrders = (start, limit, search, from, to) =>
    dispatch(action.request.manufacturer.orders.getManufacturerOrders.get(start, limit, search, from, to));

  const pageCount = Math.ceil(ordersData?.total / contentPerPage) || 1;
  const isRequestGETSuccess = statusOrders === 'success';

  const tableTitles = ['Interne Nummer', 'Apotheken', 'ID', 'datum', 'status'];
  const tableData = useMemo(
    () =>
      ordersData?.orders?.reduce((acc, item, index) => {
        acc = [
          ...acc,
          {
            'Interne Nummer': item.externalId,
            apotheken: item.pharmacyName,
            productId: item.id,
            datum: getFormateTableDate(item.date),
            status: getStatusProductOrder.de(item.status),
            id: item.id,
          },
        ];

        return acc;
      }, []),
    [ordersData?.orders],
  );

  const onClear = () => {
    setValueSearch('');

    if (currentPage === 1) {
      getManufacturerOrders(0, contentPerPage);
    } else {
      setCurrentPage(1);
    }
  };

  const onGoSearch = () => {
    if (currentPage === 1) {
      getManufacturerOrders(0, contentPerPage, valueSearch);
    } else {
      setCurrentPage(1);
    }
  };

  const onSetDateRange = (date) => {
    setDataSearch(date);
    if (currentPage === 1) {
      date
        ? getManufacturerOrders(0, contentPerPage, '', date[0].toISOString(), date[1].toISOString())
        : getManufacturerOrders(0, contentPerPage, '');
    } else {
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    const firstContentIndex = currentPage ? (currentPage - 1) * contentPerPage : 0;
    getManufacturerOrders(firstContentIndex, contentPerPage, valueSearch);
  }, [contentPerPage, currentPage]);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  return (
    <>
      <Header />
      <main className={`manufacturers-page page ${isShow ? 'show' : ''}`}>
        <div className="container">
          <h1 className="page-title">
            Bestellanfragen <sup>{ordersData?.total}</sup>
          </h1>
          <TableBuilder
            className="manufacturer-table-orders"
            path="edit"
            contentPerPage={contentPerPage}
            title={tableTitles}
            data={tableData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCount={pageCount}
            isCanSelect={false}
            pagination="type2"
            valueSearch={valueSearch || dataSearch?.length}
            isTopPagination={false}>
            <div className="table-header">
              <ul className="space-between">
                <div className="left-side">
                  <li className="elem data-picker-wrapper">
                    <span>Datum</span>
                    <FieldDatePicker
                      startDateValue={dataSearch?.[0]}
                      endDateValue={dataSearch?.[1]}
                      onEvent={onSetDateRange}
                      placeholder="Datum auswählen"
                      isRange={true}
                    />
                  </li>
                  <li className="elem">
                    <FieldSearch
                      value={valueSearch}
                      setValue={(event) => setValueSearch(event.target.value)}
                      onClear={onClear}
                      onGoSearch={onGoSearch}
                    />
                  </li>
                </div>
                <li className="elem right">
                  <DropdownCount
                    contentPerPage={contentPerPage}
                    data={variables.dropdownContent}
                    onSetContentPerPage={setContentPerPage}
                    setCurrentPage={() => setCurrentPage(1)}
                    persistName={persistName}
                  />
                </li>
              </ul>
            </div>
          </TableBuilder>
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default ManufaturerOrders;

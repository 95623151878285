import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useChangeEffect, useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import Flowers from '../../../components/flowers';
import Loader from '../../../components/popups/loader';
import { getSanitizedData } from '../../../utils';

const Dashboard = () => {
  const dispatch = useDispatch();

  const isShow = useDelay(100);
  usePageUp();

  const dataSettings = useSelector(reducer.request.setting.state.getSettings.data);
  const statusBuds = useSelector(reducer.request.doctor.buds.state.getDoctorsBuds.status);
  const statusExtracts = useSelector(reducer.request.doctor.extracts.state.getDoctorsExtracts.status);

  const [isShowLoader, setShowLoader] = useState(true);

  const getDoctorsBuds = (start, limit, search, sort, order) =>
    dispatch(action.request.doctor.buds.getDoctorsBuds.get(start, limit, search, sort, order));
  const getDoctorsExtracts = (start, limit, search, sort, order) =>
    dispatch(action.request.doctor.extracts.getDoctorsExtracts.get(start, limit, search, sort, order));

  const isRequestGETSuccess = statusBuds === 'success' || statusExtracts === 'success';

  useEffect(() => {
    getDoctorsBuds(0, 1);
    getDoctorsExtracts(0, 1);
  }, []);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  return (
    <>
      <Header />
      <main className={`doctor-dashboard-page page ${isShow ? 'show' : ''}`}>
        <div className="container">
          <div className="description">
            <h1>Herzlich Willkommen!</h1>
            <p dangerouslySetInnerHTML={getSanitizedData(dataSettings?.doctorDescription)}></p>
          </div>
          <Flowers />
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default Dashboard;

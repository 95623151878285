import React from 'react';
import './style.scss';

const SetProductAmount = ({ increaseAmount, decreaseAmount, currentAddedItem, label, isShowAmountInside = false }) => {
  const isDisabledIncrease = currentAddedItem?.maxOrderAmount === currentAddedItem?.amount || currentAddedItem?.amount + currentAddedItem?.minOrderAmount > currentAddedItem?.remainingAmount

  return (
    <>
      {label && <p className="product-added-select-amount-label">{label}</p>}
      <div className="product-added-select-amount">
        <button
          disabled={currentAddedItem?.minOrderAmount === currentAddedItem?.amount}
          className={`btn btn-decrease ${currentAddedItem?.minOrderAmount === currentAddedItem?.amount ? 'disabled' : ''
            }`}
          onClick={() => decreaseAmount(currentAddedItem)}></button>
        {isShowAmountInside ? currentAddedItem?.amount : currentAddedItem?.minOrderAmount}
        {currentAddedItem?.type === 'bud' ? ' g' : ' ml'}
        <button
          disabled={isDisabledIncrease}
          className={`btn btn-increase ${isDisabledIncrease ? 'disabled' : ''
            }`}
          onClick={() => increaseAmount(currentAddedItem)}></button>
      </div>
    </>
  );
};

export default SetProductAmount;

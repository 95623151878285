export const orders = {
  getPharmaciesOrdersSuccess: 'request/getPharmaciesOrdersSuccess',
  getPharmaciesOrdersPending: 'request/getPharmaciesOrdersPending',
  getPharmaciesOrdersFailed: 'request/getPharmaciesOrdersFailed',

  getPharmaciesOrdersIdSuccess: 'request/getPharmaciesOrdersIdSuccess',
  getPharmaciesOrdersIdPending: 'request/getPharmaciesOrdersIdPending',
  getPharmaciesOrdersIdFailed: 'request/getPharmaciesOrdersIdFailed',

  postPharmaciesOrdersSuccess: 'request/postPharmaciesOrdersSuccess',
  postPharmaciesOrdersPending: 'request/postPharmaciesOrdersPending',
  postPharmaciesOrdersFailed: 'request/postPharmaciesOrdersFailed',

  deletePharmaciesOrdersSuccess: 'request/deletePharmaciesOrdersSuccess',
  deletePharmaciesOrdersPending: 'request/deletePharmaciesOrdersPending',
  deletePharmaciesOrdersFailed: 'request/deletePharmaciesOrdersFailed',

  deletePharmaciesAllOrdersSuccess: 'request/deletePharmaciesAllOrdersSuccess',
  deletePharmaciesAllOrdersPending: 'request/deletePharmaciesAllOrdersPending',
  deletePharmaciesAllOrdersFailed: 'request/deletePharmaciesAllOrdersFailed',

  deletePharmaciesOrdersProductIdSuccess: 'request/deletePharmaciesOrdersProductIdSuccess',
  deletePharmaciesOrdersProductIdPending: 'request/deletePharmaciesOrdersProductIdPending',
  deletePharmaciesOrdersProductIdFailed: 'request/deletePharmaciesOrdersProductIdFailed',
};

import { createAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import * as api from '../../../../api';
import * as type from '../../../type';
import * as action from '../../../action';
import { toast } from 'react-toastify';

export const postLogin = {
  post: function (data, navigate) {
    return (dispatch) => {
      dispatch(this.postPending());

      api.auth
        .postLogin(data)
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }

          response.json().then((json) => {
            Cookies.set('accessToken', json.accessToken);
            Cookies.set('refreshToken', json.refreshToken);
            Cookies.set('role', json.role);
            dispatch(this.postSuccess(json));
            dispatch(action.app.common.addRole(json.role));
            navigate('/dashboard');
          });
        })
        .catch((error) => {
          if (error.status === 401) {
            toast.error('Benutzer nicht gefunden oder Daten falsch');
          }
          dispatch(this.this.postFailed(error.statusText));
        });
    };
  },

  postSuccess: createAction(type.request.auth.postLoginSuccess),
  postPending: createAction(type.request.auth.postLoginPending),
  postFailed: createAction(type.request.auth.postLoginFailed),
};

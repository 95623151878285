import React, { useEffect, useMemo, useState, useLayoutEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useChangeEffect, useDelay, useOnOutsideClick, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import Button from '../../../components/UI/button';
import Loader from '../../../components/popups/loader';
import ProductCard from '../../../components/productCard';
import LinkBack from '../../../components/UI/linkBack';
import Tabs from '../../../components/UI/tabs';

import * as variable from '../../../variables';

import defaultBudImage from '../../../assets/images/default-bud-img.svg';
import SetProductAmount from '../../../components/setProductAmount/SetProductAmount';
import { getSanitizedData } from '../../../utils';
import ManufactureInfoPopup from '../../../components/popups/manufactureInfoPopup/ManufactureInfoPopup';
import ConsistencyBar from '../../../components/consistencyBar';
import useProductInfo from '../../../hooks/useProductInfo';

const PharmacieExtractsInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const divRef = useRef(null);
  const slideRef = useRef(null);
  const { id } = useParams();

  const isShow = useDelay(100);
  usePageUp();

  const tabsList = [
    {
      id: 1,
      name: 'Über das Produkt',
      count: null,
    },
  ];

  const [activeTabID, setActiveTabID] = useState(1);
  const [isShowFullDescr, setIsShowFullDescr] = useState(false);
  const [mainImg, setMainImg] = useState();
  const [isShowCompanyInfo, setIsShowCompanyInfo] = useState(false);
  const [isShowLoader, setShowLoader] = useState(true);
  const { innerBorderRef } = useOnOutsideClick(() => setIsShowCompanyInfo(false));

  const statusExtracts = useSelector(reducer.request.pharmacie.extracts.state.getPharmaciesExtractsId.status);
  const data = useSelector(reducer.request.pharmacie.extracts.state.getPharmaciesExtractsId.data);
  const cartItems = useSelector(reducer.app.cart.state.items);

  const getPharmaciesExtractsId = (id) => dispatch(action.request.pharmacie.extracts.getPharmaciesExtractsId.get(id));
  const addToCart = (data) => dispatch(action.app.cart.addToCart(data));
  const increaseAmount = (data) => dispatch(action.app.cart.increaseAmount(data));
  const decreaseAmount = (data) => dispatch(action.app.cart.decreaseAmount(data));
  const removeFromCart = (data) => dispatch(action.app.cart.removeFromCart(data));

  const isRequestGETSuccess = statusExtracts === 'success';
  const isAvalible = data?.manufacturers.filter(
    (item) => item.available,
  ).length;
  const isAdded = cartItems[data?.type]?.some((item) => item.id === data?.id);

  const currentAddedItem = useMemo(
    () => cartItems[data?.type]?.find((item) => item.id === data?.id),
    [cartItems, data],
  );

  const terpenesSymptoms = data?.useTerpenesSymptoms ? data?.terpenes.map((item) => item.symptom) : [data?.symptoms];

  const { productInfo } = useProductInfo({ data, currentAddedItem, isShowManufacturerDescription: true });

  const altImages = useMemo(
    () => [data?.imageKey, data?.imageKey1, data?.imageKey2, data?.imageKey3, data?.imageKey4].filter(Boolean),
    [data],
  );

  const onAddRemoveHandler = (data, isAdded) => {
    isAdded ? removeFromCart(data.id) : addToCart(data);
  };

  const onCancel = () => {
    navigate('/extracts');
  };

  const showDescriptionHandler = () => {
    setIsShowFullDescr(!isShowFullDescr);
  };

  const changeMainImgHandler = (img) => {
    setMainImg(img);
  };

  const goBottomHandler = () => {
    divRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scroll = (scrollOffset) => {
    slideRef.current.scrollBy({
      left: scrollOffset,
      behavior: 'smooth',
    });
  };

  useLayoutEffect(() => {
    setMainImg(data?.imageKey);
  }, [data]);

  useLayoutEffect(() => {
    getPharmaciesExtractsId(id);
  }, [id]);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  const sortManufactures = data?.manufacturers.filter((item) => item.available).sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  })
  return (
    <>
      <Header />
      <main className={`pharmacie-product-page page ${isShow ? 'show' : ''}`}>
        {!isShowLoader && (
          <div className="container">
            <LinkBack onEvent={onCancel} title="Zurück zu Extrakte" />
            <section className="product-wrapper">
              <div className="section-top-part">
                <div className="product-images">
                  {altImages.length > 1 && (
                    <ul className="product-images-alt">
                      {altImages.map((item, index) => (
                        <li
                          onClick={() => changeMainImgHandler(item)}
                          key={index}
                          className={`product-images-alt-item ${mainImg === item ? 'active' : ''}`}>
                          <img src={`https://${variable.url}/uploads/${item}`} alt="" />
                        </li>
                      ))}
                    </ul>
                  )}
                  <p className="product-images-main">
                    <img src={mainImg ? `https://${variable.url}/uploads/${mainImg}` : defaultBudImage} alt="" />
                  </p>
                </div>
                <ul className="product-info">
                  <li className="product-header">
                    <h1 className={`product-title ${data?.active && !!isAvalible ? '' : 'no-amount'}`}>{data?.name}</h1>
                    {data?.active && !!isAvalible && (
                      <p className="product-header-amount">
                        Mindestbestellmenge (Verpackungseinheit) <strong>{sortManufactures[0]?.minOrderAmount}</strong> (ml) /
                        Maximalbestellmenge pro Apotheke <strong>{Math.max(sortManufactures[0]?.remainingAmount, 0)}</strong> (ml)
                      </p>
                    )}
                  </li>
                  <li className={`product-add ${data?.active && !!isAvalible ? '' : 'group'}`}>
                    {data?.active && !!isAvalible ? (
                      isAdded ? (
                        <ul className="product-added">
                          <SetProductAmount
                            increaseAmount={increaseAmount}
                            decreaseAmount={decreaseAmount}
                            currentAddedItem={currentAddedItem}
                          />
                          <li className="product-added-total-amount">In Warenkorb {currentAddedItem?.amount} ml</li>
                          <button onClick={() => navigate('/pre-order')} className="product-added-open-cart">
                            zum Warenkorb
                          </button>
                        </ul>
                      ) : (
                        <Button color="green" title="In den Produktkorb" onEvent={() => addToCart(data)} width={233} />
                      )
                    ) : (
                      <>
                        <Button color="gray" disabled={true} title="Momentan nicht lieferbar" width={233} />
                        {!!data?.substitutions.length && (
                          <button onClick={goBottomHandler} className="product-add-analogs">
                            Liste potenzieller Ersatzprodukte (basierend auf THC-, CBD- und Terpenwerten)
                          </button>
                        )}
                      </>
                    )}
                  </li>
                  <ul className="product-settings">
                    {Object.keys(productInfo).map((key, index) => {
                      const value = productInfo[key];
                      const companyInfo = index === 0 && productInfo[key];

                      return (
                        <li key={index} className="list-item">
                          <span className="list-name">{key}</span>
                          <span className="between-text-dots"></span>
                          {index === 0 ? (
                            <span className="list-value ">
                              {value[1] ? (
                                <img src={`https://${variable.url}/uploads/${value[1]}`} alt="" />
                              ) : (
                                value[0] || 'N.A.'
                              )}
                              {value[2] && (
                                <div ref={innerBorderRef} className="manufacturer-info-wrapper">
                                  <button
                                    className="list-btn-info"
                                    onClick={() => setIsShowCompanyInfo(!isShowCompanyInfo)}></button>
                                  {companyInfo && isShowCompanyInfo && (
                                    <ManufactureInfoPopup
                                      description={value[2]}
                                      setIsShowCompanyInfo={setIsShowCompanyInfo}
                                      isShowCompanyInfo={isShowCompanyInfo}
                                    />
                                  )}
                                </div>
                              )}
                            </span>
                          ) : (
                            <span className="list-value">{value}</span>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </ul>
              </div>
              <Tabs list={tabsList} activeTabID={activeTabID} setActiveTabID={setActiveTabID} />
              <div className="section-bottom-part">
                <div className="product-description">
                  <h1 className="product-title">Produktbeschreibung</h1>
                  <p
                    className={`product-description-text ${isShowFullDescr ? 'max' : 'min'}`}
                    dangerouslySetInnerHTML={getSanitizedData(data?.description)}></p>
                  {data?.description.length >= 404 && (
                    <button onClick={showDescriptionHandler} className="product-description-show-more">
                      Mehr
                    </button>
                  )}
                </div>
                <div className="product-details">
                  <h1 className="product-title">Zielwert - Hauptbestandteile * (%)</h1>
                  <p className="product-under-title">
                    *Ein vom Lieferanten angegebener Zielwert, der bei jeder Charge erreicht werden sollte.
                  </p>
                  <ConsistencyBar data={data} />
                  <h1 className="product-title syptoms">Symptome, bei denen dieses Produkt eingesetzt werden kann</h1>
                  <ul className="product-syptoms">
                    {terpenesSymptoms.map((item, index) => (
                      <li key={index} className="product-syptoms-item">
                        {item || 'N.A.'}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
            {!!data?.substitutions.length && data?.useSubstitution && (
              <section className="analog-wrapper" ref={divRef}>
                <div className="analog-header">
                  <h1 className="analog-title">Liste potenzieller Ersatzprodukte (basierend auf THC-, CBD- und Terpenwerten)</h1>
                  <div className="analog-nav">
                    <button className={``} onClick={() => scroll(-239)}></button>
                    <button className={``} onClick={() => scroll(239)}></button>
                  </div>
                </div>
                <ul className="analog-card" ref={slideRef}>
                  {data?.substitutions.map((item) => (
                    <ProductCard
                      key={item.id}
                      {...item}
                      onEvent={onAddRemoveHandler}
                      type="extract"
                      icon="cart"
                      isAdded={cartItems['extract'].some(({ id }) => item.id === id)}
                    />
                  ))}
                </ul>
              </section>
            )}
          </div>
        )}
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default PharmacieExtractsInfo;

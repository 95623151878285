export const state = {
  getDosagesAll: {
    data: (state) => state.request.dosages.getDosagesAll.data,
    status: (state) => state.request.dosages.getDosagesAll.status,
    error: (state) => state.request.dosages.getDosagesAll.error,
  },

  getDosageId: {
    data: (state) => state.request.dosages.getDosageId.data,
    status: (state) => state.request.dosages.getDosageId.status,
    error: (state) => state.request.dosages.getDosageId.error,
  },

  putDosageId: {
    data: (state) => state.request.dosages.putDosageId.data,
    status: (state) => state.request.dosages.putDosageId.status,
    error: (state) => state.request.dosages.putDosageId.error,
  },

  deleteDosageId: {
    data: (state) => state.request.dosages.deleteDosageId.data,
    status: (state) => state.request.dosages.deleteDosageId.status,
    error: (state) => state.request.dosages.deleteDosageId.error,
  },

  getDosages: {
    data: (state) => state.request.dosages.getDosages.data,
    status: (state) => state.request.dosages.getDosages.status,
    error: (state) => state.request.dosages.getDosages.error,
  },

  postDosages: {
    data: (state) => state.request.dosages.postDosages.data,
    status: (state) => state.request.dosages.postDosages.status,
    error: (state) => state.request.dosages.postDosages.error,
  },
};

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.admin.orders.getOrderId.getSuccess, ({ getOrderId }, action) => {
      getOrderId.status = 'success';
      getOrderId.data = action.payload;
    })
    .addCase(action.request.admin.orders.getOrderId.getPending, ({ getOrderId }, action) => {
      getOrderId.status = 'pending';
    })
    .addCase(action.request.admin.orders.getOrderId.getFailed, ({ getOrderId }, action) => {
      getOrderId.status = 'failed';
      getOrderId.error = action.payload;
      getOrderId.data = null;
    });

  builder
    .addCase(action.request.admin.orders.putOrderId.putSuccess, ({ putOrderId }, action) => {
      putOrderId.status = 'success';
    })
    .addCase(action.request.admin.orders.putOrderId.putPending, ({ putOrderId }, action) => {
      putOrderId.status = 'pending';
    })
    .addCase(action.request.admin.orders.putOrderId.putFailed, ({ putOrderId }, action) => {
      putOrderId.status = 'failed';
      putOrderId.error = action.payload;
    });

  builder
    .addCase(action.request.admin.orders.deleteOrderId.deleteSuccess, ({ deleteOrderId }, action) => {
      deleteOrderId.status = 'success';
    })
    .addCase(action.request.admin.orders.deleteOrderId.deletePending, ({ deleteOrderId }, action) => {
      deleteOrderId.status = 'pending';
    })
    .addCase(action.request.admin.orders.deleteOrderId.deleteFailed, ({ deleteOrderId }, action) => {
      deleteOrderId.status = 'failed';
      deleteOrderId.error = action.payload;
    });

  builder
    .addCase(action.request.admin.orders.getOrders.getSuccess, ({ getOrders }, action) => {
      getOrders.status = 'success';
      getOrders.data = action.payload;
    })
    .addCase(action.request.admin.orders.getOrders.getPending, ({ getOrders }, action) => {
      getOrders.status = 'pending';
    })
    .addCase(action.request.admin.orders.getOrders.getFailed, ({ getOrders }, action) => {
      getOrders.status = 'failed';
      getOrders.error = action.payload;
      getOrders.data = null;
    });
});

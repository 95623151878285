export const state = {
  getTerpenesAll: {
    data: (state) => state.request.terpenes.getTerpenesAll.data,
    status: (state) => state.request.terpenes.getTerpenesAll.status,
    error: (state) => state.request.terpenes.getTerpenesAll.error,
  },
  postTerpenes: {
    data: (state) => state.request.terpenes.postTerpenes.data,
    status: (state) => state.request.terpenes.postTerpenes.status,
    error: (state) => state.request.terpenes.postTerpenes.error,
  },
};

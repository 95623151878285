import React, { useEffect, useState } from 'react';

const useDelay = (time) => {
  const [isDelay, setDelay] = useState(false);

  useEffect(() => {
    setTimeout(() => setDelay(true), time);
  }, []);

  return isDelay;
};

export { useDelay };

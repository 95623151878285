import React, { useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd/dist';
import update from 'immutability-helper';
import ButtonClear from '../../../../../../components/UI/buttonClear';

const DragAndDropItem = ({ item, index, setData }) => {
  const onClear = (event, index) => {
    event.stopPropagation();
    setData((prevData) => ({
      ...prevData,
      prescription: prevData.prescription.filter((_, currentIndex) => currentIndex !== index),
    }));
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setData((prevCards) => ({
      ...prevCards,
      prescription: update(prevCards.prescription, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards.prescription[dragIndex]],
        ],
      }),
    }));
  }, []);

  const onChange = useCallback((event, index) => {
    setData((prevData) => ({
      ...prevData,
      prescription: prevData.prescription.map((item, currentIndex) => {
        if (currentIndex !== index) return { ...item };
        return { ...item, value: event.target.value };
      }),
    }));
  }, []);

  const style = {
    // border: '1px dashed gray',
    // padding: '0.5rem 1rem',
    // marginBottom: '.5rem',
    // backgroundColor: 'white',
    cursor: 'move',
  };

  const { id } = item;

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'prescription',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'prescription',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <div className="selected-item" ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      {item.title}
      {item.editable && (
        <input
          type="input"
          value={item?.value}
          onClick={(event) => event.stopPropagation()}
          onChange={(event) => onChange(event, index)}
        />
      )}
      <ButtonClear onEvent={(event) => onClear(event, index)} />
    </div>
  );
};

export default DragAndDropItem;

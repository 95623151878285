export const state = {
  getFormsAll: {
    data: (state) => state.request.forms.getFormsAll.data,
    status: (state) => state.request.forms.getFormsAll.status,
    error: (state) => state.request.forms.getFormsAll.error,
  },

  getFormId: {
    data: (state) => state.request.forms.getFormId.data,
    status: (state) => state.request.forms.getFormId.status,
    error: (state) => state.request.forms.getFormId.error,
  },

  putFormId: {
    data: (state) => state.request.forms.putFormId.data,
    status: (state) => state.request.forms.putFormId.status,
    error: (state) => state.request.forms.putFormId.error,
  },

  deleteFormId: {
    data: (state) => state.request.forms.deleteFormId.data,
    status: (state) => state.request.forms.deleteFormId.status,
    error: (state) => state.request.forms.deleteFormId.error,
  },

  getForms: {
    data: (state) => state.request.forms.getForms.data,
    status: (state) => state.request.forms.getForms.status,
    error: (state) => state.request.forms.getForms.error,
  },

  postForms: {
    data: (state) => state.request.forms.postForms.data,
    status: (state) => state.request.forms.postForms.status,
    error: (state) => state.request.forms.postForms.error,
  },
};

export const dosages = {
  getDosagesAllSuccess: 'request/getDosagesAllSuccess',
  getDosagesAllPending: 'request/getDosagesAllPending',
  getDosagesAllFailed: 'request/getDosagesAllFailed',

  getDosageIdSuccess: 'request/getDosageIdSuccess',
  getDosageIdPending: 'request/getDosageIdPending',
  getDosageIdFailed: 'request/getDosageIdFailed',

  putDosageIdSuccess: 'request/putDosageIdSuccess',
  putDosageIdPending: 'request/putDosageIdPending',
  putDosageIdFailed: 'request/putDosageIdFailed',

  deleteDosageIdSuccess: 'request/deleteDosageIdSuccess',
  deleteDosageIdPending: 'request/deleteDosageIdPending',
  deleteDosageIdFailed: 'request/deleteDosageIdFailed',

  getDosagesSuccess: 'request/getDosagesSuccess',
  getDosagesPending: 'request/getDosagesPending',
  getDosagesFailed: 'request/getDosagesFailed',

  postDosagesSuccess: 'request/postDosagesSuccess',
  postDosagesPending: 'request/postDosagesPending',
  postDosagesFailed: 'request/postDosagesFailed',
};

import React from 'react';

import './style.scss';

const Tabs = ({ list, activeTabID, setActiveTabID }) => {
  return (
    <div className="tabs-container">
      <ul className="tabs">
        {list.map((item) => {
          const activeTab = activeTabID === item.id;

          return (
            <li className="tab" key={item.id}>
              <button className={`tab-btn ${activeTab ? 'active' : ''}`} onClick={() => setActiveTabID(item.id)}>
                {item.name}
                {!!item.count && ` (${item.count})`}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../store/reducer';
import * as action from '../../../../store/action';

import { usePageUp, useChangeEffect } from '../../../../hooks';

import Header from '../../../../components/header';
import Tabs from '../../../../components/UI/tabs';
import List from './tabs/list';
import Dasage from './tabs/dosage';
import Delivery from './tabs/delivery';
import Loader from '../../../../components/popups/loader';
import { useDefaultPerPage } from '../../../../hooks/useDefaultPerPage';

const Buds = () => {
  const dispatch = useDispatch();

  const { contentPerPage: bud } = useDefaultPerPage('superuserBudsList');
  const { contentPerPage: dosage } = useDefaultPerPage('superuserDosageBuds');
  const { contentPerPage: delivery } = useDefaultPerPage('superuserDelivery');

  const dataBuds = useSelector(reducer.request.admin.bud.state.getBuds.data);
  const dataDosages = useSelector(reducer.request.dosage.state.getDosages.data);
  const dataForms = useSelector(reducer.request.form.state.getForms.data);

  const statusGetCultivarsAll = useSelector(reducer.request.cultivar.state.getCultivarsAll.status);
  const statusGetBuds = useSelector(reducer.request.admin.bud.state.getBuds.status);
  const statusGetDosages = useSelector(reducer.request.dosage.state.getDosages.status);
  const statusGetForms = useSelector(reducer.request.form.state.getForms.status);

  const getCultivarsAll = () => dispatch(action.request.cultivars.getCultivarsAll.get());
  const getBuds = (start, limit) => dispatch(action.request.admin.buds.getBuds.get(start, limit));
  const getDosages = (start, limit, type) => dispatch(action.request.dosages.getDosages.get(start, limit, type));
  const getForms = (start, limit) => dispatch(action.request.forms.getForms.get(start, limit));

  const [isShowLoader, setShowLoader] = useState(true);
  const [activeTabID, setActiveTabID] = useState(1);

  const isRequestGETSuccess =
    statusGetCultivarsAll === 'success' &&
    statusGetBuds === 'success' &&
    statusGetDosages === 'success' &&
    statusGetForms === 'success';

  const tabsList = [
    {
      id: 1,
      name: 'Liste',
      count: dataBuds?.total,
    },
    {
      id: 2,
      name: 'Dosierung',
      count: dataDosages?.total,
    },
    {
      id: 3,
      name: 'Abgabeformen',
      count: dataForms?.total,
    },
  ];

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  useEffect(() => {
    getCultivarsAll();
    getBuds(0, bud);
    getDosages(0, dosage, 'bud');
    getForms(0, delivery);
  }, [bud, dosage, delivery]);

  usePageUp();

  return (
    <>
      <Header />

      <main className={`buds-page page ${!isShowLoader ? 'show' : ''}`}>
        <div className="container">
          <h1 className="page-title">Blüten</h1>
          <Tabs list={tabsList} activeTabID={activeTabID} setActiveTabID={setActiveTabID} />

          <List visible={activeTabID === 1} isShowLoader={isShowLoader} />
          <Dasage visible={activeTabID === 2} isShowLoader={isShowLoader} />
          <Delivery visible={activeTabID === 3} isShowLoader={isShowLoader} />
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default Buds;

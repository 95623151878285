export const state = {
  getManufacturer: {
    data: (state) => state.request.manufacturer.user.getManufacturer.data,
    status: (state) => state.request.manufacturer.user.getManufacturer.status,
    error: (state) => state.request.manufacturer.user.getManufacturer.error,
  },
  postManufacturerMail: {
    data: (state) => state.request.manufacturer.user.postManufacturerMail.data,
    status: (state) => state.request.manufacturer.user.postManufacturerMail.status,
    error: (state) => state.request.manufacturer.user.postManufacturerMail.error,
  },
  putManufacturer: {
    data: (state) => state.request.manufacturer.user.putManufacturer.data,
    status: (state) => state.request.manufacturer.user.putManufacturer.status,
    error: (state) => state.request.manufacturer.user.putManufacturer.error,
  },
};

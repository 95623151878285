export const manufacturers = {
  getManufacturersAllSuccess: 'request/getManufacturersAllSuccess',
  getManufacturersAllPending: 'request/getManufacturersAllPending',
  getManufacturersAllFailed: 'request/getManufacturersAllFailed',

  getManufactureIdSuccess: 'request/getManufactureAllSuccess',
  getManufactureIdPending: 'request/getManufactureAllPending',
  getManufactureIdFailed: 'request/getManufactureAllFailed',

  putManufactureIdSuccess: 'request/putManufactureAllSuccess',
  putManufactureIdPending: 'request/putManufactureAllPending',
  putManufactureIdFailed: 'request/putManufactureAllFailed',

  deleteManufactureIdSuccess: 'request/deleteManufactureAllSuccess',
  deleteManufactureIdPending: 'request/deleteManufactureAllPending',
  deleteManufactureIdFailed: 'request/deleteManufactureAllFailed',

  getManufacturersSuccess: 'request/getManufacturersSuccess',
  getManufacturersPending: 'request/getManufacturersPending',
  getManufacturersFailed: 'request/getManufacturersFailed',

  postManufacturersSuccess: 'request/postManufacturersSuccess',
  postManufacturersPending: 'request/postManufacturersPending',
  postManufacturersFailed: 'request/postManufacturersFailed',

  deleteManufacturersSuccess: 'request/deleteManufacturersSuccess',
  deleteManufacturersPending: 'request/deleteManufacturersPending',
  deleteManufacturersFailed: 'request/deleteManufacturersFailed',
};

import React from 'react';
// import { useSearchParams } from 'react-router-dom';

import './style.scss';

const Pagination = ({ currentPage, setCurrentPage, pageCount }) => {
  // const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="pagination">
      <button
        className="pagination-btn prev"
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(currentPage - 1)}
      ></button>

      <p className="pagination-info">
        {currentPage}/{pageCount}
      </p>

      <button
        className="pagination-btn next"
        disabled={currentPage === pageCount}
        onClick={() => setCurrentPage(currentPage + 1)}
      ></button>
    </div>
  );
};

export default Pagination;

export const variables = {
  genetics: [
    { id: 1, name: 'genetics', label: 'Indica' },
    { id: 2, name: 'genetics', label: 'Sativa' },
    { id: 3, name: 'genetics', label: 'Hybrid' },
    { id: 4, name: 'genetics', label: 'n.a.' },
  ],

  concentration: [
    { id: 1, name: 'concentration', label: 'thc' },
    { id: 2, name: 'concentration', label: 'cbd' },
    { id: 3, name: 'concentration', label: 'cbn' },
  ],
};

import React from 'react';

import './style.scss';

import RadioButton from './radioButton';

const RadioSelect = ({ label, listRadio, data, dataKey, setData }) => {
  return (
    <div className="radio-select">
      <p className="label">{label}</p>

      <ul className="list">
        {listRadio.map((item) => (
          <li className="item" key={item.id}>
            <RadioButton
              label={item.label}
              name={item.name}
              isChecked={item.label === data[dataKey]}
              onChange={() => setData({ ...data, [dataKey]: item.label })}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
export default RadioSelect;

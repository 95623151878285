import { createAction } from '@reduxjs/toolkit';

import * as api from '../../../../api';
import * as type from '../../../type';
import * as action from '../../../action';

export const putMailTemplatesID = {
  put: function (...props) {
    return (dispatch) => {
      dispatch(this.putPending());

      api.mailTemplates
        .putMailTemplatesID(...props)
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          dispatch(this.putSuccess());
        })
        .catch((error) => {
          if (error.status === 403) {
            window.location = '/';
          }
          if (error.status === 401) {
            dispatch(action.request.auth.getRefresh.get(this.put(...props)));
          } else {
            dispatch(this.putFailed(error.statusText));
          }
        });
    };
  },

  putSuccess: createAction(type.request.mailTemplates.putMailTemplatesIDSuccess),
  putPending: createAction(type.request.mailTemplates.putMailTemplatesIDPending),
  putFailed: createAction(type.request.mailTemplates.putMailTemplatesIDFailed),
};

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useOnOutsideClick } from '../../../hooks';

import './style.scss';

const Dropdown = ({ title, onEvent, disabled, handleLink }) => {
  const [showDropdown, setShowDropDown] = useState(false);
  const { innerBorderRef } = useOnOutsideClick(() => setShowDropDown(false));

  return (
    <div className="custom-dropdown" ref={innerBorderRef}>
      <button className="btn" onClick={() => setShowDropDown(!showDropdown)}>
        {title}
      </button>

      {showDropdown && (
        <ul className="content">
          {!disabled && (
            <li className="item">
              <Link onClick={handleLink} to="/settings">
                Einstellungen
              </Link>
            </li>
          )}
          <li className="item">
            <button onClick={onEvent}>Abmelden</button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Dropdown;

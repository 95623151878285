import { createAction } from '@reduxjs/toolkit';

import * as api from '../../../../../api';
import * as type from '../../../../type';
import * as action from '../../../../action';
import { toast } from 'react-toastify';

export const deleteManufactureId = {
  delete: function (...props) {
    return (dispatch) => {
      dispatch(this.deletePending());

      api.admin.manufacturers
        .deleteManufactureId(...props)
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          dispatch(this.deleteSuccess());
          toast.success('Aktion erfolgreich');
        })
        .catch((error) => {
          if (error.status === 403 || error.status === 404) {
            window.location = '/';
          }
          if (error.status === 401) {
            dispatch(action.request.auth.getRefresh.get(this.delete(...props)));
          } else {
            dispatch(this.deleteFailed(error.statusText));
            toast.error('Aktion fehlgeschlagen');
          }
        });
    };
  },

  deleteSuccess: createAction(type.request.admin.manufacturers.deleteManufactureIdSuccess),
  deletePending: createAction(type.request.admin.manufacturers.deleteManufactureIdPending),
  deleteFailed: createAction(type.request.admin.manufacturers.deleteManufactureIdFailed),
};

export const extracts = {
  getExtractsAllSuccess: 'request/getExtractsAllSuccess',
  getExtractsAllPending: 'request/getExtractsAllPending',
  getExtractsAllFailed: 'request/getExtractsAllFailed',

  getExtractIdSuccess: 'request/getExtractIdSuccess',
  getExtractIdPending: 'request/getExtractIdPending',
  getExtractIdFailed: 'request/getExtractIdFailed',

  patchExtractIdSuccess: 'request/patchExtractIdSuccess',
  patchExtractIdPending: 'request/patchExtractIdPending',
  patchExtractIdFailed: 'request/patchExtractIdFailed',

  deleteExtractIdSuccess: 'request/deleteExtractIdSuccess',
  deleteExtractIdPending: 'request/deleteExtractIdPending',
  deleteExtractIdFailed: 'request/deleteExtractIdFailed',

  getExtractsSuccess: 'request/getExtractsSuccess',
  getExtractsPending: 'request/getExtractsPending',
  getExtractsFailed: 'request/getExtractsFailed',

  postExtractsSuccess: 'request/postExtractsSuccess',
  postExtractsPending: 'request/postExtractsPending',
  postExtractsFailed: 'request/postExtractsFailed',

  deleteExtractsSuccess: 'request/deleteExtractsSuccess',
  deleteExtractsPending: 'request/deleteExtractsPending',
  deleteExtractsFailed: 'request/deleteExtractsFailed',
};

export const initialState = {
  getPharmaciesAll: { data: null, status: null, error: null },
  getPharmacyId: { data: null, status: null, error: null },
  patchPharmacyId: { data: null, status: null, error: null },
  deletePharmacyId: { data: null, status: null, error: null },
  getPharmacies: { data: null, status: null, error: null },
  deletePharmacies: { data: null, status: null, error: null },

  getPharmaciesArchieved: { data: null, status: null, error: null },
  deletePharmaciesArchieved: { data: null, status: null, error: null },
};

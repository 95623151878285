import React, { forwardRef } from 'react';

import './style.scss';

import prescriptionFormImage from '../../../../assets/images/prescription-form.svg';

const PrescriptionPrint = forwardRef(({ prescriptionData, country }, ref) => {
  return (
    <section ref={ref}>
      <div className={`prescription-print ${!!prescriptionData?.fullPrescription?.length ? 'show-arrow' : ''}`}>
        <img src={prescriptionFormImage} alt="" />
        <p className="prescription-print-main-text">{prescriptionData?.fullPrescription}</p>
        <p className="prescription-print-field field1">{prescriptionData?.patientInsurance}</p>
        <p className="prescription-print-field small field2 ">{prescriptionData?.patientName}</p>
        <p className="prescription-print-field small field3 ">{prescriptionData?.patientSurname}</p>
        <p className="prescription-print-field small country ">{country?.name}</p>
        <p className="prescription-print-field small field4 ">{prescriptionData?.patientZip}</p>
        <p className="prescription-print-field small field5 ">{prescriptionData?.patientCity}</p>
        <p className="prescription-print-field small field6 ">{prescriptionData?.patientStreet}</p>
        <p className="prescription-print-field small field7 ">{prescriptionData?.patientHouse}</p>
        <p className="prescription-print-field field8">{prescriptionData?.taxId}</p>
        <p className="prescription-print-field field9">{prescriptionData?.insuredId}</p>
        <p className="prescription-print-field field10">{prescriptionData?.status}</p>
        <p className="prescription-print-field field11">{prescriptionData?.businessPremisesId}</p>
        <p className="prescription-print-field small datePatient">{`${prescriptionData?.patientDay || '01'}/${
          prescriptionData?.patientMonth || '01'
        }/${prescriptionData?.patientYear || '1990'}`}</p>
        <p className="prescription-print-field datePrescr">{`${prescriptionData?.dateDay || '01'}/${
          prescriptionData?.dateMonth || '01'
        }/${prescriptionData?.dateYear || '1990'}`}</p>
      </div>
      {!!prescriptionData?.fullPrescription?.length && (
        <div className="prescription-complite">
          <p className="prescription-complite-title">Rezeptangaben</p>
          <p className="prescription-complite-text">{prescriptionData?.fullPrescription?.join(', ')}</p>
        </div>
      )}
    </section>
  );
});

export default PrescriptionPrint;

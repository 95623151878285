import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../../../store/reducer';
import * as action from '../../../../../../store/action';

import { useChangeEffect } from '../../../../../../hooks';
import { TabContent } from '../../../../../../HOC';
import { variables } from './variables';

import CardBuilder from '../../../../../../components/cardBuilder';
import DropdownCount from '../../../../../../components/dropdownCount';
import Pagination from '../../../../../../components/UI/pagination';
import Button from '../../../../../../components/UI/button';
import CreateInstructionsPopup from '../../../../../../components/popups/createInstructionsPopup';
import Loader from '../../../../../../components/popups/loader';
import { useDefaultPerPage } from '../../../../../../hooks/useDefaultPerPage';

const Dosage = ({ visible, isShowLoader }) => {
  const dispatch = useDispatch();

  const dataDosages = useSelector(reducer.request.dosage.state.getDosages.data);

  const statusPostDosages = useSelector(reducer.request.dosage.state.postDosages.status);
  const statusPutDosageId = useSelector(reducer.request.dosage.state.putDosageId.status);
  const statusDeleteDosageId = useSelector(reducer.request.dosage.state.deleteDosageId.status);
  const statusGetDosages = useSelector(reducer.request.dosage.state.getDosages.status);

  const postDosages = (data) => dispatch(action.request.dosages.postDosages.post(data));
  const putDosageId = (id, data) => dispatch(action.request.dosages.putDosageId.put(id, data));
  const deleteDosageId = (id) => dispatch(action.request.dosages.deleteDosageId.delete(id));
  const getDosages = (start, limit, type) => dispatch(action.request.dosages.getDosages.get(start, limit, type));

  const [isShowCreateInstructionsPopup, setShowCreateInstructionsPopup] = useState(false);

  const persistName = 'superuserDosageExtracts';

  const { contentPerPage, setContentPerPage } = useDefaultPerPage(persistName);

  const [currentPage, setCurrentPage] = useState(1);

  const pageCount = Math.ceil(dataDosages?.total / contentPerPage) || 1;

  // get new data after delete item
  useChangeEffect(() => {
    if (statusDeleteDosageId === 'success') getData();
  }, [statusDeleteDosageId]);

  // get new data after change card
  useChangeEffect(() => {
    if (statusPutDosageId === 'success') getData();
  }, [statusPutDosageId]);

  // get new data after create new card
  useChangeEffect(() => {
    if (statusPostDosages === 'success') {
      setShowCreateInstructionsPopup(false);
      getData();
    }
  }, [statusPostDosages]);

  // get new data after change page
  useChangeEffect(() => {
    getData();
  }, [currentPage, contentPerPage]);

  const getData = () => {
    const firstContentIndex = (currentPage - 1) * contentPerPage;

    getDosages(firstContentIndex, contentPerPage, 'extract');
  };

  return (
    <>
      <TabContent visible={visible}>
        <CardBuilder
          type="dosages"
          className="dosage-content"
          data={dataDosages?.dosages}
          onDelete={deleteDosageId}
          onPut={putDosageId}
          statusPut={statusPutDosageId}
          statusDelete={statusDeleteDosageId}>
          <div className="card-header">
            <ul className="left-side">
              <li className="elem">
                <DropdownCount
                  contentPerPage={contentPerPage}
                  data={variables.dropdownContent}
                  onSetContentPerPage={setContentPerPage}
                  setCurrentPage={() => setCurrentPage(1)}
                  persistName="superuserDosageExtracts"
                />
              </li>
              <li className="elem">
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
              </li>
            </ul>

            <Button
              title="Dosierungsanleitung erstellen"
              onEvent={() => setShowCreateInstructionsPopup(true)}
              width="267"
              color="green"
            />
          </div>
        </CardBuilder>

        {isShowCreateInstructionsPopup && (
          <CreateInstructionsPopup
            type="extract"
            onSave={postDosages}
            onClose={() => setShowCreateInstructionsPopup(false)}
          />
        )}
      </TabContent>

      {!isShowLoader &&
        (statusGetDosages === 'pending' ||
          statusDeleteDosageId === 'pending' ||
          statusPutDosageId === 'pending' ||
          statusPostDosages === 'pending') && <Loader type="transparent" />}
    </>
  );
};

export default Dosage;

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.cultivars.getCultivarsAll.getSuccess, ({ getCultivarsAll }, action) => {
      getCultivarsAll.status = 'success';
      getCultivarsAll.data = action.payload;
    })
    .addCase(action.request.cultivars.getCultivarsAll.getPending, ({ getCultivarsAll }, action) => {
      getCultivarsAll.status = 'pending';
    })
    .addCase(action.request.cultivars.getCultivarsAll.getFailed, ({ getCultivarsAll }, action) => {
      getCultivarsAll.status = 'failed';
      getCultivarsAll.error = action.payload;
      getCultivarsAll.data = null;
    });

  builder
    .addCase(action.request.cultivars.getCultivarId.getSuccess, ({ getCultivarId }, action) => {
      getCultivarId.status = 'success';
      getCultivarId.data = action.payload;
    })
    .addCase(action.request.cultivars.getCultivarId.getPending, ({ getCultivarId }, action) => {
      getCultivarId.status = 'pending';
    })
    .addCase(action.request.cultivars.getCultivarId.getFailed, ({ getCultivarId }, action) => {
      getCultivarId.status = 'failed';
      getCultivarId.error = action.payload;
      getCultivarId.data = null;
    });

  builder
    .addCase(action.request.cultivars.putCultivarId.putSuccess, ({ putCultivarId }, action) => {
      putCultivarId.status = 'success';
      putCultivarId.data = action.payload;
    })
    .addCase(action.request.cultivars.putCultivarId.putPending, ({ putCultivarId }, action) => {
      putCultivarId.status = 'pending';
    })
    .addCase(action.request.cultivars.putCultivarId.putFailed, ({ putCultivarId }, action) => {
      putCultivarId.status = 'failed';
      putCultivarId.error = action.payload;
      putCultivarId.data = null;
    });

  builder
    .addCase(action.request.cultivars.deleteCultivarId.deleteSuccess, ({ deleteCultivarId }, action) => {
      deleteCultivarId.status = 'success';
      deleteCultivarId.data = action.payload;
    })
    .addCase(action.request.cultivars.deleteCultivarId.deletePending, ({ deleteCultivarId }, action) => {
      deleteCultivarId.status = 'pending';
    })
    .addCase(action.request.cultivars.deleteCultivarId.deleteFailed, ({ deleteCultivarId }, action) => {
      deleteCultivarId.status = 'failed';
      deleteCultivarId.error = action.payload;
      deleteCultivarId.data = null;
    });

  builder
    .addCase(action.request.cultivars.postCultivars.postSuccess, ({ postCultivars }, action) => {
      postCultivars.status = 'success';
      postCultivars.data = action.payload;
    })
    .addCase(action.request.cultivars.postCultivars.postPending, ({ postCultivars }, action) => {
      postCultivars.status = 'pending';
    })
    .addCase(action.request.cultivars.postCultivars.postFailed, ({ postCultivars }, action) => {
      postCultivars.status = 'failed';
      postCultivars.error = action.payload;
      postCultivars.data = null;
    });
  builder.addCase(action.request.cultivars.resetPostData, (state) => {
    state.postTerpenes = initialState.postTerpenes;
  });
});

import React, { useState } from 'react';

import './style.scss';

import ButtonClear from '../buttonClear';

const SearchField = ({ value, setValue, onClear, onGoSearch }) => {
  const onPresEnter = (event) => {
    if (event.keyCode == 13) onGoSearch();
  };

  const valueLength = !!value?.length;

  return (
    <div className="field-search">
      <button className="btn" onClick={onGoSearch}></button>
      <input type="text" name="search" placeholder="Suche" value={value} onChange={setValue} onKeyDown={onPresEnter} />
      {valueLength && <ButtonClear onEvent={onClear} />}
    </div>
  );
};

export default SearchField;

import React from 'react';

import './style.scss';

import * as variable from '../../../variables';

import DefaultImg from '../../../assets/images/default-img.png';
import { ReactComponent as IconStar } from '../../../assets/icons/icon-star.svg';

const PreviewImg = ({ data }) => {
  const selectCultivarData = [
    { id: 1, name: 'Mun Shine' },
    { id: 2, name: 'LA Confidential' },
    { id: 3, name: 'Black Label' },
    { id: 4, name: 'Master Kosh' },
    { id: 5, name: 'Blue Dream' },
  ];

  const companyName = data?.cultivars?.map((id) => selectCultivarData.find((item) => item.id === id)?.name).join(' / ');

  return (
    <div className="preview-img">
      <div className="header">
        <p className="logo">
          <img src={data.companyImageKey ? `https://${variable.url}/uploads/${data.companyImageKey}` : ''} alt="" />
        </p>
        <div>
          <p className="cultivar">{companyName}</p>
          <p className="company-name">{data?.companyName}</p>
        </div>
      </div>
      <p className="img">
        <img src={data.imageKey ? `https://${variable.url}/uploads/${data.imageKey}` : DefaultImg} alt="" />
      </p>
      <div className="footer">
        <p className="product-name">
          {data?.exclusive && <IconStar />}
          {data?.name}
        </p>
        <p className="genetics">{data?.genetics}</p>
      </div>
    </div>
  );
};

export default PreviewImg;

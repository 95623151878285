import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      action.request.manufacturer.extracts.getManufacturerExtracts.getSuccess,
      ({ getManufacturerExtracts }, action) => {
        getManufacturerExtracts.status = 'success';
        getManufacturerExtracts.data = action.payload;
      },
    )
    .addCase(
      action.request.manufacturer.extracts.getManufacturerExtracts.getPending,
      ({ getManufacturerExtracts }, action) => {
        getManufacturerExtracts.status = 'pending';
      },
    )
    .addCase(
      action.request.manufacturer.extracts.getManufacturerExtracts.getFailed,
      ({ getManufacturerExtracts }, action) => {
        getManufacturerExtracts.status = 'failed';
        getManufacturerExtracts.error = action.payload;
        getManufacturerExtracts.data = null;
      },
    );
  builder
    .addCase(
      action.request.manufacturer.extracts.getManufacturerExtractsId.getSuccess,
      ({ getManufacturerExtractsId }, action) => {
        getManufacturerExtractsId.status = 'success';
        getManufacturerExtractsId.data = action.payload;
      },
    )
    .addCase(
      action.request.manufacturer.extracts.getManufacturerExtractsId.getPending,
      ({ getManufacturerExtractsId }, action) => {
        getManufacturerExtractsId.status = 'pending';
      },
    )
    .addCase(
      action.request.manufacturer.extracts.getManufacturerExtractsId.getFailed,
      ({ getManufacturerExtractsId }, action) => {
        getManufacturerExtractsId.status = 'failed';
        getManufacturerExtractsId.error = action.payload;
        getManufacturerExtractsId.data = null;
      },
    );
  builder
    .addCase(
      action.request.manufacturer.extracts.patchManufacturerExtractsId.patchSuccess,
      ({ patchManufacturerExtractsId }, action) => {
        patchManufacturerExtractsId.status = 'success';
        patchManufacturerExtractsId.data = action.payload;
      },
    )
    .addCase(
      action.request.manufacturer.extracts.patchManufacturerExtractsId.patchPending,
      ({ patchManufacturerExtractsId }, action) => {
        patchManufacturerExtractsId.status = 'pending';
      },
    )
    .addCase(
      action.request.manufacturer.extracts.patchManufacturerExtractsId.patchFailed,
      ({ patchManufacturerExtractsId }, action) => {
        patchManufacturerExtractsId.status = 'failed';
        patchManufacturerExtractsId.error = action.payload;
        patchManufacturerExtractsId.data = null;
      },
    );
});

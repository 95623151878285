export const prescription = {
  addToPrescription: 'app/addToPrescription',
  savePatientInfo: 'app/savePatientInfo',
  clearPatientInfo: 'app/clearPatientInfo',
  removeFromPrescription: 'app/removeFromPrescription',
  upItemInPrescription: 'app/upItemInPrescription',
  downItemInPrescription: 'app/downItemInPrescription',
  updateItemInPrescription: 'app/updateItemInPrescription',
  removeAllProducts: 'app/removeAllProducts',
};

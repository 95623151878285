import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useChangeEffect, useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import Flowers from '../../../components/flowers';
import Button from '../../../components/UI/button';
import Loader from '../../../components/popups/loader';
import TableBuilder from '../../../components/tableBuilder';

import { getFormateTableDate, getStatusProductOrder } from '../../../utils';
import { useDefaultPerPage } from '../../../hooks/useDefaultPerPage';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isShow = useDelay(100);

  usePageUp();

  const [currentPage, setCurrentPage] = useState(1);
  const [isShowLoader, setShowLoader] = useState(true);

  const { contentPerPage: buds } = useDefaultPerPage('manufacturerBuds');
  const { contentPerPage: extracts } = useDefaultPerPage('manufacturerExtracts');
  const { contentPerPage: orders } = useDefaultPerPage('manufaturerOrders');

  const ordersData = useSelector(reducer.request.manufacturer.orders.state.getManufacturerOrders.data);
  const statusOrders = useSelector(reducer.request.manufacturer.orders.state.getManufacturerOrders.status);
  const statusBuds = useSelector(reducer.request.manufacturer.buds.state.getManufacturerBuds.status);
  const statusExtracts = useSelector(reducer.request.manufacturer.extracts.state.getManufacturerExtracts.status);

  const getManufacturerOrders = (start, limit, search, from, to) =>
    dispatch(action.request.manufacturer.orders.getManufacturerOrders.get(start, limit, search, from, to));
  const getManufacturerBuds = (start, limit, search, sort, order) =>
    dispatch(action.request.manufacturer.buds.getManufacturerBuds.get(start, limit, search, sort, order));
  const getManufacturerExtracts = (start, limit, search, sort, order) =>
    dispatch(action.request.manufacturer.extracts.getManufacturerExtracts.get(start, limit, search, sort, order));

  const tableTitles = ['Interne Nummer', 'Apotheken', 'ID', 'datum', 'status'];
  const isRequestGETSuccess = statusOrders === 'success' && statusBuds === 'success' && statusExtracts === 'success';

  const tableData = useMemo(
    () =>
      ordersData?.orders?.reduce((acc, item) => {
        acc = [
          ...acc,
          {
            'Interne Nummer': item.externalId,
            apotheken: item.pharmacyName,
            productId: item.id,
            datum: getFormateTableDate(item.date),
            status: getStatusProductOrder.de(item.status),
            id: item.id,
          },
        ];

        return acc;
      }, []),
    [ordersData?.orders],
  );

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  useEffect(() => {
    getManufacturerOrders(0, orders);
    getManufacturerBuds(0, buds);
    getManufacturerExtracts(0, extracts);
  }, [orders, buds, extracts]);

  return (
    <>
      <Header />
      <main className={`manufacturer-dashboard-page page ${isShow ? 'show' : ''}`}>
        {!isShowLoader && (
          <div className="container ">
            <Flowers type="manufacturer" />
            {ordersData?.orders?.length ? (
              <>
                <div className="dashboard-page-title">
                  <h1>Letzte Bestellanfragen</h1>
                  <sup>{ordersData?.total}</sup>
                </div>
                <TableBuilder
                  className="manufacturer-table-orders"
                  path="/orders/edit"
                  title={tableTitles}
                  data={tableData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  isCanSelect={false}
                  pagination="type2"
                  isTopPagination={false}
                  isWithNavigation={false}></TableBuilder>
              </>
            ) : (
              <section className="empty-orders">
                <h1 className="empty-orders-title">Sie haben keine Produkte hinzugefügt</h1>
                <p className="empty-orders-text">
                  Bitte geben Sie die Produkte ein, die Sie verkaufen möchten, und unser Team wird diese hinzufügen
                </p>
                <div className="empty-orders-btns">
                  <Button title="Blüte erstellen" onEvent={() => navigate('/buds')} width="173" color="green" />
                  <Button title="Produkt hinzufügen" onEvent={() => navigate('/extracts')} width="173" color="green" />
                </div>
              </section>
            )}
          </div>
        )}
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default Dashboard;

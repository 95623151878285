import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../../store/reducer';
import * as action from '../../../../../store/action';

import { TabContent } from '../../../../../HOC';

import Button from '../../../../../components/UI/button';
import Range from '../../../../../components/range';
import Loader from '../../../../../components/popups/loader';

const RecipeSettings = ({ visible }) => {
  const dispatch = useDispatch();

  const dataSetting = useSelector(reducer.request.setting.state.getSettings.data);
  const statusPatchSettings = useSelector(reducer.request.setting.state.patchSettings.status);

  const patchSettings = (data) => dispatch(action.request.settings.patchSettings.patch(data));

  const [data, setData] = useState({
    budsPrescriptionAmount: 1,
    css: '',
    doctorDescription: '',
    doctorLogo: null,
    extractsPrescriptionAmount: 1,
    logo: null,
    manufacturerDescription: '',
    manufacturerLogo: null,
    pharmacyDescription: '',
    pharmacyLogo: null,
  });

  useEffect(() => {
    if (dataSetting) setData(dataSetting);
  }, [dataSetting]);

  const onSave = () => {
    patchSettings(data);
  };

  return (
    <>
      <TabContent visible={visible}>
        <div className="recipe-settings">
          <div className="content">
            <Range
              title="Buds"
              message="Schritte der Menge (mg)"
              data={data.budsPrescriptionAmount}
              setData={(value) => setData({ ...data, budsPrescriptionAmount: value })}
            />
            <Range
              title="Extrakte"
              message="Stufen der Menge (mg)"
              data={data.extractsPrescriptionAmount}
              setData={(value) => setData({ ...data, extractsPrescriptionAmount: value })}
            />
          </div>
          <Button title="Speichern und weiter" onEvent={onSave} width="206" color="green" />
        </div>
      </TabContent>

      {visible && statusPatchSettings === 'pending' && <Loader type="transparent" />}
    </>
  );
};

export default RecipeSettings;

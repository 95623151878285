import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useChangeEffect, useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import LinkBack from '../../../components/UI/linkBack';
import Switcher from '../../../components/UI/switcher';
import Form from '../../../components/UI/form/Form.jsx';
import UploadImgDragDrop from '../../../components/uploadImgDragDrop';
import Button from '../../../components/UI/button';
import ButtonClear from '../../../components/UI/buttonClear';
import FormInput from '../../../components/UI/form/formInput/FormInput';
import FormTextarea from '../../../components/UI/form/formTextarea';
import Loader from '../../../components/popups/loader';

import { getProductInfo, getSanitizedData } from '../../../utils';

import defaultExtractImg from '../../../assets/images/default-extract-img.svg';

import { ProductEditSchema, ProductEditSchemaEx } from '../../../components/UI/form/validation';

import * as variable from '../../../variables';

const ManufacturerExtractEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const isShow = useDelay(100);
  usePageUp();

  const typeUploadFiles = 'extract';

  const statusExtracts = useSelector(reducer.request.manufacturer.extracts.state.getManufacturerExtractsId.status);
  const dataExtracts = useSelector(reducer.request.manufacturer.extracts.state.getManufacturerExtractsId.data);
  const dataUpload = useSelector(reducer.request.uploads.state.postUploads.data);
  const statusUpload = useSelector(reducer.request.uploads.state.postUploads.status);
  const isDataChages = useSelector(reducer.app.common.state.isDataChages);

  const setDataChage = (isChange) => dispatch(action.app.common.isDataChages(isChange));
  const getManufacturerExtractsId = (id) =>
    dispatch(action.request.manufacturer.extracts.getManufacturerExtractsId.get(id));
  const patchManufacturerExtractsId = (id, data) =>
    dispatch(action.request.manufacturer.extracts.patchManufacturerExtractsId.patch(id, data));
  const postUploads = (data) => dispatch(action.request.uploads.postUploads.post(data));
  const deleteUpload = (filesIdsArray) => dispatch(action.request.uploads.deleteUploads.delete(filesIdsArray));
  const resetUploadData = () => dispatch(action.request.uploads.resetUploadData());

  const [isAvailable, setIsAvailable] = useState();
  const [attachment, setAttachment] = useState([]);
  const [newData, setNewData] = useState();
  const [isShowLoader, setShowLoader] = useState(true);

  const attachmentLength = Object.keys(attachment).filter((key) => attachment[key]).length;
  const isRequestGETSuccess = statusExtracts === 'success';

  useEffect(() => {
    setIsAvailable(dataExtracts?.active);
  }, [dataExtracts]);

  useEffect(() => {
    if (!!newData && !!dataExtracts) {
      if (
        !_.isEqual(
          {
            name: dataExtracts?.name,
            description: dataExtracts?.description,
            maxOrderAmount: parseFloat(dataExtracts?.maxOrderAmount) || 0,
            minOrderAmount: parseFloat(dataExtracts?.minOrderAmount) || 0,
            availableAmount: parseFloat(dataExtracts?.availableAmount) || 0,
          },
          {
            name: dataExtracts?.name,
            description: dataExtracts?.description,
            ...newData,
            maxOrderAmount: parseFloat(newData?.maxOrderAmount) || 0,
            minOrderAmount: parseFloat(newData?.minOrderAmount) || 0,
            availableAmount: parseFloat(newData?.availableAmount) || 0,
          },
        )
      ) {
        setDataChage(true);
      } else if (
        dataExtracts.exclusive &&
        !_.isEqual(
          {
            mainImageKey: dataExtracts.imageKey,
            imageKey1: dataExtracts.imageKey1,
            imageKey2: dataExtracts.imageKey2,
            imageKey3: dataExtracts.imageKey3,
            imageKey4: dataExtracts.imageKey4,
          },
          attachment,
        )
      ) {
        setDataChage(true);
      } else {
      }
    }
  }, [newData, dataExtracts, attachment]);

  const onCancel = () => {
    if (isDataChages) {
      if (window.confirm('Bestätigen Sie die Aktion auf der Seite. Die Änderungen zurücksetzen?')) {
        setDataChage(false);
        navigate('/extracts');
      }
    } else {
      navigate('/extracts');
    }
  };
  const onSubmit = useCallback((data) => {
    patchManufacturerExtractsId(id, {
      active: isAvailable,
      ...data,
    });

    setDataChage(false);
    navigate('/extracts');
  }, [isAvailable]);

  const onChangeStatus = () => {
    setIsAvailable((prev) => !prev);
  };

  const onAttachmentHandler = (file) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', typeUploadFiles);

    postUploads(formData);
  };

  const onDeleteAttachmentHandler = (key, value) => {
    setAttachment({ ...attachment, [key]: null });
    deleteUpload({ fileIds: [value] });
    patchManufacturerExtractsId(id, { [key]: null });
  };

  useEffect(() => {
    getManufacturerExtractsId(id);
    setDataChage(false);
  }, []);

  useEffect(() => {
    if (dataUpload) {
      const emptyKey = Object.keys(attachment).find((key) => attachment[key] === null);

      setAttachment({ ...attachment, [emptyKey]: dataUpload });
      patchManufacturerExtractsId(id, { [emptyKey]: dataUpload });
      resetUploadData();
    }
  }, [dataUpload]);

  useEffect(() => {
    if (dataExtracts)
      setAttachment({
        mainImageKey: dataExtracts.imageKey,
        imageKey1: dataExtracts.imageKey1,
        imageKey2: dataExtracts.imageKey2,
        imageKey3: dataExtracts.imageKey3,
        imageKey4: dataExtracts.imageKey4,
      });
  }, [dataExtracts]);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  const isStatusChanged = isAvailable !== dataExtracts?.active;

  return (
    <>
      <Header />
      <main className={`product-edit-page page ${isShow ? 'show' : ''}`}>
        <div className="container">
          <LinkBack onEvent={onCancel} title="Zurück zu Extrakten" />
          {!isShowLoader && (
            <Form
              defaultValues={
                !dataExtracts?.exclusive
                  ? {
                      minOrderAmount: dataExtracts?.minOrderAmount || 0,
                      maxOrderAmount: dataExtracts?.maxOrderAmount || 0,
                      availableAmount: dataExtracts?.availableAmount || 0,
                    }
                  : {
                      name: dataExtracts?.name,
                      minOrderAmount: dataExtracts?.minOrderAmount || 0,
                      maxOrderAmount: dataExtracts?.maxOrderAmount || 0,
                      availableAmount: dataExtracts?.availableAmount || 0,
                      description: dataExtracts?.description,
                    }
              }
              className="add-edit product-form"
              onChange={(data) => setNewData(data)}
              onSubmit={onSubmit}
              resolver={yupResolver(dataExtracts?.exclusive ? ProductEditSchemaEx : ProductEditSchema)}>
              <div className="left-side">
                <section className="section-wrapper">
                  <h2 className="title">Produktinformationen</h2>
                  <ul className="field-list-one">
                    <li>
                      {dataExtracts?.exclusive && <FormInput name="name" type="text" label="Produktname" />}
                      <Switcher
                        label="Status aktiv"
                        name="active"
                        isChecked={!!isAvailable}
                        onChange={onChangeStatus}
                      />
                    </li>
                    <li>
                      <FormInput
                        name="minOrderAmount"
                        type="number"
                        label="Mindestbestellmenge (Verpackungseinheit) (g)"
                      />
                      <FormInput name="maxOrderAmount" type="number" label="Maximalbestellmenge pro Apotheke (g)" />
                      <FormInput name="availableAmount" type="number" label="Restmenge (aktuell lieferbar) (g)" />
                    </li>
                    {dataExtracts?.exclusive && (
                      <li>
                        <FormTextarea label="Produktbeschreibung" name="description" />
                      </li>
                    )}
                  </ul>
                  {dataExtracts?.exclusive && (
                    <>
                      <div className="title-wrapper">
                        <h2 className="title">Bilder</h2>
                        <span>{`${attachmentLength}/5`}</span>
                      </div>
                      <ul
                        className={
                          Object.keys(attachment).some((key) => attachment[key])
                            ? `bud-product-images-wrapper`
                            : `bud-product-images-wrapper empty`
                        }>
                        {Object.keys(attachment).map((key, index) => {
                          const value = attachment[key];

                          return (
                            <li
                              key={index}
                              className={
                                value ? (index === 0 ? 'product-image-main' : 'product-image') : 'empty-image'
                              }>
                              {index === 0 && value && <sup>Hauptfoto</sup>}
                              {index !== 0 && value && <sup>Zusätzlichs</sup>}
                              {value && <img src={`https://${variable.url}/uploads/${value}`} alt="" />}
                              {value && (
                                <ButtonClear
                                  className="product-image-delete"
                                  onEvent={() => onDeleteAttachmentHandler(key, value)}
                                />
                              )}
                            </li>
                          );
                        })}
                      </ul>
                      <ul>
                        <li>
                          <UploadImgDragDrop
                            title="Bilder hochladen"
                            type="one"
                            onChange={onAttachmentHandler}
                            disabled={attachmentLength === 5}
                            textImageSize="150x150px"
                            status={statusUpload !== 'pending'}
                          />
                        </li>
                      </ul>
                    </>
                  )}
                </section>
                <section className="section-buttons">
                  <Button title="Abbrechen und zurück" onEvent={onCancel} width="201" color="transparent" />
                  <Button
                    disabled={!(isDataChages || isStatusChanged)}
                    title="Speichern und weiter"
                    type="submit"
                    width="201"
                    color="green"
                  />
                </section>
                <section className="section-wrapper">
                  <h2 className="title">Produktinformation</h2>
                  <ul className="field-list-two">
                    {Object.keys(getProductInfo(dataExtracts)).map((key, index) => {
                      const value = getProductInfo(dataExtracts)[key];
                      return (
                        <li key={index} className="list-item">
                          <span className="list-name">{key}</span>
                          <span className="between-text-dots"></span>
                          {key === 'beschreibung' ? (
                            <span className="list-value " dangerouslySetInnerHTML={getSanitizedData(value)}></span>
                          ) : (
                            <span className="list-value">{value}</span>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </section>
              </div>
              <aside className="right-side">
                <div>
                  <ul className="field-list-three">
                    <li>
                      <img
                        src={
                          dataExtracts?.companyImageKey &&
                          `https://${variable.url}/uploads/${dataExtracts?.companyImageKey}`
                        }
                        alt=""
                      />
                      <div>
                        <h4>{dataExtracts?.cultivars.join(', ')}</h4>
                        <sup>{dataExtracts?.companyName}</sup>
                      </div>
                    </li>
                    <li>
                      <img
                        src={
                          attachment?.mainImageKey
                            ? `https://${variable.url}/uploads/${attachment.mainImageKey}`
                            : defaultExtractImg
                        }
                        alt=""
                      />
                    </li>
                    <li>
                      <p className={`bud-title ${dataExtracts?.exclusive ? 'exclusive-star' : ''}`}>
                        {dataExtracts?.name}
                      </p>
                      <p>{dataExtracts?.genetics}</p>
                    </li>
                  </ul>
                </div>
              </aside>
            </Form>
          )}
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default ManufacturerExtractEdit;

export const getStatusProductOrder = {
  en: (data) => {
    let status = '';

    switch (data) {
      case 'offen':
        status = 'opened';
        break;
      case 'bestätigt':
        status = 'approved';
        break;
      case 'versendet':
        status = 'sent';
        break;
      case 'neu':
        status = 'new';
        break;
      case 'abgelehnt':
        status = 'declined';
        break;
      case 'gesperrt':
        status = 'closed';
        break;
      default:
        status = '';
    }

    return status;
  },

  de: (data) => {
    let status = '';

    switch (data) {
      case 'opened':
        status = 'offen';
        break;
      case 'approved':
        status = 'bestätigt';
        break;
      case 'sent':
        status = 'versendet';
        break;
      case 'declined':
        status = 'abgelehnt';
        break;
      case 'new':
        status = 'neu';
        break;
      case 'closed':
        status = 'gesperrt';
        break;
      default:
        status = '';
    }

    return status;
  },
};

export const getFormatedPharmacieData = (dataPharmacie, isSameAddresses) => {
  return isSameAddresses
    ? {
      name: dataPharmacie?.name,
      billingZip: dataPharmacie?.billingAddress?.zip,
      billingCity: dataPharmacie?.billingAddress?.city,
      billingStreet: dataPharmacie?.billingAddress?.street,
      billingHouse: dataPharmacie?.billingAddress?.house,
      deliveryZip: dataPharmacie?.billingAddress?.zip,
      deliveryCity: dataPharmacie?.billingAddress?.city,
      deliveryStreet: dataPharmacie?.billingAddress?.street,
      deliveryHouse: dataPharmacie?.billingAddress?.house,
      contactName: dataPharmacie?.contactName,
      contactEmail: dataPharmacie?.contactEmail,
      contactPhone: dataPharmacie?.contactPhone,
      contactWeb: dataPharmacie?.contactWeb,
      contactFax: dataPharmacie?.contactFax,
      contactAddress: dataPharmacie?.contactAddress,
      docCheckId: dataPharmacie?.docCheckId,
    }
    : {
      name: dataPharmacie?.name,
      billingZip: dataPharmacie?.billingAddress?.zip,
      billingCity: dataPharmacie?.billingAddress?.city,
      billingStreet: dataPharmacie?.billingAddress?.street,
      billingHouse: dataPharmacie?.billingAddress?.house,
      deliveryZip: dataPharmacie?.deliveryAddress?.zip,
      deliveryCity: dataPharmacie?.deliveryAddress?.city,
      deliveryStreet: dataPharmacie?.deliveryAddress?.street,
      deliveryHouse: dataPharmacie?.deliveryAddress?.house,
      contactName: dataPharmacie?.contactName,
      contactEmail: dataPharmacie?.contactEmail,
      contactPhone: dataPharmacie?.contactPhone,
      contactWeb: dataPharmacie?.contactWeb,
      contactFax: dataPharmacie?.contactFax,
      contactAddress: dataPharmacie?.contactAddress,
      docCheckId: dataPharmacie?.docCheckId,
    };
};

export const state = {
  getMailTemplatesID: {
    data: (state) => state.request.mailTemplates.getMailTemplatesID.data,
    status: (state) => state.request.mailTemplates.getMailTemplatesID.status,
    error: (state) => state.request.mailTemplates.getMailTemplatesID.error,
  },

  putMailTemplatesID: {
    data: (state) => state.request.mailTemplates.putMailTemplatesID.data,
    status: (state) => state.request.mailTemplates.putMailTemplatesID.status,
    error: (state) => state.request.mailTemplates.putMailTemplatesID.error,
  },

  getMailTemplates: {
    data: (state) => state.request.mailTemplates.getMailTemplates.data,
    status: (state) => state.request.mailTemplates.getMailTemplates.status,
    error: (state) => state.request.mailTemplates.getMailTemplates.error,
  },
};

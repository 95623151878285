export const arrayDropdown = {
  full: [
    { id: 1, name: 'offen' },
    { id: 2, name: 'bestätigt' },
    { id: 3, name: 'versendet' },
    { id: 4, name: 'abgelehnt' },
  ],
  approved: [
    { id: 3, name: 'versendet' },
    { id: 4, name: 'abgelehnt' },
  ],
};

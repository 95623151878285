import React from 'react';

import './style.scss';

const Field = ({
  label,
  name,
  type,
  value,
  placeholder,
  error,
  onEvent,
  onEvent2,
  disabled,
  required,
  className = '',
  maxLength,
}) => {
  return (
    <p className={`default-field ${error ? 'error' : ''} ${className}`}>
      {label && (
        <label className="label">
          {label}
          {required && <sup>*</sup>}
        </label>
      )}
      <input
        className="input"
        type={type}
        name={name}
        value={value || ''}
        placeholder={placeholder}
        onChange={onEvent}
        onKeyDown={onEvent2}
        disabled={disabled}
        maxLength={maxLength}
      />
      {error && <span className="text-error">{error}</span>}
    </p>
  );
};

export default Field;

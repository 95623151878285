import Cookies from 'js-cookie';

import * as variable from '../../variables';

export const extracts = {
  getExtractsAll: (manufacturerId) => {
    const accessToken = Cookies.get('accessToken');
    const url = manufacturerId
      ? `https://${variable.url}/admin/extracts/all/${manufacturerId}`
      : `https://${variable.url}/admin/extracts/all`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getExtractId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/extracts/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  patchExtractId: (id, data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/extracts/${id}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteExtractId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/extracts/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getExtracts: (start, limit, search, sort, order) => {
    const searchParam = search ? `&search=${search}` : '';

    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/extracts?start=${start}&limit=${limit}${searchParam}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  postExtracts: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/extracts`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteExtracts: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/extracts`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import * as action from '../../store/action';
import * as reduce from '../../store/reducer';
import { variables } from './variables';

import './style.scss';

import COTLogo from '../../assets/images/C_TRAX_w.svg';

import UserInfo from './userInfo/UserInfo';
import MobileNav from '../mobileNav';

import * as reducer from '../../store/reducer';
import { useMemo } from 'react';
import CartInfo from '../cartInfo';
import { useBeforeLeavePage } from '../../hooks/useBeforeLeavePage';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isOpenCart, setIsOpenCart] = useState(false);

  const dataUser = useSelector(reducer.request.manufacturer.user.state.getManufacturer.data);
  const dataSetting = useSelector(reducer.request.setting.state.getSettings.data);
  const isDataChages = useSelector(reducer.app.common.state.isDataChages);
  const dataPharmacie = useSelector(reducer.request.pharmacie.user.state.getPharmaciesUser.data);

  const getUserData = () => dispatch(action.request.manufacturer.user.getManufacturer.get());
  const setDataChage = (isChange) => dispatch(action.app.common.isDataChages(isChange));
  const getSettings = () => dispatch(action.request.settings.getSettings.get());
  const getLogout = () => dispatch(action.request.auth.getLogout.get(navigate));

  const role = useSelector(reducer.app.common.state.role);
  const cart = useSelector(reducer.app.cart.state.items);
  const prescription = useSelector(reducer.app.prescription.state.items);

  const totalCartItems = useMemo(() => Object.keys(cart).reduce((acc, key) => acc + cart[key].length, 0), [cart]);
  const totalPrescriptionItems = prescription.length;

  useBeforeLeavePage(isDataChages);

  const logo = {
    admin: dataSetting?.logo,
    doctor: dataSetting?.doctorLogo,
    manufacturer: dataUser?.logo || dataSetting?.manufacturerLogo,
    pharmacy: dataPharmacie?.logo || dataSetting?.pharmacyLogo,
    disactivated_pharmacy: dataSetting?.pharmacyLogo,
  };

  const userName= {
    admin: 'Superuser',
    doctor: 'Verordnende',
    manufacturer: dataUser?.name||'Lieferant',
    pharmacy: dataPharmacie?.name||'Pharmacy',
    disactivated_pharmacy: 'Disactivated_pharmacy',
  }

  const handleLink = (e) => {
    if (role === 'disactivated_pharmacy') e.preventDefault();
    if (isDataChages) {
      window.confirm('Bestätigen Sie die Aktion auf der Seite. Die Änderungen zurücksetzen?')
        ? setDataChage(false)
        : e.preventDefault();
    }
  };

  const onExit = useCallback(() => {
    role && getLogout();
  }, [role]);

  useEffect(() => {
    role !== 'disactivated_pharmacy' && getSettings();
    role === 'manufacturer' && getUserData();
  }, []);

  return (
    <header className="main-header">
      <div className="container">
        <nav className="site-nav">
          <Link onClick={handleLink} className="logo" to="/dashboard">
            <img src={COTLogo} alt="" />
          </Link>

          <ul className={`nav ${role === 'disactivated_pharmacy' ? 'disabled' : ''}`}>
            {variables[role]?.navLinks.map((item) => (
              <li className="nav-item" key={item.id}>
                <NavLink onClick={handleLink} className="nav-link" to={`/${item.link}`} key={item.id}>
                  {item.title}
                </NavLink>
              </li>
            ))}
          </ul>
          {(role === 'disactivated_pharmacy' || role === 'pharmacy') && (
            <button
              disabled={!totalCartItems || pathname.includes('/pre-order')}
              onClick={() => (!!totalCartItems || !pathname.includes('/pre-order')) && setIsOpenCart(!isOpenCart)}
              className={`nav-link cart-button ${
                !totalCartItems || pathname.includes('/pre-order') ? 'disabled' : ''
              }`}>
              Warenkorb
              {!!totalCartItems && <span className="cart-total-items">{totalCartItems}</span>}
            </button>
          )}
          {role === 'doctor' && (
            <button
            
            // disabled={!totalPrescriptionItems || pathname.includes('/prescription')}
              onClick={() =>
                (!!totalPrescriptionItems || !pathname.includes('/prescription')) && navigate('/prescription')
              }
              className={`nav-link cart-button prescription ${
                !totalPrescriptionItems || pathname.includes('/prescription') ? 'disabled' : ''
              }`}>
              Rezeptkonfigurator
              {!!totalPrescriptionItems && <span className="cart-total-items">{totalPrescriptionItems}</span>}
            </button>
          )}
        </nav>

        <CartInfo isOpenCart={isOpenCart} setIsOpenCart={setIsOpenCart} />

        <UserInfo logo={logo[role]} title={userName[role]} role={role} onEvent={onExit} handleLink={handleLink} />

        <MobileNav
          logo={logo[role]}
          listNav={variables[role]?.navLinks}
          role={role}
          onEvent={onExit}
          handleLink={handleLink}
        />
      </div>
    </header>
  );
};

export default Header;

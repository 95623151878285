export const variables = {
  admin: {
    navLinks: [
      {
        id: 1,
        title: 'Blüten',
        link: 'buds',
      },
      {
        id: 2,
        title: 'Extrakte',
        link: 'extracts',
      },
      {
        id: 3,
        title: 'Lieferanten',
        link: 'manufacturers',
      },
      {
        id: 4,
        title: 'Apotheken',
        link: 'pharmacies',
      },
      {
        id: 5,
        title: 'Bestellanfragen',
        link: 'orders',
      },
    ],
  },

  manufacturer: {
    navLinks: [
      {
        id: 1,
        title: 'Meine Blüten',
        link: 'buds',
      },
      {
        id: 2,
        title: 'Extrakte',
        link: 'extracts',
      },
      {
        id: 3,
        title: 'Bestellanfragen',
        link: 'orders',
      },
    ],
  },

  pharmacy: {
    navLinks: [
      {
        id: 1,
        title: 'Blüten',
        link: 'buds',
      },
      {
        id: 2,
        title: 'Extrakte',
        link: 'extracts',
      },
      {
        id: 3,
        title: 'Bestellanfragen',
        link: 'orders',
      },
    ],
  },

  disactivated_pharmacy: {
    navLinks: [
      {
        id: 1,
        title: 'Blüten',
        link: 'buds',
      },
      {
        id: 2,
        title: 'Extrakte',
        link: 'extracts',
      },
      {
        id: 3,
        title: 'Bestellanfragen',
        link: 'orders',
      },
    ],
  },

  doctor: {
    navLinks: [
      {
        id: 1,
        title: 'Blüten',
        link: 'buds',
      },
      {
        id: 2,
        title: 'Extrakte',
        link: 'extracts',
      },
    ],
  },
};

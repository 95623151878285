import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useChangeEffect, useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import Form from '../../../components/UI/form/Form.jsx';
import Button from '../../../components/UI/button';
import FormInput from '../../../components/UI/form/formInput/FormInput';
import Checkbox from '../../../components/UI/checkbox';
import PrescriptionItem from './PrescriptionItem';
import PrintPrescription from './PrintPrescription';
import PrescriptionPrint from './prescriptionPrint';
import Loader from '../../../components/popups/loader';
import { useLayoutEffect } from 'react';
import Dropdown2 from '../../../components/UI/dropdown2';
import DeletePopup from '../../../components/popups/deletePopup';

const DoctorPrescription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const leftColumnRef = useRef();
  const prescriptionRef = useRef();
  const groupBtnRef = useRef();
  const clearRef = useRef();
  const printPrescriptionRef = useRef();
  const isShow = useDelay(100);
  usePageUp();

  const countryConfig = [{ id: 1, name: 'Deutschland' }];

  const savePatientInfo = (data) => dispatch(action.app.prescription.savePatientInfo(data));
  const clearPatientInfo = (isSaveDoctorId) => dispatch(action.app.prescription.clearPatientInfo(isSaveDoctorId));
  const prescriptionData = useSelector(reducer.app.prescription.state.patientInfo);

  const handleClearRedirect = () => {
    dispatch(action.app.prescription.removeAllProducts());
    navigate('/dashboard');
  }

  const [height, setHeight] = useState({
    leftColumn: 0,
    prescription: 0,
  });

  const [isSaveDoctorId, setIsSaveDoctorId] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [maxProducts, setMaxProducts] = useState(1);
  const [isAdult, setIsAdult] = useState(true);
  const [isShowLoader, setShowLoader] = useState(true);
  const [country, setCountry] = useState();
  const [isShowPopUp, setIsShowPopUp] = useState(false)

  const prescriptionItems = useSelector(reducer.app.prescription.state.items);

  const dosageStatus = useSelector(reducer.request.dosage.state.getDosagesAll.status);
  const formStatus = useSelector(reducer.request.form.state.getFormsAll.status);
  const getsDosages = () => dispatch(action.request.dosages.getDosagesAll.get());
  const getForms = () => dispatch(action.request.forms.getFormsAll.get());

  const isRequestGETSuccess = dosageStatus === 'success' && formStatus === 'success';

  const clearPatientInfoHandler = useCallback(
    (methods) => {
      if (isSaveDoctorId) {
        methods.reset({
          patientDay: '',
          patientMonth: '',
          patientYear: '',
          patientInsurance: '',
          patientName: '',
          patientSurname: '',
          patientZip: '',
          patientCity: '',
          patientStreet: '',
          patientHouse: '',
          taxId: '',
          insuredId: '',
          status: '',
          businessPremisesId: '',
        });
        methods.setValue('doctorId', prescriptionData?.doctorId);
      } else {
        methods.reset({
          patientDay: '',
          patientMonth: '',
          patientYear: '',
          patientInsurance: '',
          patientName: '',
          patientSurname: '',
          patientZip: '',
          patientCity: '',
          patientStreet: '',
          patientHouse: '',
          taxId: '',
          insuredId: '',
          status: '',
          businessPremisesId: '',
          doctorId: '',
        });
      }
      setCountry();
      clearPatientInfo(isSaveDoctorId);
    },
    [isSaveDoctorId, prescriptionData?.doctorId],
  );

  const onCopyPrescription = () => {
    try {
      navigator.clipboard.writeText(prescription);
      toast.success('Rezept kopiert');
    } catch {
      toast.error('Kopieren fehlgeschlagen');
    }
  };

  const onPrint = useReactToPrint({
    content: () => printPrescriptionRef.current,
  });

  const prescription = useMemo(() => {
    return prescriptionItems
      .slice(0, maxProducts)
      .map((item) => {
        let result = '';
        const concentration = parseFloat(item.concentration);

        if (item.type === 'bud') {
          result += `Cannabisblüten ${item.name}, ${item.amount}g, `;
          result += item.amount > 100 ? '"A", ' : '';
          result += item.form ? `${item.form.receipe}, ` : '';
          result +=
            item.dosage?.dosageFields
              ?.map((field) => (field.prefix ?? '') + field.input + (field.sufix ?? ''))
              ?.join('') ?? '';

          return result;
        }

        if (!item.prescription) return result;

        for (const prescriptionItem of item.prescription) {
          switch (prescriptionItem.title) {
            case 'Produktname':
              result += item.name + ' ';
              break;
            case 'Verschriebene Menge':
              result += item.amount + ' ';
              break;
            case 'Wirkstoffmenge basierend auf Multiplikator':
              result += (item.amount * prescriptionItem.value).toFixed(2) + ' ';
              break;
            case 'Gesamtmenge "THC"':
              result +=
                (item.concentrationTarget
                  ? item.concentrationTarget === 'cbd'
                    ? item.amount * (item.thc / item.cbd) * concentration
                    : item.amount * concentration
                  : item.amount * item.thc
                ).toFixed(2) +
                'mg' +
                ' ';
              break;
            case 'Dosierungsanleitung':
              if (item.dosage?.dosageFields) {
                result +=
                  item.dosage.dosageFields
                    ?.map((field) => (field.prefix ?? '') + field.input + (field.sufix ?? ''))
                    .join('') + ' ';
              }
              break;
            case '"A" hinzufügen, wenn Gesamtmenge "THC" > 1000mg':
              const amount = item.concentrationTarget
                ? item.concentrationTarget === 'cbd'
                  ? item.amount * (item.thc / item.cbd) * concentration
                  : item.amount * concentration
                : item.amount * item.thc;
              result += amount > 1000 ? '"A",' : '';
              break;
            case '"," hinzufügen':
              result = result.trimEnd() + ', ';
              break;
            case 'THC per Einheit':
              result +=
                (item.concentrationTarget === 'cbd'
                  ? ((item.thc / item.cbd) * concentration).toFixed(2)
                  : concentration.toFixed(2)) + ' ';
              break;
            case 'CBD per Einheit':
              result +=
                (item.concentrationTarget === 'thc'
                  ? ((item.cbd / item.thc) * concentration).toFixed(2)
                  : concentration.toFixed(2)) + ' ';
              break;
            default:
              result += prescriptionItem.value + ' ';
              break;
          }
        }
        return result;
      })
      .filter(Boolean);
  }, [maxProducts, prescriptionItems]);

  const savePatientInfoHandler = useCallback((data) => {
    delete data.fullPrescription;
    savePatientInfo({ ...data });
  }, []);

  useEffect(() => {
    getsDosages();
    getForms();
  }, []);

  useLayoutEffect(() => {
    setHeight({
      leftColumn: leftColumnRef.current?.clientHeight,
      prescription: prescriptionRef.current?.clientHeight,
    });
  }, [dosageStatus, formStatus, prescription, showMore]);

  useEffect(() => {
    prescription ? savePatientInfo({ fullPrescription: prescription }) : savePatientInfo({ fullPrescription: null });
  }, [prescription]);

  useEffect(() => {
    if (prescriptionData?.patientDay && prescriptionData?.patientMonth && prescriptionData?.patientYear) {
      const date = new Date().getTime();
      const fullData = new Date(
        `${prescriptionData.patientMonth}/${prescriptionData.patientDay}/${prescriptionData.patientYear}`,
      ).getTime();
      const age = Math.floor(date - fullData) / 31556926000;
      setIsAdult(age >= 18);
    }
  }, [prescriptionData?.patientDay, prescriptionData?.patientMonth, prescriptionData?.patientYear]);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  return (
    <>
      <PrintPrescription
        ref={printPrescriptionRef}
        prescription={prescription}
        patientInfo={prescriptionData}
        country={country}
      />
      <Header />
      <main className={`prescription-edit-page page ${isShow ? 'show' : ''}`}>
        {!isShowLoader && (
          <div className="container">
            <Form
              defaultValues={{ ...prescriptionData }}
              className="prescription-form"
              onChange={(data) => savePatientInfoHandler(data)}
              innerRef={leftColumnRef}>
              {(methods) => (
                <>
                  <section className="section-wrapper">
                    <h2 className="title">Rezeptkonfigurator</h2>
                    <ul className="prescription-type">
                      <li className="prescription-type-title">
                        Anzahl der zu verschreibenden <br /> Produkte für dieses Rezept
                      </li>
                      <div className="btn-group">
                        {Array(3)
                          .fill(0)
                          .map((_, index) => (
                            <li
                              key={index}
                              onClick={() => setMaxProducts(index + 1)}
                              className={`prescription-type-item ${maxProducts === index + 1 ? 'selected' : ''}`}>
                              <p className="prescription-type-item-name">
                                {index + 1} {index !== 0 ? 'Produkte' : 'Produkt'}
                              </p>
                              <p className="prescription-type-item-info">
                                {index === 0 ? 'PKV/GKV Verschreibung' : 'PKV Verschreibung'}
                              </p>
                            </li>
                          ))}
                      </div>
                    </ul>
                    <ul className="prescription-patient">
                      <li className="prescription-patient-header">
                        <p className="prescription-patient-title">Patientendaten</p>
                        <button
                          onClick={() => setShowMore(!showMore)}
                          className={`prescription-patient-show-more ${showMore ? 'max' : 'min'}`}>
                          Patientendaten hinzufügen
                        </button>
                      </li>
                      <ul
                        className="prescription-patient-info"
                        style={showMore ? { maxHeight: `752px` } : { maxHeight: '0px' }}>
                        <li>
                          <FormInput name="patientInsurance" type="text" label="Krankenkasse bzw. Kostenträger" />
                        </li>
                        <li>
                          <FormInput name="patientName" type="text" label="Name" />
                          <FormInput name="patientSurname" type="text" label="Vorname" />
                        </li>
                        <li>
                          <div className="group-country">
                            <Dropdown2
                              title="Land"
                              name="country"
                              select={country || 'Wählen'}
                              setSelect={setCountry}
                              content={countryConfig}
                            />
                            <FormInput name="patientZip" type="text" label="PLZ" />
                          </div>
                          <FormInput name="patientCity" type="text" label="Stadt" />
                        </li>
                        <li>
                          <FormInput name="patientStreet" type="text" label="Straße" />
                          <FormInput name="patientHouse" type="text" label="Hausnummer" />
                        </li>
                        <li>
                          <p className="info-text">Geburtsdatum</p>
                          <div className={`input-data ${isAdult ? 'success' : 'error'}`}>
                            <FormInput name="patientDay" type="text" label="Tag" maxLength={2} />
                            <FormInput name="patientMonth" type="text" label="Monat" maxLength={2} />
                            <FormInput name="patientYear" type="text" label="Jahr" maxLength={4} />
                          </div>
                          {/* <p className={`info-text ${isAdult ? 'success' : 'error'}`}>Erst ab 18 Jahren</p> */}
                        </li>
                        <li>
                          <FormInput name="taxId" type="text" label="Kassen-Nr." maxLength={9} />
                          <FormInput name="insuredId" type="text" label="Versicherten-Nr" maxLength={9} />
                        </li>
                        <li>
                          <FormInput name="status" type="text" label="Status" maxLength={9} />
                          <FormInput name="businessPremisesId" type="text" label="Betriebsstätten-Nr" />
                        </li>
                        <li>
                          <FormInput name="doctorId" type="text" label="Arzt-Nr." />
                          <Checkbox
                            label="Arzt-Nr speichern"
                            isChecked={isSaveDoctorId}
                            onChange={() => setIsSaveDoctorId(!isSaveDoctorId)}
                          />
                        </li>
                        <li>
                          <p className="info-text">
                            Ausstellungs-
                            <br />
                            datum
                          </p>
                          <div className="input-data">
                            <FormInput name="dateDay" type="text" label="Tag" maxLength={2} />
                            <FormInput name="dateMonth" type="text" label="Monat" maxLength={2} />
                            <FormInput name="dateYear" type="text" label="Jahr" maxLength={4} />
                          </div>
                          <button
                            ref={clearRef}
                            onClick={() => clearPatientInfoHandler(methods)}
                            className="clear-form">
                            Patientendaten entfernen
                          </button>
                        </li>
                      </ul>
                    </ul>
                  </section>
                  {prescriptionItems.map((item, index) => (
                    <PrescriptionItem
                      key={item.id + item.type}
                      {...item}
                      isActive={index <= maxProducts - 1}
                      isFirst={index === 0}
                      isLast={index === prescriptionItems.length - 1}
                    />
                  ))}
                </>
              )}
            </Form>
            <aside className="right-side">
              <div className="warning-message">
                <p>
                  ACHTUNG: Bei Ausstellung eines GKV-Rezeptes darf laut Bundesmantelvertrag-Ärzte lediglich ein
                  Rezepturarzneimittel verordnet werden. Das bedeutet, dass für jede Blütensorte ein eigenes BtM-Rezept
                  ausgestellt werden sollte.
                </p>
              </div>
              <PrescriptionPrint prescriptionData={prescriptionData} ref={prescriptionRef} country={country} />
              <div className="group-btn" ref={groupBtnRef}>
              <Button title="Produkte entfernen" onEvent={() => setIsShowPopUp(true)} width="201" color="red" />
                <Button title="Angaben kopieren" onEvent={onCopyPrescription} width="201" color="gray" />
                <Button
                  title="Rezept ausdrucken"
                  onEvent={() => {
                    if (isAdult) {
                      onPrint();
                    } else {
                      toast.error('Der Patient ist nicht volljährig');
                    }
                  }}
                  width="201"
                  color="green"
                />
              </div>
            </aside>
          </div>
        )}
      </main>
      {isShowPopUp && <DeletePopup title='Sind Sie sicher?' text='' onClose={() => setIsShowPopUp(false)} onDelete={handleClearRedirect} />}
      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default DoctorPrescription;

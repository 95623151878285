import Cookies from 'js-cookie';

import * as variable from '../variables';

export const mailTemplates = {
  getMailTemplatesID: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/mail-templates/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  putMailTemplatesID: (id, data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/mail-templates/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  getMailTemplates: (start, limit, search) => {
    const accessToken = Cookies.get('accessToken');

    const searchParam = search ? `&search=${search}` : '';

    return fetch(`https://${variable.url}/mail-templates?start=${start}&limit=${limit}${searchParam}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
};

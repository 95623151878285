import React, { useState } from 'react';
import { useOnOutsideClick } from '../../../hooks';

import './style.scss';

const Dropdown3 = ({ label, className, title, select, setSelect, content, disabled }) => {
  const [isShow, setShow] = useState(false);
  const { innerBorderRef } = useOnOutsideClick(() => setShow(false));

  const onSelect = (item) => {
    setShow(false);
    setSelect({ id: item.id, name: item.name });
  };

  return (
    <section className="dropdown3" ref={innerBorderRef}>
      <div className="text-wrapper">
        <span className={`selected-text selected-text--${select?.name || select}`}>
          {select?.name || select || label}
        </span>
        {!disabled && <button className={`button-choose ${className}`} onClick={() => setShow(!isShow)}></button>}
      </div>
      {isShow && !disabled && (
        <ul className="content">
          {content?.map((item) => (
            <li className="item" key={item.id}>
              <button className="button-type" onClick={() => onSelect(item)}>
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};

export default Dropdown3;

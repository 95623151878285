import React, { useEffect } from 'react';

const usePageUp = (time) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
};

export { usePageUp };

import React from 'react';
import { useNavigate } from 'react-router-dom';

import './style.scss';

import defaultBudImg from '../../assets/images/default-bud-img.svg';
import defaultExtractImg from '../../assets/images/default-extract-img.svg';

import * as variable from '../../variables';

const ProductCard = (props) => {
  const navigate = useNavigate();

  const isAvalible = props.manufacturers.filter(
    (item) => item.available,
  ).length;

  return (
    <div className="product-card-wrapper">
      {(props.icon === 'prescription' || (props?.active && !!isAvalible)) && (
        <button
          onClick={() => props.onEvent({ ...props }, props.isAdded)}
          className={`product-card-header-add ${props.icon} ${props.isAdded ? 'added' : ''}`}
        />
      )}
      <ul onClick={() => navigate(`/${props?.type}s/${props.id}`)} className="product-card">
        <li className="product-card-header">
          <div className="product-card-info-wrapper">
            <p className="product-card-header-cultivars">{props?.cultivars.join(', ')}</p>
            <p className="product-card-header-genetics">{props?.genetics}</p>
          </div>
        </li>
        <li className="product-card-img">
          <img
            src={
              props?.imageKey
                ? `https://${variable.url}/uploads/${props?.imageKey}`
                : props?.type === 'bud'
                ? defaultBudImg
                : defaultExtractImg
            }
            alt=""
          />
        </li>
        <li className="product-card-company-img">
          <img src={props?.companyImageKey && `https://${variable.url}/uploads/${props?.companyImageKey}`} alt="" />
        </li>
        <li>
          <p className="product-card-name">{props?.name}</p>
        </li>
      </ul>
    </div>
  );
};

export default ProductCard;

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.pharmacie.buds.getPharmaciesBuds.getSuccess, ({ getPharmaciesBuds }, action) => {
      getPharmaciesBuds.status = 'success';
      getPharmaciesBuds.data = action.payload;
    })
    .addCase(action.request.pharmacie.buds.getPharmaciesBuds.getPending, ({ getPharmaciesBuds }, action) => {
      getPharmaciesBuds.status = 'pending';
    })
    .addCase(action.request.pharmacie.buds.getPharmaciesBuds.getFailed, ({ getPharmaciesBuds }, action) => {
      getPharmaciesBuds.status = 'failed';
      getPharmaciesBuds.error = action.payload;
      getPharmaciesBuds.data = null;
    });
  builder
    .addCase(action.request.pharmacie.buds.getPharmaciesBudsId.getSuccess, ({ getPharmaciesBudsId }, action) => {
      getPharmaciesBudsId.status = 'success';
      getPharmaciesBudsId.data = action.payload;
    })
    .addCase(action.request.pharmacie.buds.getPharmaciesBudsId.getPending, ({ getPharmaciesBudsId }, action) => {
      getPharmaciesBudsId.status = 'pending';
    })
    .addCase(action.request.pharmacie.buds.getPharmaciesBudsId.getFailed, ({ getPharmaciesBudsId }, action) => {
      getPharmaciesBudsId.status = 'failed';
      getPharmaciesBudsId.error = action.payload;
      getPharmaciesBudsId.data = null;
    });
});

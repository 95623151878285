import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../../store/reducer';
import * as action from '../../../../../store/action';

import { TabContent } from '../../../../../HOC';

import UploadImg from '../../../../../components/uploadImg';
import Button from '../../../../../components/UI/button';
import Textarea from '../../../../../components/UI/textarea';
import Loader from '../../../../../components/popups/loader';

const Design = ({ visible }) => {
  const dispatch = useDispatch();

  const dataSetting = useSelector(reducer.request.setting.state.getSettings.data);
  const dataUpload = useSelector(reducer.request.uploads.state.postUploads.data);

  const statusPostUpload = useSelector(reducer.request.uploads.state.postUploads.status);
  const statusPatchSettings = useSelector(reducer.request.setting.state.patchSettings.status);

  const postUploads = (data) => dispatch(action.request.uploads.postUploads.post(data));
  const patchSettings = (data) => dispatch(action.request.settings.patchSettings.patch(data));

  const [imgKey, setImgKey] = useState('');
  const [data, setData] = useState({
    budsPrescriptionAmount: 1,
    css: '',
    doctorDescription: '',
    doctorLogo: null,
    extractsPrescriptionAmount: 1,
    logo: null,
    manufacturerDescription: '',
    manufacturerLogo: null,
    pharmacyDescription: '',
    pharmacyLogo: null,
  });

  useEffect(() => {
    if (dataSetting) setData(dataSetting);
  }, [dataSetting]);

  useEffect(() => {
    if (statusPostUpload === 'success') setData({ ...data, [imgKey]: dataUpload });
  }, [statusPostUpload]);

  const onSave = () => {
    patchSettings(data);
  };

  const onUploadImg = (name, data) => {
    setImgKey(name);
    postUploads(data);
  };

  return (
    <>
      <TabContent visible={visible}>
        <div className="design-content">
          <section className="left-column">
            <div className="content">
              <Textarea
                label="Benutzerdefiniertes css"
                value={data.css}
                placeholder=""
                onChange={(event) => setData({ ...data, css: event.target.value })}
              />
            </div>

            <Button title="Speichern und weiter" width="206" color="green" onEvent={onSave} />
          </section>

          <aside className="right-column">
            <UploadImg
              title="UI Logo Apotheken"
              view="second"
              type="other"
              img={data.logo}
              onUploadImg={(data) => onUploadImg('logo', data)}
              onSelectImg={(img) => setData({ ...data, logo: img })}
            />
          </aside>
        </div>
      </TabContent>

      {visible && (statusPatchSettings === 'pending' || statusPostUpload === 'pending') && (
        <Loader type="transparent" />
      )}
    </>
  );
};

export default Design;

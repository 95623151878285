import { createAction } from '@reduxjs/toolkit';

import * as api from '../../../../../api';
import * as type from '../../../../type';
import * as action from '../../../../action';
import { toast } from 'react-toastify';

export const postManufacturers = {
  post: function (...props) {
    return (dispatch) => {
      dispatch(this.postPending());

      api.admin.manufacturers
        .postManufacturers(...props)
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          dispatch(this.postSuccess());
          toast.success('Aktion erfolgreich');
        })
        .catch((error) => {
          if (error.status === 403 || error.status === 404) {
            window.location = '/';
          }
          if (error.status === 401) {
            dispatch(action.request.auth.getRefresh.get(this.post(...props)));
          } else {
            dispatch(this.postFailed(error.statusText));
            toast.error('Aktion fehlgeschlagen');
          }
        });
    };
  },

  postSuccess: createAction(type.request.admin.manufacturers.postManufacturersSuccess),
  postPending: createAction(type.request.admin.manufacturers.postManufacturersPending),
  postFailed: createAction(type.request.admin.manufacturers.postManufacturersFailed),
};

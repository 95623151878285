export const variables = {
  dropdownContent: [
    { id: 1, title: '10' },
    { id: 2, title: '20' },
    { id: 3, title: '50' },
    { id: 4, title: '100' },
  ],

  dropdown2Content: [
    { id: 3, name: 'Alle' },
    { id: 1, name: 'Aktiv' },
    { id: 2, name: 'Inaktiv' },
  ],
};

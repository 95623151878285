import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import './style.scss';

import * as reducer from '../../../../../store/reducer';
import * as action from '../../../../../store/action';

import { TabContent } from '../../../../../HOC';
import { useChangeEffect } from '../../../../../hooks';

import AddEdit from '../../../../../components/addEdit';
import Field from '../../../../../components/UI/field';
import Checkbox from '../../../../../components/UI/checkbox';
import ButtonClear from '../../../../../components/UI/buttonClear';
import OpeningHours from '../../../../../components/openingHours';
import Loader from '../../../../../components/popups/loader';

const EditPharmacy = ({ visible, isShowLoader }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();

  const dataUpload = useSelector(reducer.request.uploads.state.postUploads.data);
  const dataGetPharmacyId = useSelector(reducer.request.admin.pharmacy.state.getPharmacyId.data);

  const statusGetPharmacyId = useSelector(reducer.request.admin.pharmacy.state.getPharmacyId.status);
  const statusPostUpload = useSelector(reducer.request.uploads.state.postUploads.status);
  const statusPatchPharmacyId = useSelector(reducer.request.admin.pharmacy.state.patchPharmacyId.status);
  const isDataChages = useSelector(reducer.app.common.state.isDataChages);

  const setDataChage = (isChange) => dispatch(action.app.common.isDataChages(isChange));
  const postUploads = (data) => dispatch(action.request.uploads.postUploads.post(data));
  const patchPharmacyId = (id, data) => dispatch(action.request.admin.pharmacies.patchPharmacyId.patch(id, data));
  const deletePharmacyId = (id) => dispatch(action.request.admin.pharmacies.deletePharmacyId.delete(id));

  const [imgKey, setImgKey] = useState(null);
  const [data, setData] = useState({
    active: true,
    archieved: false,
    billingAddress: {
      zip: '',
      city: '',
      street: '',
      house: '',
    },
    contactAddress: '',
    contactEmail: '',
    contactFax: '',
    contactName: '',
    contactPhone: null, // null | string
    contactWeb: '',
    deliveryAddress: {
      zip: '',
      city: '',
      street: '',
      house: '',
    },
    docCheckId: '',
    files: [],
    isSameAddresses: false,
    logo: null,
    name: '',
    openingHours: {
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
    },
    ordersCount: 1,
  });

  const pharmacyID = param.pharmacyID;
  const isEdit = pharmacyID;

  useEffect(() => {
    if (isEdit && dataGetPharmacyId) {
      setData(dataGetPharmacyId);
      setImgKey(dataGetPharmacyId.logo);
    }
  }, [dataGetPharmacyId]);

  useChangeEffect(() => {
    if (statusPatchPharmacyId === 'success') navigate('/pharmacies');
  }, [statusPatchPharmacyId]);

  // set value if checked the same address
  useEffect(() => {
    if (data.isSameAddresses) {
      setData({
        ...data,
        deliveryAddress: {
          zip: data.billingAddress.zip,
          city: data.billingAddress.city,
          street: data.billingAddress.street,
          house: data.billingAddress.house,
        },
      });
    }
  }, [data.isSameAddresses]);

  useEffect(() => {
    if (statusPostUpload === 'success') setData({ ...data, [imgKey]: dataUpload });
  }, [statusPostUpload]);

  const onSave = () => {
    const active = data.active ? {} : { active: true };

    if (dataGetPharmacyId?.archieved) {
      patchPharmacyId(pharmacyID, { ...data, files: data.files.map((file) => file.key), ...active, archieved: false });
    } else {
      if (isEdit) patchPharmacyId(pharmacyID, { ...data, files: data.files.map((file) => file.key), ...active });
    }

    setDataChage(false);
  };

  const onCancel = () => {
    if (isDataChages) {
      if (window.confirm('Profil dauerhaft entfernen und es verschwindet aus der Archivliste. Fortsetzen?'))
        navigate('/pharmacies', { state: { tab: dataGetPharmacyId?.archieved ? 2 : 1 } });
    } else {
      navigate('/pharmacies', { state: { tab: dataGetPharmacyId?.archieved ? 2 : 1 } });
    }
  };

  const onArchive = () => {
    if (!dataGetPharmacyId?.archieved) {
      if (isEdit)
        window.confirm('Profil in die Liste der archivierten Profile verschieben?') &&
          patchPharmacyId(pharmacyID, { ...data, files: data.files.map((file) => file.key), archieved: true });
    } else {
      if (window.confirm('Profil dauerhaft entfernen und es verschwindet aus der Archivliste. Fortsetzen?')) {
        deletePharmacyId(pharmacyID);
        navigate('/pharmacies', { state: { tab: 2 } });
      }
    }
  };

  const onSetBillingAddress = (event, isSame) => {
    const { value, name } = event.target;
    let newData = {};

    if (isSame) {
      newData = {
        ...data,
        billingAddress: { ...data.billingAddress, [name]: value },
        deliveryAddress: { ...data.billingAddress, [name]: value },
      };
    } else {
      newData = { ...data, billingAddress: { ...data.billingAddress, [name]: value } };
    }

    setData(newData);
  };

  const onUploadImg = (name, data) => {
    setImgKey(name);
    postUploads(data);
  };

  useEffect(() => {
    if (statusGetPharmacyId === 'success' && data.id) {
      if (imgKey !== dataGetPharmacyId?.logo) {
        setDataChage(true);
        return;
      } else if (!_.isEqual(data, dataGetPharmacyId)) {
        setDataChage(true);
        console.log(data, dataGetPharmacyId, 'data, dataGetPharmacyId true')
        return;
      } else {
        console.log(data, dataGetPharmacyId, 'data, dataGetPharmacyId false')
        setDataChage(false);
      }
    }
  }, [statusGetPharmacyId, imgKey, data]);


  return (
    <>
      <TabContent visible={visible}>
        <AddEdit
          className='pharmacy'
          type='pharmacy'
          data={data}
          setData={setData}
          textSave={data.active ? 'Speichern und weiter' : 'Wiederherstellen'}
          onSave={onSave}
          onCancel={onCancel}
          textDelete={dataGetPharmacyId?.archieved ? 'Löschen' : 'Archivieren'}
          onDelete={onArchive}
          onUploadImg={onUploadImg}
          isSaveButtonDisabled={!dataGetPharmacyId?.archieved && !isDataChages && data.active}
        >
          <section className='info-section'>
            <h2 className='title'>Bearbeiten - Stammdaten Apotheke</h2>

            <ul className='field-list-one'>
              <li>
                <Field
                  label='Name'
                  type='text'
                  value={data.name}
                  onEvent={(event) => setData({ ...data, name: event.target.value })}
                />
              </li>
              <li>
                <Field
                  label='PLZ'
                  type='text'
                  name='zip'
                  value={data.billingAddress.zip}
                  onEvent={(event) => onSetBillingAddress(event, data.isSameAddresses)}
                />
              </li>
              <li>
                <Field
                  label='Stadt'
                  type='text'
                  name='city'
                  value={data.billingAddress.city}
                  onEvent={(event) => onSetBillingAddress(event, data.isSameAddresses)}
                />
              </li>
              <li>
                <Field
                  label='Straße'
                  type='text'
                  name='street'
                  value={data.billingAddress.street}
                  onEvent={(event) => onSetBillingAddress(event, data.isSameAddresses)}
                />
              </li>
              <li>
                <Field
                  label='Hausnummer'
                  type='text'
                  name='house'
                  value={data.billingAddress.house}
                  onEvent={(event) => onSetBillingAddress(event, data.isSameAddresses)}
                />
              </li>
              <li>
                <Checkbox
                  label='Rechnungs- und Lieferadresse sind gleich'
                  isChecked={data.isSameAddresses}
                  onChange={() => setData({ ...data, isSameAddresses: !data.isSameAddresses })}
                />
              </li>
            </ul>

            {!data.isSameAddresses && (
              <div className='shipping-address'>
                <p className='title'>Lieferadresse</p>
                <ul className='list'>
                  <li>
                    <Field
                      label='PLZ'
                      type='text'
                      value={data.deliveryAddress.zip}
                      onEvent={(event) => setData({ ...data, deliveryAddress: { ...data.deliveryAddress, zip: event.target.value } })}
                    />
                  </li>
                  <li>
                    <Field
                      label='Stadt'
                      type='text'
                      value={data.deliveryAddress.city}
                      onEvent={(event) => setData({ ...data, deliveryAddress: { ...data.deliveryAddress, city: event.target.value } })}
                    />
                  </li>
                  <li>
                    <Field
                      label='Straße'
                      type='text'
                      value={data.deliveryAddress.street}
                      onEvent={(event) => setData({ ...data, deliveryAddress: { ...data.deliveryAddress, street: event.target.value } })}
                    />
                  </li>
                  <li>
                    <Field
                      label='Hausnummer'
                      type='text'
                      value={data.deliveryAddress.house}
                      onEvent={(event) => setData({ ...data, deliveryAddress: { ...data.deliveryAddress, house: event.target.value } })}
                    />
                  </li>
                </ul>
              </div>
            )}
          </section>

          <section className='info-section'>
            <h2 className='title'>Ansprechpartner</h2>

            <ul className='field-list-two'>
              <li>
                <Field
                  label='Name'
                  type='text'
                  value={data.contactName}
                  onEvent={(event) => setData({ ...data, contactName: event.target.value })}
                />
              </li>
              <li>
                <Field
                  label='E-Mail'
                  type='text'
                  value={data.contactEmail}
                  onEvent={(event) => setData({ ...data, contactEmail: event.target.value })}
                />
              </li>
              <li>
                <Field
                  label='Telefon nr.'
                  type='text'
                  value={data.contactPhone || ''}
                  onEvent={(event) => setData({ ...data, contactPhone: event.target.value })}
                />
              </li>
              <li>
                <Field
                  label='Fax-Nr.'
                  type='text'
                  value={data.contactFax}
                  onEvent={(event) => setData({ ...data, contactFax: event.target.value })}
                />
              </li>
              <li>
                <Field
                  label='Web'
                  type='text'
                  value={data.contactWeb}
                  onEvent={(event) => setData({ ...data, contactWeb: event.target.value })}
                />
              </li>
            </ul>
          </section>

          <section className='info-section border-0'>
            <h2 className='title'>Informationen</h2>
            <ul className='field-list-three'>
              <li>
                <OpeningHours
                  data={data}
                  setData={setData}
                />
              </li>

              <li>
                {!!data.files.length && (
                  <section className='pdf-section'>
                    <h3 className='title'>Dateien</h3>

                    <ul className='list-pdf'>
                      {data.files.map((item) => (
                        <li className='item-pdf'>
                          {item.name}
                          <ButtonClear onEvent={() => { }} />
                        </li>
                      ))}
                    </ul>
                  </section>
                )}
              </li>

              <li>
                <Field
                  label='DocCheck Token ID'
                  type='text'
                  value={data.docCheckId}
                  disabled={true}
                  onEvent={(event) => setData({ ...data, docCheckId: event.target.value })}
                />
              </li>
            </ul>
          </section>
        </AddEdit>
      </TabContent>

      {!isShowLoader && statusPatchPharmacyId === 'pending' && <Loader type='transparent' />}
    </>
  );
};

export default EditPharmacy;

import Cookies from 'js-cookie';

import * as variable from '../variables';

export const dosages = {
  getDosagesAll: () => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/dosages/all`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getDosageId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/dosages/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  putDosageId: (id, data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/dosages/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteDosageId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/dosages/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getDosages: (start, limit, type) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/dosages?start=${start}&limit=${limit}&type=${type}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  postDosages: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/dosages`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

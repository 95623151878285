import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useDelay } from '../../../../hooks';

import './style.scss';

import Button from '../../../../components/UI/button';
import Header from '../../../../components/header';

const ApproveAwait = () => {
  const navigate = useNavigate();
  const isShow = useDelay(100);

  return (
    <>
      <Header />
      <main className={`profile-edit-page page pharmacie-settings-approve ${isShow ? 'show' : ''}`}>
        <section className="section-wrapper">
          <h1 className="title">Ihre Kontaktdaten waren erfolgreich hinzugefügt</h1>
          <p className="under-title">Bitte warten Sie, bis Ihr Profil freigegeben wird</p>
          <div className="group-btn">
            <Button title="Information bearbeiten" onEvent={() => navigate('/disactivated-settings')} width="201" color="green" />
          </div>
        </section>
      </main>
    </>
  );
};

export default ApproveAwait;

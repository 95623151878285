import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.admin.pharmacies.getPharmaciesAll.getSuccess, ({ getPharmaciesAll }, action) => {
      getPharmaciesAll.status = 'success';
      getPharmaciesAll.data = action.payload;
    })
    .addCase(action.request.admin.pharmacies.getPharmaciesAll.getPending, ({ getPharmaciesAll }, action) => {
      getPharmaciesAll.status = 'pending';
    })
    .addCase(action.request.admin.pharmacies.getPharmaciesAll.getFailed, ({ getPharmaciesAll }, action) => {
      getPharmaciesAll.status = 'failed';
      getPharmaciesAll.error = action.payload;
      getPharmaciesAll.data = null;
    });

  builder
    .addCase(action.request.admin.pharmacies.getPharmacyId.getSuccess, ({ getPharmacyId }, action) => {
      getPharmacyId.status = 'success';
      getPharmacyId.data = action.payload;
    })
    .addCase(action.request.admin.pharmacies.getPharmacyId.getPending, ({ getPharmacyId }, action) => {
      getPharmacyId.status = 'pending';
    })
    .addCase(action.request.admin.pharmacies.getPharmacyId.getFailed, ({ getPharmacyId }, action) => {
      getPharmacyId.status = 'failed';
      getPharmacyId.error = action.payload;
      getPharmacyId.data = null;
    });

  builder
    .addCase(action.request.admin.pharmacies.patchPharmacyId.patchSuccess, ({ patchPharmacyId }, action) => {
      patchPharmacyId.status = 'success';
      patchPharmacyId.data = action.payload;
    })
    .addCase(action.request.admin.pharmacies.patchPharmacyId.patchPending, ({ patchPharmacyId }, action) => {
      patchPharmacyId.status = 'pending';
    })
    .addCase(action.request.admin.pharmacies.patchPharmacyId.patchFailed, ({ patchPharmacyId }, action) => {
      patchPharmacyId.status = 'failed';
      patchPharmacyId.error = action.payload;
      patchPharmacyId.data = null;
    });

  builder
    .addCase(action.request.admin.pharmacies.deletePharmacyId.deleteSuccess, ({ deletePharmacyId }, action) => {
      deletePharmacyId.status = 'success';
      deletePharmacyId.data = action.payload;
    })
    .addCase(action.request.admin.pharmacies.deletePharmacyId.deletePending, ({ deletePharmacyId }, action) => {
      deletePharmacyId.status = 'pending';
    })
    .addCase(action.request.admin.pharmacies.deletePharmacyId.deleteFailed, ({ deletePharmacyId }, action) => {
      deletePharmacyId.status = 'failed';
      deletePharmacyId.error = action.payload;
      deletePharmacyId.data = null;
    });

  builder
    .addCase(action.request.admin.pharmacies.getPharmacies.getSuccess, ({ getPharmacies }, action) => {
      getPharmacies.status = 'success';
      getPharmacies.data = action.payload;
    })
    .addCase(action.request.admin.pharmacies.getPharmacies.getPending, ({ getPharmacies }, action) => {
      getPharmacies.status = 'pending';
    })
    .addCase(action.request.admin.pharmacies.getPharmacies.getFailed, ({ getPharmacies }, action) => {
      getPharmacies.status = 'failed';
      getPharmacies.error = action.payload;
      getPharmacies.data = null;
    });

  builder
    .addCase(action.request.admin.pharmacies.deletePharmacies.deleteSuccess, ({ deletePharmacies }, action) => {
      deletePharmacies.status = 'success';
      deletePharmacies.data = action.payload;
    })
    .addCase(action.request.admin.pharmacies.deletePharmacies.deletePending, ({ deletePharmacies }, action) => {
      deletePharmacies.status = 'pending';
    })
    .addCase(action.request.admin.pharmacies.deletePharmacies.deleteFailed, ({ deletePharmacies }, action) => {
      deletePharmacies.status = 'failed';
      deletePharmacies.error = action.payload;
      deletePharmacies.data = null;
    });

  builder
    .addCase(
      action.request.admin.pharmacies.getPharmaciesArchieved.getSuccess,
      ({ getPharmaciesArchieved }, action) => {
        getPharmaciesArchieved.status = 'success';
        getPharmaciesArchieved.data = action.payload;
      }
    )
    .addCase(
      action.request.admin.pharmacies.getPharmaciesArchieved.getPending,
      ({ getPharmaciesArchieved }, action) => {
        getPharmaciesArchieved.status = 'pending';
      }
    )
    .addCase(action.request.admin.pharmacies.getPharmaciesArchieved.getFailed, ({ getPharmaciesArchieved }, action) => {
      getPharmaciesArchieved.status = 'failed';
      getPharmaciesArchieved.error = action.payload;
      getPharmaciesArchieved.data = null;
    });

  builder
    .addCase(
      action.request.admin.pharmacies.deletePharmaciesArchieved.deleteSuccess,
      ({ deletePharmaciesArchieved }, action) => {
        deletePharmaciesArchieved.status = 'success';
        deletePharmaciesArchieved.data = action.payload;
      }
    )
    .addCase(
      action.request.admin.pharmacies.deletePharmaciesArchieved.deletePending,
      ({ deletePharmaciesArchieved }, action) => {
        deletePharmaciesArchieved.status = 'pending';
      }
    )
    .addCase(
      action.request.admin.pharmacies.deletePharmaciesArchieved.deleteFailed,
      ({ deletePharmaciesArchieved }, action) => {
        deletePharmaciesArchieved.status = 'failed';
        deletePharmaciesArchieved.error = action.payload;
        deletePharmaciesArchieved.data = null;
      }
    );
});

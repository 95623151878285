export const initialState = {
  getMailTemplatesID: {
    data: null,
    status: null,
    error: null,
  },

  putMailTemplatesID: {
    data: null,
    status: null,
    error: null,
  },

  getMailTemplates: {
    data: null,
    status: null,
    error: null,
  },
};

import { useMemo } from 'react';

const useProductInfo = ({ data, currentAddedItem, isShowManufacturerDescription = false }) => {
  const productInfo = useMemo(
    () => ({
      unternehmensname: [
        data?.companyName,
        data?.companyImageKey,
        isShowManufacturerDescription && (currentAddedItem?.description || data?.manufacturerDescription),
      ],
      genetik: data?.genetics,
      kultivar: data?.cultivars.join(', '),
      terpene: data?.terpenes.map((item) => item.name).join(', ') || 'N.A.',
    }),
    [data, isShowManufacturerDescription, currentAddedItem],
  );

  return { productInfo };
};

export default useProductInfo;

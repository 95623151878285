import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

import ButtonClear from '../UI/buttonClear';

const FieldDatePicker = ({
  title,
  startDateValue,
  endDateValue,
  isRange,
  disabled,
  onEvent,
  placeholder,
  className = '',
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (startDateValue) setStartDate(new Date(startDateValue));
  }, [startDateValue]);

  useEffect(() => {
    if (endDateValue) setEndDate(new Date(endDateValue));
  }, [endDateValue]);

  const onChange = (dates) => {
    if (isRange) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);

      if (dates.every(Boolean)) {
        onEvent([dates[0], new Date(dates[1].setHours(23, 59, 59, 999))]);
      }
    } else {
      setStartDate(dates);
      onEvent(dates);
    }
  };

  const onClearField = () => {
    setStartDate(null);
    setEndDate(null);
    onEvent(null);
  };

  return (
    <div className={`field-date-picker ${className}`}>
      {title && <label className="label">{title}</label>}
      {isRange ? (
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          placeholderText={placeholder}
          dateFormat="dd.MM.yyyy"
          monthsShown={2}
          disabled={disabled}
        />
      ) : (
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          placeholderText={placeholder}
          dateFormat="dd.MM.yyyy"
          monthsShown={2}
          disabled={disabled}
        />
      )}

      {(startDate || endDate) && <ButtonClear onEvent={onClearField} />}
    </div>
  );
};

export default FieldDatePicker;

import Cookies from 'js-cookie';

import * as variable from '../../variables';

export const buds = {
  getDoctorsBuds: (start, limit, search) => {
    const searchParam = search ? `&search=${search}` : '';
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/doctors/buds?start=${start}&limit=${limit}${searchParam}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getDoctorsBudsId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/doctors/buds/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
};

export const state = {
  getExtractsAll: {
    data: (state) => state.request.admin.extracts.getExtractsAll.data,
    status: (state) => state.request.admin.extracts.getExtractsAll.status,
    error: (state) => state.request.admin.extracts.getExtractsAll.error,
  },

  getExtractId: {
    data: (state) => state.request.admin.extracts.getExtractId.data,
    status: (state) => state.request.admin.extracts.getExtractId.status,
    error: (state) => state.request.admin.extracts.getExtractId.error,
  },

  patchExtractId: {
    data: (state) => state.request.admin.extracts.patchExtractId.data,
    status: (state) => state.request.admin.extracts.patchExtractId.status,
    error: (state) => state.request.admin.extracts.patchExtractId.error,
  },

  deleteExtractId: {
    data: (state) => state.request.admin.extracts.deleteExtractId.data,
    status: (state) => state.request.admin.extracts.deleteExtractId.status,
    error: (state) => state.request.admin.extracts.deleteExtractId.error,
  },

  getExtracts: {
    data: (state) => state.request.admin.extracts.getExtracts.data,
    status: (state) => state.request.admin.extracts.getExtracts.status,
    error: (state) => state.request.admin.extracts.getExtracts.error,
  },

  postExtracts: {
    data: (state) => state.request.admin.extracts.postExtracts.data,
    status: (state) => state.request.admin.extracts.postExtracts.status,
    error: (state) => state.request.admin.extracts.postExtracts.error,
  },

  deleteExtracts: {
    data: (state) => state.request.admin.extracts.deleteExtracts.data,
    status: (state) => state.request.admin.extracts.deleteExtracts.status,
    error: (state) => state.request.admin.extracts.deleteExtracts.error,
  },
};

export const state = {
  getPharmaciesExtracts: {
    data: (state) => state.request.pharmacie.extracts.getPharmaciesExtracts.data,
    status: (state) => state.request.pharmacie.extracts.getPharmaciesExtracts.status,
    error: (state) => state.request.pharmacie.extracts.getPharmaciesExtracts.error,
  },

  getPharmaciesExtractsId: {
    data: (state) => state.request.pharmacie.extracts.getPharmaciesExtractsId.data,
    status: (state) => state.request.pharmacie.extracts.getPharmaciesExtractsId.status,
    error: (state) => state.request.pharmacie.extracts.getPharmaciesExtractsId.error,
  },
};

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import './style.scss';

import * as action from '../../../store/action';
import * as reducer from '../../../store/reducer';

import { useChangeEffect, useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import LinkBack from '../../../components/UI/linkBack';
import Field from '../../../components/UI/field';
import Button from '../../../components/UI/button';
import Loader from '../../../components/popups/loader';
import FieldDatePicker from '../../../components/fieldDatePicker/FieldDatePicker';
import Dropdown3 from '../../../components/UI/dropdown3';

import { getFormateTableDate, getStatusProductOrder } from '../../../utils';
import { arrayDropdown } from './variables';

import defaultBudImg from '../../../assets/images/default-bud-img.svg';
import defaultExtractImg from '../../../assets/images/default-extract-img.svg';

import * as variable from '../../../variables';

const ManufacturerOrderEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const leftColumnRef = useRef();
  const uploadImgRef = useRef();
  const groupBtnRef = useRef();

  const isShow = useDelay(100);
  const heightGroupBtn = groupBtnRef.current?.clientHeight;
  const headerKeys = ['Produkt', 'Menge', 'Status', 'Versanddatum'];

  usePageUp();

  const data = useSelector(reducer.request.manufacturer.orders.state.getManufacturerOrdersId.data);
  const statusOrders = useSelector(reducer.request.manufacturer.orders.state.getManufacturerOrdersId.status);
  const isDataChages = useSelector(reducer.app.common.state.isDataChages);

  const setDataChage = (isChange) => dispatch(action.app.common.isDataChages(isChange));
  const getManufacturerOrdersId = (id) => dispatch(action.request.manufacturer.orders.getManufacturerOrdersId.get(id));
  const putManufacturerOrdersId = (id, data) =>
    dispatch(action.request.manufacturer.orders.putManufacturerOrdersId.put(id, data));

  const currentOrderStatus = data?.status === 'new' ? 'neu' : 'gesperrt';
  const isRequestGETSuccess = statusOrders === 'success';

  const fullAddress = `${data?.pharmacy.address.street} ${data?.pharmacy.address.house} , ${data?.pharmacy.address.zip} ${data?.pharmacy.address.city} `;

  const [height, setHeight] = useState({
    leftColumn: 0,
    uploadImg: 0,
  });
  const [productOrderStatus, setProductOrderStatus] = useState({});
  const [productShippingDate, setProductShippingDate] = useState({});
  const [putData, setPutData] = useState(null);
  const [oldData, setOldData] = useState(null);
  const [isShowLoader, setShowLoader] = useState(true);

  const onChangeShippingDate = (id, date) => {
    setProductShippingDate((prev) => ({ ...prev, [id]: date?.toISOString() || null }));
  };

  const onCancel = () => {
    if (isDataChages) {
      if (window.confirm('Bestätigen Sie die Aktion auf der Seite. Die Änderungen zurücksetzen?')) {
        setDataChage(false);
        navigate('/orders');
      }
    } else {
      navigate('/orders');
    }
  };

  const onSave = useCallback(() => {
    putManufacturerOrdersId(id, putData);
    setDataChage(false);
    navigate('/orders');
  }, [id, putData, oldData, isDataChages]);

  useEffect(() => {
    productOrderStatus &&
      productShippingDate &&
      setPutData({
        products: Object.keys(productOrderStatus)
          ?.map((key) => ({
            id: parseInt(key),
            status: getStatusProductOrder.en(productOrderStatus[key]),
            date: productShippingDate[key],
          }))
          .sort((a, b) => a.id - b.id),
      });
  }, [data?.products, productOrderStatus, productShippingDate]);

  useEffect(() => {
    setOldData({
      products: data?.products
        .map((item) => ({ id: item.id, status: item.status, date: item.date }))
        .sort((a, b) => a.id - b.id),
    });

    setProductOrderStatus(
      data?.products.reduce((acc, item) => {
        acc[item?.id] = getStatusProductOrder.de(item?.status);
        return acc;
      }, {}),
    );

    setProductShippingDate(
      data?.products.reduce((acc, item) => {
        acc[item?.id] = item?.date;
        return acc;
      }, {}),
    );

    setHeight({
      leftColumn: leftColumnRef.current?.clientHeight,
      uploadImg: uploadImgRef.current?.clientHeight,
    });
  }, [data?.products]);

  useEffect(() => {
    getManufacturerOrdersId(id);
    setDataChage(false);
  }, []);

  useEffect(() => {
    if (oldData?.products?.length && putData?.products?.length) {
      if (!_.isEqual(oldData.products, putData.products)) {
        setDataChage(true);
      } else {
        setDataChage(false);
      }
    }
  }, [oldData, putData]);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  return (
    <>
      <Header />
      <main className={`order-edit-page page ${isShow ? 'show' : ''}`}>
        <div className="container">
          <LinkBack onEvent={onCancel} title="Zurück zu Bestellanfragen" />
          <div className="add-edit left-side" ref={leftColumnRef}>
            <section className="section-wrapper">
              <h2 className="title">Bestellinformation</h2>
              <ul className="field-list-one">
                <li>
                  <div>
                    <Field label="Bestellnummer" type="text" value={data?.id} disabled={true} />
                    <Field label="Datum" type="text" value={getFormateTableDate(data?.date)} disabled={true} />
                  </div>
                  <div className="order-status-main">
                    <p>Status</p>
                    <p className={currentOrderStatus}>{currentOrderStatus}</p>
                  </div>
                </li>
              </ul>
              <ul className="field-list-two">
                <li>
                  {headerKeys.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </li>
                {data?.products.map((item, index) => {
                  return (
                    <ul key={index} className="order-row">
                      <li className="order-row-img">
                        <img
                          src={
                            item?.imageKey
                              ? `https://${variable.url}/uploads/${item?.imageKey}`
                              : item.type === 'bud'
                              ? defaultBudImg
                              : defaultExtractImg
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        <p className="order-row-title">{item.name}</p>
                        <p className="order-row-text">{item.genetics}</p>
                        <p className="order-row-text">{item.cultivars.join(',')}</p>
                      </li>
                      <li className="order-row-amount">{`${item.amount} ${item.type === 'bud' ? 'g' : 'ml'}`}</li>
                      <li>
                        {productOrderStatus && (
                          <Dropdown3
                            className="status-dropdown"
                            select={productOrderStatus[item.id]}
                            setSelect={(status) =>
                              setProductOrderStatus({ ...productOrderStatus, [item.id]: status.name })
                            }
                            content={item.status === 'approved' ? arrayDropdown.approved : arrayDropdown.full}
                            disabled={item.status === 'sent' || item.status === 'declined' || data?.status === 'closed'}
                          />
                        )}
                      </li>
                      <li>
                        {item.status !== 'declined' && (
                          <FieldDatePicker
                            placeholder={getFormateTableDate(item.date || new Date(), false)}
                            disabled={item.status === 'sent'}
                            isRange={false}
                            onEvent={(date) => onChangeShippingDate(item.id, date)}
                          />
                        )}
                      </li>
                    </ul>
                  );
                })}
              </ul>
            </section>

            <aside className="right-side ">
              <div ref={uploadImgRef}>
                <ul className="field-list-three">
                  <li>
                    <h1>Apotheken-Information</h1>
                  </li>
                  <li>
                    <p className="info-title">Interne Apothekennummer</p>
                    {data?.pharmacy.externalId ? (
                      <p>{data?.pharmacy.externalId}</p>
                    ) : (
                      <Link to={`/pharmacie/${data?.pharmacy.id}`} className="info-button">
                        Assign
                      </Link>
                    )}
                  </li>
                  <li>
                    <p className="info-title">Apotheken</p>
                    <Link to={`/pharmacie/${data?.pharmacy.id}`} className="info-button">
                      {data?.pharmacy.name}
                    </Link>
                  </li>
                  <li>
                    <p className="info-title">Stadt</p>
                    <p>{fullAddress}</p>
                  </li>
                </ul>
              </div>

              <div
                className="block"
                style={{ height: `${height.leftColumn - height.uploadImg - heightGroupBtn}px` }}></div>

              <div className="group-btn" ref={groupBtnRef}>
                <Button title="Abbrechen und zurück" onEvent={onCancel} width="201" color="transparent" />
                <Button
                  title="Speichern und weiter"
                  onEvent={onSave}
                  disabled={!isDataChages}
                  width="201"
                  color="green"
                />
              </div>
            </aside>
          </div>
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default ManufacturerOrderEdit;

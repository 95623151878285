import Cookies from 'js-cookie';

import * as variable from '../../variables';

export const buds = {
  getManufacturerBuds: (start, limit, search, sort, order) => {
    const searchParam = search ? `&search=${search}` : '';
    const sortParam = sort ? `&sort=${sort}` : '';
    const orderParam = order ? `&order=${order}` : '';

    const accessToken = Cookies.get('accessToken');

    return fetch(
      `https://${variable.url}/manufacturers/buds?start=${start}&limit=${limit}${searchParam}${sortParam}${orderParam}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  },

  getManufacturerBudsId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/manufacturers/buds/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  patchManufacturerBudsId: (id, data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/manufacturers/buds/${id}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

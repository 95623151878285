import React, { useEffect, useState } from 'react';

import './style.scss';

import { useChangeEffect } from '../../hooks';

import InfoCard from './infoCard';
import DeletePopup from '../popups/deletePopup';
import CreateInstructionsPopup from '../popups/createInstructionsPopup';
import CreateShapePopup from '../popups/createShapePopup';

const CardBuilder = ({ type, className, data, onDelete, onPut, statusPut, statusDelete, children }) => {
  const [deletePopup, setDeletePopup] = useState({ id: null, isShow: false });
  const [editPopup, setEditPopup] = useState({ id: null, isShow: false });
  const [cardsData, setCardsData] = useState([]);

  useEffect(() => {
    setCardsData(data);
  }, [data]);

  // close popup after success request
  useChangeEffect(() => {
    if (statusPut === 'success') setEditPopup({ id: null, isShow: false });
  }, [statusPut]);

  // close popup after success request
  useChangeEffect(() => {
    if (statusDelete === 'success') setDeletePopup({ id: null, isShow: false });
  }, [statusDelete]);

  const onToggleActive = (id) => {
    cardsData.forEach((item) => {
      if (item.id === id) {
        onPut(id, { ...item, active: !item.active });
      }
    });
  };

  return (
    <>
      <section className={`card-builder ${className}`}>
        {children}

        <ul className='cards'>
          {cardsData?.map((cardData) => (
            <li
              className='card'
              key={cardData.id}
            >
              <InfoCard
                cardData={cardData}
                onToggleActive={() => onToggleActive(cardData.id)}
                type={type}
                onSave={onPut}
                onShowDeletePopup={() => setDeletePopup({ id: cardData.id, isShow: true })}
                onShowEditPopup={() => setEditPopup({ id: cardData.id, isShow: true })}
              />
            </li>
          ))}
        </ul>
      </section>

      {deletePopup.isShow && (
        <DeletePopup
          onDelete={() => onDelete(deletePopup.id)}
          onClose={() => setDeletePopup({ id: null, isShow: false })}
        />
      )}

      {type === 'dosages' && editPopup.isShow && (
        <CreateInstructionsPopup
          cardData={cardsData.find((cardData) => cardData.id === editPopup.id)}
          onSave={onPut}
          onClose={() => setEditPopup({ id: null, isShow: false })}
        />
      )}

      {type === 'forms' && editPopup.isShow && (
        <CreateShapePopup
          cardData={cardsData.find((cardData) => cardData.id === editPopup.id)}
          onSave={onPut}
          onClose={() => setEditPopup({ id: null, isShow: false })}
        />
      )}
    </>
  );
};

export default CardBuilder;

export const extracts = {
  getManufacturerExtractsSuccess: 'request/getManufacturerExtractsSuccess',
  getManufacturerExtractsPending: 'request/getManufacturerExtractsPending',
  getManufacturerExtractsFailed: 'request/getManufacturerExtractsFailed',

  getManufacturerExtractsIdSuccess: 'request/getManufacturerExtractsIdSuccess',
  getManufacturerExtractsIdPending: 'request/getManufacturerExtractsIdPending',
  getManufacturerExtractsIdFailed: 'request/getManufacturerExtractsIdFailed',

  patchManufacturerExtractsIdSuccess: 'request/patchManufacturerExtractsIdSuccess',
  patchManufacturerExtractsIdPending: 'request/patchManufacturerExtractsIdPending',
  patchManufacturerExtractsIdFailed: 'request/patchManufacturerExtractsIdFailed',
};

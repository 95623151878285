import React, { useEffect, useState } from 'react';

import './style.scss';

import Checkbox from '../../components/UI/checkbox';
import Dropdown2 from '../../components/UI/dropdown2';
import Button from '../../components/UI/button/Button';
import ButtonTrash from '../UI/buttonTrash';

const deutscheDays = {
  monday: 'Montag',
  tuesday: 'Dienstag',
  wednesday: 'Mittwoch',
  thursday: 'Donnerstag',
  friday: 'Freitag',
  saturday: 'Samstag',
  sunday: 'Sonntag',
}

const OpeningHours = ({ data, setData }) => {
  const [daysData, setDaysData] = useState([
    { id: 1, name: 'Montag', isActive: false, isSecondField: false },
    { id: 2, name: 'Dienstag', isActive: false, isSecondField: false },
    { id: 3, name: 'Mittwoch', isActive: false, isSecondField: false },
    { id: 4, name: 'Donnerstag', isActive: false, isSecondField: false },
    { id: 5, name: 'Freitag', isActive: false, isSecondField: false },
    { id: 6, name: 'Samstag', isActive: false, isSecondField: false },
    { id: 7, name: 'Sonntag', isActive: false, isSecondField: false },
  ]);

  const [days, setDays] = useState({
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  });

  useEffect(() => {
    setDays({
      monday: data.openingHours.monday,
      tuesday: data.openingHours.tuesday,
      wednesday: data.openingHours.wednesday,
      thursday: data.openingHours.thursday,
      friday: data.openingHours.friday,
      saturday: data.openingHours.saturday,
      sunday: data.openingHours.sunday,
    });
  }, [data.openingHours]);

  // --- show active day or second field when component is mounted
  useEffect(() => {
    const copyDaysData = [...daysData];

    Object.values(data.openingHours).map((item, index) => {
      copyDaysData[index].isActive = item?.mainHours.open;
      copyDaysData[index].isSecondField = item?.additionalHours?.open;
    });

    setDaysData(copyDaysData);
  }, []);

  const onToggleActiveDay = (day, isActive) => {
    const mainHours = {
      mainHours: {
        open: '09:00',
        close: '18:00',
      },
    };

    setData({ ...data, openingHours: { ...data.openingHours, [day]: isActive ? mainHours : null } });
  };

  const onToggleAdditionalHoursDay = (day, isActive) => {
    const openingHours = { ...data.openingHours };
    const mainHours = { ...openingHours[day].mainHours };
    const additionalHours = {
      additionalHours: {
        open: '13:00',
        close: '14:00',
      },
    };

    setData({
      ...data,
      openingHours: { ...openingHours, [day]: isActive ? { mainHours, ...additionalHours } : { mainHours } },
    });
  };

  const onSetHours = (day, type, status, value) => {
    const openingHours = { ...data.openingHours };

    setData({
      ...data,
      openingHours: {
        ...openingHours,
        [day]: { ...openingHours[day], [type]: { ...openingHours[day][type], [status]: value } },
      },
    });
  };

  return (
    <section className="opening-hours">
      <h3 className="title">Öffnungszeiten</h3>

      <Days
        data={data}
        daysKey={Object.keys(days).filter((_item, index) => index % 2 === 0)}
        onToggleActiveDay={onToggleActiveDay}
        onToggleAdditionalHoursDay={onToggleAdditionalHoursDay}
        onSetHours={onSetHours}
      />
      <Days
        data={data}
        daysKey={Object.keys(days).filter((_item, index) => index % 2 != 0)}
        onToggleActiveDay={onToggleActiveDay}
        onToggleAdditionalHoursDay={onToggleAdditionalHoursDay}
        onSetHours={onSetHours}
      />
    </section>
  );
};

export default OpeningHours;

const Days = ({ data, daysKey, onToggleActiveDay, onToggleAdditionalHoursDay, onSetHours }) => {
  return (
    <ul className="days">
      {daysKey.map((day, index) => {
        const active = data.openingHours[day];

        return (
          <li className="day" key={index}>
            <Checkbox label={deutscheDays[day]} isChecked={active} onChange={() => onToggleActiveDay(day, !active)} />

            {active ? (
              <ul className="list">
                <Item type="mainHours" hours={active?.mainHours} day={day} onSetHours={onSetHours}>
                  <Button
                    title="+"
                    width="26"
                    disabled={active?.additionalHours}
                    onEvent={() => onToggleAdditionalHoursDay(day, true)}
                    color="transparent"
                  />
                </Item>

                {active?.additionalHours && (
                  <Item type="additionalHours" hours={active?.additionalHours} day={day} onSetHours={onSetHours}>
                    <ButtonTrash bg="transparent" onClick={() => onToggleAdditionalHoursDay(day, false)} />
                  </Item>
                )}
              </ul>
            ) : (
              <p className="message">Geschlossen</p>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const Item = ({ type, hours, day, onSetHours, children }) => {
  const createDropdownContent = (startTime, count) => {
    let data = [];

    data = new Array(count).fill(null).map((_item, index) => {
      const number = index % 2 != 0 ? startTime : (startTime += 1);
      const time = `${String(number).length === 2 ? number : `0${number}`}:${index % 2 != 0 ? '30' : '00'}`;

      return { id: index + 1, name: time };
    });

    return data;
  };

  const dropdownContent = createDropdownContent(6, 27);

  return (
    <li className="item">
      <Dropdown2
        title="Öffnet"
        select={hours?.open}
        setSelect={(data) => onSetHours(day, type, 'open', data.name)}
        content={dropdownContent}
      />
      <Dropdown2
        title="Geschlossen"
        select={hours?.close}
        setSelect={(data) => onSetHours(day, type, 'close', data.name)}
        content={dropdownContent}
      />

      {children}
    </li>
  );
};

export const putUserDataFormated = (data) => {
  return {
    description: data.description,
    name: data.name,
    email: data.email,
    phone: data.phone,
    active: true,
    billingAddress: {
      zip: data.billingZip,
      city: data.billingCity,
      street: data.billingStreet,
      house: data.billingHouse,
    },
    deliveryAddress: {
      zip: data.deliveryZip,
      city: data.deliveryCity,
      street: data.deliveryStreet,
      house: data.deliveryHouse,
    },

    contact: {
      name: data.contactName,
      email: data.contactEmail,
      phone: data.contactPhone,
    },
    technicalContact: {
      name: data.technicalName,
      email: data.technicalEmail,
      phone: data.technicalPhone,
    },
    bookkeepingContact: {
      name: data.bookkeepingName,
      email: data.bookkeepingEmail,
      phone: data.bookkeepingPhone,
    },
  };
};

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../store/reducer';
import * as action from '../../../../store/action';

import { usePageUp, useChangeEffect } from '../../../../hooks';

import Header from '../../../../components/header';
import Tabs from '../../../../components/UI/tabs';
import List from './tabs/list';
import Archive from './tabs/archive';
import Loader from '../../../../components/popups/loader';
import { useLocation } from 'react-router-dom';
import { useDefaultPerPage } from '../../../../hooks/useDefaultPerPage';

const Pharmacies = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const { contentPerPage: pharmacie } = useDefaultPerPage('superuserPharmaciesMain');
  const { contentPerPage: pharmacieArch } = useDefaultPerPage('superuserPharmaciesArchive');

  const dataPharmacies = useSelector(reducer.request.admin.pharmacy.state.getPharmacies.data);
  const dataPharmaciesArchieved = useSelector(reducer.request.admin.pharmacy.state.getPharmaciesArchieved.data);

  const statusGetPharmacies = useSelector(reducer.request.admin.pharmacy.state.getPharmacies.status);
  const statusGetPharmaciesArchieved = useSelector(reducer.request.admin.pharmacy.state.getPharmaciesArchieved.status);

  const getPharmacies = (start, limit, archieved) =>
    dispatch(action.request.admin.pharmacies.getPharmacies.get(start, limit, archieved));
  const getPharmaciesArchieved = (start, limit, archieved, search) =>
    dispatch(action.request.admin.pharmacies.getPharmaciesArchieved.get(start, limit, archieved, search));

  const [isShowLoader, setShowLoader] = useState(true);
  const [activeTabID, setActiveTabID] = useState(state?.tab || 1);

  const isRequestGETSuccess = statusGetPharmacies === 'success' && statusGetPharmaciesArchieved === 'success';

  const tabsList = [
    {
      id: 1,
      name: 'Liste',
      count: dataPharmacies?.total,
    },
    {
      id: 2,
      name: 'Archiv',
      count: dataPharmaciesArchieved?.total,
    },
  ];

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  useEffect(() => {
    getPharmacies(0, pharmacie, false);
    getPharmaciesArchieved(0, pharmacieArch, true);
  }, [pharmacie, pharmacieArch]);

  usePageUp();

  return (
    <>
      <Header />

      <main className={`pharmacies-page page ${!isShowLoader ? 'show' : ''}`}>
        <div className="container">
          <h1 className="page-title">Apotheken</h1>

          <Tabs list={tabsList} activeTabID={activeTabID} setActiveTabID={setActiveTabID} />

          <List visible={activeTabID === 1} isShowLoader={isShowLoader} />
          <Archive visible={activeTabID === 2} isShowLoader={isShowLoader} />
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default Pharmacies;

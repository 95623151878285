export const auth = {
  postLoginSuccess: 'request/postLoginSuccess',
  postLoginPending: 'request/postLoginPending',
  postLoginFailed: 'request/postLoginFailed',

  postLoginDocCheckSuccess: 'request/postLoginDocCheckSuccess',
  postLoginDocCheckPending: 'request/postLoginDocCheckPending',
  postLoginDocCheckFailed: 'request/postLoginDocCheckFailed',

  getRefreshSuccess: 'request/getRefreshSuccess',
  getRefreshPending: 'request/getRefreshPending',
  getRefreshFailed: 'request/getRefreshFailed',

  getLogoutSuccess: 'request/getLogoutSuccess',
  getLogoutPending: 'request/getLogoutPending',
  getLogoutFailed: 'request/getLogoutFailed',

  postForgotPasswordSuccess: 'request/postForgotPasswordSuccess',
  postForgotPasswordPending: 'request/postForgotPasswordPending',
  postForgotPasswordFailed: 'request/postForgotPasswordFailed',
};

export const state = {
  getPharmaciesBuds: {
    data: (state) => state.request.pharmacie.buds.getPharmaciesBuds.data,
    status: (state) => state.request.pharmacie.buds.getPharmaciesBuds.status,
    error: (state) => state.request.pharmacie.buds.getPharmaciesBuds.error,
  },

  getPharmaciesBudsId: {
    data: (state) => state.request.pharmacie.buds.getPharmaciesBudsId.data,
    status: (state) => state.request.pharmacie.buds.getPharmaciesBudsId.status,
    error: (state) => state.request.pharmacie.buds.getPharmaciesBudsId.error,
  },
};

import Cookies from 'js-cookie';

import * as variable from '../variables';

export const uploads = {
  getUploadId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/uploads/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getUploads: (params) => {
    const accessToken = Cookies.get('accessToken');
    const { sort, type } = params;

    const sortParam = sort ? `?sort=${sort}` : '';

    return fetch(`https://${variable.url}/uploads${sortParam}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  postUploads: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/uploads`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: data,
    });
  },

  deleteUploads: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/uploads`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

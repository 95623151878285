import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as action from '../../../store/action';
import * as reducer from '../../../store/reducer';

import TemplatePopup from '../../templatePopup';
import Button from '../../UI/button';
import ButtonClear from '../../UI/buttonClear';
import FormTextarea from '../../UI/form/formTextarea';
import Form from '../../UI/form/Form';
import FileUploader from '../../fileUploader/FileUploader';

import { ProductAddSchema } from '../../UI/form/validation';

const AddProductPopup = ({ onClose, setIsShowComplitePopup, title, type }) => {
  const dispatch = useDispatch();
  const [attachment, setAttachment] = useState([]);

  const dataUpload = useSelector(reducer.request.uploads.state.postUploads.data);

  const postManufacturerProduct = (data) => dispatch(action.request.manufacturer.user.postManufacturerMail.post(data));
  const postUploads = (data) => dispatch(action.request.uploads.postUploads.post(data));
  const deleteUpload = (filesIdsArray) => dispatch(action.request.uploads.deleteUploads.delete(filesIdsArray));
  const resetUploadData = () => dispatch(action.request.uploads.resetUploadData());

  const typeUploadFiles = type;

  const onSubmit = useCallback(
    (data) => {
      postManufacturerProduct({
        body: data.description,
        files: attachment.map((item) => item.key),
        type,
      });
      setIsShowComplitePopup(true);
      resetUploadData();
      onClose();
    },
    [attachment],
  );

  const onAttachmentHandler = (file) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', typeUploadFiles);

    setAttachment((prev) => [
      ...prev,
      {
        key: null,
        mimetype: 'application/image',
        name: file.name,
        type: typeUploadFiles,
      },
    ]);

    postUploads(formData);
  };

  const onDeleteAttachmentHandler = (key) => {
    setAttachment(attachment.filter((att) => att.key !== key));
    deleteUpload({ fileIds: [key] });
  };

  useEffect(() => {
    if (dataUpload) {
      const files = [...attachment];
      const poped = files.pop();

      setAttachment([...files, { ...poped, key: dataUpload }]);
    }
  }, [dataUpload]);

  useEffect(() => {
    setAttachment([]);
  }, [onClose]);

  return (
    <TemplatePopup onClose={onClose} showCloseButton={true} className="add-new-product">
      <Form className="add-new-product-content" onSubmit={onSubmit} resolver={yupResolver(ProductAddSchema)}>
        <h1 className="add-new-product-title">{title}</h1>
        <p className="add-new-product-text">
          Hinterlassen Sie Informationen zu Ihrem Produkt und wir werden es Ihrem Unternehmen hinzufügen
        </p>
        <FormTextarea label="Produktbeschreibung" name="description" />
        <ul className="add-new-product-attachemt">
          <li>
            <FileUploader onChange={onAttachmentHandler} type="img" />
          </li>
          {attachment.map((item, index) => (
            <li key={index}>
              <span>{item.name}</span>
              <ButtonClear className="delete-attachment" onEvent={() => onDeleteAttachmentHandler(item.key)} />
            </li>
          ))}
        </ul>
        <Button title="Anfrage abschicken" type="submit" width="173" color="green add-new-button-btn" />
      </Form>
    </TemplatePopup>
  );
};

export default AddProductPopup;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import './style.scss';

import * as reducer from '../../../../store/reducer';
import * as action from '../../../../store/action';
import * as util from '../../../../utils';

import { usePageUp, useChangeEffect } from '../../../../hooks';

import Header from '../../../../components/header';
import AddEdit from '../../../../components/addEdit';
import LinkBack from '../../../../components/UI/linkBack';
import Field from '../../../../components/UI/field';
import Switcher from '../../../../components/UI/switcher';
import Checkbox from '../../../../components/UI/checkbox';
import DropdownSearch from '../../../../components/UI/dropdownSearch';
import Loader from '../../../../components/popups/loader';
import Textarea from '../../../../components/UI/textarea';

const ManufacturerAddEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();

  const dataUpload = useSelector(reducer.request.uploads.state.postUploads.data);
  const dataExtractsAll = useSelector(reducer.request.admin.extract.state.getExtractsAll.data);
  const dataBudsAll = useSelector(reducer.request.admin.bud.state.getBudsAll.data);
  const dataManufactureID = useSelector(reducer.request.admin.manufacture.state.getManufactureId.data);

  const statusGetExtractsAll = useSelector(reducer.request.admin.extract.state.getExtractsAll.status);
  const statusGetBudsAll = useSelector(reducer.request.admin.bud.state.getBudsAll.status);
  const statusGetManufactureID = useSelector(reducer.request.admin.manufacture.state.getManufactureId.status);

  const statusPostUpload = useSelector(reducer.request.uploads.state.postUploads.status);
  const statusPutManufactureID = useSelector(reducer.request.admin.manufacture.state.putManufactureId.status);
  const statusPostManufacturers = useSelector(reducer.request.admin.manufacture.state.postManufacturers.status);
  const isDataChages = useSelector(reducer.app.common.state.isDataChages);

  const setDataChage = (isChange) => dispatch(action.app.common.isDataChages(isChange));
  const postUploads = (data) => dispatch(action.request.uploads.postUploads.post(data));
  const getExtractsAll = (manufacturerID) => dispatch(action.request.admin.extracts.getExtractsAll.get(manufacturerID));
  const getBudsAll = (manufacturerID) => dispatch(action.request.admin.buds.getBudsAll.get(manufacturerID));
  const getManufactureId = (id) => dispatch(action.request.admin.manufacturers.getManufactureId.get(id));
  const putManufactureId = (id, data) => dispatch(action.request.admin.manufacturers.putManufactureId.put(id, data));
  const postManufacturers = (data) => dispatch(action.request.admin.manufacturers.postManufacturers.post(data));

  const [isShowLoader, setShowLoader] = useState(true);
  const [imgKey, setImgKey] = useState(null);
  const [data, setData] = useState({
    active: true,
    billingAddress: {
      zip: '',
      city: '',
      street: '',
      house: '',
    },
    bookkeepingContact: {
      name: '',
      email: '',
      phone: '',
    },
    buds: [],
    contact: {
      name: '',
      email: '',
      phone: '',
    },
    deliveryAddress: {
      zip: '',
      city: '',
      street: '',
      house: '',
    },
    email: '',
    extracts: [],
    isSameAddresses: true,
    logo: null, // null | string
    name: '',
    phone: '',
    technicalContact: {
      name: '',
      email: '',
      phone: '',
    },
  });

  const [errorData, setErrorData] = useState({
    billingAddress: {
      zip: true,
      city: true,
      street: true,
      house: true,
    },
    contact: {
      name: true,
      email: true,
      phone: true,
    },
    deliveryAddress: {
      zip: true,
      city: true,
      street: true,
      house: true,
    },
    email: true,
    name: true,
    phone: true,
  });

  const manufacturerID = param.manufacturerID;
  const isEdit = manufacturerID;

  const isRequestGETSuccess =
    statusGetExtractsAll === 'success' &&
    statusGetBudsAll === 'success' &&
    (!isEdit || statusGetManufactureID === 'success');

  usePageUp();

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  useEffect(() => {
    getExtractsAll(manufacturerID);
    getBudsAll(manufacturerID);

    setDataChage(false);

    if (isEdit) getManufactureId(manufacturerID);
  }, []);

  useEffect(() => {
    if (isEdit && dataManufactureID) {
      setData(dataManufactureID);
      setImgKey(dataManufactureID?.logo);
    }
  }, [dataManufactureID]);

  useChangeEffect(() => {
    if (statusPutManufactureID === 'success') navigate('/manufacturers');
  }, [statusPutManufactureID]);

  useChangeEffect(() => {
    if (statusPostManufacturers === 'success') navigate('/manufacturers');
  }, [statusPostManufacturers]);

  useEffect(() => {
    if (statusPostUpload === 'success') setData({ ...data, [imgKey]: dataUpload });
  }, [statusPostUpload]);

  // set value if checked the same address
  useEffect(() => {
    if (data.isSameAddresses) {
      setData({
        ...data,
        deliveryAddress: {
          zip: data.billingAddress.zip,
          city: data.billingAddress.city,
          street: data.billingAddress.street,
          house: data.billingAddress.house,
        },
      });
    }
  }, [data.isSameAddresses]);

  const onSave = () => {
    if (onCheckError()) {
      if (isEdit) putManufactureId(manufacturerID, data);
      else postManufacturers(data);
      setDataChage(false);
    }
  };

  const onCancel = () => {
    if (isDataChages) {
      if (window.confirm('Bestätigen Sie die Aktion auf der Seite. Die Änderungen zurücksetzen?')) {
        setDataChage(false);
        navigate('/manufacturers');
      }
    } else {
      navigate('/manufacturers');
    }
  };

  const onSetBillingAddress = (event, isSame) => {
    const { value, name } = event.target;
    let newData = {};

    if (isSame) {
      newData = {
        ...data,
        billingAddress: { ...data.billingAddress, [name]: value },
        deliveryAddress: { ...data.deliveryAddress, [name]: value },
      };
    } else {
      newData = { ...data, billingAddress: { ...data.billingAddress, [name]: value } };
    }

    setData(newData);
  };

  const onSetData = (event, key) => {
    const { value, name, type, checked } = event.target;

    if (key) {
      setData({ ...data, [key]: { ...data[key], [name]: value } });
    } else {
      setData({ ...data, [name]: type === 'checkbox' ? checked : value });
    }
  };

  const onCheckError = () => {
    const changedData = {
      billingAddress: {
        zip: util.validation.onChekTextField(data.billingAddress.zip),
        city: util.validation.onChekTextField(data.billingAddress.city),
        street: util.validation.onChekTextField(data.billingAddress.street),
        house: util.validation.onChekTextField(data.billingAddress.house),
      },
      contact: {
        name: util.validation.onChekTextField(data.contact.name),
        email: util.validation.onChekTextField(data.contact.email),
        phone: util.validation.onChekTextField(data.contact.phone),
      },
      deliveryAddress: {
        zip: data.isSameAddresses ? true : util.validation.onChekTextField(data.deliveryAddress.zip),
        city: data.isSameAddresses ? true : util.validation.onChekTextField(data.deliveryAddress.city),
        street: data.isSameAddresses ? true : util.validation.onChekTextField(data.deliveryAddress.street),
        house: data.isSameAddresses ? true : util.validation.onChekTextField(data.deliveryAddress.house),
      },
      email: util.validation.onChekTextField(data.email),
      name: util.validation.onChekTextField(data.name),
      phone: util.validation.onChekTextField(data.phone),
    };

    setErrorData(changedData);

    return Object.values(changedData).every((item) => {
      if (Object.values(item).length) {
        return Object.values(item).every(Boolean);
      } else {
        return item;
      }
    });
  };

  const onUploadImg = (name, data) => {
    setImgKey(name);
    postUploads(data);
  };

  useEffect(() => {
    if (!!data && !!dataManufactureID) {
      if (imgKey !== dataManufactureID?.logo) {
        setDataChage(true);
      } else if (!_.isEqual(data, dataManufactureID)) {
        setDataChage(true);
      } else {
        setDataChage(false);
      }
    }
  }, [data, dataManufactureID, imgKey]);

  return (
    <>
      <Header />
      <main className={`manufacturer-add-edit-page page ${!isShowLoader ? 'show' : ''}`}>
        <div className="container">
          <LinkBack title="Zurück zu Lieferanten" onEvent={onCancel} />

          <AddEdit
            className="manufacturers"
            type="manufacture"
            data={data}
            setData={setData}
            onSave={onSave}
            onCancel={onCancel}
            onUploadImg={onUploadImg}
            isSaveButtonDisabled={!isDataChages && isEdit}>
            <section className="info-section">
              <h2 className="title">Produkt-Details</h2>

              <ul className="field-list-one">
                <li>
                  <Field
                    error={!errorData.name}
                    required={true}
                    label="Name"
                    type="text"
                    name="name"
                    value={data.name}
                    onEvent={(event) => onSetData(event)}
                    onEvent2={() => setErrorData({ ...errorData, name: true })}
                  />
                  <Switcher
                    label="User aktiv"
                    name="active"
                    isChecked={data.active}
                    onChange={(event) => onSetData(event)}
                  />
                </li>
                <li>
                  <Field
                    error={!errorData.email}
                    required={true}
                    label="E-Mail"
                    type="text"
                    name="email"
                    value={data.email}
                    onEvent={(event) => onSetData(event)}
                    onEvent2={() => setErrorData({ ...errorData, email: true })}
                  />
                </li>

                <li>
                  <Field
                    error={!errorData.phone}
                    required={true}
                    label="Telefon"
                    type="text"
                    name="phone"
                    value={data.phone}
                    onEvent={(event) => onSetData(event)}
                    onEvent2={() => setErrorData({ ...errorData, phone: true })}
                  />
                </li>

                <li>
                  <Field
                    error={!errorData.billingAddress.zip}
                    required={true}
                    label="PLZ"
                    type="text"
                    name="zip"
                    value={data.billingAddress.zip}
                    onEvent={(event) => onSetBillingAddress(event, data.isSameAddresses)}
                    onEvent2={() =>
                      setErrorData({ ...errorData, billingAddress: { ...errorData.billingAddress, zip: true } })
                    }
                  />
                </li>
                <li>
                  <Field
                    error={!errorData.billingAddress.city}
                    required={true}
                    label="Stadt"
                    type="text"
                    name="city"
                    value={data.billingAddress.city}
                    onEvent={(event) => onSetBillingAddress(event, data.isSameAddresses)}
                    onEvent2={() =>
                      setErrorData({ ...errorData, billingAddress: { ...errorData.billingAddress, city: true } })
                    }
                  />
                </li>
                <li>
                  <Field
                    error={!errorData.billingAddress.street}
                    required={true}
                    label="Straße"
                    type="text"
                    name="street"
                    value={data.billingAddress.street}
                    onEvent={(event) => onSetBillingAddress(event, data.isSameAddresses)}
                    onEvent2={() =>
                      setErrorData({ ...errorData, billingAddress: { ...errorData.billingAddress, street: true } })
                    }
                  />
                </li>
                <li>
                  <Field
                    error={!errorData.billingAddress.house}
                    required={true}
                    label="Hausnummer"
                    type="text"
                    name="house"
                    value={data.billingAddress.house}
                    onEvent={(event) => onSetBillingAddress(event, data.isSameAddresses)}
                    onEvent2={() =>
                      setErrorData({ ...errorData, billingAddress: { ...errorData.billingAddress, house: true } })
                    }
                  />
                </li>
                <li>
                  <Checkbox
                    label="Rechnungs- und Lieferadresse sind gleich"
                    isChecked={data.isSameAddresses}
                    onChange={() => setData({ ...data, isSameAddresses: !data.isSameAddresses })}
                  />
                </li>
              </ul>

              {!data.isSameAddresses && (
                <div className="shipping-address">
                  <p className="title">Lieferadresse</p>
                  <ul className="list">
                    <li>
                      <Field
                        error={!errorData.deliveryAddress.zip}
                        required={true}
                        label="PLZ"
                        type="text"
                        name="zip"
                        value={data.deliveryAddress.zip}
                        onEvent={(event) => onSetData(event, 'deliveryAddress')}
                        onEvent2={() =>
                          setErrorData({ ...errorData, deliveryAddress: { ...errorData.deliveryAddress, zip: true } })
                        }
                      />
                    </li>
                    <li>
                      <Field
                        error={!errorData.deliveryAddress.city}
                        required={true}
                        label="Stadt"
                        type="text"
                        name="city"
                        value={data.deliveryAddress.city}
                        onEvent={(event) => onSetData(event, 'deliveryAddress')}
                        onEvent2={() =>
                          setErrorData({ ...errorData, deliveryAddress: { ...errorData.deliveryAddress, city: true } })
                        }
                      />
                    </li>
                    <li>
                      <Field
                        error={!errorData.deliveryAddress.street}
                        required={true}
                        label="Straße"
                        type="text"
                        name="street"
                        value={data.deliveryAddress.street}
                        onEvent={(event) => onSetData(event, 'deliveryAddress')}
                        onEvent2={() =>
                          setErrorData({
                            ...errorData,
                            deliveryAddress: { ...errorData.deliveryAddress, street: true },
                          })
                        }
                      />
                    </li>
                    <li>
                      <Field
                        error={!errorData.deliveryAddress.house}
                        required={true}
                        label="Hausnummer"
                        type="text"
                        name="house"
                        value={data.deliveryAddress.house}
                        onEvent={(event) => onSetData(event, 'deliveryAddress')}
                        onEvent2={() =>
                          setErrorData({ ...errorData, deliveryAddress: { ...errorData.deliveryAddress, house: true } })
                        }
                      />
                    </li>
                  </ul>
                </div>
              )}
            </section>

            <section className="info-section">
              <h2 className="title">Ansprechpartner</h2>

              <ul className="field-list-two">
                <li>
                  <Field
                    error={!errorData.contact.name}
                    required={true}
                    label="Name"
                    type="text"
                    name="name"
                    value={data.contact.name}
                    onEvent={(event) => onSetData(event, 'contact')}
                    onEvent2={() => setErrorData({ ...errorData, contact: { ...errorData.contact, name: true } })}
                  />
                </li>
                <li>
                  <Field
                    error={!errorData.contact.email}
                    required={true}
                    label="E-Mail"
                    type="text"
                    name="email"
                    value={data.contact.email}
                    onEvent={(event) => onSetData(event, 'contact')}
                    onEvent2={() => setErrorData({ ...errorData, contact: { ...errorData.contact, email: true } })}
                  />
                </li>
                <li>
                  <Field
                    error={!errorData.contact.phone}
                    required={true}
                    label="Telefon"
                    type="text"
                    name="phone"
                    value={data.contact.phone}
                    onEvent={(event) => onSetData(event, 'contact')}
                    onEvent2={() => setErrorData({ ...errorData, contact: { ...errorData.contact, phone: true } })}
                  />
                </li>
              </ul>
            </section>

            <section className="info-section">
              <h2 className="title">Technischer Ansprechpartner</h2>

              <ul className="field-list-two">
                <li>
                  <Field
                    label="Name"
                    type="text"
                    name="name"
                    value={data.technicalContact.name}
                    onEvent={(event) => onSetData(event, 'technicalContact')}
                  />
                </li>
                <li>
                  <Field
                    label="E-Mail"
                    type="text"
                    name="email"
                    value={data.technicalContact.email}
                    onEvent={(event) => onSetData(event, 'technicalContact')}
                  />
                </li>
                <li>
                  <Field
                    label="Telefon"
                    type="text"
                    name="phone"
                    value={data.technicalContact.phone}
                    onEvent={(event) => onSetData(event, 'technicalContact')}
                  />
                </li>
              </ul>
            </section>

            <section className="info-section">
              <h2 className="title">Ansprechpartner Buchhaltung</h2>

              <ul className="field-list-two">
                <li>
                  <Field
                    label="Name"
                    type="text"
                    name="name"
                    value={data.bookkeepingContact.name}
                    onEvent={(event) => onSetData(event, 'bookkeepingContact')}
                  />
                </li>
                <li>
                  <Field
                    label="E-Mail"
                    type="text"
                    name="email"
                    value={data.bookkeepingContact.email}
                    onEvent={(event) => onSetData(event, 'bookkeepingContact')}
                  />
                </li>
                <li>
                  <Field
                    label="Telefon"
                    type="text"
                    name="phone"
                    value={data.bookkeepingContact.phone}
                    onEvent={(event) => onSetData(event, 'bookkeepingContact')}
                  />
                </li>
              </ul>
            </section>

            <section className="info-section border-0 mb-0">
              <ul className="field-list-two">
                <li>
                  <DropdownSearch
                    label="verbundene Blüten"
                    type="Flowers"
                    selectData={dataBudsAll}
                    data={data.buds}
                    setData={(arrBuds) => setData({ ...data, buds: arrBuds })}
                  />
                </li>
                <li>
                  <DropdownSearch
                    label="verbundene Extrakte"
                    type="Extracts"
                    selectData={dataExtractsAll}
                    data={data.extracts}
                    setData={(arrExtracts) => setData({ ...data, extracts: arrExtracts })}
                  />
                </li>
                <li className="description">
                  <Textarea
                    label="Unternehmensbeschreibung"
                    value={data.description}
                    onChange={(event) => setData({ ...data, description: event.target.value })}
                  />
                </li>
              </ul>
            </section>
          </AddEdit>
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
      {(statusPutManufactureID === 'pending' || statusPostManufacturers === 'pending') && <Loader type="transparent" />}
    </>
  );
};

export default ManufacturerAddEdit;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../../../store/reducer';
import * as action from '../../../../../../store/action';
import * as util from '../../../../../../utils';

import { useChangeEffect } from '../../../../../../hooks';
import { TabContent } from '../../../../../../HOC';
import { variables } from './variables';

import TableBuilder from '../../../../../../components/tableBuilder';
import FieldSearch from '../../../../../../components/UI/fieldSearch';
import DropdownCount from '../../../../../../components/dropdownCount';
import Pagination from '../../../../../../components/UI/pagination';
import Selected from '../../../../../../components/selected';
import Button from '../../../../../../components/UI/button/Button';
import Loader from '../../../../../../components/popups/loader';
import DeletePopup from '../../../../../../components/popups/deletePopup';
import { useDefaultPerPage } from '../../../../../../hooks/useDefaultPerPage';

const List = ({ visible, isShowLoader }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataCultivarsAll = useSelector(reducer.request.cultivar.state.getCultivarsAll.data);
  const dataExtracts = useSelector(reducer.request.admin.extract.state.getExtracts.data);

  const statusDeleteExtracts = useSelector(reducer.request.admin.extract.state.deleteExtracts.status);
  const statusGetExtracts = useSelector(reducer.request.admin.extract.state.getExtracts.status);

  const deleteExtracts = (data) => dispatch(action.request.admin.extracts.deleteExtracts.delete(data));
  const getExtracts = (start, limit, search) =>
    dispatch(action.request.admin.extracts.getExtracts.get(start, limit, search));

  const [valueSearch, setValueSearch] = useState('');

  const persistName = 'superuserExtractsList';

  const { contentPerPage, setContentPerPage } = useDefaultPerPage(persistName);

  const [currentPage, setCurrentPage] = useState(1);

  const [isShowDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);

  const countSelectedRows = selectedIDs.length;
  const pageCount = Math.ceil(dataExtracts?.total / contentPerPage) || 1;

  const tableTitles = ['№', 'Produktname', 'Kultivar / Genetik', 'THC / CBD / CBN (%)', 'Unternehmen'];
  const tableData = dataExtracts?.extracts.reduce((acc, item, index) => {
    acc = [
      ...acc,
      {
        id: item.id,
        number: currentPage !== 1 ? (currentPage - 1) * contentPerPage + index + 1 : index + 1,
        name: item.name,
        cultivars_genetics: `${util.getCultivarsName(item.cultivars, dataCultivarsAll)}  / ${item.genetics}`,
        thc_cbd_cbn: `${item.thc}  / ${item.cbd} / ${item.cbn}`,
        companyName: item.companyName,
      },
    ];

    return acc;
  }, []);

  // get new data after delete item
  useChangeEffect(() => {
    if (statusDeleteExtracts === 'success') {
      setShowDeletePopup(false);
      setSelectedIDs([]);
      getData();
    }
  }, [statusDeleteExtracts]);

  // get new data when change page or search
  useChangeEffect(() => {
    getData();
  }, [currentPage, contentPerPage]);

  const onGoSearch = () => {
    if (currentPage === 1) {
      getExtracts(0, contentPerPage, valueSearch);
    } else {
      setCurrentPage(1);
    }
  };

  const onClear = () => {
    setValueSearch('');

    if (currentPage === 1) {
      getExtracts(0, contentPerPage);
    } else {
      setCurrentPage(1);
    }
  };

  const onDelete = () => {
    deleteExtracts({
      extractsIds: selectedIDs,
    });
  };

  const getData = () => {
    const firstContentIndex = (currentPage - 1) * contentPerPage;
    getExtracts(firstContentIndex, contentPerPage, valueSearch);
  };

  return (
    <>
      <TabContent visible={visible}>
        <TableBuilder
          className="extract-table"
          path="edit"
          contentPerPage={contentPerPage}
          title={tableTitles}
          data={tableData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageCount={pageCount}
          selectedIDs={selectedIDs}
          setSelectedIDs={setSelectedIDs}
          valueSearch={valueSearch}>
          <div className="table-header">
            <ul className="left-side">
              <li className="elem">
                <FieldSearch
                  value={valueSearch}
                  setValue={(event) => setValueSearch(event.target.value)}
                  onClear={onClear}
                  onGoSearch={onGoSearch}
                />
              </li>
              <li className="elem">
                <DropdownCount
                  contentPerPage={contentPerPage}
                  data={variables.dropdownContent}
                  onSetContentPerPage={setContentPerPage}
                  setCurrentPage={() => setCurrentPage(1)}
                  persistName={persistName}
                />
              </li>
              <li className="elem">
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
              </li>
            </ul>

            <ul className="right-side">
              {!!countSelectedRows && (
                <li className="elem">
                  <Selected count={countSelectedRows} onTrash={() => setShowDeletePopup(true)} />
                </li>
              )}
              <li className="elem">
                <Button title="Produkt hinzufügen" onEvent={() => navigate('add')} width="173" color="green" />
              </li>
            </ul>
          </div>
        </TableBuilder>
      </TabContent>

      {isShowDeletePopup && <DeletePopup title='Ausgewählte Extrakte löschen?' onDelete={onDelete} onClose={() => setShowDeletePopup(false)} />}

      {!isShowLoader && (statusGetExtracts === 'pending' || statusDeleteExtracts === 'pending') && (
        <Loader type="transparent" />
      )}
    </>
  );
};

export default List;

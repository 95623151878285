import Cookies from 'js-cookie';

import * as variable from '../variables';

export const auth = {
  postLogin: (data) => {
    return fetch(`https://${variable.url}/auth/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  postLoginDocCheck: (data) => {
    return fetch(`https://${variable.url}/auth/login-doc-check`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  getRefresh: () => {
    const refreshToken = Cookies.get('refreshToken');

    if (refreshToken !== typeof undefined) {
      return fetch(`https://${variable.url}/auth/refresh`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      });
    } else {
      window.location = '/login';
    }
  },

  getLogout: () => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/auth/logout`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  postForgotPassword: (data) => {
    return fetch(`https://${variable.url}/auth/forgot`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

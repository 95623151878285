import React, { useState, useEffect } from 'react';

import './style.scss';

import TemplatePopup from '../../../components/templatePopup';
import Button from '../../../components/UI/button';
import Field from '../../../components/UI/field';

const CreateShapePopup = ({ onSave, onClose, cardData }) => {
  const [data, setData] = useState({
    active: true,
    name: '',
    receipe: '',
  });

  const isEdit = cardData;

  useEffect(() => {
    isEdit && setData(cardData);
  }, [cardData]);

  const onSaveEvent = () => {
    if (isEdit) {
      onSave(cardData.id, data);
    } else {
      onSave(data);
    }
  };

  return (
    <TemplatePopup
      onClose={onClose}
      title='Neue Abgabeform hinzufügen'
      className='create-shape-popup'
    >
      <div className='content'>
        <div className='add-name'>
          <Field
            label='Name der Abgabeform'
            name='name'
            type='text'
            value={data.name}
            onEvent={(event) => setData({ ...data, name: event.target.value })}
          />
          <Field
            label='Rezept-Text'
            name='prescription'
            type='text'
            value={data.receipe}
            onEvent={(event) => setData({ ...data, receipe: event.target.value })}
          />
        </div>

        <div className='btns'>
          <Button
            title='Abbrechen'
            width='137'
            color='gray'
            onEvent={onClose}
          />
          <Button
            title='Speichern'
            width='130'
            color='green'
            onEvent={onSaveEvent}
          />
        </div>
      </div>
    </TemplatePopup>
  );
};

export default CreateShapePopup;

import React, { useEffect, useMemo, useState, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useChangeEffect, useDelay, useOnOutsideClick, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import Button from '../../../components/UI/button';
import Loader from '../../../components/popups/loader';
import ProductCard from '../../../components/productCard';
import LinkBack from '../../../components/UI/linkBack';
import Tabs from '../../../components/UI/tabs';

import * as variable from '../../../variables';

import defaultExtractImage from '../../../assets/images/default-extract-img.svg';
import { getSanitizedData } from '../../../utils';
import ManufactureInfoPopup from '../../../components/popups/manufactureInfoPopup/ManufactureInfoPopup';
import ConsistencyBar from '../../../components/consistencyBar';
import useProductInfo from '../../../hooks/useProductInfo';

const DoctorExtractsInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const divRef = useRef(null);
  const slideRef = useRef(null);
  const isShow = useDelay(100);

  usePageUp();

  const tabsList = [
    {
      id: 1,
      name: 'Über das Produkt',
      count: null,
    },
  ];

  const [activeTabID, setActiveTabID] = useState(1);
  const [isShowFullDescr, setIsShowFullDescr] = useState(false);
  const [mainImg, setMainImg] = useState();
  const [isShowLoader, setShowLoader] = useState(true);
  const [isShowCompanyInfo, setIsShowCompanyInfo] = useState(false);
  const { innerBorderRef } = useOnOutsideClick(() => setIsShowCompanyInfo(false));

  const statusExtracts = useSelector(reducer.request.doctor.extracts.state.getDoctorsExtractsId.status);
  const data = useSelector(reducer.request.doctor.extracts.state.getDoctorsExtractsId.data);
  const prescriptionItems = useSelector(reducer.app.prescription.state.items);

  const getDoctorsExtractsId = (id) => dispatch(action.request.doctor.extracts.getDoctorsExtractsId.get(id));

  const addToPrescription = (data) => dispatch(action.app.prescription.addToPrescription(data));
  const removeFromPrescription = (id) =>
    dispatch(action.app.prescription.removeFromPrescription({ id, type: 'extract' }));

  const isRequestGETSuccess = statusExtracts === 'success';
  const terpenesSymptoms = data?.useTerpenesSymptoms ? data?.terpenes.map((item) => item.symptom) : [data?.symptoms];

  const isAdded = useMemo(
    () => prescriptionItems.some(({ id, type }) => id === data?.id && type === 'extract'),
    [data, prescriptionItems],
  );

  const altImages = useMemo(
    () => [data?.imageKey, data?.imageKey1, data?.imageKey2, data?.imageKey3, data?.imageKey4].filter(Boolean),
    [data],
  );

  const { productInfo } = useProductInfo({ data });

  const onAddRemoveHandler = (data, isAdded, isRemove = true) => {
    isAdded ? (isRemove ? removeFromPrescription(data.id) : navigate('/prescription')) : addToPrescription(data);
  };

  const onCancel = () => {
    navigate('/extracts');
  };

  const showDescriptionHandler = () => {
    setIsShowFullDescr(!isShowFullDescr);
  };

  const changeMainImgHandler = (img) => {
    setMainImg(img);
  };

  const scroll = (scrollOffset) => {
    slideRef.current.scrollBy({
      left: scrollOffset,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setMainImg(data?.imageKey);
  }, [data]);

  useLayoutEffect(() => {
    getDoctorsExtractsId(id);
  }, [id]);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  return (
    <>
      <Header />
      <main className={`doctor-product-page pharmacie-product-page page ${isShow ? 'show' : ''}`}>
        {!isShowLoader && (
          <div className="container">
            <LinkBack onEvent={onCancel} title="Zurück zu Extrakten" />
            <section className="product-wrapper">
              <div className="section-top-part">
                <div className="product-images">
                  {altImages.length > 1 && (
                    <ul className="product-images-alt">
                      {altImages.map((item, index) => (
                        <li
                          onClick={() => changeMainImgHandler(item)}
                          key={index}
                          className={`product-images-alt-item ${mainImg === item ? 'active' : ''}`}>
                          <img src={`https://${variable.url}/uploads/${item}`} alt="" />
                        </li>
                      ))}
                    </ul>
                  )}
                  <p className="product-images-main">
                    <img src={mainImg ? `https://${variable.url}/uploads/${mainImg}` : defaultExtractImage} alt="" />
                  </p>
                </div>
                <ul className="product-info">
                  <li className="product-header">
                    <h1 className="product-title">{data?.name}</h1>
                  </li>
                  <li className="product-add">
                    <Button
                      color={`green ${isAdded ? 'added' : ''}`}
                      title={isAdded ? 'In Rezeptkonfigurator übernehmen' : 'zum Rezept hinzufügen'}
                      width={300}
                      onEvent={() => onAddRemoveHandler(data, isAdded, false)}
                    />
                  </li>
                  <ul className="product-settings">
                    {Object.keys(productInfo).map((key, index) => {
                      const value = productInfo[key];
                      const companyInfo = index === 0 && productInfo[key];

                      return (
                        <li key={index} className="list-item">
                          <span className="list-name">{key}</span>
                          <span className="between-text-dots"></span>
                          {index === 0 ? (
                            <span className="list-value ">
                              {value[1] ? (
                                <img src={`https://${variable.url}/uploads/${value[1]}`} alt="" />
                              ) : (
                                value[0] || 'N.A.'
                              )}
                              {value[2] && (
                                <div ref={innerBorderRef} className="manufacturer-info-wrapper">
                                  <button
                                    className="list-btn-info"
                                    onClick={() => setIsShowCompanyInfo(!isShowCompanyInfo)}></button>
                                  {companyInfo && isShowCompanyInfo && (
                                    <ManufactureInfoPopup
                                      description={value[2]}
                                      setIsShowCompanyInfo={setIsShowCompanyInfo}
                                      isShowCompanyInfo={isShowCompanyInfo}
                                    />
                                  )}
                                </div>
                              )}
                            </span>
                          ) : (
                            <span className="list-value">{value}</span>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </ul>
              </div>
              <Tabs list={tabsList} activeTabID={activeTabID} setActiveTabID={setActiveTabID} />
              <div className="section-bottom-part">
                <div className="product-description">
                  <h1 className="product-title">Produktbeschreibung</h1>
                  <p
                    className={`product-description-text ${isShowFullDescr ? 'max' : 'min'}`}
                    dangerouslySetInnerHTML={getSanitizedData(data?.description)}></p>
                  {data?.description.length >= 404 && (
                    <button onClick={showDescriptionHandler} className="product-description-show-more">
                      Mehr
                    </button>
                  )}
                </div>
                <div className="product-details">
                  <h1 className="product-title">Zielwert - Hauptbestandteile * (%)</h1>
                  <p className="product-under-title">
                    *Ein vom Lieferanten angegebener Zielwert, der bei jeder Charge erreicht werden sollte.
                  </p>
                  <ConsistencyBar data={data} />
                  <h1 className="product-title syptoms">Symptome, bei denen dieses Produkt eingesetzt werden kann</h1>
                  <ul className="product-syptoms">
                    {terpenesSymptoms.map((item, index) => (
                      <li key={index} className="product-syptoms-item">
                        {item || 'N.A.'}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
            {!!data?.substitutions.length && data?.useSubstitution && (
              <section className="analog-wrapper" ref={divRef}>
                <div className="analog-header">
                  <h1 className="analog-title">
                    Liste potenzieller Ersatzprodukte (basierend auf THC-, CBD- und Terpenwerten)
                  </h1>
                  <div className="analog-nav">
                    <button className={``} onClick={() => scroll(-239)}></button>
                    <button className={``} onClick={() => scroll(239)}></button>
                  </div>
                </div>
                <ul className="analog-card" ref={slideRef}>
                  {data?.substitutions.map((item, index) => (
                    <ProductCard
                      key={item.id}
                      {...item}
                      onEvent={onAddRemoveHandler}
                      type="extract"
                      icon="prescription"
                      isAdded={prescriptionItems.some(({ id, type }) => id === item.id && type === 'extract')}
                    />
                  ))}
                </ul>
              </section>
            )}
          </div>
        )}
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default DoctorExtractsInfo;

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as action from '../../../store/action';
import * as reducer from '../../../store/reducer';

import { useChangeEffect, useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import LinkBack from '../../../components/UI/linkBack';
import Button from '../../../components/UI/button';
import Loader from '../../../components/popups/loader';
import Field from '../../../components/UI/field';

import { getPharmacieInfo } from '../../../utils/getPharmacieInfo';

import * as variable from '../../../variables';

import defaultImg from '../../../assets/images/default-img.png';
import UploadFiles from '../../../components/uploadFiles/UploadFiles';
import _ from 'lodash';

const ManufacturerPharmacie = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const isShow = useDelay(100);
  const typeUploadFiles = 'pdf';

  usePageUp();

  const data = useSelector(reducer.request.manufacturer.pharmacies.state.getManufacturerPharmaciesId.data);
  const statusOrders = useSelector(reducer.request.manufacturer.pharmacies.state.getManufacturerPharmaciesId.status);
  const isDataChages = useSelector(reducer.app.common.state.isDataChages);

  const setDataChage = (isChange) => dispatch(action.app.common.isDataChages(isChange));
  const getManufacturerPharmaciesId = (id) =>
    dispatch(action.request.manufacturer.pharmacies.getManufacturerPharmaciesId.get(id));
  const putManufacturerPharmaciesId = (id, data) =>
    dispatch(action.request.manufacturer.pharmacies.putManufacturerPharmaciesId.put(id, data));

  const [attachment, setAttachment] = useState([]);
  const [externalId, setExternalId] = useState();
  const [isEditExternal, setIsEditExternal] = useState();
  const [isShowLoader, setShowLoader] = useState(true);

  const isRequestGETSuccess = statusOrders === 'success';

  const onCancel = () => {
    if (isDataChages) {
      if (window.confirm('Bestätigen Sie die Aktion auf der Seite. Die Änderungen zurücksetzen?')) {
        setDataChage(false);
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  };

  const onExternalHandler = useCallback(() => {
    if (externalId) {
      setExternalId(externalId);
      putManufacturerPharmaciesId(id, { files: attachment.map((item) => item.key), externalId });
      setIsEditExternal(false);
      setDataChage(false);
    }
  }, [externalId, attachment]);

  const editExternalIdHandler = (isEdit) => {
    setExternalId(externalId);
    setIsEditExternal(isEdit);
  };

  useEffect(() => {
    if (data) {
      setAttachment(data.files);
      setExternalId(data.externalId);
      setIsEditExternal(!data.externalId);
    }
  }, [data]);

  useEffect(() => {
    getManufacturerPharmaciesId(id);
    setDataChage(false);
  }, []);

  useEffect(() => {
    setDataChage(isEditExternal);
  }, [isEditExternal]);

  useEffect(() => {
    if (data && attachment && externalId) {
      if (!_.isEqual(data.files, attachment) && attachment.every((item) => item.key)) {
        putManufacturerPharmaciesId(id, { files: attachment.map((item) => item.key), externalId });
      }
    }
  }, [data, attachment]);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  return (
    <>
      <Header />
      <main className={`pharmacie-edit-page page ${isShow ? 'show' : ''}`}>
        <div className="container">
          <LinkBack onEvent={onCancel} title="Zurück zu Bestellinformation" />
          <h2 className="title">{data?.name}</h2>
          <div className="add-edit">
            <div className="left-side">
              <section className="section-wrapper field-list-one">
                <ul>
                  <li>
                    <Field
                      label="Interne Nummer"
                      placeholder={externalId || 'XX-XXX-XX'}
                      type="text"
                      value={externalId}
                      disabled={!isEditExternal}
                      onEvent={(e) => setExternalId(e.target.value)}
                    />
                    {isEditExternal ? (
                      <Button title="Speichern" onEvent={onExternalHandler} width="201" color="green" />
                    ) : (
                      <button onClick={() => editExternalIdHandler(true)} className="edit-external-btn">
                        Edit
                      </button>
                    )}
                  </li>
                </ul>
                <ul>
                  {Object.keys(getPharmacieInfo(data).billingAddress).map((key, index) => {
                    const value = getPharmacieInfo(data).billingAddress[key];
                    return (
                      <li key={index} className="list-item">
                        <span className="list-name">{key}</span>
                        <span className="between-text-dots"></span>
                        <span className="list-value">{value}</span>
                      </li>
                    );
                  })}
                </ul>
                <ul>
                  <h3 className="list-title">Rechnungs- und Lieferadresse sind gleich</h3>
                  {Object.keys(getPharmacieInfo(data).deliveryAddress).map((key, index) => {
                    const value = getPharmacieInfo(data).deliveryAddress[key];
                    return (
                      <li key={index} className="list-item">
                        <span className="list-name">{key}</span>
                        <span className="between-text-dots"></span>
                        <span className="list-value">{value}</span>
                      </li>
                    );
                  })}
                </ul>
                <ul>
                  <h2 className="group-title">Ansprechpartner</h2>
                  {Object.keys(getPharmacieInfo(data).interlocutor).map((key, index) => {
                    const value = getPharmacieInfo(data).interlocutor[key];
                    return (
                      <li key={index} className="list-item">
                        <span className="list-name">{key}</span>
                        <span className="between-text-dots"></span>
                        {key === 'Web' ? (
                          <a href={value} className="list-value">
                            {value}
                          </a>
                        ) : (
                          <span className="list-value">{value}</span>
                        )}
                      </li>
                    );
                  })}
                </ul>
                <ul>
                  <h2 className="group-title">Informationen</h2>
                  <h3 className="list-title">Öffnungszeiten</h3>
                  {Object.keys(getPharmacieInfo(data).openingHours).map((key, index) => {
                    const value = getPharmacieInfo(data).openingHours[key];

                    return (
                      <li key={index} className="list-item">
                        <span className="list-name">{key}</span>
                        <span className="between-text-dots"></span>
                        <span className="list-value">
                          {`${value[0] ? `${value[0]}-${value[1]}` : 'geschlossen'} ${
                            value[2] ? `, ${value[2]}-${value[3]}` : ''
                          }`}
                        </span>
                      </li>
                    );
                  })}
                </ul>
                <UploadFiles title="Dateien" attachment={attachment} setAttachment={setAttachment} />
                <ul>
                  <li>
                    <Field label="DocCheck Token ID" type="text" value={data?.docCheckId} disabled={true} />
                  </li>
                </ul>
              </section>
            </div>
            <aside className="right-side">
              <ul className="field-list-two">
                <li className="pharmacie-logo">
                  <img src={data?.logo ? `https://${variable.url}/uploads/${data?.logo}` : defaultImg} alt="" />
                </li>
              </ul>
            </aside>
          </div>
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default ManufacturerPharmacie;

import { createAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import * as api from '../../../../../api';
import * as type from '../../../../type';
import * as action from '../../../../action';

export const putManufacturer = {
  put: function (...props) {
    return (dispatch) => {
      dispatch(this.putPending());

      api.manufacturer.user
        .putManufacturer(...props)
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          dispatch(this.putSuccess());
          toast.success('Benutzerinformationen erfolgreich geändert');
        })
        .catch((error) => {
          if (error.status === 403 || error.status === 404) {
            window.location = '/';
          }
          if (error.status === 401) {
            dispatch(action.request.auth.getRefresh.get(this.put(...props)));
          } else {
            dispatch(this.putFailed(error.statusText));
            toast.error('Benutzerinformationen konnten nicht geändert werden');
          }
        });
    };
  },

  putSuccess: createAction(type.request.manufacturer.user.putManufacturerSuccess),
  putPending: createAction(type.request.manufacturer.user.putManufacturerPending),
  putFailed: createAction(type.request.manufacturer.user.putManufacturerFailed),
};

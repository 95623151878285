import * as Yup from 'yup';

const phoneRegExp = /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g;

const title = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'Dieses Feld darf nicht nur Leerzeichen enthalten')
  .min(2, 'Enter at least 2 characters')
  .max(24, 'Enter up to 24 characters')
  .required('Dieses Feld ist erforderlich');

const description = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'Dieses Feld darf nicht nur Leerzeichen enthalten')
  .min(1, 'Min - 1 symbol')
  .max(1000, 'Max - 1000 symbols')
  .required('Dieses Feld ist erforderlich');

const phone = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'Dieses Feld darf nicht nur Leerzeichen enthalten')
  // .matches(phoneRegExp, 'Bitte gib nicht mehr als 20 Zeichen ein')
  .max(20, 'Bitte gib nicht mehr als 20 Zeichen ein')
  .required('Dieses Feld ist erforderlich');

const contactFax = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'Dieses Feld darf nicht nur Leerzeichen enthalten')
  // .matches(phoneRegExp, 'Bitte gib nicht mehr als 20 Zeichen ein')
  .max(20, 'Bitte gib nicht mehr als 20 Zeichen ein')

const isTerms = Yup.bool().isTrue('Dieses Feld ist erforderlich');

// ManufaturerProfile =======
const firmenname = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'Dieses Feld darf nicht nur Leerzeichen enthalten')
  .max(80, 'Bitte gib nicht mehr als 80 Zeichen ein')
  .required('Bitte gib nicht mehr als 80 Zeichen ein');

const contactname = Yup.string()
  .nullable()
  .notRequired()
  .when('contactname', {
    is: (value) => value?.length,
    then: (rule) =>
      rule
        .max(35, 'Bitte gib nicht mehr als 35 Zeichen ein')
        .matches(/^[a-zA-Z\s-]+$/g, 'Das Feld darf keine Zahlen oder Symbole enthalten'),
  });

const contactphone = Yup.string()
  .nullable()
  .notRequired()
  .when('contactphone', {
    is: (value) => value?.length,
    then: (rule) =>
      rule
        // .matches(phoneRegExp, 'Bitte gib nicht mehr als 20 Zeichen ein')
        .max(20, 'Bitte gib nicht mehr als 20 Zeichen ein'),
  });

const contactemail = Yup.string()
  .nullable()
  .notRequired()
  .when('contactemail', {
    is: (value) => value?.length,
    then: (rule) =>
      rule
        .email('Bitte gib eine gültige E-Mail Adresse ein')
        .matches(/([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)/g, 'Bitte gib eine gültige E-Mail Adresse ein'),
  });

const website = Yup.string()
  .nullable()
  .notRequired()
  .when('website', {
    is: (value) => value?.length,
    then: (rule) =>
      rule.matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?\/?$/,
        'Enter correct website url, example: https://www.google.com/',
      ),
  });

const websiteReq = Yup.string()
  .nullable()
  .required('Dieses Feld ist erforderlich')
  .when('website', {
    is: (value) => value?.length,
    then: (rule) =>
      rule.matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?\/?$/,
        'Enter correct website url, example: https://www.google.com/',
      ),
  });

const email = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'Dieses Feld darf nicht nur Leerzeichen enthalten')
  .email('Bitte gib eine gültige E-Mail Adresse ein')
  .matches(/([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)/g, 'Enter the correct email')
  .required('Bitte gib eine gültige E-Mail Adresse ein');

const zip = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'Dieses Feld darf nicht nur Leerzeichen enthalten')
  .min(5, 'Bitte gib nicht mehr als 5 Zeichen ein')
  .max(5, 'Bitte gib nicht mehr als 5 Zeichen ein')
  .matches(/^\d+$/g, 'Das Feld darf keine Buchstaben oder Symbole enthalten')
  .required('Bitte gib nicht mehr als 5 Zeichen ein');

const city = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'Dieses Feld darf nicht nur Leerzeichen enthalten')
  .min(1, 'Min - 1 symbol')
  .required('Dieses Feld ist erforderlich');

const street = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'Dieses Feld darf nicht nur Leerzeichen enthalten')
  .min(1, 'Min - 1 symbol')
  .required('Dieses Feld ist erforderlich');

const house = Yup.string()
  .nullable()
  .matches(/^\s*\S[\s\S]*$/, 'Dieses Feld darf nicht nur Leerzeichen enthalten')
  .min(1, 'Min - 1 symbol')
  .required('Dieses Feld ist erforderlich');

const mandescription = Yup.string()
  .nullable()

  .notRequired()
  .when('mandescription', {
    is: (value) => value?.length,
    then: (rule) =>
      rule
        .matches(/^\s*\S[\s\S]*$/, 'Dieses Feld darf nicht nur Leerzeichen enthalten')
        .min(1, 'Min - 1 symbol')
        .max(1000, 'Max - 1000 symbols'),
  });

const minOrderAmount = Yup.string()
  .nullable()
  .test('minOrderAmount', 'Die Zahl muss größer als 0 sein', (context) => {
    return parseInt(context) !== 0;
  })
  .when('maxOrderAmount', (maxOrderAmount, schema) => {
    return schema.test({
      test: (minOrderAmount) => {
        return parseFloat(minOrderAmount) <= parseFloat(maxOrderAmount);
      },
      message: 'Mindestzahl ist zu groß',
    });
  })
  .required('Die Zahl muss größer als 0 sein');

const maxOrderAmount = Yup.string()
  .nullable()
  .test('maxOrderAmount', 'Die Zahl muss größer als 0 sein', (context) => {
    return parseInt(context) !== 0;
  })
  .when('minOrderAmount', (minOrderAmount, schema) => {
    return schema.test({
      test: (maxOrderAmount) => {
        return parseFloat(maxOrderAmount) >= parseFloat(minOrderAmount);
      },
      message: 'Maximalmenge ist zu klein',
    });
  })
  .required('Die Zahl muss größer als 0 sein');

const availableAmount = Yup.string()
  .nullable()
  .matches(
    /^(?!(?:^[-+]?[0.]+(?:[Ee]|$)))(?!(?:^-))(?:(?:[+-]?)(?=[0123456789.])(?:(?:(?:[0123456789]+)(?:(?:[.])(?:[0123456789]*))?|(?:(?:[.])(?:[0123456789]+))))(?:(?:[Ee])(?:(?:[+-]?)(?:[0123456789]+))|))$/,
    'Die Zahl muss größer als 0 sein',
  )
  .when('minOrderAmount', (minOrderAmount, schema) => {
    return schema.test({
      test: (availableAmount) => {
        return parseFloat(availableAmount) >= parseFloat(minOrderAmount);
      },
      message: 'Restmenge ist zu klein',
    });
  })
  .required('Die Zahl muss größer als 0 sein');

export const ProductEditSchemaEx = Yup.object().shape(
  {
    name: title,
    description,
    minOrderAmount,
    maxOrderAmount,
    availableAmount,
  },
  [['minOrderAmount', 'maxOrderAmount', 'availableAmount']],
);

export const ProductEditSchema = Yup.object().shape(
  {
    minOrderAmount,
    maxOrderAmount,
    availableAmount,
  },
  [['minOrderAmount', 'maxOrderAmount', 'availableAmount']],
);

export const ProductAddSchema = Yup.object().shape({
  description,
});

export const ManufacturerEditFullSchema = Yup.object().shape({
  name: firmenname,
  email,
  phone,
  billingZip: zip,
  billingCity: city,
  billingStreet: street,
  billingHouse: house,
  deliveryZip: zip,
  deliveryCity: city,
  deliveryStreet: street,
  deliveryHouse: house,
  contactName: firmenname,
  contactEmail: email,
  contactPhone: phone,
  technicalName: contactname,
  technicalEmail: contactemail,
  technicalPhone: contactphone,
  bookkeepingName: contactname,
  bookkeepingEmail: contactemail,
  bookkeepingPhone: contactphone,
  description: mandescription,
});

export const ManufacturerEditSchema = Yup.object().shape({
  name: firmenname,
  email,
  phone,
  billingZip: zip,
  billingCity: city,
  billingStreet: street,
  billingHouse: house,
  contactName: firmenname,
  contactEmail: email,
  contactPhone: phone,
  technicalName: contactname,
  technicalEmail: contactemail,
  technicalPhone: contactphone,
  bookkeepingName: contactname,
  bookkeepingEmail: contactemail,
  bookkeepingPhone: contactphone,
  description: mandescription,
});

export const PharmacieEditSchema = Yup.object().shape({
  name: firmenname,
  billingZip: zip,
  billingCity: city,
  billingStreet: street,
  billingHouse: house,
  contactName: firmenname,
  contactEmail: email,
  contactPhone: phone,
  contactWeb: websiteReq,
  contactFax: contactFax,
  // contactAddress: description,
});

export const PharmacieEditFullSchema = Yup.object().shape({
  name: firmenname,
  billingZip: zip,
  billingCity: city,
  billingStreet: street,
  billingHouse: house,
  deliveryZip: zip,
  deliveryCity: city,
  deliveryStreet: street,
  deliveryHouse: house,
  contactName: firmenname,
  contactEmail: email,
  contactPhone: phone,
  contactWeb: websiteReq,
  contactFax: contactFax,
  // contactAddress: description,
});

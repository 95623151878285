export const mailTemplates = {
  getMailTemplatesIDSuccess: 'request/getMailTemplatesIDSuccess',
  getMailTemplatesIDPending: 'request/getMailTemplatesIDPending',
  getMailTemplatesIDFailed: 'request/getMailTemplatesIDFailed',

  putMailTemplatesIDSuccess: 'request/putMailTemplatesIDSuccess',
  putMailTemplatesIDPending: 'request/putMailTemplatesIDPending',
  putMailTemplatesIDFailed: 'request/putMailTemplatesIDFailed',

  getMailTemplatesSuccess: 'request/getMailTemplatesSuccess',
  getMailTemplatesPending: 'request/getMailTemplatesPending',
  getMailTemplatesFailed: 'request/getMailTemplatesFailed',
};

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.terpenes.getTerpenesAll.getSuccess, ({ getTerpenesAll }, action) => {
      getTerpenesAll.status = 'success';
      getTerpenesAll.data = action.payload;
    })
    .addCase(action.request.terpenes.getTerpenesAll.getPending, ({ getTerpenesAll }, action) => {
      getTerpenesAll.status = 'pending';
    })
    .addCase(action.request.terpenes.getTerpenesAll.getFailed, ({ getTerpenesAll }, action) => {
      getTerpenesAll.status = 'failed';
      getTerpenesAll.error = action.payload;
      getTerpenesAll.data = null;
    });

  builder
    .addCase(action.request.terpenes.getTerpeneId.getSuccess, ({ getTerpeneId }, action) => {
      getTerpeneId.status = 'success';
      getTerpeneId.data = action.payload;
    })
    .addCase(action.request.terpenes.getTerpeneId.getPending, ({ getTerpeneId }, action) => {
      getTerpeneId.status = 'pending';
    })
    .addCase(action.request.terpenes.getTerpeneId.getFailed, ({ getTerpeneId }, action) => {
      getTerpeneId.status = 'failed';
      getTerpeneId.error = action.payload;
      getTerpeneId.data = null;
    });

  builder
    .addCase(action.request.terpenes.putTerpeneId.putSuccess, ({ putTerpeneId }, action) => {
      putTerpeneId.status = 'success';
      putTerpeneId.data = action.payload;
    })
    .addCase(action.request.terpenes.putTerpeneId.putPending, ({ putTerpeneId }, action) => {
      putTerpeneId.status = 'pending';
    })
    .addCase(action.request.terpenes.putTerpeneId.putFailed, ({ putTerpeneId }, action) => {
      putTerpeneId.status = 'failed';
      putTerpeneId.error = action.payload;
      putTerpeneId.data = null;
    });

  builder
    .addCase(action.request.terpenes.deleteTerpeneId.deleteSuccess, ({ deleteTerpeneId }, action) => {
      deleteTerpeneId.status = 'success';
      deleteTerpeneId.data = action.payload;
    })
    .addCase(action.request.terpenes.deleteTerpeneId.deletePending, ({ deleteTerpeneId }, action) => {
      deleteTerpeneId.status = 'pending';
    })
    .addCase(action.request.terpenes.deleteTerpeneId.deleteFailed, ({ deleteTerpeneId }, action) => {
      deleteTerpeneId.status = 'failed';
      deleteTerpeneId.error = action.payload;
      deleteTerpeneId.data = null;
    });

  builder
    .addCase(action.request.terpenes.postTerpenes.postSuccess, ({ postTerpenes }, action) => {
      postTerpenes.status = 'success';
      postTerpenes.data = action.payload;
    })
    .addCase(action.request.terpenes.postTerpenes.postPending, ({ postTerpenes }, action) => {
      postTerpenes.status = 'pending';
    })
    .addCase(action.request.terpenes.postTerpenes.postFailed, ({ postTerpenes }, action) => {
      postTerpenes.status = 'failed';
      postTerpenes.error = action.payload;
      postTerpenes.data = null;
    });
  builder.addCase(action.request.terpenes.resetPostData, (state) => {
    state.postTerpenes = initialState.postTerpenes;
  });
});

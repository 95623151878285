import React from 'react';

import './style.scss';

const Textarea = ({ error, label, value, placeholder, onChange, onKeyDown, disabled }) => {
  return (
    <p className={`default-textarea ${error ? 'error' : ''}`}>
      {label && <label className="label">{label}</label>}
      <textarea
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        disabled={disabled}
      ></textarea>
    </p>
  );
};

export default Textarea;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../../store/reducer';
import * as util from '../../../../../utils';
import * as action from '../../../../../store/action';

import { TabContent } from '../../../../../HOC';
import { useChangeEffect } from '../../../../../hooks';
import { variables } from './variables';

import TableBuilder from '../../../../../components/tableBuilder';
import FieldSearch from '../../../../../components/UI/fieldSearch';
import DropdownCount from '../../../../../components/dropdownCount';
import Pagination from '../../../../../components/UI/pagination';
import Loader from '../../../../../components/popups/loader';
import { useEffect } from 'react';
import { useDefaultPerPage } from '../../../../../hooks/useDefaultPerPage';

const EmailTemplates = ({ visible, isShowLoader }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataMailTemplates = useSelector(reducer.request.mailTemplates.state.getMailTemplates.data);
  const statusGetMailTemplates = useSelector(reducer.request.mailTemplates.state.getMailTemplates.status);

  const getMailTemplates = (start, limit, search) =>
    dispatch(action.request.mailTemplates.getMailTemplates.get(start, limit, search));

  const [valueSearch, setValueSearch] = useState('');

  const persistName = 'superuserMailTemplates';

  const { contentPerPage, setContentPerPage } = useDefaultPerPage(persistName);

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedIDs, setSelectedIDs] = useState([]);

  const pageCount = Math.ceil(dataMailTemplates?.total / contentPerPage) || 1;

  const tableTitles = ['№', 'Status', 'Templates', 'Trigger'];
  const tableData = dataMailTemplates?.mailTemplates.reduce((acc, item, index) => {
    acc = [
      ...acc,
      {
        id: item.id,
        number: currentPage !== 1 ? (currentPage - 1) * contentPerPage + index + 1 : index + 1,
        status: util.getStatusManufacture(item.active),
        templates: item.subject,
        trigger: item.trigger,
      },
    ];

    return acc;
  }, []);

  // get new data when change page or search
  useEffect(() => {
    getData();
  }, [currentPage, contentPerPage]);

  const onGoSearch = () => {
    if (currentPage === 1) {
      getMailTemplates(0, contentPerPage, valueSearch);
    } else {
      setCurrentPage(1);
    }
  };

  const onClear = () => {
    setValueSearch('');

    if (currentPage === 1) {
      getMailTemplates(0, contentPerPage, '');
    } else {
      setCurrentPage(1);
    }
  };

  const getData = () => {
    const firstContentIndex = (currentPage - 1) * contentPerPage;
    getMailTemplates(firstContentIndex, contentPerPage, valueSearch);
  };

  return (
    <>
      <TabContent visible={visible}>
        <TableBuilder
          className="email-templates-table"
          path="email-templates/edit"
          contentPerPage={contentPerPage}
          title={tableTitles}
          data={tableData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageCount={pageCount}
          selectedIDs={selectedIDs}
          setSelectedIDs={setSelectedIDs}
          valueSearch={valueSearch}>
          <div className="table-header">
            <ul className="left-side">
              <li className="elem">
                <FieldSearch
                  value={valueSearch}
                  setValue={(event) => setValueSearch(event.target.value)}
                  onClear={onClear}
                  onGoSearch={onGoSearch}
                />
              </li>
              <li className="elem">
                <DropdownCount
                  contentPerPage={contentPerPage}
                  data={variables.dropdownContent}
                  onSetContentPerPage={setContentPerPage}
                  setCurrentPage={() => setCurrentPage(1)}
                  persistName={persistName}
                />
              </li>
              <li className="elem">
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
              </li>
            </ul>
          </div>
        </TableBuilder>
      </TabContent>

      {!isShowLoader && visible && statusGetMailTemplates === 'pending' && <Loader type="transparent" />}
    </>
  );
};

export default EmailTemplates;

import React, {  useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import Router from './Router';
import { setInitialContentPerPage } from './utils/setInitialContentPerPage';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const role = Cookies.get('role');
  const accessToken = Cookies.get('accessToken');
  const refreshToken = Cookies.get('refreshToken');

  useLayoutEffect(() => {
    !role && !location.pathname.includes('doc-check') && navigate('/login');
    setInitialContentPerPage();
  }, []);

  useLayoutEffect(() => {
    if (accessToken === undefined || role === undefined || refreshToken === undefined) {
      Cookies.remove('accessToken');
      Cookies.remove('role');
      Cookies.remove('refreshToken');
    }
  }, [accessToken, role, refreshToken]);

  return <Router />;
};

export default App;

import Cookies from 'js-cookie';

import * as variable from '../../variables';

export const pharmacies = {
  getManufacturerPharmaciesId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/manufacturers/pharmacies/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  putManufacturerPharmaciesId: (id, data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/manufacturers/pharmacies/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

import React from 'react';
import { forwardRef } from 'react';

import './style.scss';

const Button = forwardRef(({ title, onEvent, disabled, width, color, type = 'button' }, ref) => {
  return (
    <button
      className={`default-btn ${color}`}
      onClick={type === 'button' ? onEvent : () => {}}
      type={type}
      disabled={disabled}
      style={{ width: `${width}px` }}
      ref={ref}>
      {title}
    </button>
  );
});

export default Button;

import Cookies from 'js-cookie';

import * as variable from '../variables';

export const forms = {
  getFormsAll: () => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/forms/all`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getFormId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/forms/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  putFormId: (id, data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/forms/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteFormId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/forms/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getForms: (start, limit) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/forms?start=${start}&limit=${limit}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  postForms: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/forms`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

import React, { useEffect, useState } from 'react';

import './style.scss';

import TemplatePopup from '../../../components/templatePopup';
import Button from '../../../components/UI/button';
import Field from '../../../components/UI/field';
import ButtonTrash from '../../../components/UI/buttonTrash';

const CreateInstructionsPopup = ({ onSave, onClose, cardData, type }) => {
  const [data, setData] = useState({
    active: true,
    dosageFields: [],
    name: '',
    type: type || '',
  });

  const isEdit = cardData;

  useEffect(() => {
    isEdit && setData(cardData);
  }, [cardData]);

  const onAddField = () => {
    setData({
      ...data,
      dosageFields: [...data.dosageFields, { prefix: '', title: '', value: '', sufix: '' }],
    });
  };

  const onSetDosageFieldsValue = (currentIndex, event) => {
    const { name, value } = event.target;

    const changed = data.dosageFields.map((field, index) => {
      if (index === currentIndex) {
        return { ...field, [name]: value };
      }

      return field;
    });

    setData({ ...data, dosageFields: changed });
  };

  const onDeleteField = (index) => {
    const changed = data.dosageFields.filter((_field, currentIndex) => currentIndex !== index);

    setData({ ...data, dosageFields: changed });
  };

  const onSaveEvent = () => {
    if (isEdit) {
      onSave(cardData.id, data);
    } else {
      onSave(data);
    }
  };

  return (
    <TemplatePopup onClose={onClose} title="Bud-Dosierungsanleitung" className="create-instructions-popup">
      <div className="content">
        <div className="add-name">
          <Field
            label="Name der Abgabeform"
            name="name"
            type="text"
            value={data.name}
            onEvent={(event) => setData({ ...data, name: event.target.value })}
          />
          <p className="message">
            Felder hinzufügen, um Dosierungsanleitung zu <br />
            erstellen (bis zu 4 Felder)
          </p>
        </div>

        <div className="add-fields">
          <ul className="fields">
            {data.dosageFields.map((field, index) => (
              <li className="add-field" key={index}>
                <Field
                  label="Präfix"
                  name="prefix"
                  type="text"
                  value={field.prefix}
                  onEvent={(event) => onSetDosageFieldsValue(index, event)}
                />
                <Field
                  label={
                    <Field
                      name="title"
                      type="text"
                      value={field.title}
                      placeholder="Feldname eingeben"
                      onEvent={(event) => onSetDosageFieldsValue(index, event)}
                    />
                  }
                  name="value"
                  type="text"
                  value={field.value}
                  placeholder="Geben Sie Ihre Anleitung an"
                  onEvent={(event) => onSetDosageFieldsValue(index, event)}
                />
                <Field
                  label="Suffix"
                  name="sufix"
                  type="text"
                  value={field.sufix}
                  onEvent={(event) => onSetDosageFieldsValue(index, event)}
                />

                <ButtonTrash bg="transparent" onClick={() => onDeleteField(index)} />
              </li>
            ))}
          </ul>

          {data.dosageFields.length < 4 && (
            <Button title="+ Feld hinzufügen" width="155" color="transparent" onEvent={onAddField} />
          )}
        </div>

        <div className="btns">
          <Button title="Abbrechen" width="137" color="gray" onEvent={onClose} />
          <Button title="Speichern" width="130" color="green" onEvent={onSaveEvent} />
        </div>
      </div>
    </TemplatePopup>
  );
};

export default CreateInstructionsPopup;

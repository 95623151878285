import React from 'react';

import './style.scss';

import Switcher from '../../../components/UI/switcher';
import ButtonTrash from '../../../components/UI/buttonTrash';

const InfoCard = ({ cardData, type, onToggleActive, onShowDeletePopup, onShowEditPopup }) => {
  return (
    <>
      <section className='info-card'>
        <ButtonTrash
          bg='transparent'
          onClick={onShowDeletePopup}
        />
        <h2
          className='name'
          onClick={onShowEditPopup}
        >
          {cardData.name}
        </h2>

        <p className='fields'>
          {type === 'dosages' &&
            cardData.dosageFields
              ?.map((dosage) => {
                return `${dosage.prefix}${dosage.value}${dosage.sufix}`;
              })
          }

          {type === 'forms' && cardData.receipe}
        </p>

        <Switcher
          label='Status aktiv'
          isChecked={cardData.active}
          onChange={onToggleActive}
        />
      </section>
    </>
  );
};

export default InfoCard;

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.pharmacie.orders.getPharmaciesOrdersId.getSuccess, ({ getPharmaciesOrdersId }, action) => {
      getPharmaciesOrdersId.status = 'success';
      getPharmaciesOrdersId.data = action.payload;
    })
    .addCase(action.request.pharmacie.orders.getPharmaciesOrdersId.getPending, ({ getPharmaciesOrdersId }, action) => {
      getPharmaciesOrdersId.status = 'pending';
    })
    .addCase(action.request.pharmacie.orders.getPharmaciesOrdersId.getFailed, ({ getPharmaciesOrdersId }, action) => {
      getPharmaciesOrdersId.status = 'failed';
      getPharmaciesOrdersId.error = action.payload;
      getPharmaciesOrdersId.data = null;
    });

  builder
    .addCase(action.request.pharmacie.orders.getPharmaciesOrders.getSuccess, ({ getPharmaciesOrders }, action) => {
      getPharmaciesOrders.status = 'success';
      getPharmaciesOrders.data = action.payload;
    })
    .addCase(action.request.pharmacie.orders.getPharmaciesOrders.getPending, ({ getPharmaciesOrders }, action) => {
      getPharmaciesOrders.status = 'pending';
    })
    .addCase(action.request.pharmacie.orders.getPharmaciesOrders.getFailed, ({ getPharmaciesOrders }, action) => {
      getPharmaciesOrders.status = 'failed';
      getPharmaciesOrders.error = action.payload;
      getPharmaciesOrders.data = null;
    });
  builder
    .addCase(action.request.pharmacie.orders.postPharmaciesOrders.postSuccess, ({ postPharmaciesOrders }, action) => {
      postPharmaciesOrders.status = 'success';
      postPharmaciesOrders.data = action.payload;
    })
    .addCase(action.request.pharmacie.orders.postPharmaciesOrders.postPending, ({ postPharmaciesOrders }, action) => {
      postPharmaciesOrders.status = 'pending';
    })
    .addCase(action.request.pharmacie.orders.postPharmaciesOrders.postFailed, ({ postPharmaciesOrders }, action) => {
      postPharmaciesOrders.status = 'failed';
      postPharmaciesOrders.error = action.payload;
      postPharmaciesOrders.data = null;
    });
  builder
    .addCase(
      action.request.pharmacie.orders.deletePharmaciesOrders.deleteSuccess,
      ({ deletePharmaciesOrders }, action) => {
        deletePharmaciesOrders.status = 'success';
        deletePharmaciesOrders.data = action.payload;
      },
    )
    .addCase(
      action.request.pharmacie.orders.deletePharmaciesOrders.deletePending,
      ({ deletePharmaciesOrders }, action) => {
        deletePharmaciesOrders.status = 'pending';
      },
    )
    .addCase(
      action.request.pharmacie.orders.deletePharmaciesOrders.deleteFailed,
      ({ deletePharmaciesOrders }, action) => {
        deletePharmaciesOrders.status = 'failed';
        deletePharmaciesOrders.error = action.payload;
        deletePharmaciesOrders.data = null;
      },
    );
  builder
    .addCase(
      action.request.pharmacie.orders.deletePharmaciesAllOrders.deleteSuccess,
      ({ deletePharmaciesAllOrders }, action) => {
        deletePharmaciesAllOrders.status = 'success';
        deletePharmaciesAllOrders.data = action.payload;
      },
    )
    .addCase(
      action.request.pharmacie.orders.deletePharmaciesAllOrders.deletePending,
      ({ deletePharmaciesAllOrders }, action) => {
        deletePharmaciesAllOrders.status = 'pending';
      },
    )
    .addCase(
      action.request.pharmacie.orders.deletePharmaciesAllOrders.deleteFailed,
      ({ deletePharmaciesAllOrders }, action) => {
        deletePharmaciesAllOrders.status = 'failed';
        deletePharmaciesAllOrders.error = action.payload;
        deletePharmaciesAllOrders.data = null;
      },
    );
  builder
    .addCase(
      action.request.pharmacie.orders.deletePharmaciesOrdersProductId.deleteSuccess,
      ({ deletePharmaciesOrdersProductId }, action) => {
        deletePharmaciesOrdersProductId.status = 'success';
        deletePharmaciesOrdersProductId.data = action.payload;
      },
    )
    .addCase(
      action.request.pharmacie.orders.deletePharmaciesOrdersProductId.deletePending,
      ({ deletePharmaciesOrdersProductId }, action) => {
        deletePharmaciesOrdersProductId.status = 'pending';
      },
    )
    .addCase(
      action.request.pharmacie.orders.deletePharmaciesOrdersProductId.deleteFailed,
      ({ deletePharmaciesOrdersProductId }, action) => {
        deletePharmaciesOrdersProductId.status = 'failed';
        deletePharmaciesOrdersProductId.error = action.payload;
        deletePharmaciesOrdersProductId.data = null;
      },
    );
});

import { createReducer } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { initialState } from './initialState';
import * as action from '../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(action.app.cart.addToCart, (state, action) => {
    const { id, type } = action.payload;

    const selectedManufacturer = action.payload.manufacturers?.filter(
      (item) => item.available,
    )[0];

    state.items[type] = [
      ...state.items[type],
      {
        id,
        type,
        manufacturerId: selectedManufacturer?.id,
        amount: selectedManufacturer.minOrderAmount,
        minOrderAmount: selectedManufacturer.minOrderAmount,
        maxOrderAmount: selectedManufacturer.maxOrderAmount,
        remainingAmount: selectedManufacturer.remainingAmount,
        name: selectedManufacturer.name,
      },
    ];
  });

  builder.addCase(action.app.cart.increaseAmount, (state, action) => {
    const { id, type } = action.payload;

    const increaseItem = state.items[type].find((item) => {
      return item.id === id;
    });

    const newAmount =
      increaseItem.amount + increaseItem.minOrderAmount >= increaseItem.maxOrderAmount
        ? increaseItem.maxOrderAmount
        : increaseItem.amount + increaseItem.minOrderAmount;


    state.items[type] = [...state.items[type].filter((item) => item.id !== id), { ...increaseItem, amount: newAmount }];
  });

  builder.addCase(action.app.cart.decreaseAmount, (state, action) => {
    const { id, type } = action.payload;

    const decreaseItem = state.items[type].find((item) => {
      return item.id === id;
    });

    const newAmount =
      decreaseItem.amount - decreaseItem.minOrderAmount <= decreaseItem.minOrderAmount
        ? decreaseItem.minOrderAmount
        : decreaseItem.amount - decreaseItem.minOrderAmount;

    state.items[type] = [...state.items[type].filter((item) => item.id !== id), { ...decreaseItem, amount: newAmount }];
  });

  builder.addCase(action.app.cart.removeFromCart, (state, action) => {
    const { id, type } = action.payload;

    state.items[type] = [...state.items[type].filter((item) => item.id !== id)];
  });

  builder.addCase(action.app.cart.changeManufacturer, (state, action) => {
    const { productId, newManufacturer, type } = action.payload;

    state.items[type] = state.items[type].map((item) =>
      item.id === productId
        ? {
          ...item,
          manufacturerId: newManufacturer.id,
          amount: newManufacturer.minOrderAmount,
          minOrderAmount: newManufacturer.minOrderAmount,
          maxOrderAmount: newManufacturer.maxOrderAmount,
          remainingAmount: newManufacturer.remainingAmount,
          name: newManufacturer.name,
        }
        : item,
    );
  });

  builder.addCase(action.app.cart.clearCart, (state) => (state = initialState));
});

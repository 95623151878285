import React, { useEffect, useState } from 'react';

import './style.scss';

import * as variable from '../../variables';

import Button from '../UI/button/Button';
import GalleryPopup from '../popups/galleryPopup';

const UploadImg = ({ title, type, view, img, onUploadImg, onSelectImg, placeholder = 'Bildgröße 160x45px ' }) => {
  const [isShowGalleryPopup, setShowGalleryPopup] = useState(false);
  const [selectedImg, setSelectedImg] = useState('');

  const onUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', type);

    onUploadImg(formData);
  };

  useEffect(() => {
    if (selectedImg) onSelectImg(selectedImg);
  }, [selectedImg]);

  return (
    <>
      <section className='upload-img'>
        <h3 className='title'>{title}</h3>

        <div className={`upload-content ${img && view !== 'second' ? 'img' : ''}`}>
          {img && (
            <p className='upload-img'>
              <img
                src={`https://${variable.url}/uploads/${img}`}
                alt=''
              />
            </p>
          )}

          <div className='btns'>
            <Button
              title='aus Galerie wählen'
              width='184'
              color='gray'
              onEvent={() => setShowGalleryPopup(true)}
            />
            <label className='upload-button'>
              hochladen
              <input
                type='file'
                onChange={onUpload}
                onClick={(event) => (event.target.value = null)}
              />
            </label>
          </div>

          {(view === 'second' || !img) && <p className='warning'>{placeholder}</p>}
        </div>
      </section>

      {isShowGalleryPopup && (
        <GalleryPopup
          onClose={() => setShowGalleryPopup(false)}
          setSelectedImg={setSelectedImg}
        />
      )}
    </>
  );
};

export default UploadImg;

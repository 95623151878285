import React, { useEffect, useState, useMemo, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import QueryString from 'qs';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import Button from '../../../components/UI/button';
import Loader from '../../../components/popups/loader';
import CartInfoRow from '../../../components/cartInfoRow';

const PharmaciePreOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isShow = useDelay(100);
  usePageUp();

  const dataProducts = useSelector(reducer.request.pharmacie.products.state.getPharmaciesProducts.data);
  const dataStatus = useSelector(reducer.request.pharmacie.products.state.getPharmaciesProducts.status);
  const dataCart = useSelector(reducer.app.cart.state.items);

  const postPharmaciesOrders = (navigate, data) =>
    dispatch(action.request.pharmacie.orders.postPharmaciesOrders.post(navigate, data));

  const total = useMemo(() => {
    const totalBudsAmount = dataCart['bud'].reduce((acc, item) => acc + item.amount, 0);
    const totalExtractsAmount = dataCart['extract'].reduce((acc, item) => acc + item.amount, 0);
    const totalItems = Object.keys(dataCart).reduce((acc, key) => acc + dataCart[key].length, 0);

    return {
      totalBudsAmount,
      totalExtractsAmount,
      totalItems,
      totalAmount: totalBudsAmount + totalExtractsAmount,
    };
  }, [dataCart]);

  const [separetedProducts, setSeparetedProducts] = useState();

  const params = useMemo(
    () =>
      Object.keys(dataCart)
        .reduce((acc, curr) => {
          acc = [...acc, dataCart[curr].map((item) => ({ id: item.id, type: item.type }))];
          return acc;
        }, [])
        .flat(),
    [dataCart],
  );

  const products = QueryString.stringify({ products: params });

  const getPharmaciesProducts = (params) =>
    dispatch(action.request.pharmacie.products.getPharmaciesProducts.get(params));

  const createOrderHandler = () => {
    const products = Object.keys(dataCart)
      .map((key) =>
        dataCart[key].map((item) => ({
          productId: item.id,
          manufacturerId: item.manufacturerId,
          type: item.type,
          amount: item.amount,
        })),
      )
      .flat();

    postPharmaciesOrders(navigate, {
      products,
    });
  };

  useEffect(() => {
    setSeparetedProducts(() => {
      if (dataProducts) {
        return {
          buds: [...dataProducts?.filter((prod) => prod.type === 'bud')]
            .map((item) => {
              const isExist = dataCart['bud'].some((cartItem) => cartItem.id === item.id);

              return (
                isExist && {
                  ...item,
                  selectedManufacturer: { ...dataCart['bud'].find((cartItem) => cartItem.id === item.id) },
                }
              );
            })
            .filter(Boolean),
          extracts: [...dataProducts?.filter((prod) => prod.type === 'extract')]
            .map((item) => {
              const isExist = dataCart['extract'].some((cartItem) => cartItem.id === item.id);

              return (
                isExist && {
                  ...item,
                  selectedManufacturer: { ...dataCart['extract'].find((cartItem) => cartItem.id === item.id) },
                }
              );
            })
            .filter(Boolean),
        };
      }
    });
  }, [dataProducts, dataCart]);

  useEffect(() => {
    getPharmaciesProducts(products);
  }, []);

  useLayoutEffect(() => {
    total.totalItems === 0 && navigate('/dashboard');
  }, [total]);

  return (
    <>
      <Header />
      <main className={`pre-order-page page ${isShow ? 'show' : ''}`}>
        <div className="container">
          <div className="left-side-container">
            <h1 className="pre-order-title">{total.totalItems} Artikel in Ihrem Warenkorb</h1>
            <div className="left-side">
              <section className="section-wrapper">
                <div className="cart-popup-container">
                  {!!separetedProducts?.buds.length && (
                    <>
                      <p className="cart-popup-type-tile">Blüten ({separetedProducts?.buds.length})</p>
                      <ul className="cart-popup-items-wrapper">
                        {separetedProducts?.buds.map((item) => (
                          <CartInfoRow key={item?.id} data={item} separetedProducts={separetedProducts} />
                        ))}
                      </ul>
                    </>
                  )}
                  {!!separetedProducts?.extracts.length && (
                    <>
                      <p className="cart-popup-type-tile">Extrakte ({separetedProducts?.extracts.length})</p>
                      <ul className="cart-popup-items-wrapper">
                        {separetedProducts?.extracts.map((item) => (
                          <CartInfoRow key={item?.id} data={item} separetedProducts={separetedProducts} />
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </section>
            </div>
          </div>
          <aside className="right-side">
            <div className="right-side-info">
              <h1 className="right-side-info-title">Ihre Bestellanfrage</h1>
              <div className="list-item">
                <span className="list-name">Total</span>
                <span className="between-text-dots"></span>
                <span className="list-value">
                  {total.totalItems} items — {total.totalAmount}g
                </span>
              </div>
            </div>
            <Button onEvent={createOrderHandler} color="green" title="Bestätigen Sie die Bestellanfrage" width={314} />
          </aside>
        </div>
      </main>

      {dataStatus === 'pending' && <Loader type="transparent" />}
    </>
  );
};

export default PharmaciePreOrder;

import Cookies from 'js-cookie';

import * as variable from '../../variables';

export const manufacturers = {
  getManufacturersAll: () => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/manufacturers/all`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getManufactureId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/manufacturers/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  putManufactureId: (id, data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/manufacturers/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteManufactureId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/manufacturers/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getManufacturers: (start, limit, search) => {
    const searchParam = search ? `&search=${search}` : '';

    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/manufacturers?start=${start}&limit=${limit}${searchParam}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  postManufacturers: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/manufacturers`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteManufacturers: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/manufacturers`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

import React, { useEffect } from 'react';
import { useFilePicker } from 'use-file-picker';
import { getDataURLtoFile } from '../../utils/getDataURLtoFile';

import './style.scss';

const FileUploader = ({ name = 'Datei/Bild hinzufügen', onChange, type }) => {
  const fileUploadConfig = () => {
    switch (type) {
      case 'pdf':
        return {
          accept: '.pdf',
          readAs: 'DataURL',
          // maxFileSize: 4.096,
        };
      case 'img':
        return {
          accept: 'image/*',
          readAs: 'DataURL',
        };
      default:
        return {
          accept: '*',
          readAs: 'DataURL',
        };
    }
  };

  const [openFileSelector, { plainFiles, errors }] = useFilePicker(fileUploadConfig());

  useEffect(() => {
    if (plainFiles[0]) {
      onChange(plainFiles[0]);
    }
    // eslint-disable-next-line
  }, [plainFiles]);

  return (
    <div className="attachment-btn">
      <button
        onClick={(e) => {
          e.preventDefault();
          openFileSelector();
        }}
        multiple={false}>
        {name}
      </button>
      {errors[0]?.fileSizeToolarge && (
        <p className="error-message">*Die Dateigröße darf 4096 Kilobyte nicht überschreiten</p>
      )}
    </div>
  );
};

export default FileUploader;

import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

const LinkBack = ({ title, onEvent }) => {
  return (
    // <Link className="link-back" to={path}>
    //   {title}
    // </Link>
    <button className="link-back" onClick={onEvent}>
      {title}
    </button>
  );
};

export default LinkBack;

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.mailTemplates.getMailTemplatesID.getSuccess, ({ getMailTemplatesID }, action) => {
      getMailTemplatesID.status = 'success';
      getMailTemplatesID.data = action.payload;
    })
    .addCase(action.request.mailTemplates.getMailTemplatesID.getPending, ({ getMailTemplatesID }, action) => {
      getMailTemplatesID.status = 'pending';
    })
    .addCase(action.request.mailTemplates.getMailTemplatesID.getFailed, ({ getMailTemplatesID }, action) => {
      getMailTemplatesID.status = 'failed';
      getMailTemplatesID.error = action.payload;
      getMailTemplatesID.data = null;
    });

  builder
    .addCase(action.request.mailTemplates.putMailTemplatesID.putSuccess, ({ putMailTemplatesID }, action) => {
      putMailTemplatesID.status = 'success';
      putMailTemplatesID.data = action.payload;
    })
    .addCase(action.request.mailTemplates.putMailTemplatesID.putPending, ({ putMailTemplatesID }, action) => {
      putMailTemplatesID.status = 'pending';
    })
    .addCase(action.request.mailTemplates.putMailTemplatesID.putFailed, ({ putMailTemplatesID }, action) => {
      putMailTemplatesID.status = 'failed';
      putMailTemplatesID.error = action.payload;
      putMailTemplatesID.data = null;
    });

  builder
    .addCase(action.request.mailTemplates.getMailTemplates.getSuccess, ({ getMailTemplates }, action) => {
      getMailTemplates.status = 'success';
      getMailTemplates.data = action.payload;
    })
    .addCase(action.request.mailTemplates.getMailTemplates.getPending, ({ getMailTemplates }, action) => {
      getMailTemplates.status = 'pending';
    })
    .addCase(action.request.mailTemplates.getMailTemplates.getFailed, ({ getMailTemplates }, action) => {
      getMailTemplates.status = 'failed';
      getMailTemplates.error = action.payload;
      getMailTemplates.data = null;
    });
});

export const state = {
  getUploadId: {
    data: (state) => state.request.uploads.getUploadId.data,
    status: (state) => state.request.uploads.getUploadId.status,
    error: (state) => state.request.uploads.getUploadId.error,
  },

  getUploads: {
    data: (state) => state.request.uploads.getUploads.data,
    status: (state) => state.request.uploads.getUploads.status,
    error: (state) => state.request.uploads.getUploads.error,
  },

  postUploads: {
    data: (state) => state.request.uploads.postUploads.data,
    status: (state) => state.request.uploads.postUploads.status,
    error: (state) => state.request.uploads.postUploads.error,
  },

  deleteUploads: {
    data: (state) => state.request.uploads.deleteUploads.data,
    status: (state) => state.request.uploads.deleteUploads.status,
    error: (state) => state.request.uploads.deleteUploads.error,
  },
};

export const forms = {
  getFormsAllSuccess: 'request/getFormsAllSuccess',
  getFormsAllPending: 'request/getFormsAllPending',
  getFormsAllFailed: 'request/getFormsAllFailed',

  getFormIdSuccess: 'request/getFormIdSuccess',
  getFormIdPending: 'request/getFormIdPending',
  getFormIdFailed: 'request/getFormIdFailed',

  putFormIdSuccess: 'request/putFormIdSuccess',
  putFormIdPending: 'request/putFormIdPending',
  putFormIdFailed: 'request/putFormIdFailed',

  deleteFormIdSuccess: 'request/deleteFormIdSuccess',
  deleteFormIdPending: 'request/deleteFormIdPending',
  deleteFormIdFailed: 'request/deleteFormIdFailed',

  getFormsSuccess: 'request/getFormsSuccess',
  getFormsPending: 'request/getFormsPending',
  getFormsFailed: 'request/getFormsFailed',

  postFormsSuccess: 'request/postFormsSuccess',
  postFormsPending: 'request/postFormsPending',
  postFormsFailed: 'request/postFormsFailed',
};

import React from 'react';

import './style.scss';

const ButtonClear = ({ onEvent, className, typeIcon }) => {
  return (
    <span className={`btn-clear ${className ? className : ''} ${typeIcon ? typeIcon : ''}`} onClick={onEvent}></span>
  );
};

export default ButtonClear;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../../../store/reducer';
import * as action from '../../../../../../store/action';
import * as util from '../../../../../../utils';

import { usePageUp, useChangeEffect } from '../../../../../../hooks';

import Header from '../../../../../../components/header';
import LinkBack from '../../../../../../components/UI/linkBack';
import Button from '../../../../../../components/UI/button';
import Field from '../../../../../../components/UI/field';
import Switcher from '../../../../../../components/UI/switcher';
import Loader from '../../../../../../components/popups/loader';
import Textarea from '../../../../../../components/UI/textarea';

const EditMailTemplates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();

  const dataMailTemplates = useSelector(reducer.request.mailTemplates.state.getMailTemplatesID.data);

  const statusPutMailTemplates = useSelector(reducer.request.mailTemplates.state.putMailTemplatesID.status);
  const statusGetMailTemplates = useSelector(reducer.request.mailTemplates.state.getMailTemplatesID.status);

  const putMailTemplates = (id, data) => dispatch(action.request.mailTemplates.putMailTemplatesID.put(id, data));
  const getMailTemplates = (id) => dispatch(action.request.mailTemplates.getMailTemplatesID.get(id));

  const [isShowLoader, setShowLoader] = useState(true);
  const [data, setData] = useState({
    active: true,
    body: '',
    id: 1,
    subject: '',
    trigger: '',
  });

  const ID = param.ID;
  const isRequestGETSuccess = statusGetMailTemplates === 'success';

  usePageUp();

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  useEffect(() => {
    getMailTemplates(ID);
  }, []);

  useEffect(() => {
    if (dataMailTemplates) setData(dataMailTemplates);
  }, [dataMailTemplates]);

  useChangeEffect(() => {
    if (statusPutMailTemplates === 'success') navigate('/settings');
  }, [statusPutMailTemplates]);

  const onCancel = () => {
    if (window.confirm('Bestätigen Sie die Aktion auf der Seite. Die Änderungen zurücksetzen?')) navigate('/settings');
  };

  const onSave = () => {
    putMailTemplates(ID, data);
  };

  return (
    <>
      <Header />

      <main className={`edit-email-templates page  ${!isShowLoader ? 'show' : ''}`}>
        <div className="container">
          <LinkBack title="Zurück zu Blüten" onEvent={onCancel} />

          <div className="content">
            <h1 className="title">{data.trigger}</h1>
            <div className="one">
              <Field
                label="Betreff"
                type="text"
                value={data.subject}
                onEvent={(event) => setData({ ...data, subject: event.target.value })}
                className="regarding"
              />

              <Field label="ID" type="text" value={data.id} onEvent={() => {}} disabled={true} className="id" />

              <Switcher
                label="E-Mail aktiv"
                isChecked={data.active}
                onChange={() => setData({ ...data, active: !data.active })}
              />
            </div>

            <Textarea
              label="Body"
              value={data.body}
              onChange={(event) => setData({ ...data, body: event.target.value })}
            />
          </div>

          <Button title="Speichern und weiter" onEvent={onSave} width="206" color="green" />
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
      {statusPutMailTemplates === 'pending' && <Loader type="transparent" />}
    </>
  );
};

export default EditMailTemplates;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';

import './style.scss';

import * as reducer from '../../../../store/reducer';
import * as action from '../../../../store/action';
import * as util from '../../../../utils';

import { usePageUp, useChangeEffect } from '../../../../hooks';
import { variables } from './variables';

import Header from '../../../../components/header';
import TableBuilder from '../../../../components/tableBuilder';
import FieldDatePicker from '../../../../components/fieldDatePicker';
import Dropdown2 from '../../../../components/UI/dropdown2';
import FieldSearch from '../../../../components/UI/fieldSearch';
import DropdownCount from '../../../../components/dropdownCount';
import Pagination from '../../../../components/UI/pagination';
import Loader from '../../../../components/popups/loader';
import DropDownSearch from '../../../../components/UI/dropdownSearch';
import { useDefaultPerPage } from '../../../../hooks/useDefaultPerPage';

const Orders = () => {
  const dispatch = useDispatch();

  const dataCultivarsAll = useSelector(reducer.request.cultivar.state.getCultivarsAll.data);
  const dataOrders = useSelector(reducer.request.admin.order.state.getOrders.data);
  const dataPharmaciesAll = useSelector(reducer.request.admin.pharmacy.state.getPharmaciesAll.data);
  const dataManufacturersAll = useSelector(reducer.request.admin.manufacture.state.getManufacturersAll.data);
  const dataBudsAll = useSelector(reducer.request.admin.bud.state.getBudsAll.data);
  const dataExtractsAll = useSelector(reducer.request.admin.extract.state.getExtractsAll.data);

  const statusGetCultivarsAll = useSelector(reducer.request.cultivar.state.getCultivarsAll.status);
  const statusGetOrders = useSelector(reducer.request.admin.order.state.getOrders.status);
  const statusGetPharmaciesAll = useSelector(reducer.request.admin.pharmacy.state.getPharmaciesAll.status);
  const statusGetManufacturersAll = useSelector(reducer.request.admin.manufacture.state.getManufacturersAll.status);
  const statusGetBudsAll = useSelector(reducer.request.admin.bud.state.getBudsAll.status);
  const statusGetExtractsAll = useSelector(reducer.request.admin.extract.state.getExtractsAll.status);

  const getCultivarsAll = () => dispatch(action.request.cultivars.getCultivarsAll.get());
  const getOrders = (params) => dispatch(action.request.admin.orders.getOrders.get(params));
  const getPharmaciesAll = () => dispatch(action.request.admin.pharmacies.getPharmaciesAll.get());
  const getManufacturersAll = () => dispatch(action.request.admin.manufacturers.getManufacturersAll.get());
  const getBudsAll = () => dispatch(action.request.admin.buds.getBudsAll.get());
  const getExtractsAll = () => dispatch(action.request.admin.extracts.getExtractsAll.get());

  const [isShowLoader, setShowLoader] = useState(true);

  const [valueSearch, setValueSearch] = useState('');
  const [valueDates, setValueDates] = useState({ from: '', to: '' });
  const [valuePharmacies, setValuePharmacies] = useState([]);
  const [valueManufacturers, setValueManufacturers] = useState([]);
  const [valueBudsExtracts, setValueBudsExtracts] = useState([]);

  const persistName = 'superuserOrdersMain';

  const { contentPerPage, setContentPerPage } = useDefaultPerPage(persistName);

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedIDs, setSelectedIDs] = useState([]);

  const countSelectedRows = selectedIDs.length;
  const pageCount = Math.ceil(dataOrders?.total / contentPerPage) || 1;
  const pharmacies = qs.stringify({ pharmacies: valuePharmacies });
  const manufacturers = qs.stringify({ manufacturers: valueManufacturers });
  const products = qs.stringify({ products: valueBudsExtracts });

  const buds = dataBudsAll?.map((item) => ({ ...item, type: 'bud' }));
  const extracts = dataExtractsAll?.map((item) => ({ ...item, type: 'extract' }));

  const tableTitles = ['№', 'Auftrag', 'Datum', 'Apotheken', 'Lieferanten', 'Produkt', 'Kultivar', ' '];
  const tableData = dataOrders?.orders?.reduce((acc, item, index) => {
    acc = [
      ...acc,
      {
        id: item.id,
        number: item.id,
        status: util.getStatusOrder.de(item.status),
        date: new Date(item.date).toLocaleString(),
        pharmacyName: item.pharmacyName,
        productsManufacturerName: item.products.map((product, index, array) =>
          array.length > 1 ? `${index + 1}. ${product.manufacturerName}` : product.manufacturerName,
        ),
        productsName: item.products.map((product) => ({
          name: product.name,
          status: util.getStatusProductOrder.de(product.status),
        })),
        productsCultivars: item.products.map((item) => util.getCultivarsName(item.cultivars, dataCultivarsAll)),
        ' ': item.products.length > 1 ? 'true' : 'false',
      },
    ];

    return acc;
  }, []);

  const isRequestGETSuccess =
    statusGetCultivarsAll === 'success' &&
    statusGetOrders === 'success' &&
    statusGetPharmaciesAll === 'success' &&
    statusGetManufacturersAll === 'success' &&
    statusGetBudsAll === 'success' &&
    statusGetExtractsAll === 'success';

  usePageUp();

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  useEffect(() => {
    getOrders({ start: 0, limit: contentPerPage });
    getCultivarsAll();
    getPharmaciesAll();
    getManufacturersAll();
    getBudsAll();
    getExtractsAll();
  }, [contentPerPage]);

  useChangeEffect(() => {
    if (valueDates.from && valueDates.to) getData();
    if (!valueDates.from && !valueDates.to) getData();
  }, [valueDates]);

  useChangeEffect(() => {
    getData(true);
  }, [valuePharmacies]);

  useChangeEffect(() => {
    getData(true);
  }, [valueManufacturers]);

  useChangeEffect(() => {
    getData(true);
  }, [valueBudsExtracts]);

  useChangeEffect(() => {
    getData();
  }, [currentPage, contentPerPage]);

  const onGoSearch = () => {
    if (currentPage === 1) {
      getOrders({
        start: 0,
        limit: contentPerPage,
        search: valueSearch,
        from: valueDates.from,
        to: valueDates.to,
        pharmacies,
        manufacturers,
        products,
      });
    } else {
      setCurrentPage(1);
    }
  };

  const onClear = () => {
    setValueSearch('');

    if (currentPage === 1) {
      getOrders({
        start: 0,
        limit: contentPerPage,
        from: valueDates.from,
        to: valueDates.to,
        pharmacies,
        manufacturers,
        products,
      });
    } else {
      setCurrentPage(1);
    }
  };

  const getData = (isFirstPage) => {
    const firstContentIndex = (currentPage - 1) * contentPerPage;

    isFirstPage && setCurrentPage(1);

    getOrders({
      start: firstContentIndex,
      limit: contentPerPage,
      search: valueSearch,
      from: valueDates.from,
      to: valueDates.to,
      pharmacies,
      manufacturers,
      products,
    });
  };

  return (
    <>
      <Header />

      <main className={`orders page ${!isShowLoader ? 'show' : ''}`}>
        <div className="container">
          <h1 className="page-title">
            Bestellanfragen <sup>{dataOrders?.total}</sup>
          </h1>

          <div className="panel-filter">
            <FieldDatePicker
              title="Datum"
              placeholder="Datum auswählen"
              startDateValue={valueDates.from}
              endDateValue={valueDates.to}
              onEvent={(data) => setValueDates({ from: data ? data[0] : null, to: data ? data[1] : null })}
              isRange={true}
            />
            <DropDownSearch
              position="bottom"
              label="Apotheken"
              type="Apotheken"
              selectData={dataPharmaciesAll}
              data={valuePharmacies}
              setData={setValuePharmacies}
            />
            <DropDownSearch
              position="bottom"
              label="Lieferanten"
              type="Lieferanten"
              selectData={dataManufacturersAll}
              data={valueManufacturers}
              setData={setValueManufacturers}
            />
            <DropDownSearch
              isCustom={true}
              position="bottom"
              label="Produkt"
              type="Produkt"
              selectData={[...(buds || []), ...(extracts || [])]}
              data={valueBudsExtracts}
              setData={setValueBudsExtracts}
            />
          </div>

          <TableBuilder
            className="order-table"
            path="edit"
            contentPerPage={contentPerPage}
            title={tableTitles}
            data={tableData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCount={pageCount}
            selectedIDs={selectedIDs}
            setSelectedIDs={setSelectedIDs}
            valueSearch={valueSearch}>
            <div className="table-header">
              <ul className="left-side">
                <li className="elem">
                  <FieldSearch
                    value={valueSearch}
                    setValue={(event) => setValueSearch(event.target.value)}
                    onClear={onClear}
                    onGoSearch={onGoSearch}
                  />
                </li>
              </ul>

              <ul className="right-side">
                <li className="elem">
                  <DropdownCount
                    contentPerPage={contentPerPage}
                    data={variables.dropdownContent}
                    onSetContentPerPage={setContentPerPage}
                    setCurrentPage={() => setCurrentPage(1)}
                    persistName={persistName}
                  />
                </li>
                <li className="elem">
                  <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
                </li>
              </ul>
            </div>
          </TableBuilder>
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}

      {!isShowLoader && statusGetOrders === 'pending' && <Loader type="transparent" />}
    </>
  );
};

export default Orders;

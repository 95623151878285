import { createAction } from '@reduxjs/toolkit';

import * as api from '../../../../../api';
import * as type from '../../../../type';
import * as action from '../../../../action';

export const getDoctorsExtractsId = {
  get: function (...props) {
    return (dispatch) => {
      dispatch(this.getPending());

      api.doctor.extracts
        .getDoctorsExtractsId(...props)
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          response.json().then((json) => dispatch(this.getSuccess(json)));
        })
        .catch((error) => {
          if (error.status === 403 || error.status === 404) {
            window.location = '/';
          }
          if (error.status === 401) {
            dispatch(action.request.auth.getRefresh.get(this.get(...props)));
          } else {
            dispatch(this.getFailed(error.statusText));
          }
        });
    };
  },

  getSuccess: createAction(type.request.doctor.extracts.getDoctorsExtractsIdSuccess),
  getPending: createAction(type.request.doctor.extracts.getDoctorsExtractsIdPending),
  getFailed: createAction(type.request.doctor.extracts.getDoctorsExtractsIdFailed),
};

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import './style.scss';

import * as reducer from '../../../../store/reducer';
import * as action from '../../../../store/action';
import * as util from '../../../../utils';

import { usePageUp, useChangeEffect } from '../../../../hooks';
import { variables } from './variables';

import Header from '../../../../components/header';
import AddEdit from '../../../../components/addEdit';
import LinkBack from '../../../../components/UI/linkBack';
import Field from '../../../../components/UI/field';
import FieldDataPicker from '../../../../components/fieldDatePicker';
import Dropdown2 from '../../../../components/UI/dropdown2';
import DropdownSelect from '../../../../components/UI/dropdownSelect';
import Loader from '../../../../components/popups/loader';
import DeletePopup from '../../../../components/popups/deletePopup';

const OrderEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();

  const dataExtractsAll = useSelector(reducer.request.admin.extract.state.getExtractsAll.data);
  const dataBudsAll = useSelector(reducer.request.admin.bud.state.getBudsAll.data);
  const dataCultivarsAll = useSelector(reducer.request.cultivar.state.getCultivarsAll.data);
  const dataOrderID = useSelector(reducer.request.admin.order.state.getOrderId.data);

  const statusManufacturersAll = useSelector(reducer.request.admin.manufacture.state.getManufacturersAll.status);
  const statusGetExtractsAll = useSelector(reducer.request.admin.extract.state.getExtractsAll.status);
  const statusGetBudsAll = useSelector(reducer.request.admin.bud.state.getBudsAll.status);
  const statusGetCultivarsAll = useSelector(reducer.request.cultivar.state.getCultivarsAll.status);
  const statusGetOrderID = useSelector(reducer.request.admin.order.state.getOrderId.status);

  const statusDeleteOrderID = useSelector(reducer.request.admin.order.state.deleteOrderId.status);
  const statusPutOrderID = useSelector(reducer.request.admin.order.state.putOrderId.status);
  const isDataChages = useSelector(reducer.app.common.state.isDataChages);

  const setDataChage = (isChange) => dispatch(action.app.common.isDataChages(isChange));
  const getExtractsAll = () => dispatch(action.request.admin.extracts.getExtractsAll.get());
  const getBudsAll = () => dispatch(action.request.admin.buds.getBudsAll.get());
  const getCultivarsAll = () => dispatch(action.request.cultivars.getCultivarsAll.get());
  const getOrderID = (id) => dispatch(action.request.admin.orders.getOrderId.get(id));
  const putOrderID = (id, data) => dispatch(action.request.admin.orders.putOrderId.put(id, data));
  const deleteOrderID = (id, data) => dispatch(action.request.admin.orders.deleteOrderId.delete(id));

  const [isShowLoader, setShowLoader] = useState(true);
  const [isShowDeletePopup, setShowDeletePopup] = useState(false);
  const [data, setData] = useState({
    id: 0,
    pharmacyId: 0,
    pharmacyName: '',
    date: '',
    status: '',
    products: [
      {
        productId: null, // null | number
        type: '',
        manufacturerId: null, // null | number
        name: '',
        manufacturerName: '',
        cultivars: [],
        status: '',
        id: 0,
        amount: 0,
        date: null, // null | string
        exclusive: false,
        manufacturers: [],
      },
    ],
    address: {
      zip: null, // null | string
      city: null, // null | string
      street: null, // null | string
      house: null, // null | string
    },
  });

  const orderID = param.orderID;

  const isRequestGETSuccess = statusGetOrderID === 'success';

  const fullAddres = Object.values(data.address).every(Boolean)
    ? `${data.address.street} ${data.address.house},  ${data.address.zip} ${data.address.city}`
    : '-';

  usePageUp();

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  useEffect(() => {
    getBudsAll();
    getCultivarsAll();
    getExtractsAll();

    getOrderID(orderID);
  }, []);

  useEffect(() => {
    if (dataOrderID) setData(dataOrderID);
  }, [dataOrderID]);

  useChangeEffect(() => {
    if (statusPutOrderID === 'success') navigate('/orders');
  }, [statusPutOrderID]);

  useChangeEffect(() => {
    if (statusDeleteOrderID === 'success') navigate('/orders');
  }, [statusDeleteOrderID]);

  const onSave = () => {
    putOrderID(orderID, data);
    setDataChage(false);
  };

  const onCancel = () => {
    if (isDataChages) {
      if (window.confirm('Bestätigen Sie die Aktion auf der Seite. Die Änderungen zurücksetzen?')) {
        setDataChage(false);
        navigate('/orders');
      }
    } else {
      navigate('/orders');
    }
  };

  const onDelete = () => {
    deleteOrderID(orderID);
  };

  const onSetValueProducts = (index, key, value) => {
    const changed = data.products.map((item, currentIndex) => {
      if (key === 'status') {
        if (currentIndex === index) {
          return {
            ...item,
            status: value,
            date: value === 'opened' || value === 'declined' ? null : item.date || new Date().toISOString(),
          };
        }
      } else if (key === 'manufacturerName') {
        if (currentIndex === index) {
          return {
            ...item,
            manufacturerName: value.name,
            manufacturerId: value.id,
          };
        }
      } else if (key === 'name') {
        if (currentIndex === index) {
          return {
            ...item,
            name: value.name,
            productId: value.id,
          };
        }
      } else {
        if (currentIndex === index) {
          return { ...item, [key]: value };
        }
      }

      return item;
    });

    setData({ ...data, products: changed });
  };

  const onSetValueProductsCultivars = (index, value) => {
    const changed = data.products.map((item, currentIndex) => {
      if (currentIndex === index) {
        return value;
      }

      return item;
    });

    setData({ ...data, products: changed });
  };

  useEffect(() => {
    if (!!data && !!dataOrderID) {
      if (!_.isEqual(data, dataOrderID)) {
        setDataChage(true);
        return;
      } else {
        setDataChage(false);
      }
    }
  }, [data, dataOrderID]);
  return (
    <>
      <Header />
      <main className={`order-edit page ${!isShowLoader ? 'show' : ''}`}>
        <div className="container">
          <LinkBack title="Zurück zu Bestellanfragen" onEvent={onCancel} />

          <AddEdit
            textDelete="Bestellanfrage löschen"
            onDelete={() => setShowDeletePopup(true)}
            onCancel={onCancel}
            onSave={onSave}
            data={data}
            isSaveButtonDisabled={!isDataChages}>
            <section className="info-section">
              <h2 className="title">Bestellinformation</h2>

              <ul className="field-list-one">
                <li>
                  <Field
                    className="w50"
                    label="Bestellnummer"
                    type="text"
                    disabled={true}
                    value={data.id}
                    onEvent={() => {}}
                  />
                  <FieldDataPicker
                    className="w30"
                    title="Datum"
                    startDateValue={data.date}
                    onEvent={(dateValue) => setData({ ...data, date: dateValue.toISOString() })}
                  />
                  <Dropdown2
                    title="Status"
                    className={`${data.status} w20`}
                    select={util.getStatusOrder.de(data.status)}
                    setSelect={({ name }) => setData({ ...data, status: util.getStatusOrder.en(name) })}
                    content={variables.status}
                  />
                </li>

                <li>
                  <div className="field-message w50">
                    <p className="title">Apotheken</p>
                    <Link to={`/pharmacies/profile/${data.pharmacyId}`} className="link-message">
                      {data.pharmacyName}
                    </Link>
                  </div>

                  <div className="field-message w50">
                    <p className="title">Stadt</p>
                    <p className="message">{fullAddres}</p>
                  </div>
                </li>
              </ul>
            </section>

            <section className="info-section border-0">
              <h2 className="title">Bestellte Produkte</h2>

              <ul className="field-list-two">
                {data?.products.map((item, index) => (
                  <li key={item.id}>
                    <Dropdown2
                      className="w50"
                      title={`${index + 1}. Produkt`}
                      select={item.name}
                      setSelect={(value) => onSetValueProducts(index, 'name', value)}
                      content={[...(dataBudsAll || []), ...(dataExtractsAll || [])]}
                    />

                    <Dropdown2
                      className="w30"
                      title="Lieferant"
                      select={item.manufacturerName}
                      setSelect={(value) => onSetValueProducts(index, 'manufacturerName', value)}
                      content={item.manufacturers}
                      disabled={item.exclusive || item.manufacturers?.length === 1}
                    />

                    <Field
                      className="w20"
                      label="Menge, g"
                      type="text"
                      value={item.amount}
                      placeholder="0.00"
                      onEvent={(event) =>
                        onSetValueProducts(index, 'amount', parseFloat(event.target.value.replace(/[^0-9\.]/, '')))
                      }
                    />

                    <DropdownSelect
                      className="w50"
                      error={false}
                      position="bottom"
                      label="Kultivar"
                      content={dataCultivarsAll}
                      dataKey="cultivars"
                      data={item}
                      setData={(value) => onSetValueProductsCultivars(index, value)}
                      errorData={{}}
                      setErrorData={() => {}}
                      disabled={true}
                    />

                    <Dropdown2
                      className={`${item.status} w30`}
                      title="Status"
                      select={util.getStatusProductOrder.de(item.status)}
                      setSelect={({ name }) => onSetValueProducts(index, 'status', util.getStatusProductOrder.en(name))}
                      content={variables.status2}
                    />

                    {(item.status === 'approved' || item.status === 'sent') && (
                      <FieldDataPicker
                        className="w20"
                        title="Datum"
                        startDateValue={item.date}
                        onEvent={(dateValue) => onSetValueProducts(index, 'date', dateValue.toISOString())}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </section>
          </AddEdit>
        </div>
      </main>

      {isShowDeletePopup && <DeletePopup onDelete={onDelete} onClose={() => setShowDeletePopup(false)} />}

      {isShowLoader && <Loader type="transparent" />}
      {(statusPutOrderID === 'pending' || statusDeleteOrderID === 'pending') && <Loader type="transparent" />}
    </>
  );
};

export default OrderEdit;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as action from '../../../store/action';
import * as reducer from '../../../store/reducer';

import { useDelay, usePageUp, useChangeEffect } from '../../../hooks';

import Header from '../../../components/header';
import Flowers from '../../../components/flowers';
import Orders from './orders';
import Loader from '../../../components/popups/loader';
import { useDefaultPerPage } from '../../../hooks/useDefaultPerPage';

const Dashboard = () => {
  const dispatch = useDispatch();

  const { contentPerPage: orders } = useDefaultPerPage('superuserDashboardOrders');

  const statusGetBuds = useSelector(reducer.request.admin.bud.state.getBuds.status);
  const statusGetExtracts = useSelector(reducer.request.admin.extract.state.getExtracts.status);
  const statusGetManufacturers = useSelector(reducer.request.admin.manufacture.state.getManufacturers.status);
  const statusGetPharmacies = useSelector(reducer.request.admin.pharmacy.state.getPharmacies.status);
  const statusGetOrders = useSelector(reducer.request.admin.order.state.getOrders.status);

  const getBuds = (start, limit) => dispatch(action.request.admin.buds.getBuds.get(start, limit));
  const getExtracts = (start, limit) => dispatch(action.request.admin.extracts.getExtracts.get(start, limit));
  const getManufacturers = (start, limit) =>
    dispatch(action.request.admin.manufacturers.getManufacturers.get(start, limit));
  const getPharmacies = (start, limit, archieved) =>
    dispatch(action.request.admin.pharmacies.getPharmacies.get(start, limit, archieved));
  const getOrders = (params) => dispatch(action.request.admin.orders.getOrders.get(params));

  const [isShowLoader, setShowLoader] = useState(true);

  const isRequestGETSuccess =
    statusGetBuds === 'success' &&
    statusGetExtracts === 'success' &&
    statusGetManufacturers === 'success' &&
    statusGetPharmacies === 'success' &&
    statusGetOrders === 'success';

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  useEffect(() => {
    getBuds(0, 1);
    getExtracts(0, 1);
    getManufacturers(0, 1);
    getPharmacies(0, 1, false);
    getOrders({ start: 0, limit: orders });
  }, [orders]);

  usePageUp();

  return (
    <>
      <Header />

      <main className={`superuser-dashboard-page page ${!isShowLoader ? 'show' : ''}`}>
        <div className="container">
          <h1 className="page-title">Dashboard</h1>
          <Flowers />
          <Orders isShowLoader={isShowLoader} />
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default Dashboard;

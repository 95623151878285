import Cookies from 'js-cookie';

import * as variable from '../variables';

export const settings = {
  getSettings: () => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/settings`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  patchSettings: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/settings`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

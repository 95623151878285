import { createAction } from '@reduxjs/toolkit';

import * as api from '../../../../../api';
import * as type from '../../../../type';

export const getPharmaciesArchieved = {
  get: function (start, limit, archieved, search) {
    return (dispatch) => {
      dispatch(this.getPending());

      api.admin.pharmacies
        .getPharmacies(start, limit, archieved, search)
        .then((response) => {
          response.json().then((json) => dispatch(this.getSuccess(json)));
        })
        .catch((error) => {
          dispatch(this.getFailed(JSON.parse(error.message)));
        });
    };
  },

  getSuccess: createAction(type.request.admin.pharmacies.getPharmaciesArchievedSuccess),
  getPending: createAction(type.request.admin.pharmacies.getPharmaciesArchievedPending),
  getFailed: createAction(type.request.admin.pharmacies.getPharmaciesArchievedFailed),
};

export const state = {
  getPharmaciesAll: {
    data: (state) => state.request.admin.pharmacies.getPharmaciesAll.data,
    status: (state) => state.request.admin.pharmacies.getPharmaciesAll.status,
    error: (state) => state.request.admin.pharmacies.getPharmaciesAll.error,
  },

  getPharmacyId: {
    data: (state) => state.request.admin.pharmacies.getPharmacyId.data,
    status: (state) => state.request.admin.pharmacies.getPharmacyId.status,
    error: (state) => state.request.admin.pharmacies.getPharmacyId.error,
  },

  patchPharmacyId: {
    data: (state) => state.request.admin.pharmacies.patchPharmacyId.data,
    status: (state) => state.request.admin.pharmacies.patchPharmacyId.status,
    error: (state) => state.request.admin.pharmacies.patchPharmacyId.error,
  },

  deletePharmacyId: {
    data: (state) => state.request.admin.pharmacies.deletePharmacyId.data,
    status: (state) => state.request.admin.pharmacies.deletePharmacyId.status,
    error: (state) => state.request.admin.pharmacies.deletePharmacyId.error,
  },

  getPharmacies: {
    data: (state) => state.request.admin.pharmacies.getPharmacies.data,
    status: (state) => state.request.admin.pharmacies.getPharmacies.status,
    error: (state) => state.request.admin.pharmacies.getPharmacies.error,
  },

  deletePharmacies: {
    data: (state) => state.request.admin.pharmacies.deletePharmacies.data,
    status: (state) => state.request.admin.pharmacies.deletePharmacies.status,
    error: (state) => state.request.admin.pharmacies.deletePharmacies.error,
  },

  getPharmaciesArchieved: {
    data: (state) => state.request.admin.pharmacies.getPharmaciesArchieved.data,
    status: (state) => state.request.admin.pharmacies.getPharmaciesArchieved.status,
    error: (state) => state.request.admin.pharmacies.getPharmaciesArchieved.error,
  },

  deletePharmaciesArchieved: {
    data: (state) => state.request.admin.pharmacies.deletePharmaciesArchieved.data,
    status: (state) => state.request.admin.pharmacies.deletePharmaciesArchieved.status,
    error: (state) => state.request.admin.pharmacies.deletePharmaciesArchieved.error,
  },
};

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.settings.getSettings.getSuccess, ({ getSettings }, action) => {
      getSettings.status = 'success';
      getSettings.data = action.payload;
    })
    .addCase(action.request.settings.getSettings.getPending, ({ getSettings }, action) => {
      getSettings.status = 'pending';
    })
    .addCase(action.request.settings.getSettings.getFailed, ({ getSettings }, action) => {
      getSettings.status = 'failed';
      getSettings.error = action.payload;
      getSettings.data = null;
    });

  builder
    .addCase(action.request.settings.patchSettings.patchSuccess, ({ patchSettings }, action) => {
      patchSettings.status = 'success';
      patchSettings.data = action.payload;
    })
    .addCase(action.request.settings.patchSettings.patchPending, ({ patchSettings }, action) => {
      patchSettings.status = 'pending';
    })
    .addCase(action.request.settings.patchSettings.patchFailed, ({ patchSettings }, action) => {
      patchSettings.status = 'failed';
      patchSettings.error = action.payload;
      patchSettings.data = null;
    });
});

import React, { useEffect, useState } from 'react';

export const useDefaultPerPage = (persistName) => {
  const lsPerPageValues = JSON.parse(localStorage.getItem('perPageValues'));

  const [contentPerPage, setContentPerPage] = useState(parseInt(persistName && lsPerPageValues[persistName]));

  useEffect(() => {
    if (persistName) {
      localStorage.setItem(
        'perPageValues',
        JSON.stringify({ ...lsPerPageValues, [persistName]: contentPerPage || 10 }),
      );
    }
  }, [contentPerPage]);

  return { contentPerPage, setContentPerPage };
};

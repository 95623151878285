export const validation = {
  onChekTextField: (value) => {
    return !!value.length;
  },

  onCheckArrayField: (array) => {
    return !!array.length;
  },

  onCheckNumberField: (value) => {
    return !isNaN(Number(value));
  },

  isNull: (value) => {
    return value === null;
  },
};

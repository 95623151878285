import React from 'react';

import './style.scss';

import { ReactComponent as IconTrash } from '../../../../assets/icons/icon-trash.svg';

import Checkbox from '../../../UI/checkbox';

const SelectedIcon = ({ isChecked, onToggle, removeImg }) => {
  return (
    <section className="selected-icon">
      <IconTrash onClick={removeImg} />
      <Checkbox isChecked={isChecked} onChange={onToggle} />
    </section>
  );
};

export default SelectedIcon;

import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import * as action from '../../store/action';

import Dropdown2 from '../UI/dropdown2';
import SetProductAmount from '../setProductAmount';
import DeletePopup from '../popups/deletePopup/DeletePopup';

import defaultBudImage from '../../assets/images/default-bud-img.svg';
import defaultExtractImage from '../../assets/images/default-extract-img.svg';

import * as variable from '../../variables';

const CartInfoRow = (props) => {
  const dispatch = useDispatch();

  const [confimDeletePopup, setConfimDeletePopup] = useState(false);

  const increaseAmount = (data) => dispatch(action.app.cart.increaseAmount(data));
  const decreaseAmount = (data) => dispatch(action.app.cart.decreaseAmount(data));
  const removeFromCart = (data) => dispatch(action.app.cart.removeFromCart(data));
  const changeManufacturer = (data) => dispatch(action.app.cart.changeManufacturer(data));

  const manufacturersWithAvalibleAmount = props?.data.manufacturers?.filter(
    (item) => item.maxOrderAmount >= item.minOrderAmount && item.active && !!item.availableAmount,
  );

  const chageManufacturerHandler = useCallback(
    (productId, manufacturerId, type) => {
      const newManufacturer = props?.separetedProducts[`${type}s`]
        ?.find((item) => item.id === productId)
        .manufacturers.find((item) => item.id === manufacturerId);

      changeManufacturer({ productId, newManufacturer, type });
    },
    [props?.separetedProducts],
  );

  return (
    <>
      <li key={props?.data.id} className="cart-popup-item">
        <div className="cart-popup-item-info">
          <p className="cart-popup-item-img">
            <img
              src={
                props?.data.imageKey
                  ? `https://${variable.url}/uploads/${props?.data.imageKey} `
                  : props.type === 'bud'
                  ? defaultBudImage
                  : defaultExtractImage
              }
              alt=""
            />
          </p>
          <ul className="wrapper1">
            <li className="cart-popup-item-name">{props?.data.name}</li>
            <li className="cart-popup-item-genetics">{props?.data.genetics}</li>
            <li className="cart-popup-item-cultivars">{props?.data.cultivars.join(' ,')}</li>
          </ul>
        </div>
        <div className="cart-popup-item-config">
          {!props?.data.exclusive && !props.isOrderInfo ? (
            <Dropdown2
              className="cart-popup-item-manufacturer"
              title="Lieferant"
              select={props?.data.selectedManufacturer?.name}
              setSelect={(value) => chageManufacturerHandler(props?.data.id, value?.id, props?.data.type)}
              content={manufacturersWithAvalibleAmount}
              disabled={manufacturersWithAvalibleAmount?.length === 1}
              btnClassName="w-196px"
            />
          ) : props?.data.companyImageKey ? (
            <p className="cart-popup-item-manufacturer img">
              <img src={`https://${variable.url}/uploads/${props?.data.companyImageKey} `} alt="" />
            </p>
          ) : (
            <span className="cart-popup-item-manufacturer text">{props?.data.companyName}</span>
          )}
          <div className="cart-popup-item-amount">
            {props.isOrderInfo ? (
              <p className="cart-popup-item-amount-title">
                {props?.data.amount} {props?.data.type === 'bud' ? 'g' : 'ml'}
              </p>
            ) : (
              <>
                <p className="cart-popup-item-amount-title">
                  Maх {props?.data.selectedManufacturer.remainingAmount} {props?.data.type === 'bud' ? 'g' : 'ml'}
                </p>
                <SetProductAmount
                  increaseAmount={increaseAmount}
                  decreaseAmount={decreaseAmount}
                  currentAddedItem={props?.data.selectedManufacturer}
                  isShowAmountInside={true}
                />
              </>
            )}
          </div>
          {!props.isOrderInfo && (
            <>
              <button onClick={() => setConfimDeletePopup(props?.data.id)} className="cart-popup-remove-item"></button>
            </>
          )}
        </div>
      </li>
      {confimDeletePopup === props?.data.id && (
        <DeletePopup
          onClose={() => setConfimDeletePopup(false)}
          onDelete={() => {
            removeFromCart(props?.data);
            setConfimDeletePopup(false);
          }}
          title="Produkt entfernen"
          text="Möchten Sie das Element wirklich entfernen? Es ist nicht möglich, diese Aktion rückgängig zu machen."
          className="delete-popup-cart"
        />
      )}
    </>
  );
};

export default CartInfoRow;

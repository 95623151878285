import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useChangeEffect, useDelay, usePageUp } from '../../../hooks';

import Loader from '../../../components/popups/loader';

import Header from '../../../components/header';
import FieldDatePicker from '../../../components/fieldDatePicker/FieldDatePicker';
import DeletePopup from '../../../components/popups/deletePopup/DeletePopup';
import OrdersSection from './ordersSection';

const PharmacieOrders = () => {
  const dispatch = useDispatch();
  const isShow = useDelay(100);
  usePageUp();

  const [confimDeletePopup, setConfimDeletePopup] = useState(false);
  const [dataSearch, setDataSearch] = useState(null);
  const [isShowLoader, setShowLoader] = useState(true);

  const onSetDateRange = (date) => {
    setDataSearch(date);

    date ? getPharmaciesOrders(date[0].toISOString(), date[1].toISOString()) : getPharmaciesOrders();
  };

  const dataOrdersStatus = useSelector(reducer.request.pharmacie.orders.state.getPharmaciesOrders.status);
  const dataOrders = useSelector(reducer.request.pharmacie.orders.state.getPharmaciesOrders.data);

  const getPharmaciesOrders = (from, to, sort, order) =>
    dispatch(action.request.pharmacie.orders.getPharmaciesOrders.get(from, to, sort, order));

  const deletePharmaciesAllOrders = () => dispatch(action.request.pharmacie.orders.deletePharmaciesAllOrders.delete());

  const isRequestGETSuccess = dataOrdersStatus === 'success';

  useEffect(() => {
    getPharmaciesOrders();
  }, []);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  return (
    <>
      <Header />
      <main className={`pharmacie-order-page page ${isShow ? 'show' : ''}`}>
        {!isShowLoader && (
          <div className="container ">
            <div className="header">
              <div className="header-left-side">
                <h1 className="title">Meine Bestellanfragen</h1>
                {!!dataOrders?.length && (
                  <button onClick={() => setConfimDeletePopup('all')} className="remove-all-orders">
                    Alle Bestellanfragen stonieren
                  </button>
                )}
                {confimDeletePopup === 'all' && (
                  <DeletePopup
                    onClose={() => setConfimDeletePopup(false)}
                    onDelete={() => {
                      deletePharmaciesAllOrders();
                      setConfimDeletePopup(false);
                    }}
                    title="Alle Bestellanfragen stornieren"
                    text="Möchten Sie die Bestellanfrage wirklich stornieren? Es ist nicht möglich, diese Aktion abzubrechen."
                  />
                )}
              </div>
              <FieldDatePicker
                className="date-orders"
                startDateValue={dataSearch?.[0]}
                endDateValue={dataSearch?.[1]}
                onEvent={onSetDateRange}
                placeholder="Datum auswählen"
                isRange={true}
              />
            </div>
            <OrdersSection dataSearch={dataSearch} />
          </div>
        )}
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default PharmacieOrders;

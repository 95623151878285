import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../store/reducer';
import * as action from '../../../../store/action';

import { useChangeEffect, useDelay, usePageUp } from '../../../../hooks';

import Form from '../../../../components/UI/form/Form.jsx';
import Button from '../../../../components/UI/button';
import FormInput from '../../../../components/UI/form/formInput/FormInput';
import Checkbox from '../../../../components/UI/checkbox';
import OpeningHours from '../../../../components/openingHours';

import { PharmacieEditSchema, PharmacieEditFullSchema } from '../../../../components/UI/form/validation';

import Loader from '../../../../components/popups/loader';
import Header from '../../../../components/header';
import UploadFiles from '../../../../components/uploadFiles/UploadFiles';
import _ from 'lodash';
import { getFormatedPharmacieData } from '../../../../utils/getFormatedPharmacieData';

const SettingForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isShow = useDelay(100);

  const pharmacieStatus = useSelector(reducer.request.pharmacie.user.state.getPharmaciesUser.status);
  const dataPharmacie = useSelector(reducer.request.pharmacie.user.state.getPharmaciesUser.data);
  const isDataChages = useSelector(reducer.app.common.state.isDataChages);

  const setDataChage = (isChange) => dispatch(action.app.common.isDataChages(isChange));
  const getPharmaciesUser = () => dispatch(action.request.pharmacie.user.getPharmaciesUser.get());
  const patchPharmaciesUser = (data) => dispatch(action.request.pharmacie.user.patchPharmaciesUser.patch(data));

  usePageUp();

  const [newData, setNewData] = useState();
  const [attachment, setAttachment] = useState([]);
  const [isSameAddresses, setIsSameAddresses] = useState();
  const [hours, setHours] = useState({
    openingHours: {
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
    },
  });
  const [isShowLoader, setShowLoader] = useState(true);

  const isRequestGETSuccess = pharmacieStatus === 'success';

  useEffect(() => {
    setAttachment(dataPharmacie?.files || []);
    setHours({ openingHours: { ...dataPharmacie?.openingHours } });
    setIsSameAddresses(!!dataPharmacie?.isSameAddresses);
  }, [dataPharmacie]);

  useEffect(() => {
    getPharmaciesUser();
    setDataChage(false);
  }, []);


  useEffect(() => {
    if (dataPharmacie) {
      if (dataPharmacie.isSameAddresses !== isSameAddresses) {
        setDataChage(true);
        return;
      }
      if (newData && !_.isEqual(getFormatedPharmacieData(dataPharmacie, isSameAddresses), newData)) {
        setDataChage(true);
        return;
      }
      if (!_.isEqual(dataPharmacie?.openingHours, hours.openingHours)) {
        setDataChage(true);
        return;
      }
      if (!_.isEqual(attachment, dataPharmacie.files)) {
        setDataChage(true);
        return;
      }
      setDataChage(false);
    }
  }, [newData, dataPharmacie, isSameAddresses, hours, attachment]);

  const onSubmit = useCallback(
    (data) => {
      patchPharmaciesUser({
        name: data.name,
        billingAddress: {
          zip: data.billingZip,
          city: data.billingCity,
          street: data.billingStreet,
          house: data.billingHouse,
        },
        deliveryAddress: {
          zip: !isSameAddresses ? data.deliveryZip : data.billingZip,
          city: !isSameAddresses ? data.deliveryCity : data.billingCity,
          street: !isSameAddresses ? data.deliveryStreet : data.billingStreet,
          house: !isSameAddresses ? data.deliveryHouse : data.billingHouse,
        },
        isSameAddresses,
        contactName: data.contactName,
        contactEmail: data.contactEmail,
        contactPhone: data.contactPhone,
        contactFax: data.contactFax,
        contactWeb: data.contactWeb,
        openingHours: hours.openingHours,
        files: attachment.map((item) => item.key),
      });
      navigate('/dashboard');
    },
    [isSameAddresses, attachment, hours],
  );

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  return (
    <>
      <Header />
      <main className={`profile-edit-page page pharmacie-settings-no-activ ${isShow ? 'show' : ''}`}>
        {!isShowLoader && (
          <Form
            defaultValues={getFormatedPharmacieData(dataPharmacie, isSameAddresses)}
            className="add-edit profile-form"
            onSubmit={onSubmit}
            resolver={yupResolver(isSameAddresses ? PharmacieEditSchema : PharmacieEditFullSchema)}
            onChange={(data) => setNewData({ ...data })}>
            <div className="left-side">
              <section className="section-wrapper">
                <div className="form-title">
                  <h2 className="title">Hinterlegen Sie Ihre Daten, um ein Konto zu eröffnen</h2>
                  <p className="under-title">
                    Um zu bestellen, füllen Sie bitte Ihre Kontaktdaten aus. Wir benötigen sie, um sicherzustellen,
                    <br /> dass Ihre Bestellanfrage an der richtigen Adresse ankommt.
                    <strong> Alle Felder sind erforderlich.</strong>
                  </p>
                </div>
                <ul className="field-list-billing">
                  <li>Rechnungsadresse</li>
                  <li>
                    <FormInput name="name" type="text" label="Name" isRequired={true} />
                  </li>
                  <li>
                    <FormInput name="billingZip" type="text" label="PLZ" isRequired={true} />
                    <FormInput name="billingCity" type="text" label="Stadt" isRequired={true} />
                  </li>
                  <li>
                    <FormInput name="billingStreet" type="text" label="Straße" isRequired={true} />
                    <FormInput name="billingHouse" type="text" label="Hausnummer" isRequired={true} />
                  </li>
                </ul>
                <ul className="field-list-same-address">
                  <Checkbox
                    label="Rechnungs- und Lieferadresse sind gleich"
                    isChecked={isSameAddresses}
                    onChange={() => setIsSameAddresses(!isSameAddresses)}
                  />
                </ul>
                <ul className={`field-list-delivery ${isSameAddresses ? 'hide-field' : 'show-field'}`}>
                  <li>Lieferadresse</li>
                  <li className="s">
                    <FormInput name="deliveryZip" type="text" label="PLZ" isRequired={true} />
                    <FormInput name="deliveryCity" type="text" label="Stadt" isRequired={true} />
                  </li>
                  <li className="address-info-two">
                    <FormInput name="deliveryStreet" type="text" label="Straße" isRequired={true} />
                    <FormInput name="deliveryHouse" type="text" label="Hausnummer" isRequired={true} />
                  </li>
                </ul>
                <ul className="field-list-contact pharmacie-contact">
                  <li>Ansprechpartner</li>
                  <li>
                    <FormInput name="contactName" type="text" label="Name" isRequired={true} />
                    <FormInput name="contactEmail" type="text" label="E-Mail" isRequired={true} />
                  </li>
                  <li>
                    <FormInput name="contactPhone" type="text" label="Telefon" isRequired={true} />
                    <FormInput name="contactFax" type="text" label="Fax-Nr." className='contact-fax' isRequired={false} />
                  </li>
                  <li>
                    <FormInput name="contactWeb" type="text" label="Web" isRequired={true} />
                  </li>
                </ul>
                <ul className="field-list-contact contact-hours">
                  <li>Informationen</li>
                  <li>
                    <OpeningHours data={hours} setData={setHours} />
                  </li>
                </ul>
                <UploadFiles
                  title="Weitere Dokumente hochladen"
                  attachment={attachment}
                  setAttachment={setAttachment}
                />
                <div className="group-btn">
                  <Button disabled={!isDataChages} title="Bestätigen" type="submit" width="201" color="green" />
                </div>
              </section>
            </div>
          </Form>
        )}
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default SettingForm;

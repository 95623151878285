import React from 'react';

import './style.scss';

const Checkbox = ({
  selectedOneOrMore,
  isChecked,
  label,
  onChange,
  disabled,
  isAvailable = true,
  isEditable,
  setEditId,
}) => {
  return (
    <>
      {!!isAvailable && (
        <div className="default-checkbox-wrapper">
          <label
            className={`default-checkbox ${selectedOneOrMore && !isChecked ? 'selected' : ''} ${label ? 'label' : ''}`}>
            <input type="checkbox" checked={!!isChecked} onChange={onChange} disabled={disabled} />
            <span className="checkmark"></span>
            {label}
          </label>
          {isEditable && (
            <button className="edit-btn" onClick={setEditId}>
              Bearbeiten
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Checkbox;

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.admin.buds.getBudsAll.getSuccess, ({ getBudsAll }, action) => {
      getBudsAll.status = 'success';
      getBudsAll.data = action.payload;
    })
    .addCase(action.request.admin.buds.getBudsAll.getPending, ({ getBudsAll }, action) => {
      getBudsAll.status = 'pending';
    })
    .addCase(action.request.admin.buds.getBudsAll.getFailed, ({ getBudsAll }, action) => {
      getBudsAll.status = 'failed';
      getBudsAll.error = action.payload;
      getBudsAll.data = null;
    });

  builder
    .addCase(action.request.admin.buds.getBudId.getSuccess, ({ getBudId }, action) => {
      getBudId.status = 'success';
      getBudId.data = action.payload;
    })
    .addCase(action.request.admin.buds.getBudId.getPending, ({ getBudId }, action) => {
      getBudId.status = 'pending';
    })
    .addCase(action.request.admin.buds.getBudId.getFailed, ({ getBudId }, action) => {
      getBudId.status = 'failed';
      getBudId.error = action.payload;
      getBudId.data = null;
    });

  builder
    .addCase(action.request.admin.buds.patchBudId.patchSuccess, ({ patchBudId }, action) => {
      patchBudId.status = 'success';
      patchBudId.data = action.payload;
    })
    .addCase(action.request.admin.buds.patchBudId.patchPending, ({ patchBudId }, action) => {
      patchBudId.status = 'pending';
    })
    .addCase(action.request.admin.buds.patchBudId.patchFailed, ({ patchBudId }, action) => {
      patchBudId.status = 'failed';
      patchBudId.error = action.payload;
      patchBudId.data = null;
    });

  builder
    .addCase(action.request.admin.buds.deleteBudId.deleteSuccess, ({ deleteBudId }, action) => {
      deleteBudId.status = 'success';
      deleteBudId.data = action.payload;
    })
    .addCase(action.request.admin.buds.deleteBudId.deletePending, ({ deleteBudId }, action) => {
      deleteBudId.status = 'pending';
    })
    .addCase(action.request.admin.buds.deleteBudId.deleteFailed, ({ deleteBudId }, action) => {
      deleteBudId.status = 'failed';
      deleteBudId.error = action.payload;
      deleteBudId.data = null;
    });

  builder
    .addCase(action.request.admin.buds.getBuds.getSuccess, ({ getBuds }, action) => {
      getBuds.status = 'success';
      getBuds.data = action.payload;
    })
    .addCase(action.request.admin.buds.getBuds.getPending, ({ getBuds }, action) => {
      getBuds.status = 'pending';
    })
    .addCase(action.request.admin.buds.getBuds.getFailed, ({ getBuds }, action) => {
      getBuds.status = 'failed';
      getBuds.error = action.payload;
      getBuds.data = null;
    });

  builder
    .addCase(action.request.admin.buds.postBuds.postSuccess, ({ postBuds }, action) => {
      postBuds.status = 'success';
      postBuds.data = action.payload;
    })
    .addCase(action.request.admin.buds.postBuds.postPending, ({ postBuds }, action) => {
      postBuds.status = 'pending';
    })
    .addCase(action.request.admin.buds.postBuds.postFailed, ({ postBuds }, action) => {
      postBuds.status = 'failed';
      postBuds.error = action.payload;
      postBuds.data = null;
    });

  builder
    .addCase(action.request.admin.buds.deleteBuds.deleteSuccess, ({ deleteBuds }, action) => {
      deleteBuds.status = 'success';
      deleteBuds.data = action.payload;
    })
    .addCase(action.request.admin.buds.deleteBuds.deletePending, ({ deleteBuds }, action) => {
      deleteBuds.status = 'pending';
    })
    .addCase(action.request.admin.buds.deleteBuds.deleteFailed, ({ deleteBuds }, action) => {
      deleteBuds.status = 'failed';
      deleteBuds.error = action.payload;
      deleteBuds.data = null;
    });
});

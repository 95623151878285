import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';
import Field from '../../../../components/UI/field';

import * as reducer from '../../../../store/reducer';
import * as action from '../../../../store/action';
import * as variable from '../../../../variables';

import Button from '../../../../components/UI/button';
import Range from '../../../../components/range';
import Dropdown from '../../../../components/UI/dropdown2';

import DefaultBudImg from '../../../../assets/images/default-bud-img.svg';
import DefaultExtractImg from '../../../../assets/images/default-extract-img.svg';

const PrescriptionItem = ({ isActive, id, type, isFirst, isLast, ...item }) => {
  const dispatch = useDispatch();

  const DefaultImg = type === 'bud' ? DefaultBudImg : DefaultExtractImg;

  const dosages = useSelector(reducer.request.dosage.state.getDosagesAll.data);
  const forms = useSelector(reducer.request.form.state.getFormsAll.data);
  const settings = useSelector(reducer.request.setting.state.getSettings.data);

  const up = () => dispatch(action.app.prescription.upItemInPrescription({ id, type }));
  const down = () => dispatch(action.app.prescription.downItemInPrescription({ id, type }));
  const update = (newItem) => dispatch(action.app.prescription.updateItemInPrescription({ id, type, ...newItem }));
  const remove = () => dispatch(action.app.prescription.removeFromPrescription({ id, type }));

  const onDosageChange = (dosage) => {
    if (dosage.id === item.dosage?.id) return;

    update({
      ...item,
      dosage: {
        ...dosage,
        dosageFields: dosage.dosageFields.map((field) => ({ ...field, input: '' })),
      },
    });
  };

  const onDosageFieldChange = (input, index) => {
    update({
      ...item,
      dosage: {
        ...item.dosage,
        dosageFields: item.dosage.dosageFields.map((field, currentIndex) => {
          if (currentIndex !== index) return { ...field };
          return { ...field, input };
        }),
      },
    });
  };

  useEffect(() => {
    if (!settings || !forms || item.amount) return;

    const amount = (type === 'bud' ? settings.budsPrescriptionAmount : settings.extractsPrescriptionAmount) ?? 1;
    const form = type === 'bud' ? forms[0] : null;
    update({ ...item, amount, form });
  }, [settings, forms]);

  return (
    <div className={`prescription-item ${isActive ? 'active' : 'disabled'}`}>
      <div className="info">
        {isActive ? (
          <p className="active">Hinzugefügt</p>
        ) : (
          <p>Scrollen Sie nach oben, um Versicherungstyp zu ändern</p>
        )}
        <Button title="Löschen" onEvent={remove} />
      </div>
      <div className="product-info">
        <div className="product-details">
          <img src={item.imageKey ? `https://${variable.url}/uploads/${item.imageKey}` : DefaultImg} alt="" />
          <div>
            <img src={item.companyImageKey ? `https://${variable.url}/uploads/${item.companyImageKey}` : ''} alt="" />
            <p className="name">{item.name}</p>
            <p className="cultivars">
              {item.genetics} / {item.cultivars.join(', ')}
            </p>
          </div>
        </div>
        <div className="arrows">
          <button className={isFirst ? 'disabled' : ''} onClick={up} />
          <button className={isLast ? 'disabled' : ''} onClick={down} />
        </div>
      </div>
      <div className="amounts">
        <div className="grams">
          <p>Zu verordnende Menge:</p>
          <div className="range-wrapper">
            <Range
              title="Menge (Gramm)"
              data={item?.amount || 0}
              setData={(amount) => update({ ...item, amount })}
              disabled={!isActive}
              max={150}
            />
            {item.concentrationTarget && (
              <Range
                title={item.concentrationTarget === 'thc' ? 'THC-Konzentration' : 'CBD-Konzentration'}
                data={item?.concentration || 0}
                setData={(concentration) => update({ ...item, concentration })}
                max={item[item.concentrationTarget]}
                disabled={!isActive}
              />
            )}
          </div>
        </div>
        <div className="forms">
          {type === 'bud' &&
            forms
              ?.filter(({ active }) => active)
              ?.map((form) => (
                <Button
                  key={form.id}
                  title={form.name}
                  color={form.id === item.form?.id ? 'green' : 'transparent'}
                  onEvent={() => update({ ...item, form })}
                  disabled={!isActive}
                />
              ))}
        </div>
      </div>
      <div className="dosage">
        <Dropdown
          title="Dosierungsangaben hinzufügen"
          label="Dosierungsangaben auswählen"
          content={dosages?.filter((dosage) => dosage.active && dosage.type === type)}
          select={item.dosage}
          setSelect={onDosageChange}
          disabled={!isActive}
        />
        <div className="fields">
          {item.dosage?.dosageFields.map((field, index) => (
            <Field
              key={field.id}
              className="field"
              label={field.title}
              placeholder={field.value}
              value={field.input}
              onEvent={(event) => onDosageFieldChange(event.target.value, index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrescriptionItem;

import Cookies from 'js-cookie';

import * as variable from '../../variables';

export const orders = {
  getPharmaciesOrders: (from, to, sort, order) => {
    const sortParam = sort ? `&sort=${sort}` : '';
    const orderParam = order ? `&order=${order}` : '';
    const fromParam = from ? `&from=${from}` : '';
    const toParam = to ? `&to=${to}` : '';

    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/pharmacies/orders?${sortParam}${fromParam}${toParam}${orderParam}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getPharmaciesOrdersId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/pharmacies/orders/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  postPharmaciesOrders: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/pharmacies/orders`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deletePharmaciesOrders: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/pharmacies/orders/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  deletePharmaciesAllOrders: () => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/pharmacies/orders`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  deletePharmaciesOrdersProductId: (orderId, productId) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/pharmacies/orders/${orderId}/products/${productId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
};

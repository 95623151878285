export const state = {
  getPharmaciesUser: {
    data: (state) => state.request.pharmacie.user.getPharmaciesUser.data,
    status: (state) => state.request.pharmacie.user.getPharmaciesUser.status,
    error: (state) => state.request.pharmacie.user.getPharmaciesUser.error,
  },
  patchPharmaciesUser: {
    data: (state) => state.request.pharmacie.user.patchPharmaciesUser.data,
    status: (state) => state.request.pharmacie.user.patchPharmaciesUser.status,
    error: (state) => state.request.pharmacie.user.patchPharmaciesUser.error,
  },
};

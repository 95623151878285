import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.admin.manufacturers.getManufacturersAll.getSuccess, ({ getManufacturersAll }, action) => {
      getManufacturersAll.status = 'success';
      getManufacturersAll.data = action.payload;
    })
    .addCase(action.request.admin.manufacturers.getManufacturersAll.getPending, ({ getManufacturersAll }, action) => {
      getManufacturersAll.status = 'pending';
    })
    .addCase(action.request.admin.manufacturers.getManufacturersAll.getFailed, ({ getManufacturersAll }, action) => {
      getManufacturersAll.status = 'failed';
      getManufacturersAll.error = action.payload;
      getManufacturersAll.data = null;
    });

  builder
    .addCase(action.request.admin.manufacturers.getManufactureId.getSuccess, ({ getManufactureId }, action) => {
      getManufactureId.status = 'success';
      getManufactureId.data = action.payload;
    })
    .addCase(action.request.admin.manufacturers.getManufactureId.getPending, ({ getManufactureId }, action) => {
      getManufactureId.status = 'pending';
    })
    .addCase(action.request.admin.manufacturers.getManufactureId.getFailed, ({ getManufactureId }, action) => {
      getManufactureId.status = 'failed';
      getManufactureId.error = action.payload;
      getManufactureId.data = null;
    });

  builder
    .addCase(action.request.admin.manufacturers.putManufactureId.putSuccess, ({ putManufactureId }, action) => {
      putManufactureId.status = 'success';
      putManufactureId.data = action.payload;
    })
    .addCase(action.request.admin.manufacturers.putManufactureId.putPending, ({ putManufactureId }, action) => {
      putManufactureId.status = 'pending';
    })
    .addCase(action.request.admin.manufacturers.putManufactureId.putFailed, ({ putManufactureId }, action) => {
      putManufactureId.status = 'failed';
      putManufactureId.error = action.payload;
      putManufactureId.data = null;
    });

  builder
    .addCase(
      action.request.admin.manufacturers.deleteManufactureId.deleteSuccess,
      ({ deleteManufactureId }, action) => {
        deleteManufactureId.status = 'success';
        deleteManufactureId.data = action.payload;
      }
    )
    .addCase(
      action.request.admin.manufacturers.deleteManufactureId.deletePending,
      ({ deleteManufactureId }, action) => {
        deleteManufactureId.status = 'pending';
      }
    )
    .addCase(action.request.admin.manufacturers.deleteManufactureId.deleteFailed, ({ deleteManufactureId }, action) => {
      deleteManufactureId.status = 'failed';
      deleteManufactureId.error = action.payload;
      deleteManufactureId.data = null;
    });

  builder
    .addCase(action.request.admin.manufacturers.getManufacturers.getSuccess, ({ getManufacturers }, action) => {
      getManufacturers.status = 'success';
      getManufacturers.data = action.payload;
    })
    .addCase(action.request.admin.manufacturers.getManufacturers.getPending, ({ getManufacturers }, action) => {
      getManufacturers.status = 'pending';
    })
    .addCase(action.request.admin.manufacturers.getManufacturers.getFailed, ({ getManufacturers }, action) => {
      getManufacturers.status = 'failed';
      getManufacturers.error = action.payload;
      getManufacturers.data = null;
    });

  builder
    .addCase(action.request.admin.manufacturers.postManufacturers.postSuccess, ({ postManufacturers }, action) => {
      postManufacturers.status = 'success';
      postManufacturers.data = action.payload;
    })
    .addCase(action.request.admin.manufacturers.postManufacturers.postPending, ({ postManufacturers }, action) => {
      postManufacturers.status = 'pending';
    })
    .addCase(action.request.admin.manufacturers.postManufacturers.postFailed, ({ postManufacturers }, action) => {
      postManufacturers.status = 'failed';
      postManufacturers.error = action.payload;
      postManufacturers.data = null;
    });

  builder
    .addCase(
      action.request.admin.manufacturers.deleteManufacturers.deleteSuccess,
      ({ deleteManufacturers }, action) => {
        deleteManufacturers.status = 'success';
        deleteManufacturers.data = action.payload;
      }
    )
    .addCase(
      action.request.admin.manufacturers.deleteManufacturers.deletePending,
      ({ deleteManufacturers }, action) => {
        deleteManufacturers.status = 'pending';
      }
    )
    .addCase(action.request.admin.manufacturers.deleteManufacturers.deleteFailed, ({ deleteManufacturers }, action) => {
      deleteManufacturers.status = 'failed';
      deleteManufacturers.error = action.payload;
      deleteManufacturers.data = null;
    });
});

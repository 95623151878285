import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(action.app.common.addRole, (state, action) => {
    state.role = action.payload;
  });

  builder.addCase(action.app.common.deleteRole, (state, action) => {
    state.role = null;
  });

  builder.addCase(action.app.common.isDataChages, (state, action) => {
    state.isDataChages = action.payload;
  });
});

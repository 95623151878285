export const setInitialContentPerPage = () => {
  const lsPerPageValues = JSON.parse(localStorage.getItem('perPageValues'));

  const config = [
    'doctorBuds',
    'doctorExtracts',
    'manufacturerBuds',
    'manufacturerExtracts',
    'manufaturerOrders',
    'pharmacieBuds',
    'pharmacieExtracts',
    'superuserDelivery',
    'superuserDosageBuds',
    'superuserBudsList',
    'superuserDashboardOrders',
    'superuserDosageExtracts',
    'superuserExtractsList',
    'superuserManufacturerMain',
    'superuserOrdersMain',
    'superuserPharmaciesArchive',
    'superuserPharmaciesMain',
    'superuserPharmaciesProfileOrders',
    'superuserMailTemplates',
  ];

  const exclude = ['superuserDosageBuds', 'superuserDelivery', 'superuserDosageExtracts'];

  if (!lsPerPageValues) {
    localStorage.setItem(
      'perPageValues',
      JSON.stringify(config.reduce((a, v) => ({ ...a, [v]: exclude.some((i) => i === v) ? 9 : 10 }), {})),
    );
  }
};

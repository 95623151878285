import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import './style.scss';

import * as variable from '../../variables';

import { ReactComponent as IconBurger } from '../../assets/icons/icon-burger.svg';
import { ReactComponent as IconClose } from '../../assets/icons/icon-close-white.svg';

import UserLogo from '../../assets/images/user-logo.png';
import COTLogo from '../../assets/images/C_TRAX_w.svg';

const MobileNav = ({ logo, listNav, role, onEvent, handleLink }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <section className="mobile-nav">
      <button className="button-burger" onClick={() => setOpen(true)}>
        <IconBurger />
      </button>

      <div className={`content-nav ${isOpen ? 'show' : 'hide'}`}>
        <div className="top">
          <Link onClick={handleLink} className="logo" to="/dashboard">
            <img src={COTLogo} alt="" />
          </Link>

          <button className="button-close" onClick={() => setOpen(false)}>
            <IconClose />
          </button>
        </div>

        <div className="user-info">
          <p className="name">{role}</p>
          <p className="avatar">
            <img src={logo ? `https://${variable.url}/uploads/${logo}` : UserLogo} alt="" />
          </p>
        </div>

        <ul className="site-nav">
          {listNav?.map((item) => (
            <li className="nav-item" key={item.id}>
              <NavLink onClick={handleLink} className="nav-link" to={`/${item.link}`} key={item.id}>
                {item.title}
              </NavLink>
            </li>
          ))}
        </ul>

        <ul className="user-nav">
          {role !== 'doctor' && (
            <li className="nav-item">
              <Link onClick={handleLink} className="nav-link" to="/settings">
                Einstellungen
              </Link>
            </li>
          )}
          <li className="nav-item">
            <button className="nav-link" onClick={onEvent}>
              Abmelden
            </button>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default MobileNav;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../store/reducer';
import * as action from '../../../../store/action';

import { usePageUp, useChangeEffect } from '../../../../hooks';

import Header from '../../../../components/header';
import Tabs from '../../../../components/UI/tabs';
import List from './tabs/list';
import Dasage from './tabs/dosage';
import Loader from '../../../../components/popups/loader';
import { useDefaultPerPage } from '../../../../hooks/useDefaultPerPage';

const Extracts = () => {
  const dispatch = useDispatch();

  const { contentPerPage: extracts } = useDefaultPerPage('superuserExtractsList');
  const { contentPerPage: dosage } = useDefaultPerPage('superuserDosageExtracts');

  const dataExtracts = useSelector(reducer.request.admin.extract.state.getExtracts.data);
  const dataDosages = useSelector(reducer.request.dosage.state.getDosages.data);

  const statusGetCultivarsAll = useSelector(reducer.request.cultivar.state.getCultivarsAll.status);
  const statusGetExtracts = useSelector(reducer.request.admin.extract.state.getExtracts.status);
  const statusGetDosages = useSelector(reducer.request.dosage.state.getDosages.status);

  const getCultivarsAll = () => dispatch(action.request.cultivars.getCultivarsAll.get());
  const getExtracts = (start, limit) => dispatch(action.request.admin.extracts.getExtracts.get(start, limit));
  const getDosages = (start, limit, type) => dispatch(action.request.dosages.getDosages.get(start, limit, type));

  const [isShowLoader, setShowLoader] = useState(true);
  const [activeTabID, setActiveTabID] = useState(1);

  const isRequestGETSuccess =
    statusGetCultivarsAll === 'success' && statusGetExtracts === 'success' && statusGetDosages === 'success';

  const tabsList = [
    {
      id: 1,
      name: 'Liste',
      count: dataExtracts?.total,
    },
    {
      id: 2,
      name: 'Dosierung',
      count: dataDosages?.total,
    },
  ];

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  useEffect(() => {
    getCultivarsAll();
    getExtracts(0, extracts);
    getDosages(0, dosage, 'extract');
  }, [extracts, dosage]);

  usePageUp();

  return (
    <>
      <Header />

      <main className={`extracts-page page ${!isShowLoader ? 'show' : ''}`}>
        <div className="container">
          <h1 className="page-title">Extrakte</h1>
          <Tabs list={tabsList} activeTabID={activeTabID} setActiveTabID={setActiveTabID} />

          <List visible={activeTabID === 1} isShowLoader={isShowLoader} />
          <Dasage visible={activeTabID === 2} isShowLoader={isShowLoader} />
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default Extracts;

import React, { forwardRef } from 'react';

import './style.scss';

const PrintPrescription = forwardRef(({ prescription, patientInfo, country }, ref) => {
  return (
    <section className="print-prescription" ref={ref}>
      <p className="patientInsurance">{patientInfo?.patientInsurance}</p>
      <p className="small-text patientName">{patientInfo?.patientName}</p>
      <p className="small-text patientSurname">{patientInfo?.patientSurname}</p>
      <p className="small-text patientZip">{patientInfo?.patientZip}</p>
      <p className="small-text patientCity">{patientInfo?.patientCity}</p>
      <p className="small-text patientCountry">{country?.name}</p>
      <p className="small-text patientStreet">{patientInfo?.patientStreet}</p>
      <p className="small-text patientHouse">{patientInfo?.patientHouse}</p>
      <p className="taxId">{patientInfo?.taxId}</p>
      <p className="insuredId">{patientInfo?.insuredId}</p>
      <p className="status">{patientInfo?.status}</p>
      <p className="businessPremisesId">{patientInfo?.businessPremisesId}</p>
      <p className="birth small-text">
        {patientInfo?.patientDay &&
          patientInfo?.patientMonth &&
          patientInfo?.patientYear &&
          `${patientInfo.patientDay}/${patientInfo.patientMonth}/${patientInfo.patientYear}`}
      </p>
      <p className="date">
        {patientInfo?.dateDay &&
          patientInfo?.dateMonth &&
          patientInfo?.dateYear &&
          `${patientInfo.dateDay}/${patientInfo.dateMonth}/${patientInfo.dateYear}`}
      </p>
      <ul className="text">
        {prescription.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </section>
  );
});

export default PrintPrescription;

import Cookies from 'js-cookie';

import * as variable from '../../variables';

export const pharmacies = {
  getPharmaciesAll: () => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/pharmacies/all`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getPharmacyId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/pharmacies/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  patchPharmacyId: (id, data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/pharmacies/${id}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deletePharmacyId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/pharmacies/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getPharmacies: (start, limit, archieved, search, active, order, sort) => {
    const searchParam = search ? `&search=${search}` : '';
    const activeParam = active?.name === 'Aktiv' ? `&active=true` : active?.name === 'Inaktiv' ? `&active=false` : '';

    const accessToken = Cookies.get('accessToken');

    return fetch(
      `https://${variable.url}/admin/pharmacies?start=${start}&limit=${limit}&archieved=${archieved}${searchParam}${activeParam}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  },

  deletePharmacies: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/pharmacies`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

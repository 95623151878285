import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

import Button from '../../../../components/UI/button';
import Field from '../../../../components/UI/field';

const SignIn = ({ onEvent }) => {
  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const onSetValue = (event) => {
    const { value, name } = event.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    onEvent(values);
  };

  return (
    <form className="signin" onSubmit={onSubmitHandler}>
      <p className="title">Anmelden</p>
      <Field label="E-Mail" name="email" type="text" value={values.email} onEvent={onSetValue} />
      <Field label="Passwort" name="password" type="password" value={values.password} onEvent={onSetValue} />

      <Link className="forgot-password-link" to="/forgot-password">
        Passwort vergessen?
      </Link>
      <Button title="Anmelden" width="209" color="green" type="submit" />
    </form>
  );
};

export default SignIn;

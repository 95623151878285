import React from 'react';

import './style.scss';

const RadioButton = ({ label, name, isChecked, onChange }) => {
  return (
    <label className="default-radio">
      <input type="radio" name={name} checked={isChecked} onChange={onChange} />
      <span className="checkmark"></span>
      {label}
    </label>
  );
};

export default RadioButton;

export const state = {
  getManufacturerPharmaciesId: {
    data: (state) => state.request.manufacturer.pharmacies.getManufacturerPharmaciesId.data,
    status: (state) => state.request.manufacturer.pharmacies.getManufacturerPharmaciesId.status,
    error: (state) => state.request.manufacturer.pharmacies.getManufacturerPharmaciesId.error,
  },
  putManufacturerPharmaciesId: {
    data: (state) => state.request.manufacturer.pharmacies.putManufacturerPharmaciesId.data,
    status: (state) => state.request.manufacturer.pharmacies.putManufacturerPharmaciesId.status,
    error: (state) => state.request.manufacturer.pharmacies.putManufacturerPharmaciesId.error,
  },
};

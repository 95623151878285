export const getFormatedUserData = (dataUser) => {
  return {
    name: dataUser?.name,
    email: dataUser?.email,
    phone: dataUser?.phone,
    billingZip: dataUser?.billingAddress?.zip,
    billingCity: dataUser?.billingAddress?.city,
    billingStreet: dataUser?.billingAddress?.street,
    billingHouse: dataUser?.billingAddress?.house,
    deliveryZip: dataUser?.deliveryAddress?.zip,
    deliveryCity: dataUser?.deliveryAddress?.city,
    deliveryStreet: dataUser?.deliveryAddress?.street,
    deliveryHouse: dataUser?.deliveryAddress?.house,
    contactName: dataUser?.contact?.name,
    contactEmail: dataUser?.contact?.email,
    contactPhone: dataUser?.contact?.phone,
    technicalName: dataUser?.technicalContact?.name,
    technicalEmail: dataUser?.technicalContact?.email,
    technicalPhone: dataUser?.technicalContact?.phone,
    bookkeepingName: dataUser?.bookkeepingContact?.name,
    bookkeepingEmail: dataUser?.bookkeepingContact?.email,
    bookkeepingPhone: dataUser?.bookkeepingContact?.phone,
    description: dataUser?.description,
  };
};

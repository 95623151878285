export const prescriptionItems = [
  {
    title: 'Produktname',
    value: 'Produktname',
    editable: false,
  },
  {
    title: 'Verschriebene Menge',
    value: 'Verschriebene Menge',
    editable: false,
  },
  {
    title: 'Wirkstoffmenge basierend auf Multiplikator',
    value: '0',
    editable: true,
  },
  {
    title: 'Gesamtmenge "THC"',
    value: 'Gesamtmenge "THC"',
    editable: false,
  },
  {
    title: 'Dosierungsanleitung',
    value: 'Dosierungsanleitung',
    editable: false,
  },
  {
    title: '"A" hinzufügen, wenn Gesamtmenge "THC" > 1000mg',
    value: '"A" hinzufügen, wenn Gesamtmenge "THC" > 1000mg',
    editable: false,
  },
  {
    title: '"ml" hinzufügen',
    value: 'ml',
    editable: false,
  },
  {
    title: '"g" hinzufügen',
    value: 'g',
    editable: false,
  },
  {
    title: '"mg" hinzufügen',
    value: 'mg',
    editable: false,
  },
  {
    title: '"," hinzufügen',
    value: ',',
    editable: false,
  },
  {
    title: 'Eingabefeld',
    value: '',
    editable: true,
  },
];

export const individualConcentrationPrescriptionItems = [
  ...prescriptionItems,
  {
    title: 'THC per Einheit',
    value: 'THC per Einheit',
    editable: false,
  },
  {
    title: 'CBD per Einheit',
    value: 'CBD per Einheit',
    editable: false,
  },
];

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.auth.postLogin.postSuccess, ({ postLogin }, action) => {
      postLogin.status = 'success';
      postLogin.data = action.payload;
    })
    .addCase(action.request.auth.postLogin.postPending, ({ postLogin }, action) => {
      postLogin.status = 'pending';
      postLogin.data = action.payload;
    })
    .addCase(action.request.auth.postLogin.postFailed, ({ postLogin }, action) => {
      postLogin.status = 'failed';
      postLogin.data = action.payload;
    });

  builder
    .addCase(action.request.auth.postLoginDocCheck.postSuccess, ({ postLoginDocCheck }, action) => {
      postLoginDocCheck.status = 'success';
      postLoginDocCheck.data = action.payload;
    })
    .addCase(action.request.auth.postLoginDocCheck.postPending, ({ postLoginDocCheck }, action) => {
      postLoginDocCheck.status = 'pending';
      postLoginDocCheck.data = action.payload;
    })
    .addCase(action.request.auth.postLoginDocCheck.postFailed, ({ postLoginDocCheck }, action) => {
      postLoginDocCheck.status = 'failed';
      postLoginDocCheck.data = action.payload;
    });

  builder
    .addCase(action.request.auth.getRefresh.getSuccess, ({ getRefresh }, action) => {
      getRefresh.status = 'success';
      getRefresh.data = action.payload;
    })
    .addCase(action.request.auth.getRefresh.getPending, ({ getRefresh }, action) => {
      getRefresh.status = 'pending';
      getRefresh.data = action.payload;
    })
    .addCase(action.request.auth.getRefresh.getFailed, ({ getRefresh }, action) => {
      getRefresh.status = 'failed';
      getRefresh.data = action.payload;
    });

  builder
    .addCase(action.request.auth.getLogout.getSuccess, ({ getLogout }) => {
      getLogout.status = 'success';
    })
    .addCase(action.request.auth.getLogout.getPending, ({ getLogout }) => {
      getLogout.status = 'pending';
    })
    .addCase(action.request.auth.getLogout.getFailed, ({ getLogout }, action) => {
      getLogout.status = 'failed';
      getLogout.data = action.payload;
    });

  builder
    .addCase(action.request.auth.postForgotPassword.postSuccess, ({ postForgotPassword }) => {
    postForgotPassword.status = 'success';
    })
    .addCase(action.request.auth.postForgotPassword.postPending, ({ postForgotPassword }) => {
    postForgotPassword.status = 'pending';
    })
    .addCase(action.request.auth.postForgotPassword.postFailed, ({ postForgotPassword }, action) => {
      postForgotPassword.status = 'failed';
    postForgotPassword.data = action.payload;
    });
});

import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import ButtonClear from '../UI/buttonClear';

import addImage from '../../assets/images/add-image.png';

import * as variable from '../../variables';
import Loader from '../loader/Loader';

const UploadImgDragDrop = ({
  title,
  type,
  data,
  onChange,
  onDelete,
  buttonName = 'Datei hinzufügen',
  infoText = 'oder per Drag-and-Drop in die',
  isDefaultImg,
  isSingeImg,
  disabled,
  textImageSize = '160x45px',
  status = true,
}) => {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      onChange(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  return (
    <section className="upload-img">
      {status ? (
        <>
          <h3 className="title">{title}</h3>
          <div
            {...getRootProps({ className: 'dropzone' })}
            className={`upload-content ${isSingeImg ? 'single-img-wrapper' : ''}`}>
            {isSingeImg && data ? (
              <div className="single-img-content">
                <img src={`https://${variable.url}/uploads/${data}`} alt="" className="single-img" />
                <ButtonClear onEvent={() => onDelete(data)} typeIcon="trash" className="single-img-delete" />
              </div>
            ) : (
              <>
                <div className={`btns btns-dnd ${isDefaultImg ? 'btns-dnd-img' : ''}`}>
                  {isDefaultImg && <img src={addImage} alt="" />}
                  <input {...getInputProps()} />
                  <button disabled={disabled} type="button" className="upload-button upload-button-dnd" onClick={open}>
                    {buttonName}
                  </button>
                  {infoText && <span>{infoText}</span>}
                </div>
                {type === 'one' && <p className="warning">{`Bildgröße ${textImageSize}`}</p>}
              </>
            )}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default UploadImgDragDrop;

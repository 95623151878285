import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

import './style.scss';

import * as reducer from '../../store/reducer';

import { ReactComponent as IconBud } from '../../assets/icons/icon-bud.svg';
import { ReactComponent as IconExtract } from '../../assets/icons/icon-extract.svg';
import { ReactComponent as IconManufacture } from '../../assets/icons/icon-manufacture.svg';
import { ReactComponent as IconPharmacy } from '../../assets/icons/icon-pharmacy.svg';

const Flowers = () => {
  const role = Cookies.get('role');

  const dataAdminBuds = useSelector(reducer.request.admin.bud.state.getBuds.data);
  const dataAdminExtracts = useSelector(reducer.request.admin.extract.state.getExtracts.data);
  const dataManufacturers = useSelector(reducer.request.admin.manufacture.state.getManufacturers.data);
  const dataPharmacies = useSelector(reducer.request.admin.pharmacy.state.getPharmacies.data);

  const dataManufacturerBuds = useSelector(reducer.request.manufacturer.buds.state.getManufacturerBuds.data);
  const dataManufacturerExtracts = useSelector(
    reducer.request.manufacturer.extracts.state.getManufacturerExtracts.data,
  );

  const dataPharmaciesBuds = useSelector(reducer.request.pharmacie.buds.state.getPharmaciesBuds.data);
  const dataPharmaciesExtracts = useSelector(reducer.request.pharmacie.extracts.state.getPharmaciesExtracts.data);

  const dataDoctorBuds = useSelector(reducer.request.doctor.buds.state.getDoctorsBuds.data);
  const dataDoctorExtracts = useSelector(reducer.request.doctor.extracts.state.getDoctorsExtracts.data);

  const getFlowersConfig = () => {
    switch (role) {
      case 'manufacturer':
        return [
          {
            title: 'Meine Blüten',
            link: '/buds',
            count: dataManufacturerBuds?.total || 0,
            icon: 'bud',
          },
          {
            title: 'Meine Extrakte',
            link: '/extracts',
            count: dataManufacturerExtracts?.total || 0,
            icon: 'extract',
          },
        ];
      case 'pharmacy':
        return [
          {
            title: 'Blüten',
            link: '/buds',
            count: dataPharmaciesBuds?.total || 0,
            icon: 'bud',
          },
          {
            title: 'Extrakte',
            link: '/extracts',
            count: dataPharmaciesExtracts?.total || 0,
            icon: 'extract',
          },
        ];

      case 'admin':
        return [
          {
            title: 'Blüten',
            link: '/buds',
            count: dataAdminBuds?.total || 0,
            icon: 'bud',
          },
          {
            title: 'Extrakte',
            link: '/extracts',
            count: dataAdminExtracts?.total || 0,
            icon: 'extract',
          },
          {
            title: 'Lieferanten',
            link: '/manufacturers',
            count: dataManufacturers?.total || 0,
            icon: 'manufacture',
          },
          {
            title: 'Apotheken',
            link: '/pharmacies',
            count: dataPharmacies?.total || 0,
            icon: 'pharmacy',
          },
        ];
      case 'doctor':
        return [
          {
            title: 'Blüten',
            link: '/buds',
            count: dataDoctorBuds?.total,
            icon: 'bud',
          },
          {
            title: 'Extrakte',
            link: '/extracts',
            count: dataDoctorExtracts?.total,
            icon: 'extract',
          },
        ];
      default:
        return null;
    }
  };

  return (
    <ul className="flowers">
      {getFlowersConfig()?.map((flower, index) => (
        <Flower key={index} title={flower.title} count={flower.count} icon={flower.icon} path={flower.link} />
      ))}
    </ul>
  );
};

export default Flowers;

const Flower = ({ title, count, icon, path }) => {
  const icons = {
    bud: <IconBud />,
    extract: <IconExtract />,
    manufacture: <IconManufacture />,
    pharmacy: <IconPharmacy />,
  };

  return (
    <li className="flower">
      <Link to={path}>
        <i className="icon">{icons[icon]}</i>
        <h2 className="name">{title}</h2>
        <p className="count">{count}</p>
      </Link>
    </li>
  );
};

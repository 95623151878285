import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../../store/reducer';
import * as action from '../../../../../store/action';
import * as util from '../../../../../utils';

import { useChangeEffect } from '../../../../../hooks';
import { variables } from './variables';

import { TabContent } from '../../../../../HOC';
import FieldDatePicker from '../../../../../components/fieldDatePicker';
import FieldSearch from '../../../../../components/UI/fieldSearch';
import DropdownCount from '../../../../../components/dropdownCount';
import Pagination from '../../../../../components/UI/pagination';
import Loader from '../../../../../components/popups/loader';
import TableBuilder from '../../../../../components/tableBuilder';
import { useDefaultPerPage } from '../../../../../hooks/useDefaultPerPage';

const Orders = ({ visible, isShowLoader }) => {
  const dispatch = useDispatch();

  const dataCultivarsAll = useSelector(reducer.request.cultivar.state.getCultivarsAll.data);
  const dataOrders = useSelector(reducer.request.admin.order.state.getOrders.data);

  const statusGetOrders = useSelector(reducer.request.admin.order.state.getOrders.status);

  const getOrders = (params) => dispatch(action.request.admin.orders.getOrders.get(params));

  const [valueSearch, setValueSearch] = useState('');
  const [valueDates, setValueDates] = useState({ from: '', to: '' });

  const persistName = 'superuserPharmaciesProfileOrders';

  const { contentPerPage, setContentPerPage } = useDefaultPerPage(persistName);

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedIDs, setSelectedIDs] = useState([]);

  const countSelectedRows = selectedIDs.length;
  const pageCount = Math.ceil(dataOrders?.total / contentPerPage) || 1;

  const tableTitles = ['№', 'Auftrag', 'Datum', 'Lieferanten', 'Produkt', 'Kultivar', ' '];
  const tableData = dataOrders?.orders.reduce((acc, item, index) => {
    acc = [
      ...acc,
      {
        id: item.id,
        number: currentPage !== 1 ? (currentPage - 1) * contentPerPage + index + 1 : index + 1,
        status: util.getStatusOrder.de(item.status),
        date: new Date(item.date).toLocaleString(),
        productsManufacturerName: item.products.map((product, index, array) =>
          array.length > 1 ? `${index + 1}. ${product.manufacturerName}` : product.manufacturerName,
        ),
        productsName: item.products.map((product) => ({
          name: product.name,
          status: util.getStatusProductOrder.de(product.status),
        })),
        productsCultivars: item.products.map((item) => util.getCultivarsName(item.cultivars, dataCultivarsAll)),
        ' ': item.products.length > 1 ? 'true' : 'false',
      },
    ];

    return acc;
  }, []);

  useChangeEffect(() => {
    if (valueDates.from && valueDates.to) getData();
    if (!valueDates.from && !valueDates.to) getData();
  }, [valueDates]);

  useChangeEffect(() => {
    getData();
  }, [currentPage, contentPerPage]);

  const onGoSearch = () => {
    if (currentPage === 1) {
      getOrders({
        start: 0,
        limit: contentPerPage,
        search: valueSearch,
        from: valueDates.from,
        to: valueDates.to,
      });
    } else {
      setCurrentPage(1);
    }
  };

  const onClear = () => {
    setValueSearch('');

    if (currentPage === 1) {
      getOrders({
        start: 0,
        limit: contentPerPage,
        from: valueDates.from,
        to: valueDates.to,
      });
    } else {
      setCurrentPage(1);
    }
  };

  const getData = () => {
    const firstContentIndex = (currentPage - 1) * contentPerPage;
    getOrders({
      start: firstContentIndex,
      limit: contentPerPage,
      search: valueSearch,
      from: valueDates.from,
      to: valueDates.to,
    });
  };

  return (
    <>
      <TabContent visible={visible}>
        <TableBuilder
          className="pharmacy-order-table"
          path="/orders/edit"
          contentPerPage={contentPerPage}
          title={tableTitles}
          data={tableData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageCount={pageCount}
          selectedIDs={selectedIDs}
          setSelectedIDs={setSelectedIDs}>
          <div className="table-header">
            <ul className="left-side">
              <li className="elem">
                <FieldDatePicker
                  placeholder="Datum auswählen"
                  startDateValue={valueDates.from}
                  endDateValue={valueDates.to}
                  onEvent={(data) => setValueDates({ from: data ? data[0] : null, to: data ? data[1] : null })}
                  isRange={true}
                />
              </li>
              <li className="elem">
                <FieldSearch
                  value={valueSearch}
                  setValue={(event) => setValueSearch(event.target.value)}
                  onClear={onClear}
                  onGoSearch={onGoSearch}
                />
              </li>
            </ul>

            <ul className="right-side">
              <li className="elem">
                <DropdownCount
                  contentPerPage={contentPerPage}
                  data={variables.dropdownContent}
                  onSetContentPerPage={setContentPerPage}
                  setCurrentPage={() => setCurrentPage(1)}
                  persistName={persistName}
                />
              </li>
              <li className="elem">
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
              </li>
            </ul>
          </div>
        </TableBuilder>
      </TabContent>

      {!isShowLoader && statusGetOrders === 'pending' && <Loader type="transparent" />}
    </>
  );
};

export default Orders;

export const state = {
  getPharmaciesOrders: {
    data: (state) => state.request.pharmacie.orders.getPharmaciesOrders.data,
    status: (state) => state.request.pharmacie.orders.getPharmaciesOrders.status,
    error: (state) => state.request.pharmacie.orders.getPharmaciesOrders.error,
  },
  getPharmaciesOrdersId: {
    data: (state) => state.request.pharmacie.orders.getPharmaciesOrdersId.data,
    status: (state) => state.request.pharmacie.orders.getPharmaciesOrdersId.status,
    error: (state) => state.request.pharmacie.orders.getPharmaciesOrdersId.error,
  },
  postPharmaciesOrders: {
    data: (state) => state.request.pharmacie.orders.postPharmaciesOrders.data,
    status: (state) => state.request.pharmacie.orders.postPharmaciesOrders.status,
    error: (state) => state.request.pharmacie.orders.postPharmaciesOrders.error,
  },
  deletePharmaciesOrders: {
    data: (state) => state.request.pharmacie.orders.deletePharmaciesOrders.data,
    status: (state) => state.request.pharmacie.orders.deletePharmaciesOrders.status,
    error: (state) => state.request.pharmacie.orders.deletePharmaciesOrders.error,
  },
  deletePharmaciesAllOrders: {
    data: (state) => state.request.pharmacie.orders.deletePharmaciesAllOrders.data,
    status: (state) => state.request.pharmacie.orders.deletePharmaciesAllOrders.status,
    error: (state) => state.request.pharmacie.orders.deletePharmaciesAllOrders.error,
  },
  deletePharmaciesOrdersProductId: {
    data: (state) => state.request.pharmacie.orders.deletePharmaciesOrdersProductId.data,
    status: (state) => state.request.pharmacie.orders.deletePharmaciesOrdersProductId.status,
    error: (state) => state.request.pharmacie.orders.deletePharmaciesOrdersProductId.error,
  },
};

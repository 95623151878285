import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import './style.scss';

import * as reducer from '../../../../store/reducer';
import * as action from '../../../../store/action';
import * as util from '../../../../utils';

import { usePageUp, useChangeEffect } from '../../../../hooks';
import { variables } from './variables';

import Header from '../../../../components/header';
import AddEdit from '../../../../components/addEdit';
import LinkBack from '../../../../components/UI/linkBack';
import Field from '../../../../components/UI/field';
import Switcher from '../../../../components/UI/switcher';
import Checkbox from '../../../../components/UI/checkbox';
import DropdownSelect from '../../../../components/UI/dropdownSelect';
import Textarea from '../../../../components/UI/textarea';
import UploadImg from '../../../../components/uploadImg';
import RadioSelect from '../../../../components/UI/radioSelect';
import Button from '../../../../components/UI/button';
import Loader from '../../../../components/popups/loader';
import ProductQuantityPopup from '../../../../components/popups/productQuantityPopup';
import { toast } from 'react-toastify';

const BudAddEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();

  const dataUpload = useSelector(reducer.request.uploads.state.postUploads.data);
  const dataTerpenesAll = useSelector(reducer.request.terpene.state.getTerpenesAll.data);
  const dataCultivarsAll = useSelector(reducer.request.cultivar.state.getCultivarsAll.data);
  const dataBudsAll = useSelector(reducer.request.admin.bud.state.getBudsAll.data);
  const dataBudID = useSelector(reducer.request.admin.bud.state.getBudId.data);

  const statusPostUpload = useSelector(reducer.request.uploads.state.postUploads.status);
  const statusPostBuds = useSelector(reducer.request.admin.bud.state.postBuds.status);
  const statusPatchBudID = useSelector(reducer.request.admin.bud.state.patchBudId.status);

  const statusGetManufacturersAll = useSelector(reducer.request.admin.manufacture.state.getManufacturersAll.status);
  const statusGetTerpenesAll = useSelector(reducer.request.terpene.state.getTerpenesAll.status);
  const statusGetCultivarsAll = useSelector(reducer.request.cultivar.state.getCultivarsAll.status);
  const responseCultivars = useSelector(reducer.request.cultivar.state.postCultivars.data);
  const responseTerpenes = useSelector(reducer.request.terpene.state.postTerpenes.data);
  const statusGetBudsAll = useSelector(reducer.request.admin.bud.state.getBudsAll.status);
  const statusGetBudID = useSelector(reducer.request.admin.bud.state.getBudId.status);
  const isDataChages = useSelector(reducer.app.common.state.isDataChages);

  const setDataChage = (isChange) => dispatch(action.app.common.isDataChages(isChange));
  const postUploads = (data) => dispatch(action.request.uploads.postUploads.post(data));
  const getManufacturersAll = () => dispatch(action.request.admin.manufacturers.getManufacturersAll.get());
  const getTerpenesAll = () => dispatch(action.request.terpenes.getTerpenesAll.get());
  const getCultivarsAll = () => dispatch(action.request.cultivars.getCultivarsAll.get());
  const getBudsAll = () => dispatch(action.request.admin.buds.getBudsAll.get());
  const getBudID = (id) => dispatch(action.request.admin.buds.getBudId.get(id));
  const patchBudID = (id, data) => dispatch(action.request.admin.buds.patchBudId.patch(id, data));
  const postBuds = (data) => dispatch(action.request.admin.buds.postBuds.post(data));
  const postCultivars = (data) => dispatch(action.request.cultivars.postCultivars.post(data));
  const postTerpenes = (data) => dispatch(action.request.terpenes.postTerpenes.post(data));
  const putCultivarId = (id, data) => dispatch(action.request.cultivars.putCultivarId.put(id, data));
  const putTerpeneId = (id, data) => dispatch(action.request.terpenes.putTerpeneId.put(id, data));
  const deleteCultivarId = (id) => dispatch(action.request.cultivars.deleteCultivarId.delete(id));
  const deleteTerpeneId = (id) => dispatch(action.request.terpenes.deleteTerpeneId.delete(id));
  const resetCultivars = () => dispatch(action.request.cultivars.resetPostData());
  const resetTerpenes = () => dispatch(action.request.terpenes.resetPostData());

  function addNewItem(data, type) {
    switch (type) {
      case 'cultivars':
        postCultivars(data);
        setTimeout(() => getCultivarsAll(), 1000);
        break;
      case 'terpenes':
        postTerpenes(data);
        setTimeout(() => getTerpenesAll(), 1000);
        break;
      default:
        return;
    }
  }

  function updateItem(id, data, type) {
    switch (type) {
      case 'cultivars':
        putCultivarId(id, data);
        setTimeout(() => getCultivarsAll(), 1000);
        break;
      case 'terpenes':
        putTerpeneId(id, data);
        setTimeout(() => getTerpenesAll(), 1000);
        break;
      default:
        return;
    }
  }

  function deleteItem(id, type) {
    switch (type) {
      case 'cultivars':
        deleteCultivarId(id);
        setTimeout(() => getCultivarsAll(), 1000);
        break;
      case 'terpenes':
        deleteTerpeneId(id);
        setTimeout(() => getTerpenesAll(), 1000);
        break;
      default:
        return;
    }
  }

  const [isShowLoader, setShowLoader] = useState(true);
  const [amountError, setAmountError] = useState({
    minOrderAmount: null,
    maxOrderAmount: null,
    availableAmount: null,
  });
  const [isShowProductQuantityPopup, setProductQuantityPopup] = useState(false);
  const [imgKey, setImgKey] = useState('');
  const [data, setData] = useState({
    active: true,
    availableAmount: null, // null | number
    cbd: 0,
    cbn: 0,
    companyImageKey: null, // null | string
    companyName: '',
    cultivars: [],
    description: '',
    exclusive: false,
    genetics: 'n.a.',
    imageKey: null, // null | string
    manufacturers: [],
    maxOrderAmount: 0,
    minOrderAmount: 0,
    name: '',
    substitutions: [],
    symptoms: '',
    terpenes: [],
    thc: 0,
    useSubstitution: false,
    useTerpenesSymptoms: false,
  });

  const [errorData, setErrorData] = useState({
    name: true,
    thc: true,
    cbd: true,
    cbn: true,
    minOrderAmount: true,
    maxOrderAmount: true,
    availableAmount: true,
  });

  const budID = param.budID;
  const isEdit = budID;

  const isRequestGETSuccess =
    statusGetManufacturersAll === 'success' &&
    statusGetTerpenesAll === 'success' &&
    statusGetCultivarsAll === 'success' &&
    statusGetBudsAll === 'success' &&
    (!isEdit || statusGetBudID === 'success');

  usePageUp();

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  useEffect(() => {
    if (isRequestGETSuccess) {
      if (
        !_.isEqual(
          {
            ...dataBudID,
            thc: dataBudID?.thc?.toString(),
            cbd: dataBudID?.cbd?.toString(),
            cbn: dataBudID?.cbn?.toString(),
            maxOrderAmount: dataBudID?.maxOrderAmount?.toString(),
            minOrderAmount: dataBudID?.minOrderAmount?.toString(),
            availableAmount: dataBudID?.availableAmount?.toString(),
          },
          {
            ...data,
            thc: data?.thc?.toString(),
            cbd: data?.cbd?.toString(),
            cbn: data?.cbn?.toString(),
            maxOrderAmount: data?.maxOrderAmount?.toString(),
            minOrderAmount: data?.minOrderAmount?.toString(),
            availableAmount: data?.availableAmount?.toString(),
          },
        )
      ) {
        setDataChage(true);
        return;
      } else {
        setDataChage(false);
      }
    }
  }, [data, dataBudID, isRequestGETSuccess]);

  useEffect(() => {
    getManufacturersAll();
    getTerpenesAll();
    getCultivarsAll();
    getBudsAll();

    setDataChage(false);

    if (isEdit) getBudID(budID);
  }, []);

  useEffect(() => {
    if (isEdit && dataBudID) setData(dataBudID);
  }, [dataBudID]);

  useEffect(() => {
    if (responseCultivars) {
      setData({ ...data, cultivars: [...data.cultivars, responseCultivars.id] });
      resetCultivars();
    }
    if (responseTerpenes) {
      setData({ ...data, terpenes: [...data.terpenes, responseTerpenes.id] });
      resetTerpenes();
    }
  }, [responseCultivars, responseTerpenes]);

  useChangeEffect(() => {
    if (statusPatchBudID === 'success') navigate('/buds');
  }, [statusPatchBudID]);

  useChangeEffect(() => {
    if (statusPostBuds === 'success') navigate('/buds');
  }, [statusPostBuds]);

  useEffect(() => {
    if (statusPostUpload === 'success') setData({ ...data, [imgKey]: dataUpload });
  }, [statusPostUpload]);

  const onSave = () => {
    if (data.exclusive && !Object.keys(amountError).every((key) => amountError[key] === null)) {
      return
    }
    if (onCheckError()) {
      if (isEdit) {
        if (data.exclusive) {
          delete data.manufacturers;
        }
        patchBudID(budID, data);
      } else postBuds(data);
      setDataChage(false);
    }
  };

  const onCancel = () => {
    if (isDataChages) {
      if (window.confirm('Bestätigen Sie die Aktion auf der Seite. Die Änderungen zurücksetzen?')) {
        setDataChage(false);
        navigate('/buds');
      }
    } else {
      navigate('/buds');
    }
  };

  const onCheckError = () => {
    const changedData = {
      name: util.validation.onChekTextField(data.name),
      thc: util.validation.onCheckNumberField(data.thc),
      cbd: util.validation.onCheckNumberField(data.cbd),
      cbn: util.validation.onCheckNumberField(data.cbn),
      minOrderAmount: data.exclusive ? util.validation.onCheckNumberField(data.minOrderAmount) : true,
      maxOrderAmount: data.exclusive ? util.validation.onCheckNumberField(data.maxOrderAmount) : true,
      availableAmount: data.exclusive
        ? util.validation.isNull(data.availableAmount) || util.validation.onCheckNumberField(data.availableAmount)
        : true,
    };

    setErrorData(changedData);

    return Object.values(changedData).every(Boolean);
  };

  const onUploadImg = (name, data) => {
    setImgKey(name);
    postUploads(data);
  };

  useEffect(() => {
    if (parseInt(data?.minOrderAmount) > parseInt(data?.maxOrderAmount)) {
      setAmountError({
        minOrderAmount: 'Mindestmenge ist zu groß',
        maxOrderAmount: 'Maximalmenge ist zu klein',
        availableAmount: null,
      });
    } else {
      setAmountError({
        minOrderAmount: null,
        maxOrderAmount: null,
        availableAmount: null,
      });
    }
  }, [data]);

  return (
    <>
      <Header />
      <main className={`bud-add-edit-page page ${!isShowLoader ? 'show' : ''}`}>
        <div className="container">
          <LinkBack title="Zurück zu Blüten" onEvent={onCancel} />

          <AddEdit
            className="bud"
            onCancel={onCancel}
            onSave={onSave}
            type="bud"
            data={data}
            isSaveButtonDisabled={!isDataChages}>
            <section className="info-section">
              <h2 className="title">
                Produktinformationen
                <span>(alle Felder sind erforderlich)</span>
              </h2>

              <ul className="field-list-one">
                <li>
                  <ul>
                    <li>
                      <Field
                        label="Name Inverkehrbringer"
                        type="text"
                        value={data.companyName}
                        onEvent={(event) => setData({ ...data, companyName: event.target.value })}
                      />
                    </li>
                    <li>
                      <Field
                        error={!errorData.name}
                        label="Produktname"
                        type="text"
                        value={data.name}
                        onEvent={(event) => setData({ ...data, name: event.target.value })}
                        onEvent2={() => setErrorData({ ...errorData, name: true })}
                      />
                    </li>

                    <li>
                      <RadioSelect
                        label="Genetik / Dominanz"
                        listRadio={variables.genetics}
                        data={data}
                        dataKey="genetics"
                        setData={setData}
                      />
                    </li>

                    <li>
                      <Switcher
                        label="Status aktiv"
                        isChecked={data.active}
                        onChange={() => setData({ ...data, active: !data.active })}
                      />
                    </li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li>
                      <UploadImg
                        title="Logo Inverkehrbringer"
                        type="bud"
                        img={data.companyImageKey}
                        onUploadImg={(data) => onUploadImg('companyImageKey', data)}
                        onSelectImg={(img) => setData({ ...data, companyImageKey: img })}
                      />
                    </li>

                    <li>
                      <UploadImg
                        title="Produktbild"
                        type="bud"
                        img={data.imageKey}
                        onUploadImg={(data) => onUploadImg('imageKey', data)}
                        onSelectImg={(img) => setData({ ...data, imageKey: img })}
                      />
                    </li>
                  </ul>
                </li>
                <li>
                  <Textarea
                    label="Produktbeschreibung"
                    value={data.description}
                    onChange={(event) => setData({ ...data, description: event.target.value })}
                  />
                </li>
              </ul>
            </section>

            <section className="info-section border-0">
              <h2 className="title">Produkt-Details</h2>

              <ul className="field-list-two">
                <li>
                  <Field
                    error={!errorData.thc}
                    label="THC-Menge (%)"
                    type="text"
                    value={data.thc ?? ''}
                    placeholder="0.00"
                    onEvent={(event) => setData({ ...data, thc: event.target.value.replace(/[^0-9\.]/, '') })}
                    onEvent2={() => setErrorData({ ...errorData, thc: true })}
                  />
                </li>
                <li>
                  <Field
                    error={!errorData.cbd}
                    label="CBD-Menge"
                    type="text"
                    value={data.cbd ?? ''}
                    placeholder="0.00"
                    onEvent={(event) => setData({ ...data, cbd: event.target.value.replace(/[^0-9\.]/, '') })}
                    onEvent2={() => setErrorData({ ...errorData, cbd: true })}
                  />
                </li>
                <li>
                  <Field
                    error={!errorData.cbn}
                    label="CBN-Menge"
                    type="text"
                    value={data.cbn ?? ''}
                    placeholder="0.00"
                    onEvent={(event) => setData({ ...data, cbn: event.target.value.replace(/[^0-9\.]/, '') })}
                    onEvent2={() => setErrorData({ ...errorData, cbn: true })}
                  />
                </li>
                <li>
                  <DropdownSelect
                    position="bottom"
                    label="Kultivar"
                    content={dataCultivarsAll}
                    dataKey="cultivars"
                    data={data}
                    setData={setData}
                    isCanAddNew={true}
                    addNewItem={(data) => addNewItem(data, 'cultivars')}
                    updateItem={(id, data) => updateItem(id, data, 'cultivars')}
                    deleteItem={(id) => deleteItem(id, 'cultivars')}
                  />
                </li>
                <li>
                  <DropdownSelect
                    position="bottom"
                    label="Terpene"
                    content={dataTerpenesAll}
                    dataKey="terpenes"
                    data={data}
                    setData={setData}
                    isCanAddNew={true}
                    addNewItem={(data) => addNewItem(data, 'terpenes')}
                    updateItem={(id, data) => updateItem(id, data, 'terpenes')}
                    deleteItem={(id) => deleteItem(id, 'terpenes')}
                  />
                </li>
                <li>
                  <Checkbox
                    label="Terpene verwenden"
                    disabled={!data.terpenes.length}
                    isChecked={data.useTerpenesSymptoms}
                    onChange={() => {
                      setData({
                        ...data,
                        useTerpenesSymptoms: !data.useTerpenesSymptoms,
                        symptoms: !data.useTerpenesSymptoms
                          ? data.terpenes.map((id) => dataTerpenesAll.find((item) => item.id === id).symptom).join(', ')
                          : '',
                      });
                    }}
                  />
                  <Textarea
                    label="Mögliche Einsatzbereiche basierend auf dem Kultivar"
                    placeholder=""
                    disabled={data.useTerpenesSymptoms}
                    value={data.symptoms}
                    onChange={(event) => setData({ ...data, symptoms: event.target.value })}
                  />
                </li>
                <li>
                  <Checkbox
                    label="Exklusives Produkt"
                    isChecked={data.exclusive}
                    onChange={() =>
                      setData({
                        ...data,
                        exclusive: !data.exclusive,
                        minOrderAmount: 0,
                        maxOrderAmount: 0,
                        availableAmount: null,
                      })
                    }
                  />

                  <Button
                    title="Einstellungen zur Bestellmenge"
                    onEvent={() => {
                      if (data.manufacturers.length === 0) {
                        toast.error('Fügen Sie zuerst Lieferanten für dieses Produkt hinzu');
                      } else {
                        setProductQuantityPopup(true);
                      }
                    }}
                    color="transparent"
                    disabled={data.exclusive}
                  />
                </li>

                {data.exclusive && (
                  <li>
                    <Field
                      error={!errorData.minOrderAmount || amountError?.minOrderAmount}
                      label="Mindestbestellmenge (Verpackungseinheit) (g)"
                      type="text"
                      value={data.minOrderAmount ?? ''}
                      placeholder="0.00"
                      onEvent={(event) =>
                        setData({ ...data, minOrderAmount: event.target.value.replace(/[^0-9\.]/, '') })
                      }
                      onEvent2={() => setErrorData({ ...errorData, minOrderAmount: true })}
                    />
                    <Field
                      error={!errorData.maxOrderAmount || amountError?.maxOrderAmount}
                      label="Maximalbestellmenge pro Apotheke (g)"
                      type="text"
                      value={data.maxOrderAmount ?? ''}
                      placeholder="0.00"
                      onEvent={(event) =>
                        setData({ ...data, maxOrderAmount: event.target.value.replace(/[^0-9\.]/, '') })
                      }
                      onEvent2={() => setErrorData({ ...errorData, maxOrderAmount: true })}
                    />
                    <Field
                      error={!errorData.availableAmount || amountError?.availableAmount}
                      label="Restmenge (aktuell lieferbar) (g)"
                      type="text"
                      value={data.availableAmount ?? ''}
                      placeholder="0.00"
                      onEvent={(event) =>
                        setData({ ...data, availableAmount: event.target.value.replace(/[^0-9\.]/, '') })
                      }
                      onEvent2={() => setErrorData({ ...errorData, availableAmount: true })}
                    />
                  </li>
                )}

                <li>
                  <Checkbox
                    label="Liste potenzieller Ersatzprodukte (basierend auf THC-, CBD- und Terpenwerten)"
                    isChecked={data.useSubstitution}
                    onChange={() => setData({ ...data, useSubstitution: !data.useSubstitution })}
                  />
                </li>
                {data.useSubstitution && (
                  <li>
                    <DropdownSelect
                      position="top"
                      label="Produkte"
                      content={dataBudsAll}
                      dataKey="substitutions"
                      data={data}
                      setData={setData}
                    />
                  </li>
                )}
              </ul>
            </section>
          </AddEdit>
        </div>
      </main>

      {isShowProductQuantityPopup && (
        <ProductQuantityPopup data={data} setData={setData} onClose={() => setProductQuantityPopup(false)} />
      )}

      {isShowLoader && <Loader type="transparent" />}
      {(statusPatchBudID === 'pending' || statusPostBuds === 'pending') && <Loader type="transparent" />}
    </>
  );
};

export default BudAddEdit;

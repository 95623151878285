import React from 'react';

import { useController } from 'react-hook-form';

const Textarea = ({ name, onChangeValue, placeholder, className, disabled, shouldUnregister, maxLength, label }) => {
  const { field, fieldState } = useController({ name, shouldUnregister, defaultValue: '' });
  const length = field?.value?.length || 0;

  const { onChange, value, ...restField } = field;
  const handleChange = (e) => {
    const val = e.target.value;
    if (maxLength && maxLength < val?.length) return;

    onChange(e);
    onChangeValue?.(val);
  };

  const { error } = fieldState;

  return (
    <p className={`default-textarea ${error ? 'error' : ''}`}>
      {label && <label className="label">{label}</label>}
      <textarea
        {...restField}
        name={name}
        disabled={disabled}
        onChange={handleChange}
        placeholder={placeholder}
        value={value || ''}
      />
      {error && <span className="text-error">{error.message}</span>}
    </p>
  );
};

export default Textarea;

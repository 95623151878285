export const orders = {
  getOrderIdSuccess: 'request/getOrderIdSuccess',
  getOrderIdPending: 'request/getOrderIdPending',
  getOrderIdFailed: 'request/getOrderIdFailed',

  putOrderIdSuccess: 'request/putOrderIdSuccess',
  putOrderIdPending: 'request/putOrderIdPending',
  putOrderIdFailed: 'request/putOrderIdFailed',

  deleteOrderIdSuccess: 'request/deleteOrderIdSuccess',
  deleteOrderIdPending: 'request/deleteOrderIdPending',
  deleteOrderIdFailed: 'request/deleteOrderIdFailed',

  getOrdersSuccess: 'request/getOrdesSuccess',
  getOrdersPending: 'request/getOrdesPending',
  getOrdersFailed: 'request/getOrdesFailed',
};

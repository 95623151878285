import Cookies from 'js-cookie';

import * as variable from '../../variables';

export const orders = {
  getManufacturerOrders: (start, limit, search, from, to) => {
    const searchParam = search ? `&search=${search}` : '';
    const fromParam = from ? `&from=${from}` : '';
    const toParam = to ? `&to=${to}` : '';

    const accessToken = Cookies.get('accessToken');

    return fetch(
      `https://${variable.url}/manufacturers/orders?start=${start}&limit=${limit}${searchParam}${fromParam}${toParam}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  },

  getManufacturerOrdersId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/manufacturers/orders/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  putManufacturerOrdersId: (id, data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/manufacturers/orders/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

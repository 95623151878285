import { createAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import * as api from '../../../../api';
import * as type from '../../../type';
import * as action from '../../../action';
import { setInitialContentPerPage } from '../../../../utils/setInitialContentPerPage';

export const getLogout = {
  get: function (navigate) {
    return (dispatch) => {
      dispatch(this.getPending());

      api.auth
        .getLogout()
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }

          const persist = localStorage.getItem('persist:root');
          localStorage.clear();
          localStorage.setItem('persist:root', persist);

          setInitialContentPerPage();

          sessionStorage.clear();
          Cookies.remove('accessToken');
          Cookies.remove('refreshToken');
          Cookies.remove('role');
          dispatch(this.getSuccess());
          navigate('/login');
        })
        .catch((error) => {
          if (error.status === 403 || error.status === 404) {
            window.location = '/';
          }
          if (error.status === 401) {
            dispatch(action.request.auth.getRefresh.get(this.get(navigate)));
          } else {
            dispatch(this.getFailed(error.statusText));
          }
        });
    };
  },

  getSuccess: createAction(type.request.auth.getLogoutSuccess),
  getPending: createAction(type.request.auth.getLogoutPending),
  getFailed: createAction(type.request.auth.getLogoutFailed),
};

import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useChangeEffect, useDelay } from '../../../../hooks';

import * as action from '../../../../store/action';
import * as reducer from '../../../../store/reducer';

import Flowers from '../../../../components/flowers';
import Header from '../../../../components/header';
import Loader from '../../../../components/popups/loader';
import OrdersSection from '../../pharmacieOrders/ordersSection';
import { useDefaultPerPage } from '../../../../hooks/useDefaultPerPage';

const Main = () => {
  const dispatch = useDispatch();
  const isShow = useDelay(100);

  const { contentPerPage: buds } = useDefaultPerPage('pharmacieBuds');
  const { contentPerPage: extracts } = useDefaultPerPage('pharmacieExtracts');

  const dataOrdersStatus = useSelector(reducer.request.pharmacie.orders.state.getPharmaciesOrders.status);
  const budsStatus = useSelector(reducer.request.pharmacie.buds.state.getPharmaciesBuds.status);
  const extractsStatus = useSelector(reducer.request.pharmacie.extracts.state.getPharmaciesExtracts.status);

  const getPharmacieExtracts = (start, limit, search, sort, order) =>
    dispatch(action.request.pharmacie.extracts.getPharmaciesExtracts.get(start, limit, search, sort, order));
  const getPharmacieBuds = (start, limit, search, sort, order) =>
    dispatch(action.request.pharmacie.buds.getPharmaciesBuds.get(start, limit, search, sort, order));
  const getPharmaciesOrders = (from, to, sort, order) =>
    dispatch(action.request.pharmacie.orders.getPharmaciesOrders.get(from, to, sort, order));

  const isRequestGETSuccess =
    dataOrdersStatus === 'success' && budsStatus === 'success' && extractsStatus === 'success';

  const [isShowLoader, setShowLoader] = useState(true);

  useLayoutEffect(() => {
    getPharmacieExtracts(0, buds);
    getPharmacieBuds(0, extracts);
    getPharmaciesOrders();
  }, [buds, extracts]);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  return (
    <>
      <Header />
      <main className={`pharmacie-dashboard-page page ${isShow ? 'show' : ''}`}>
        <div className="container ">
          <h1 className="dashboard-title">Dashboard</h1>
          <Flowers type="pharmacies" />
          <section>
            <h1 className="title">Letzte Bestellanfragen</h1>
            {!isShowLoader && <OrdersSection dataSearch={null} className="dashboard" />}
          </section>
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default Main;

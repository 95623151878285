import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useChangeEffect, useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import Loader from '../../../components/popups/loader';
import Form from '../../../components/UI/form/Form.jsx';
import Button from '../../../components/UI/button';
import FormInput from '../../../components/UI/form/formInput/FormInput';
import FormTextarea from '../../../components/UI/form/formTextarea';
import Checkbox from '../../../components/UI/checkbox';
import UploadImgDragDrop from '../../../components/uploadImgDragDrop';

import { ManufacturerEditSchema, ManufacturerEditFullSchema } from '../../../components/UI/form/validation';

import { getFormatedUserData, putUserDataFormated } from '../../../utils';
import { useLayoutEffect } from 'react';

const ManufacturerProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const leftColumnRef = useRef();
  const uploadImgRef = useRef();
  const groupBtnRef = useRef();

  const heightGroupBtn = groupBtnRef.current?.clientHeight;
  const isShow = useDelay(100);
  const typeUploadFiles = 'other';
  usePageUp();

  const statusUser = useSelector(reducer.request.manufacturer.user.state.getManufacturer.status);
  const statusUpload = useSelector(reducer.request.uploads.state.postUploads.status);
  const dataUser = useSelector(reducer.request.manufacturer.user.state.getManufacturer.data);
  const dataUpload = useSelector(reducer.request.uploads.state.postUploads.data);
  const isDataChages = useSelector(reducer.app.common.state.isDataChages);

  const setDataChage = (isChange) => dispatch(action.app.common.isDataChages(isChange));
  const getUserData = () => dispatch(action.request.manufacturer.user.getManufacturer.get());
  const putUserData = (data) => dispatch(action.request.manufacturer.user.putManufacturer.put(data));
  const postUploads = (data) => dispatch(action.request.uploads.postUploads.post(data));
  const deleteUpload = (filesIdsArray) => dispatch(action.request.uploads.deleteUploads.delete(filesIdsArray));
  const resetUploadData = () => dispatch(action.request.uploads.resetUploadData());

  const isRequestGETSuccess = statusUser === 'success';

  const [height, setHeight] = useState({
    leftColumn: 0,
    uploadImg: 0,
  });
  const [attachment, setAttachment] = useState();
  const [sameAddress, setSameAddress] = useState(!!dataUser?.isSameAddresses);
  const [newData, setNewData] = useState();
  const [isShowLoader, setShowLoader] = useState(true);

  const onAttachmentHandler = (file) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', typeUploadFiles);

    postUploads(formData);
  };

  const onDeleteAttachmentHandler = (key) => {
    if (window.confirm('Möchten Sie diese Datei wirklich löschen?')) {
      setAttachment(null);
      deleteUpload({ fileIds: [key] });
    }
  };

  const onCancel = () => {
    if (isDataChages) {
      if (window.confirm('Bestätigen Sie die Aktion auf der Seite. Die Änderungen zurücksetzen?')) {
        setDataChage(false);
        navigate('/dashboard');
      }
    } else {
      navigate('/dashboard');
    }
  };

  const onSubmit = useCallback(
    (data) => {
      putUserData({
        ...putUserDataFormated(data),
        isSameAddresses: sameAddress,
        logo: attachment,
      });

      setDataChage(false);
      navigate('/dashboard');
    },
    [attachment, sameAddress],
  );

  useEffect(() => {
    if (dataUser && attachment !== undefined) {
      if (attachment !== dataUser?.logo) {
        setDataChage(true);
        return;
      } else if (newData && !_.isEqual(getFormatedUserData(dataUser), newData)) {
        setDataChage(true);
        return;
      } else {
        setDataChage(false);
      }
    }
  }, [newData, dataUser, attachment]);

  useEffect(() => {
    if (dataUpload) {
      setAttachment(dataUpload);
      resetUploadData();
    }
  }, [dataUpload]);

  useChangeEffect(() => {
    setHeight({
      leftColumn: leftColumnRef.current?.clientHeight,
      uploadImg: uploadImgRef.current?.offsetHeight,
    });
  }, [isShowLoader, leftColumnRef, uploadImgRef]);

  useLayoutEffect(() => {
    setAttachment(dataUser?.logo);
  }, [dataUser]);

  useEffect(() => {
    getUserData();
    setDataChage(false);
  }, []);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  return (
    <>
      <Header />
      <main className={`profile-edit-page page ${isShow ? 'show' : ''}`}>
        {!isShowLoader && (
          <div className="container">
            <Form
              defaultValues={getFormatedUserData(dataUser)}
              className="add-edit profile-form"
              onChange={(data) => setNewData({ ...data })}
              onSubmit={onSubmit}
              resolver={yupResolver(sameAddress ? ManufacturerEditSchema : ManufacturerEditFullSchema)}>
              <div className="left-side" ref={leftColumnRef}>
                <section className="section-wrapper">
                  <h2 className="title">
                    Unternehmensdetails<sup>*</sup>
                    <span>(Pflichtfeld)</span>
                  </h2>
                  <ul className="field-list-billing">
                    <li>Rechnungsadresse</li>
                    <li>
                      <FormInput name="name" type="text" label="Firmenname" isRequired={true} />
                    </li>
                    <li>
                      <FormInput name="email" type="text" label="Email" isRequired={true} />
                      <FormInput name="phone" type="number" label="Telefon" isRequired={true} />
                    </li>
                    <li>
                      <FormInput name="billingZip" type="number" label="PLZ" isRequired={true} />
                      <FormInput name="billingCity" type="text" label="Stadt" isRequired={true} />
                    </li>
                    <li>
                      <FormInput name="billingStreet" type="text" label="Straße" isRequired={true} />
                      <FormInput name="billingHouse" type="text" label="Hausnummer" isRequired={true} />
                    </li>
                  </ul>
                  <ul className="field-list-same-address">
                    <Checkbox
                      label="Rechnungs- und Lieferadresse sind gleich"
                      isChecked={sameAddress}
                      onChange={() => setSameAddress(!sameAddress)}
                    />
                  </ul>
                  <ul className={`field-list-delivery ${sameAddress ? 'hide-field' : 'show-field'}`}>
                    <li>Lieferadresse</li>
                    <li className="s">
                      <FormInput name="deliveryZip" type="text" label="PLZ" isRequired={true} />
                      <FormInput name="deliveryCity" type="text" label="Stadt" isRequired={true} />
                    </li>
                    <li className="address-info-two">
                      <FormInput name="deliveryStreet" type="text" label="Straße" isRequired={true} />
                      <FormInput name="deliveryHouse" type="text" label="Hausnummer" isRequired={true} />
                    </li>
                  </ul>
                  <ul className="field-list-contact">
                    <li>Ansprechpartner</li>
                    <li>
                      <FormInput name="contactName" type="text" label="Name" isRequired={true} />
                      <FormInput name="contactEmail" type="text" label="E-Mail" isRequired={true} />
                    </li>
                    <li>
                      <FormInput name="contactPhone" type="text" label="Telefon" isRequired={true} />
                    </li>
                  </ul>
                  <ul className="field-list-contact">
                    <li>Technischer Ansprechpartner</li>
                    <li>
                      <FormInput name="technicalName" type="text" label="Name" />
                      <FormInput name="technicalEmail" type="text" label="E-Mail" />
                    </li>
                    <li>
                      <FormInput name="technicalPhone" type="text" label="Telefon" />
                    </li>
                  </ul>
                  <ul className="field-list-contact">
                    <li>Ansprechpartner Buchhaltung</li>
                    <li>
                      <FormInput name="bookkeepingName" type="text" label="Name" />
                      <FormInput name="bookkeepingEmail" type="text" label="E-mail" />
                    </li>
                    <li>
                      <FormInput name="bookkeepingPhone" type="text" label="Telefon" />
                    </li>
                  </ul>
                  <FormTextarea label="Kurzbeschreibung des Unternehmens" name="description" />
                </section>
              </div>
              <aside className="right-side">
                <div ref={uploadImgRef}>
                  <p className="title">Logo Lieferant</p>
                  <div className="upload-img-wrapper">
                    <UploadImgDragDrop
                      type="one"
                      data={attachment}
                      isDefaultImg={true}
                      isSingeImg={true}
                      onChange={onAttachmentHandler}
                      onDelete={onDeleteAttachmentHandler}
                      buttonName="hochladen"
                      infoText={false}
                      status={statusUpload !== 'pending'}
                    />
                  </div>
                </div>

                <div
                  className="block"
                  style={{ height: `${height.leftColumn - height.uploadImg - heightGroupBtn}px` }}></div>

                <div className="group-btn" ref={groupBtnRef}>
                  <Button title="Abbrechen und zurück" onEvent={onCancel} width="201" color="gray" />
                  <Button
                    disabled={!isDataChages}
                    title="Speichern und weiter"
                    type="submit"
                    width="201"
                    color="green"
                  />
                </div>
              </aside>
            </Form>
          </div>
        )}
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default ManufacturerProfile;

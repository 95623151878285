import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../../../store/reducer';
import * as action from '../../../../../../store/action';
import * as util from '../../../../../../utils';

import { useChangeEffect } from '../../../../../../hooks';
import { TabContent } from '../../../../../../HOC';
import { variables } from './variables';

import TableBuilder from '../../../../../../components/tableBuilder';
import FieldSearch from '../../../../../../components/UI/fieldSearch';
import DropdownCount from '../../../../../../components/dropdownCount';
import Pagination from '../../../../../../components/UI/pagination';
import Selected from '../../../../../../components/selected';
import Button from '../../../../../../components/UI/button/Button';
import Loader from '../../../../../../components/popups/loader';
import DeletePopup from '../../../../../../components/popups/deletePopup';
import { useDefaultPerPage } from '../../../../../../hooks/useDefaultPerPage';
import { useCallback } from 'react';

const List = ({ visible, isShowLoader }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataCultivarsAll = useSelector(reducer.request.cultivar.state.getCultivarsAll.data);
  const dataBuds = useSelector(reducer.request.admin.bud.state.getBuds.data);

  const statusDeleteBuds = useSelector(reducer.request.admin.bud.state.deleteBuds.status);
  const statusGetBuds = useSelector(reducer.request.admin.bud.state.getBuds.status);

  const deleteBuds = (data) => dispatch(action.request.admin.buds.deleteBuds.delete(data));
  const getBuds = (start, limit, search) => dispatch(action.request.admin.buds.getBuds.get(start, limit, search));

  const [valueSearch, setValueSearch] = useState('');

  const persistName = 'superuserBudsList';

  const { contentPerPage, setContentPerPage } = useDefaultPerPage(persistName);

  const [currentPage, setCurrentPage] = useState(1);

  const [isShowDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);

  const countSelectedRows = selectedIDs.length;
  const pageCount = Math.ceil(dataBuds?.total / contentPerPage) || 1;

  const tableTitles = ['№', 'Blütenname', 'Kultivar / Genetik', 'THС / CBD / CBN    (%)', 'Unternehmen'];
  const tableData = dataBuds?.buds.reduce((acc, item, index) => {
    acc = [
      ...acc,
      {
        id: item.id,
        number: currentPage !== 1 ? (currentPage - 1) * contentPerPage + index + 1 : index + 1,
        name: item.name,
        cultivars_genetics: `${util.getCultivarsName(item.cultivars, dataCultivarsAll)} / ${item.genetics}`,
        thc_cbd_cbn: `${item.thc}  / ${item.cbd} / ${item.cbn}`,
        companyName: item.companyName,
      },
    ];

    return acc;
  }, []);

  // get new data after delete item
  useChangeEffect(() => {
    if (statusDeleteBuds === 'success') {
      setShowDeletePopup(false);
      setSelectedIDs([]);
      getData();
    }
  }, [statusDeleteBuds]);

  // get new data when change page or search
  useEffect(() => {
    getData();
  }, [currentPage, contentPerPage]);

  const onGoSearch = () => {
    if (currentPage === 1) {
      getBuds(0, contentPerPage, valueSearch);
    } else {
      setCurrentPage(1);
    }
  };

  const onClear = () => {
    setValueSearch('');

    if (currentPage === 1) {
      getBuds(0, contentPerPage);
    } else {
      setCurrentPage(1);
    }
  };

  const onDelete = () => {
    deleteBuds({
      budsIds: selectedIDs,
    });
  };

  const getData = () => {
    const firstContentIndex = (currentPage - 1) * contentPerPage;
    getBuds(firstContentIndex, contentPerPage, valueSearch);
  };

  return (
    <>
      <TabContent visible={visible}>
        <TableBuilder
          className="bud-table"
          path="edit"
          contentPerPage={contentPerPage}
          title={tableTitles}
          data={tableData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageCount={pageCount}
          selectedIDs={selectedIDs}
          setSelectedIDs={setSelectedIDs}
          valueSearch={valueSearch}>
          <div className="table-header">
            <ul className="left-side">
              <li className="elem">
                <FieldSearch
                  value={valueSearch}
                  setValue={(event) => setValueSearch(event.target.value)}
                  onClear={onClear}
                  onGoSearch={onGoSearch}
                />
              </li>
              <li className="elem">
                <DropdownCount
                  contentPerPage={contentPerPage}
                  data={variables.dropdownContent}
                  onSetContentPerPage={setContentPerPage}
                  setCurrentPage={() => setCurrentPage(1)}
                  persistName={persistName}
                />
              </li>
              <li className="elem">
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
              </li>
            </ul>

            <ul className="right-side">
              {!!countSelectedRows && (
                <li className="elem">
                  <Selected count={countSelectedRows} onTrash={() => setShowDeletePopup(true)} />
                </li>
              )}
              <li className="elem">
                <Button title="Blüte hinzufügen" onEvent={() => navigate('add')} width="151" color="green" />
              </li>
            </ul>
          </div>
        </TableBuilder>
      </TabContent>

      {isShowDeletePopup && <DeletePopup title='Ausgewählte Blüten löschen?' onDelete={onDelete} onClose={() => setShowDeletePopup(false)} />}

      {!isShowLoader && (statusGetBuds === 'pending' || statusDeleteBuds === 'pending') && (
        <Loader type="transparent" />
      )}
    </>
  );
};

export default List;

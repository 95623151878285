import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      action.request.pharmacie.products.getPharmaciesProducts.getSuccess,
      ({ getPharmaciesProducts }, action) => {
        getPharmaciesProducts.status = 'success';
        getPharmaciesProducts.data = action.payload;
      },
    )
    .addCase(
      action.request.pharmacie.products.getPharmaciesProducts.getPending,
      ({ getPharmaciesProducts }, action) => {
        getPharmaciesProducts.status = 'pending';
      },
    )
    .addCase(action.request.pharmacie.products.getPharmaciesProducts.getFailed, ({ getPharmaciesProducts }, action) => {
      getPharmaciesProducts.status = 'failed';
      getPharmaciesProducts.error = action.payload;
      getPharmaciesProducts.data = null;
    });
});

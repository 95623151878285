export const state = {
  getDoctorsBuds: {
    data: (state) => state.request.doctor.buds.getDoctorsBuds.data,
    status: (state) => state.request.doctor.buds.getDoctorsBuds.status,
    error: (state) => state.request.doctor.buds.getDoctorsBuds.error,
  },

  getDoctorsBudsId: {
    data: (state) => state.request.doctor.buds.getDoctorsBudsId.data,
    status: (state) => state.request.doctor.buds.getDoctorsBudsId.status,
    error: (state) => state.request.doctor.buds.getDoctorsBudsId.error,
  },
};

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.pharmacie.user.getPharmaciesUser.getSuccess, ({ getPharmaciesUser }, action) => {
      getPharmaciesUser.status = 'success';
      getPharmaciesUser.data = action.payload;
    })
    .addCase(action.request.pharmacie.user.getPharmaciesUser.getPending, ({ getPharmaciesUser }, action) => {
      getPharmaciesUser.status = 'pending';
    })
    .addCase(action.request.pharmacie.user.getPharmaciesUser.getFailed, ({ getPharmaciesUser }, action) => {
      getPharmaciesUser.status = 'failed';
      getPharmaciesUser.error = action.payload;
      getPharmaciesUser.data = null;
    });
  builder
    .addCase(action.request.pharmacie.user.patchPharmaciesUser.patchSuccess, ({ patchPharmaciesUser }, action) => {
      patchPharmaciesUser.status = 'success';
      patchPharmaciesUser.data = action.payload;
    })
    .addCase(action.request.pharmacie.user.patchPharmaciesUser.patchPending, ({ patchPharmaciesUser }, action) => {
      patchPharmaciesUser.status = 'pending';
    })
    .addCase(action.request.pharmacie.user.patchPharmaciesUser.patchFailed, ({ patchPharmaciesUser }, action) => {
      patchPharmaciesUser.status = 'failed';
      patchPharmaciesUser.error = action.payload;
      patchPharmaciesUser.data = null;
    });
});

import React, { useState, useRef, useEffect } from 'react';

import './style.scss';

import ButtonClear from '../buttonClear';
import Checbox from '../checkbox';
import FieldSearch from '../fieldSearch';
import { useOnOutsideClick } from '../../../hooks';

const DropdownSearch = ({ label, position, type, selectData, data, setData, isCustom = false }) => {
  const [showDropdown, setShowDropDown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [heightContent, setHeightContent] = useState(0);

  const { innerBorderRef } = useOnOutsideClick(() => setShowDropDown(false));

  const contentRef = useRef(null);

  const dataCount = data?.length;
  const isEmptyData = !dataCount;
  const filteredSelectedData = selectData?.filter(
    (item) => item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1,
  );

  useEffect(() => {
    setHeightContent(contentRef.current?.clientHeight);
  }, [filteredSelectedData]);

  const onToggle = (currentID) => {
    let changedData;

    if (data.includes(currentID)) {
      changedData = data.filter((ID) => ID !== currentID);
    } else {
      changedData = [...data, currentID];
    }

    setData(changedData);
  };

  const onToggleCustom = (arg) => {
    let changedData;

    if (data.some((dataItem) => dataItem.productId === arg.productId)) {
      changedData = data.filter((dataItem) => dataItem.productId !== arg.productId);
    } else {
      changedData = [...data, arg];
    }

    setData(changedData);
  };

  const onClear = (event, currentID) => {
    event.stopPropagation();

    setData(data.filter((ID) => ID !== currentID));
  };

  return (
    <div className={`dropdown-search ${position}`} ref={innerBorderRef}>
      <p className="label">{label}</p>
      <div className={`field ${showDropdown ? 'open' : ''}`} onClick={() => setShowDropDown(!showDropdown)}>
        {isEmptyData ? <span className="placeholder">auswählen</span> : `${type} • ${dataCount}`}
      </div>

      <ul className="selected-list">
        {data?.map((id) => (
          <li className="selected-item" key={id}>
            {selectData?.find((item) => item.id === id)?.name}
            <ButtonClear onEvent={(event) => onClear(event, id)} />
          </li>
        ))}
      </ul>

      <div
        className={`content ${showDropdown ? 'show' : 'hide'}`}
        ref={contentRef}
        style={{ top: `-${heightContent - 18}px` }}>
        <FieldSearch
          value={searchValue}
          setValue={(event) => setSearchValue(event.target.value)}
          onClear={() => setSearchValue('')}
        />

        {filteredSelectedData?.length ? (
          <ul className="list">
            {filteredSelectedData.map((item) => (
              <li className="item" key={item.id}>
                <Checbox
                  label={item.name}
                  isChecked={
                    isCustom ? data.some((dataItem) => dataItem.productId === item.id) : data.includes(item.id)
                  }
                  onChange={() =>
                    isCustom ? onToggleCustom({ productId: item.id, type: item.type }) : onToggle(item.id)
                  }
                />
              </li>
            ))}
          </ul>
        ) : (
          <p className="message">Nichts gefunden</p>
        )}
      </div>
    </div>
  );
};

export default DropdownSearch;

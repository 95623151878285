import { createAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import * as api from '../../../../../api';
import * as type from '../../../../type';
import * as action from '../../../../action';

export const patchPharmaciesUser = {
  patch: function (...props) {
    return (dispatch) => {
      dispatch(this.patchPending());

      api.pharmacie.user
        .patchPharmaciesUser(...props)
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          dispatch(this.patchSuccess());
          toast.success('Benutzerdaten erfolgreich geändert');
        })
        .catch((error) => {
          if (error.status === 403 || error.status === 404) {
            window.location = '/';
          }
          if (error.status === 401) {
            dispatch(action.request.auth.getRefresh.get(this.patch(...props)));
          } else {
            dispatch(this.patchFailed(error.statusText));
            toast.error('Benutzerdaten konnten nicht geändert werden');
          }
        });
    };
  },

  patchSuccess: createAction(type.request.pharmacie.user.patchPharmaciesUserSuccess),
  patchPending: createAction(type.request.pharmacie.user.patchPharmaciesUserPending),
  patchFailed: createAction(type.request.pharmacie.user.patchPharmaciesUserFailed),
};

export const getSort = {
  en: (data) => {
    let status = '';

    switch (data) {
      case 'zuletzt hochgeladen':
        status = 'createdAt';
        break;
      case 'aufsteigend (A bis Z)':
        status = 'name';
        break;
      case 'absteigend (Z bis A)':
        status = '-name';
        break;
      default:
        status = '';
    }

    return status;
  },

  de: (data) => {
    let status = '';

    switch (data) {
      case 'createdAt':
        status = 'zuletzt hochgeladen';
        break;
      case 'name':
        status = 'aufsteigend (A bis Z)';
        break;
      case '-name':
        status = 'absteigend (Z bis A)';
        break;
      default:
        status = '';
    }

    return status;
  },
};

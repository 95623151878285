export const orders = {
  getManufacturerOrdersSuccess: 'request/getManufacturerOrdersSuccess',
  getManufacturerOrdersPending: 'request/getManufacturerOrdersPending',
  getManufacturerOrdersFailed: 'request/getManufacturerOrdersFailed',

  getManufacturerOrdersIdSuccess: 'request/getManufacturerOrdersIdSuccess',
  getManufacturerOrdersIdPending: 'request/getManufacturerOrdersIdPending',
  getManufacturerOrdersIdFailed: 'request/getManufacturerOrdersIdFailed',

  putManufacturerOrdersIdSuccess: 'request/putManufacturerOrdersIdSuccess',
  putManufacturerOrdersIdPending: 'request/putManufacturerOrdersIdPending',
  putManufacturerOrdersIdFailed: 'request/putManufacturerOrdersIdFailed',
};

export const cultivars = {
  getCultivarsAllSuccess: 'request/getCultivarsAllSuccess',
  getCultivarsAllPending: 'request/getCultivarsAllPending',
  getCultivarsAllFailed: 'request/getCultivarsAllFailed',

  getCultivarIdSuccess: 'request/getCultivarIdSuccess',
  getCultivarIdPending: 'request/getCultivarIdPending',
  getCultivarIdFailed: 'request/getCultivarIdFailed',

  putCultivarIdSuccess: 'request/putCultivarIdSuccess',
  putCultivarIdPending: 'request/putCultivarIdPending',
  putCultivarIdFailed: 'request/putCultivarIdFailed',

  deleteCultivarIdSuccess: 'request/deleteCultivarIdSuccess',
  deleteCultivarIdPending: 'request/deleteCultivarIdPending',
  deleteCultivarIdFailed: 'request/deleteCultivarIdFailed',

  postCultivarsSuccess: 'request/postCultivarsSuccess',
  postCultivarsPending: 'request/postCultivarsPending',
  postCultivarsFailed: 'request/postCultivarsFailed',

  resetPostData: 'request/resetPostData',
};

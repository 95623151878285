import React from 'react';

import './style.scss';

import { useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import Error404 from '../../../components/error404';

const ErrorPage = () => {
  const isShow = useDelay(100);
  usePageUp();

  return (
    <>
      <Header />
      <main className={`error-page ${isShow ? 'show' : ''}`}>
        <Error404 />
      </main>
    </>
  );
};

export default ErrorPage;

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.doctor.buds.getDoctorsBuds.getSuccess, ({ getDoctorsBuds }, action) => {
      getDoctorsBuds.status = 'success';
      getDoctorsBuds.data = action.payload;
    })
    .addCase(action.request.doctor.buds.getDoctorsBuds.getPending, ({ getDoctorsBuds }, action) => {
      getDoctorsBuds.status = 'pending';
    })
    .addCase(action.request.doctor.buds.getDoctorsBuds.getFailed, ({ getDoctorsBuds }, action) => {
      getDoctorsBuds.status = 'failed';
      getDoctorsBuds.error = action.payload;
      getDoctorsBuds.data = null;
    });
  builder
    .addCase(action.request.doctor.buds.getDoctorsBudsId.getSuccess, ({ getDoctorsBudsId }, action) => {
      getDoctorsBudsId.status = 'success';
      getDoctorsBudsId.data = action.payload;
    })
    .addCase(action.request.doctor.buds.getDoctorsBudsId.getPending, ({ getDoctorsBudsId }, action) => {
      getDoctorsBudsId.status = 'pending';
    })
    .addCase(action.request.doctor.buds.getDoctorsBudsId.getFailed, ({ getDoctorsBudsId }, action) => {
      getDoctorsBudsId.status = 'failed';
      getDoctorsBudsId.error = action.payload;
      getDoctorsBudsId.data = null;
    });
});

import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import './style.scss';

import * as action from '../../../store/action';

import COTLogo from '../../../assets/images/C_TRAX_bl.svg';

import SignIn from './signIn';
import DocCheckFrame from '../../../components/docCheckFrame';

const SignInPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {pathname} = useLocation()

  const postLogin = (values) => {
    dispatch(action.request.auth.postLogin.post(values, navigate));
  };

  const onSignIn = (values) => {
    postLogin(values);
  };

  useEffect(() => {
    if(pathname === '/') {
      //TODO: may be need a refactor
      navigate('/login')
    }
  }, [pathname, navigate])

  return (
    <main className="signin-page">
      <div className="content">
        <p className="main-logo">
          <img src={COTLogo} alt="" />
        </p>

        <div className="forms">
          <SignIn onEvent={onSignIn} />
          <div className="doc-check">
            <p className="message">
              Ausführliche Informationen für Ärzt:innen und Bestellanfragen für Apotheken finden Sie im geschlossenen
              Bereich.
            </p>

            <DocCheckFrame />
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignInPage;

import Cookies from 'js-cookie';

import * as variable from '../../variables';

export const buds = {
  getBudsAll: (manufacturerId) => {
    const accessToken = Cookies.get('accessToken');
    const url = manufacturerId
      ? `https://${variable.url}/admin/buds/all/${manufacturerId}`
      : `https://${variable.url}/admin/buds/all`;
    return fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getBudId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/buds/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  patchBudId: (id, data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/buds/${id}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteBudId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/buds/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getBuds: (start, limit, search, sort, order) => {
    const searchParam = search ? `&search=${search}` : '';

    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/buds?start=${start}&limit=${limit}${searchParam}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  postBuds: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/buds`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteBuds: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/buds`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

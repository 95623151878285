import React, { useEffect, useRef, useState } from 'react';

import './style.scss';

import PreviewImg from './previewImg';
import UploadImg from '../../components/uploadImg';
import Button from '../UI/button';

const AddEdit = ({
  children,
  className,
  type,
  textDelete,
  onDelete = false,
  onCancel,
  textSave = 'Speichern und weiter',
  onSave,
  data,
  setData,
  onUploadImg,
  isSaveButtonDisabled = false,
}) => {
  const [height, setHeight] = useState({
    leftColumn: 0,
    uploadImg: 0,
  });

  const leftColumnRef = useRef();
  const uploadImgRef = useRef();
  const groupBtnRef = useRef();

  const heightGroupBtn = groupBtnRef.current?.clientHeight;

  useEffect(() => {
    setHeight({
      leftColumn: leftColumnRef.current?.clientHeight,
      uploadImg: uploadImgRef.current?.clientHeight,
    });
  }, [data]);

  return (
    <div className={`add-edit ${className}`}>
      <div className="left-column" ref={leftColumnRef}>
        {children}
      </div>

      <aside className="right-column">
        <div className="main-img upload-img" ref={uploadImgRef}>
          {(type === 'bud' || type === 'extract') && <PreviewImg data={data} />}

          {(type === 'pharmacy' || type === 'manufacture') && (
            <UploadImg
              title="Logo Apotheke"
              view="second"
              type="other"
              img={data.logo}
              onUploadImg={(data) => onUploadImg('logo', data)}
              onSelectImg={(img) => setData({ ...data, logo: img })}
            />
          )}
        </div>

        <div className="block" style={{ height: `${height.leftColumn - height.uploadImg - heightGroupBtn}px` }}></div>

        <div className="group-btn" ref={groupBtnRef}>
          {onDelete && <Button title={textDelete} onEvent={onDelete} width="201" color="red" />}
          <Button title="Abbrechen und zurück" onEvent={onCancel} width="201" color="gray" />
          <Button disabled={isSaveButtonDisabled} title={textSave} onEvent={onSave} width="201" color="green" />
        </div>
      </aside>
    </div>
  );
};

export default AddEdit;

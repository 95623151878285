import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';

import './style.scss';

import * as action from '../../../../store/action';
import * as reducer from '../../../../store/reducer';

import { usePageUp, useChangeEffect } from '../../../../hooks';

import Header from '../../../../components/header';
import LinkBack from '../../../../components/UI/linkBack';
import Tabs from '../../../../components/UI/tabs';
import EditPharmacy from './editPharmacy';
import Orders from './orders';
import Loader from '../../../../components/popups/loader';

const PharmacyProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param = useParams();

  const dataOrders = useSelector(reducer.request.admin.order.state.getOrders.data);
  const dataPharmacyId = useSelector(reducer.request.admin.pharmacy.state.getPharmacyId.data);

  const statusGetPharmacyId = useSelector(reducer.request.admin.pharmacy.state.getPharmacyId.status);
  const statusGetCultivarsAll = useSelector(reducer.request.cultivar.state.getCultivarsAll.status);
  const statusGetOrders = useSelector(reducer.request.admin.order.state.getOrders.status);
  const isDataChages = useSelector(reducer.app.common.state.isDataChages);

  const setDataChage = (isChange) => dispatch(action.app.common.isDataChages(isChange));
  const getPharmacyId = (id) => dispatch(action.request.admin.pharmacies.getPharmacyId.get(id));
  const getCultivarsAll = () => dispatch(action.request.cultivars.getCultivarsAll.get());
  const getOrders = (params) => dispatch(action.request.admin.orders.getOrders.get(params));

  const [isShowLoader, setShowLoader] = useState(true);
  const [activeTabID, setActiveTabID] = useState(1);

  const pharmacyID = param.pharmacyID;
  const pharmacies = qs.stringify({ pharmacies: [pharmacyID] });

  const isRequestGETSuccess =
    statusGetPharmacyId === 'success' && statusGetCultivarsAll === 'success' && statusGetOrders === 'success';

  const tabsList = [
    {
      id: 1,
      name: 'Apotheke',
      count: '',
    },
    {
      id: 2,
      name: 'Bestellanfragen',
      count: dataOrders?.total,
    },
  ];

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  useEffect(() => {
    getCultivarsAll();
    getPharmacyId(pharmacyID);
    getOrders({ start: 0, limit: 10, pharmacies: pharmacies });
  }, []);

  usePageUp();

  const onCancel = () => {
    if (isDataChages) {
      if (window.confirm('Bestätigen Sie die Aktion auf der Seite. Die Änderungen zurücksetzen?')) {
        setDataChage(false);
        navigate('/pharmacies', { state: { tab: dataPharmacyId?.archieved ? 2 : 1 } });
      }
    } else {
      navigate('/pharmacies', { state: { tab: dataPharmacyId?.archieved ? 2 : 1 } });
    }
  };


  return (
    <>
      <Header />
      <main className={`pharmacy-profile-page page ${!isShowLoader ? 'show' : ''}`}>
        <div className="container">
          <LinkBack
            title={dataPharmacyId?.archieved ? 'Zurück ins Archiv' : 'Zurück zu Apotheken'}
            onEvent={onCancel}
          />

          <h1 className="page-title">Apothekenprofil</h1>
          <Tabs list={tabsList} activeTabID={activeTabID} setActiveTabID={setActiveTabID} />

          <EditPharmacy visible={activeTabID === 1} isShowLoader={isShowLoader} />
          <Orders visible={activeTabID === 2} isShowLoader={isShowLoader} />
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default PharmacyProfile;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../store/reducer';
import * as action from '../../../../store/action';

import { usePageUp } from '../../../../hooks';

import { getFormateTableDate, getStatusProductOrder } from '../../../../utils';

import Button from '../../../../components/UI/button';
import DeletePopup from '../../../../components/popups/deletePopup/DeletePopup';

import defaultBudImg from '../../../../assets/images/default-bud-img.svg';
import defaultExtractImg from '../../../../assets/images/default-extract-img.svg';

import * as variable from '../../../../variables';

const OrdersSection = ({ dataSearch, className = '' }) => {


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const headerKeys = ['', 'Produkt', 'Lieferant', 'Menge', 'Status', 'Lieferdatum', ''];

  const [confimDeletePopup, setConfimDeletePopup] = useState(false);
  const [isShowFull, setIsShowFull] = useState([]);

  const showHandler = (id) => {
    setIsShowFull(
      isShowFull.some((item) => id === item) ? isShowFull.filter((item) => id !== item) : [...isShowFull, id],
    );
  };

  const dataOrders = useSelector(reducer.request.pharmacie.orders.state.getPharmaciesOrders.data);

  const deletePharmaciesOrdersProductId = (orderId, productId) =>
    dispatch(action.request.pharmacie.orders.deletePharmaciesOrdersProductId.delete(orderId, productId));
  const deletePharmaciesOrderId = (orderId) =>
    dispatch(action.request.pharmacie.orders.deletePharmaciesOrders.delete(orderId));
  const postPharmaciesOrders = (navigate, data) =>
    dispatch(action.request.pharmacie.orders.postPharmaciesOrders.post(navigate, data));

  const repeatOrderHandler = (order) => {
    postPharmaciesOrders(navigate, {
      products: order.products.map((item) => ({
        productId: item.productId,
        type: item.type,
        manufacturerId: item.manufacturerId,
        amount: item.amount,
      })),
    });
    navigate('/orders');
  };

  return (
    <section className={`order-section-wrapper ${className}`}>
      {!!dataOrders?.length ? (
        dataOrders?.map((order) => (
          <ul key={order.id} className={`order-wrapper ${order.status}`}>
            <li>
              <div className={`order-header`}>
                <div className="order-header-info">
                  <p className="order-header-info-name">
                    Bestellanfrage № {order.id} from {getFormateTableDate(order.date, false)}
                  </p>
                  <p className={`order-header-info-status ${order.status}`}>{getStatusProductOrder.de(order.status)}</p>
                </div>
                <div className="order-header-settings">
                  {
                    order.status === 'new' && order.products.every((item) => item.status === 'opened') && (
                      <button onClick={() => setConfimDeletePopup(order.id)} className="remove-order">
                        Bestellanfrage stornieren
                      </button>
                    )
                    // TODO:REPEAT ORDER
                    // : (
                    //   <button onClick={() => repeatOrderHandler(order)} className="repeat-order">
                    //     Bestellanfrage wiederholen
                    //   </button>
                    // )
                  }
                  {confimDeletePopup === order.id && (
                    <DeletePopup
                      onClose={() => setConfimDeletePopup(false)}
                      onDelete={() => {
                        deletePharmaciesOrderId(order.id);
                        setConfimDeletePopup(false);
                      }}
                      title={`Bestellanfrage №${order.id} stornieren`}
                      text="Möchten Sie die Bestellanfrage wirklich stornieren? Es ist nicht möglich, diese Aktion abzubrechen."
                    />
                  )}
                  <button onClick={() => showHandler(order.id)} className="show-more-info">
                    Mehr sehen
                  </button>
                </div>
              </div>
              <ul
                className={`order-items`}
                style={
                  isShowFull.some((id) => id === order.id)
                    ? { maxHeight: `${order.products.length * 99 + 41}px` }
                    : { maxHeight: '0px' }
                }>
                <li>
                  {headerKeys.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </li>
                {order.products?.map((product) => {
                  return (
                    <ul key={product.id} className="order-row">
                      <li className="order-row-img">
                        <img
                          src={
                            product.imageKey
                              ? `https://${variable.url}/uploads/${product.imageKey}`
                              : product.type === 'bud'
                                ? defaultBudImg
                                : defaultExtractImg
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        <p
                          onClick={() => navigate(`/${product.type}s/${product.productId}`)}
                          className="order-row-title">
                          {product.name}
                        </p>
                        <p className="order-row-text">{product.genetics}</p>
                        <p className="order-row-text">{product.cultivars.join(',')}</p>
                      </li>
                      <li className="order-row-manufacturer">{product.manufacturerName}</li>
                      <li className="order-row-amount">{`${product.amount} ${product.type === 'bud' ? 'g' : 'ml'}`}</li>
                      <li className={`order-row-status`}>
                        <span className={`status ${getStatusProductOrder.de(product.status)}`}>
                          {getStatusProductOrder.de(product.status)}
                        </span>
                      </li>
                      <li className="order-row-delivery">{getFormateTableDate(product.date, false) || 'n.a'}</li>
                      {product.status === 'opened' ? (
                        <button onClick={() => setConfimDeletePopup(product.id)} className="order-row-remove-btn">
                          Löschen
                        </button>
                      ) : (
                        <button className="order-row-remove-btn empty"></button>
                      )}
                      {confimDeletePopup === product.id && (
                        <DeletePopup
                          onClose={() => setConfimDeletePopup(false)}
                          onDelete={() => {
                            deletePharmaciesOrdersProductId(order.id, product.id);
                            setConfimDeletePopup(false);
                          }}
                          title="Produkt entfernen"
                          text="Möchten Sie das Element wirklich entfernen? Es ist nicht möglich, diese Aktion rückgängig zu machen."
                        />
                      )}
                    </ul>
                  );
                })}
              </ul>
            </li>
          </ul>
        ))
      ) : (
        <div className="empty">
          {dataSearch ? (
            <>
              <h1 className="empty-title">Es wurden keine Ergebnisse für Ihre Suche gefunden</h1>
              <p className="empty-text">
                Versuchen Sie, Ihre Suche zu verfeinern, indem Sie die Filtereinstellungen ändern
              </p>
            </>
          ) : (
            <>
              <h1 className="empty-title">Hier finden Sie Ihre bisherigen Bestellanfragen</h1>
              <p className="empty-text">Um eine Bestellanfrage zu starten, legen Sie Produkte in den Warenkorb.</p>
              <div className="empty-btns">
                <Button onEvent={() => navigate('/buds')} color="green" title="Blüten" width={118} />
                <Button onEvent={() => navigate('/extracts')} color="green" title="Extrakte" width={118} />
              </div>
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default OrdersSection;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import FileUploader from '../fileUploader';
import ButtonClear from '../UI/buttonClear';
import Loader from '../loader/Loader';

import * as reducer from '../../store/reducer';
import * as action from '../../store/action';

import * as variable from '../../variables';

const UploadFiles = ({ title = 'Dateien', attachment, setAttachment, type = 'other' }) => {
  const dispatch = useDispatch();
  const [showAllFiles, setShowAllFiles] = useState(false);

  const dataUpload = useSelector(reducer.request.uploads.state.postUploads.data);
  const statusUpload = useSelector(reducer.request.uploads.state.postUploads.status);

  const postUploads = (data) => dispatch(action.request.uploads.postUploads.post(data));
  const deleteUpload = (filesIdsArray) => dispatch(action.request.uploads.deleteUploads.delete(filesIdsArray));
  const resetUploadData = () => dispatch(action.request.uploads.resetUploadData());

  const isLoaded = statusUpload === 'success';

  const onAttachmentHandler = (type, file) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', type);

    setAttachment((prev) => [
      ...prev,
      {
        key: null,
        name: file.name,
        type,
      },
    ]);

    postUploads(formData);
  };

  const onDeleteAttachmentHandler = (key) => {
    if (window.confirm('Möchten Sie diese Datei wirklich löschen?')) {
      if (type === 'logo') {
        setAttachment(null);
      } else {
        setAttachment(attachment.filter((item) => item.key !== key));
      }
      deleteUpload({ fileIds: [key] });
    }
  };

  useEffect(() => {
    if (dataUpload) {
      const files = [...attachment];
      const poped = files.pop();
      const type = dataUpload.split('.').pop();

      if (type === poped.name.split('.').pop()) {
        setAttachment([...files, { ...poped, key: dataUpload }]);
      }
      resetUploadData();
    }
  }, [dataUpload]);

  return isLoaded ? (
    <div className="attachments-wrapper">
      <ul>
        <h3 className="list-title">{title}</h3>
        {onAttachmentHandler && <FileUploader onChange={(file) => onAttachmentHandler(type, file)} type="pdf" />}
      </ul>
      <ul className={`add-new-attachment ${showAllFiles ? 'attachments-max' : 'attachments-min'}`}>
        {attachment.map((item, index) => (
          <li key={index}>
            <a href={`https://${variable.url}/uploads/${item.key}`} target="_blank" rel="noreferrer">
              {item.name}
            </a>
            <ButtonClear className="delete-attachment" onEvent={() => onDeleteAttachmentHandler(item.key)} />
          </li>
        ))}
      </ul>
      {attachment.length > 3 && (
        <button
          className={`show-more-btn ${showAllFiles ? 'rotate' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            setShowAllFiles(!showAllFiles);
          }}>
          Mehr anzeigen
        </button>
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default UploadFiles;

export const buds = {
  getBudsAllSuccess: 'request/getBudsAllSuccess',
  getBudsAllPending: 'request/getBudsAllPending',
  getBudsAllFailed: 'request/getBudsAllFailed',

  getBudIdSuccess: 'request/getBudIdSuccess',
  getBudIdPending: 'request/getBudIdPending',
  getBudIdFailed: 'request/getBudIdFailed',

  patchBudIdSuccess: 'request/patchBudIdSuccess',
  patchBudIdPending: 'request/patchBudIdPending',
  patchBudIdFailed: 'request/patchBudIdFailed',

  deleteBudIdSuccess: 'request/deleteBudIdSuccess',
  deleteBudIdPending: 'request/deleteBudIdPending',
  deleteBudIdFailed: 'request/deleteBudIdFailed',

  getBudsSuccess: 'request/getBudsSuccess',
  getBudsPending: 'request/getBudsPending',
  getBudsFailed: 'request/getBudsFailed',

  postBudsSuccess: 'request/postBudsSuccess',
  postBudsPending: 'request/postBudsPending',
  postBudsFailed: 'request/postBudsFailed',

  deleteBudsSuccess: 'request/deleteBudsSuccess',
  deleteBudsPending: 'request/deleteBudsPending',
  deleteBudsFailed: 'request/deleteBudsFailed',
};

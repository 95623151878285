import { createAction } from '@reduxjs/toolkit';

import * as api from '../../../../api';
import * as type from '../../../type';

export const postForgotPassword = {
  post: function(data, navigate) {
    return (dispatch) => {
      dispatch(this.postPending());

      api.auth
        .postForgotPassword(data)
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          dispatch(this.postSuccess());
          navigate('/login');
        })
        .catch((error) => {
          dispatch(this.postFailed(error.statusText));
        });
    };
  },

  postSuccess: createAction(type.request.auth.postForgotPasswordSuccess),
  postPending: createAction(type.request.auth.postForgotPasswordPending),
  postFailed: createAction(type.request.auth.postForgotPasswordFailed),
};

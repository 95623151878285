import React, { useState } from 'react';

import './style.scss';

import ButtonClear from '../buttonClear';
import Checbox from '../checkbox';
import { useOnOutsideClick } from '../../../hooks';
import Field from '../field';
import Button from '../button';

const DropdownSelect = ({
  error,
  label,
  dataKey,
  data,
  setData,
  content,
  position,
  errorData,
  setErrorData,
  className = '',
  disabled = false,
  isCanAddNew = false,
  addNewItem,
  updateItem,
  deleteItem,
}) => {
  const [showDropdown, setShowDropDown] = useState(false);
  const { innerBorderRef } = useOnOutsideClick(() => setShowDropDown(false));
  const [editId, setEditId] = useState(null);

  const onToggle = (currentID) => {
    let changedData;

    if (data[dataKey].includes(currentID)) {
      changedData = { ...data, [dataKey]: data[dataKey].filter((ID) => ID !== currentID) };
    } else {
      changedData = { ...data, [dataKey]: [...data[dataKey], currentID] };
    }

    setData(changedData);
    error && setErrorData({ ...errorData, [dataKey]: true });
  };

  const onClear = (event, currentID) => {
    event.stopPropagation();
    setData({ ...data, [dataKey]: data[dataKey].filter((ID) => ID !== currentID) });
  };

  const EditItem = ({ data, isNewItem }) => {
    const [newData, setNewData] = useState(data);

    const deleteHandler = (id) => {
      if (window.confirm(`Möchten Sie diese wirklich löschen ${newData.name}?`)) {
        deleteItem(id);
        setEditId(null);
      }
    };

    return (
      <li className="item add-new">
        <div className="main">
          <Field
            type="text"
            value={newData.name}
            placeholder={`${label} hinzufügen`}
            onEvent={(event) => setNewData({ ...newData, name: event.target.value })}
          />
          {newData.name && isNewItem && (
            <ButtonClear
              onEvent={() => setNewData(dataKey === 'terpenes' ? { name: '', symptom: '' } : { name: '' })}
            />
          )}
          <Button
            title={isNewItem ? 'Neue hinzufügen' : 'Aktualisieren'}
            width="150"
            color="green"
            disabled={dataKey === 'terpenes' ? newData.name === '' || newData.symptom === '' : newData.name === ''}
            onEvent={() => {
              if (isNewItem) {
                addNewItem(newData);
                setNewData(dataKey === 'terpenes' ? { name: '', symptom: '' } : { name: '' });
              } else {
                updateItem(
                  newData.id,
                  dataKey === 'terpenes' ? { name: newData.name, symptom: newData.symptom } : { name: newData.name },
                );
                setEditId(null);
              }
            }}
          />
          {!isNewItem && (
            <Button
              title="Löschen"
              width="150"
              color="red"
              onEvent={() => {
                deleteHandler(newData.id);
              }}
            />
          )}
        </div>
        {dataKey === 'terpenes' && newData.name && (
          <Field
            type="text"
            value={newData.symptom}
            placeholder={`Symptome`}
            onEvent={(event) => setNewData({ ...newData, symptom: event.target.value })}
          />
        )}
      </li>
    );
  };

  return (
    <div className={`dropdown-select ${position} ${className} ${error ? 'error' : ''} `} ref={innerBorderRef}>
      <p className="label">{label}</p>
      <button
        disabled={disabled}
        className={`selected-field ${showDropdown ? 'open' : ''} ${disabled ? 'disabled' : ''}`}
        onClick={() => {
          setShowDropDown(!showDropdown);
          setEditId(null);
        }}>
        {data[dataKey]?.map((id) => {
          const currentItemName = content?.find((item) => item.id === id)?.name;
          return (
            currentItemName && (
              <div className="selected-item" key={id}>
                {currentItemName}
                <ButtonClear onEvent={(event) => onClear(event, id)} />
              </div>
            )
          );
        })}
      </button>

      <ul className={`content ${showDropdown ? 'show' : 'hide'}`}>
        {isCanAddNew && (
          <EditItem data={dataKey === 'terpenes' ? { name: '', symptom: '' } : { name: '' }} isNewItem={true} />
        )}
        {content?.map((item) => (
          <>
            {editId === item.id ? (
              <EditItem data={item} />
            ) : (
              <li className="item" key={item.id}>
                <Checbox
                  label={item.name}
                  isChecked={data[dataKey]?.includes(item.id)}
                  onChange={() => onToggle(item.id)}
                  isEditable={isCanAddNew}
                  setEditId={() => setEditId(item.id)}
                />
              </li>
            )}
          </>
        ))}
      </ul>
    </div>
  );
};

export default DropdownSelect;

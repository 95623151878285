import React, { useState } from 'react';

import './style.scss';

const Range = ({ title, message, data, setData, min = '1', max = '20', disabled }) => {
  return (
    <section className="range-container">
      <h3 className="title">{title}</h3>
      <div className="info">
        <p className="desc">{message}</p>
        <input type="text" value={data} disabled={true} />
      </div>
      <div className="slidecontainer">
        <input
          type="range"
          value={data || ''}
          min={min}
          max={max}
          onChange={(event) => setData(event.target.value)}
          style={{ backgroundSize: `${((data - min) * 100) / (max - min) + '% 100%'}` }}
          disabled={disabled}
        />

        <span className="min">{min}</span>
        <span className="max">{max}</span>
      </div>
    </section>
  );
};

export default Range;

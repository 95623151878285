import React from 'react';

import './style.scss';

import { ControllerRenderProps, FieldValues, useController } from 'react-hook-form';

const FormInput = ({
  name,
  onChangeValue,
  placeholder,
  type,
  className,
  disabled,
  shouldUnregister,
  label,
  isRequired,
  maxLength,
}) => {
  const { field, fieldState, formState } = useController({
    name,
    defaultValue: '',
    shouldUnregister,
  });

  const { onChange, ...restField } = field;

  const handleChange = (e) => {
    onChange(e);
    onChangeValue?.(e.target.value);
  };

  const { isValid } = formState;
  const { error, isTouched } = fieldState;

  return (
    <p className={`${className} default-field ${error ? 'error' : ''}`}>
      {label && (
        <label className="label">
          {label}
          {isRequired && <sup className="req-symbol"> *</sup>}
        </label>
      )}
      <input
        type={type}
        className="input"
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleChange}
        maxLength={maxLength}
        min={type === 'number' ? 0 : undefined}
        step={type === 'number' ? 0.01 : undefined}
        {...restField}
      />
      {error && <span className="text-error form-input-error">{error.message}</span>}
    </p>
  );
};

export default FormInput;

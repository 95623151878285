import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './style.scss';

import { ReactComponent as IconSort } from '../../../assets/icons/icon-sort.svg';

import * as action from '../../../store/action';
import * as reducer from '../../../store/reducer';
import * as variable from '../../../variables';
import * as util from '../../../utils';

import { TabContent } from '../../../HOC';
import { useChangeEffect } from '../../../hooks';

import TemplatePopup from '../../../components/templatePopup';
import Button from '../../../components/UI/button';
import ButtonClear from '../../../components/UI/buttonClear';
import Tabs from '../../../components/UI/tabs';
import Selected from '../../selected';
import SelectedIcon from './selectedIcon';
import Loader from '../../../components/popups/loader';
import Dropdown2 from '../../../components/UI/dropdown2';

const GalleryPopup = ({ onClose, setSelectedImg }) => {
  const dispatch = useDispatch();

  const dataUploads = useSelector(reducer.request.uploads.state.getUploads.data);

  const statusDeleteUploads = useSelector(reducer.request.uploads.state.deleteUploads.status);
  const statusGetUploads = useSelector(reducer.request.uploads.state.getUploads.status);

  const deleteUploads = (data) => dispatch(action.request.uploads.deleteUploads.delete(data));
  const getUploads = (params) => dispatch(action.request.uploads.getUploads.get(params));

  const [activeTabID, setActiveTabID] = useState(1);
  const [selectedImgIDs, setSelectedImgIDs] = useState([]);
  const [removeImgIDs, setRemoveImgIDs] = useState([]);
  const [sortValue, setSortValue] = useState('createdAt');

  const selectedCount = selectedImgIDs.length;
  const removeCount = removeImgIDs.length;

  const budsImg = dataUploads?.filter((item) => item.type === 'bud');
  const extractsImg = dataUploads?.filter((item) => item.type === 'extract');
  const otherImg = dataUploads?.filter((item) => item.type === 'other');

  const tabsList = [
    {
      id: 1,
      name: 'Blüten',
      count: budsImg?.length,
    },
    {
      id: 2,
      name: 'Extrakte',
      count: extractsImg?.length,
    },
    {
      id: 3,
      name: 'Sonstiges',
      count: otherImg?.length,
    },
  ];

  const dropdownContent = [
    {
      id: 1,
      name: 'zuletzt hochgeladen',
      de: 'createdAt',
    },
    {
      id: 2,
      name: 'aufsteigend (A bis Z)',
      de: 'name',
    },
    {
      id: 3,
      name: 'absteigend (Z bis A)',
      de: '-name',
    },
  ];

  useEffect(() => {
    getUploads({});
  }, []);

  // get new data when sort
  useChangeEffect(() => {
    getUploads({ sort: sortValue });
  }, [sortValue]);

  // get new data after delete item
  useChangeEffect(() => {
    if (statusDeleteUploads === 'success') {
      setSelectedImgIDs([]);
      setRemoveImgIDs([]);
      getUploads({});
    }
  }, [statusDeleteUploads]);

  useEffect(() => {
    if (selectedCount) setSelectedImgIDs([]);
    if (removeCount) setRemoveImgIDs([]);
  }, [activeTabID]);

  const toggleSelectedImgIDs = (key) => {
    let modifiedImgIDs = [];

    if (selectedImgIDs.includes(key)) {
      modifiedImgIDs = selectedImgIDs.filter((currentID) => currentID !== key);
      clearRemoveImgIDs(key);
    } else {
      modifiedImgIDs = [...selectedImgIDs, key];
    }

    setSelectedImgIDs(modifiedImgIDs);
  };

  const toggleRemoveImgIDs = (key) => {
    let modifiedImgIDs = [];

    if (removeImgIDs.includes(key)) {
      modifiedImgIDs = removeImgIDs.filter((currentID) => currentID !== key);
    } else {
      modifiedImgIDs = [...removeImgIDs, key];
    }

    setRemoveImgIDs(modifiedImgIDs);
  };

  const clearRemoveImgIDs = (key) => {
    if (removeImgIDs.includes(key)) {
      setRemoveImgIDs(removeImgIDs.filter((currentID) => currentID !== key));
    }
  };

  const onDelete = () => {
    deleteUploads({
      fileIds: removeImgIDs,
    });
  };

  const onApply = () => {
    setSelectedImg(selectedImgIDs[0]);
    onClose();
  };

  return (
    <>
      <TemplatePopup onClose={onClose} title="Bild von Galerie auswählen" className="gallery">
        <ButtonClear onEvent={onClose} />

        <div className="right-side">
          {!!removeCount && <Selected count={removeCount} onTrash={onDelete} />}

          <div className="sort">
            <p className="name">
              <IconSort />
              Sortieren:
            </p>
            <Dropdown2
              select={util.getSort.de(sortValue)}
              setSelect={({ name }) => setSortValue(util.getSort.en(name))}
              content={dropdownContent}
            />
          </div>
        </div>

        <Tabs list={tabsList} activeTabID={activeTabID} setActiveTabID={setActiveTabID} />

        <ImgList
          visible={activeTabID === 1}
          list={budsImg}
          selectedImgIDs={selectedImgIDs}
          removeImgIDs={removeImgIDs}
          toggleSelectedImgIDs={toggleSelectedImgIDs}
          toggleRemoveImgIDs={toggleRemoveImgIDs}
          removeImg={(fileId) => deleteUploads({ fileIds: [fileId] })}
        />
        <ImgList
          visible={activeTabID === 2}
          list={extractsImg}
          selectedImgIDs={selectedImgIDs}
          removeImgIDs={removeImgIDs}
          toggleSelectedImgIDs={toggleSelectedImgIDs}
          toggleRemoveImgIDs={toggleRemoveImgIDs}
          removeImg={(fileId) => deleteUploads({ fileIds: [fileId] })}
        />
        <ImgList
          visible={activeTabID === 3}
          list={otherImg}
          selectedImgIDs={selectedImgIDs}
          removeImgIDs={removeImgIDs}
          toggleSelectedImgIDs={toggleSelectedImgIDs}
          toggleRemoveImgIDs={toggleRemoveImgIDs}
          removeImg={(fileId) => deleteUploads({ fileIds: [fileId] })}
        />

        <div className="btns">
          <Button title="Schließen" width="129" color="gray" onEvent={onClose} />
          <Button
            title="Apply"
            width="101"
            color="green"
            disabled={selectedCount === 0 || selectedCount > 1 || removeCount}
            onEvent={onApply}
          />
        </div>
      </TemplatePopup>

      {(statusGetUploads === 'pending' || statusDeleteUploads === 'pending') && <Loader type="transparent" />}
    </>
  );
};

export default GalleryPopup;

const ImgList = ({
  visible,
  list,
  selectedImgIDs,
  removeImgIDs,
  toggleSelectedImgIDs,
  toggleRemoveImgIDs,
  removeImg,
}) => {
  return (
    <TabContent visible={visible}>
      <ul className="img-list">
        {list?.map((item) => {
          const isSelect = selectedImgIDs.includes(item.key);

          return (
            <li className={`img-item ${isSelect ? 'selected' : ''}`} key={item.key}>
              {isSelect && (
                <SelectedIcon
                  isChecked={removeImgIDs.includes(item.key)}
                  onToggle={() => toggleRemoveImgIDs(item.key)}
                  removeImg={() => removeImg(item.key)}
                />
              )}
              <p className="img" onClick={() => toggleSelectedImgIDs(item.key)}>
                <img src={`https://${variable.url}/uploads/${item.key}`} alt="" />
              </p>
              <p className="title">{item.name}</p>
            </li>
          );
        })}
      </ul>
    </TabContent>
  );
};

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.manufacturer.buds.getManufacturerBuds.getSuccess, ({ getManufacturerBuds }, action) => {
      getManufacturerBuds.status = 'success';
      getManufacturerBuds.data = action.payload;
    })
    .addCase(action.request.manufacturer.buds.getManufacturerBuds.getPending, ({ getManufacturerBuds }, action) => {
      getManufacturerBuds.status = 'pending';
    })
    .addCase(action.request.manufacturer.buds.getManufacturerBuds.getFailed, ({ getManufacturerBuds }, action) => {
      getManufacturerBuds.status = 'failed';
      getManufacturerBuds.error = action.payload;
      getManufacturerBuds.data = null;
    });
  builder
    .addCase(action.request.manufacturer.buds.getManufacturerBudsId.getSuccess, ({ getManufacturerBudsId }, action) => {
      getManufacturerBudsId.status = 'success';
      getManufacturerBudsId.data = action.payload;
    })
    .addCase(action.request.manufacturer.buds.getManufacturerBudsId.getPending, ({ getManufacturerBudsId }, action) => {
      getManufacturerBudsId.status = 'pending';
    })
    .addCase(action.request.manufacturer.buds.getManufacturerBudsId.getFailed, ({ getManufacturerBudsId }, action) => {
      getManufacturerBudsId.status = 'failed';
      getManufacturerBudsId.error = action.payload;
      getManufacturerBudsId.data = null;
    });
  builder
    .addCase(
      action.request.manufacturer.buds.patchManufacturerBudsId.patchSuccess,
      ({ patchManufacturerBudsId }, action) => {
        patchManufacturerBudsId.status = 'success';
        patchManufacturerBudsId.data = action.payload;
      },
    )
    .addCase(
      action.request.manufacturer.buds.patchManufacturerBudsId.patchPending,
      ({ patchManufacturerBudsId }, action) => {
        patchManufacturerBudsId.status = 'pending';
      },
    )
    .addCase(
      action.request.manufacturer.buds.patchManufacturerBudsId.patchFailed,
      ({ patchManufacturerBudsId }, action) => {
        patchManufacturerBudsId.status = 'failed';
        patchManufacturerBudsId.error = action.payload;
        patchManufacturerBudsId.data = null;
      },
    );
});

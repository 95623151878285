export const uploads = {
  getUploadIdSuccess: 'request/getUploadIdSuccess',
  getUploadIdPending: 'request/getUploadIdPending',
  getUploadIdFailed: 'request/getUploadIdFailed',

  getUploadsSuccess: 'request/getUploadsSuccess',
  getUploadsPending: 'request/getUploadsPending',
  getUploadsFailed: 'request/getUploadsFailed',

  postUploadsSuccess: 'request/postUploadsSuccess',
  postUploadsPending: 'request/postUploadsPending',
  postUploadsFailed: 'request/postUploadsFailed',

  deleteUploadsSuccess: 'request/deleteUploadsSuccess',
  deleteUploadsPending: 'request/deleteUploadsPending',
  deleteUploadsFailed: 'request/deleteUploadsFailed',

  resetUploadData: 'local/resetUploadData',
};

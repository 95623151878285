import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      action.request.pharmacie.extracts.getPharmaciesExtracts.getSuccess,
      ({ getPharmaciesExtracts }, action) => {
        getPharmaciesExtracts.status = 'success';
        getPharmaciesExtracts.data = action.payload;
      },
    )
    .addCase(
      action.request.pharmacie.extracts.getPharmaciesExtracts.getPending,
      ({ getPharmaciesExtracts }, action) => {
        getPharmaciesExtracts.status = 'pending';
      },
    )
    .addCase(action.request.pharmacie.extracts.getPharmaciesExtracts.getFailed, ({ getPharmaciesExtracts }, action) => {
      getPharmaciesExtracts.status = 'failed';
      getPharmaciesExtracts.error = action.payload;
      getPharmaciesExtracts.data = null;
    });
  builder
    .addCase(
      action.request.pharmacie.extracts.getPharmaciesExtractsId.getSuccess,
      ({ getPharmaciesExtractsId }, action) => {
        getPharmaciesExtractsId.status = 'success';
        getPharmaciesExtractsId.data = action.payload;
      },
    )
    .addCase(
      action.request.pharmacie.extracts.getPharmaciesExtractsId.getPending,
      ({ getPharmaciesExtractsId }, action) => {
        getPharmaciesExtractsId.status = 'pending';
      },
    )
    .addCase(
      action.request.pharmacie.extracts.getPharmaciesExtractsId.getFailed,
      ({ getPharmaciesExtractsId }, action) => {
        getPharmaciesExtractsId.status = 'failed';
        getPharmaciesExtractsId.error = action.payload;
        getPharmaciesExtractsId.data = null;
      },
    );
});

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.uploads.getUploadId.getSuccess, ({ getUploadId }, action) => {
      getUploadId.status = 'success';
      getUploadId.data = action.payload;
    })
    .addCase(action.request.uploads.getUploadId.getPending, ({ getUploadId }, action) => {
      getUploadId.status = 'pending';
    })
    .addCase(action.request.uploads.getUploadId.getFailed, ({ getUploadId }, action) => {
      getUploadId.status = 'failed';
      getUploadId.error = action.payload;
      getUploadId.data = null;
    });

  builder
    .addCase(action.request.uploads.getUploads.getSuccess, ({ getUploads }, action) => {
      getUploads.status = 'success';
      getUploads.data = action.payload;
    })
    .addCase(action.request.uploads.getUploads.getPending, ({ getUploads }, action) => {
      getUploads.status = 'pending';
    })
    .addCase(action.request.uploads.getUploads.getFailed, ({ getUploads }, action) => {
      getUploads.status = 'failed';
      getUploads.error = action.payload;
      getUploads.data = null;
    });

  builder
    .addCase(action.request.uploads.postUploads.postSuccess, ({ postUploads }, action) => {
      postUploads.status = 'success';
      postUploads.data = action.payload;
    })
    .addCase(action.request.uploads.postUploads.postPending, ({ postUploads }, action) => {
      postUploads.status = 'pending';
    })
    .addCase(action.request.uploads.postUploads.postFailed, ({ postUploads }, action) => {
      postUploads.status = 'failed';
      postUploads.error = action.payload;
      postUploads.data = null;
    });

  builder
    .addCase(action.request.uploads.deleteUploads.deleteSuccess, ({ deleteUploads }, action) => {
      deleteUploads.status = 'success';
      deleteUploads.data = action.payload;
    })
    .addCase(action.request.uploads.deleteUploads.deletePending, ({ deleteUploads }, action) => {
      deleteUploads.status = 'pending';
    })
    .addCase(action.request.uploads.deleteUploads.deleteFailed, ({ deleteUploads }, action) => {
      deleteUploads.status = 'failed';
      deleteUploads.error = action.payload;
      deleteUploads.data = null;
    });

  builder.addCase(action.request.uploads.resetUploadData, (state) => {
    state.postUploads = initialState.postUploads;
    state.deleteUploads = initialState.deleteUploads;
    state.getUploads = initialState.getUploads;
    state.getUploadId = initialState.getUploadId;
  });
});

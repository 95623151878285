import React from 'react';

import './style.scss';

import { Portal } from '../../../HOC';

const Loader = ({ type = '' }) => {
  return (
    <Portal>
      <div className={`background-loader ${type}`}>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </Portal>
  );
};

export default Loader;

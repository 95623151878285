import React from 'react';
import { useOnOutsideClick } from '../../../hooks';

import './styles.scss';

const ManufactureInfoPopup = ({ isShowCompanyInfo, setIsShowCompanyInfo, description }) => {
  return (
    <span className={`info-popup ${isShowCompanyInfo ? 'show' : 'hide'}`}>
      <strong>Über das Unternehmen</strong>
      <br />
      {description}
    </span>
  );
};

export default ManufactureInfoPopup;

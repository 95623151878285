import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(action.app.prescription.addToPrescription, (state, action) => {
    state.items.push({ ...action.payload, dosage: null, form: null, amount: null, concentration: 1 });
  });

  builder.addCase(action.app.prescription.savePatientInfo, (state, action) => {
    state.patientInfo = {
      ...state.patientInfo,
      fullPrescription: state.patientInfo.fullPrescription,
      ...action.payload,
    };
  });

  builder.addCase(action.app.prescription.clearPatientInfo, (state, action) => {
    if (action.payload) {
      state.patientInfo = {
        ...initialState.patientInfo,
        doctorId: state.patientInfo.doctorId,
        fullPrescription: state.patientInfo.fullPrescription || [],
      };
    } else {
      state.patientInfo = { ...initialState.patientInfo, fullPrescription: state.patientInfo.fullPrescription || [] };
    }
  });

  builder.addCase(action.app.prescription.removeFromPrescription, (state, action) => {
    state.items = state.items.filter(({ id, type }) => id !== action.payload.id || type !== action.payload.type);
  });

  builder.addCase(action.app.prescription.upItemInPrescription, (state, action) => {
    const index = state.items.findIndex(({ id, type }) => id === action.payload.id && type === action.payload.type);
    if (index <= 0) return;

    [state.items[index - 1], state.items[index]] = [state.items[index], state.items[index - 1]];
  });

  builder.addCase(action.app.prescription.downItemInPrescription, (state, action) => {
    const index = state.items.findIndex(({ id, type }) => id === action.payload.id && type === action.payload.type);
    if (index === -1 || index === state.items.length - 1) return;

    [state.items[index], state.items[index + 1]] = [state.items[index + 1], state.items[index]];
  });

  builder.addCase(action.app.prescription.updateItemInPrescription, (state, action) => {
    const index = state.items.findIndex(({ id, type }) => id === action.payload.id && type === action.payload.type);
    if (index === -1) return;

    state.items[index] = action.payload;
  });

  builder.addCase(action.app.prescription.removeAllProducts, (state) => {
    state.items = [];
  })

});

export const pharmacies = {
  getPharmaciesAllSuccess: 'request/getPharmaciesAllSuccess',
  getPharmaciesAllPending: 'request/getPharmaciesAllPending',
  getPharmaciesAllFailed: 'request/getPharmaciesAllFailed',

  getPharmacyIdSuccess: 'request/getPharmacyIdSuccess',
  getPharmacyIdPending: 'request/getPharmacyIdPending',
  getPharmacyIdFailed: 'request/getPharmacyIdFailed',

  patchPharmacyIdSuccess: 'request/patchPharmacyIdSuccess',
  patchPharmacyIdPending: 'request/patchPharmacyIdPending',
  patchPharmacyIdFailed: 'request/patchPharmacyIdFailed',

  deletePharmacyIdSuccess: 'request/deletePharmacyIdSuccess',
  deletePharmacyIdPending: 'request/deletePharmacyIdPending',
  deletePharmacyIdFailed: 'request/deletePharmacyIdFailed',

  getPharmaciesSuccess: 'request/getPharmaciesSuccess',
  getPharmaciesPending: 'request/getPharmaciesPending',
  getPharmaciesFailed: 'request/getPharmaciesFailed',

  deletePharmaciesSuccess: 'request/deletePharmaciesSuccess',
  deletePharmaciesPending: 'request/deletePharmaciesPending',
  deletePharmaciesFailed: 'request/deletePharmaciesFailed',

  // ------------------------------------------------------

  getPharmaciesArchievedSuccess: 'request/getPharmaciesArchievedSuccess',
  getPharmaciesArchievedPending: 'request/getPharmaciesArchievedPending',
  getPharmaciesArchievedFailed: 'request/getPharmaciesArchievedFailed',

  deletePharmaciesArchievedSuccess: 'request/deletePharmaciesArchievedSuccess',
  deletePharmaciesArchievedPending: 'request/deletePharmaciesArchievedPending',
  deletePharmaciesArchievedFailed: 'request/deletePharmaciesArchievedFailed',
};

export const user = {
  getManufaсturerSuccess: 'request/getManufaсturerSuccess',
  getManufaсturerPending: 'request/getManufaсturerPending',
  getManufaсturerFailed: 'request/getManufaсturerFailed',

  putManufacturerSuccess: 'request/putManufacturerSuccess',
  putManufacturerPending: 'request/putManufacturerPending',
  putManufacturerFailed: 'request/putManufacturerFailed',

  postManufacturerMailSuccess: 'request/postManufacturerMailSuccess',
  postManufacturerMailPending: 'request/postManufacturerMailPending',
  postManufacturerMailFailed: 'request/postManufacturerMailFailed',
};

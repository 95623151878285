export const getStatusOrder = {
  en: (data) => {
    let status = '';

    switch (data) {
      case 'neu':
        status = 'new';
        break;
      case 'abgeschlossen':
        status = 'closed';
        break;
      default:
        status = '';
    }

    return status;
  },

  de: (data) => {
    let status = '';

    switch (data) {
      case 'new':
        status = 'neu';
        break;
      case 'closed':
        status = 'abgeschlossen';
        break;
      default:
        status = '';
    }

    return status;
  },
};

export const state = {
  getBudsAll: {
    data: (state) => state.request.admin.buds.getBudsAll.data,
    status: (state) => state.request.admin.buds.getBudsAll.status,
    error: (state) => state.request.admin.buds.getBudsAll.error,
  },

  getBudId: {
    data: (state) => state.request.admin.buds.getBudId.data,
    status: (state) => state.request.admin.buds.getBudId.status,
    error: (state) => state.request.admin.buds.getBudId.error,
  },

  patchBudId: {
    data: (state) => state.request.admin.buds.patchBudId.data,
    status: (state) => state.request.admin.buds.patchBudId.status,
    error: (state) => state.request.admin.buds.patchBudId.error,
  },

  deleteBudId: {
    data: (state) => state.request.admin.buds.deleteBudId.data,
    status: (state) => state.request.admin.buds.deleteBudId.status,
    error: (state) => state.request.admin.buds.deleteBudId.error,
  },

  getBuds: {
    data: (state) => state.request.admin.buds.getBuds.data,
    status: (state) => state.request.admin.buds.getBuds.status,
    error: (state) => state.request.admin.buds.getBuds.error,
  },

  postBuds: {
    data: (state) => state.request.admin.buds.postBuds.data,
    status: (state) => state.request.admin.buds.postBuds.status,
    error: (state) => state.request.admin.buds.postBuds.error,
  },

  deleteBuds: {
    data: (state) => state.request.admin.buds.deleteBuds.data,
    status: (state) => state.request.admin.buds.deleteBuds.status,
    error: (state) => state.request.admin.buds.deleteBuds.error,
  },
};

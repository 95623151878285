export const state = {
  getManufacturerBuds: {
    data: (state) => state.request.manufacturer.buds.getManufacturerBuds.data,
    status: (state) => state.request.manufacturer.buds.getManufacturerBuds.status,
    error: (state) => state.request.manufacturer.buds.getManufacturerBuds.error,
  },

  getManufacturerBudsId: {
    data: (state) => state.request.manufacturer.buds.getManufacturerBudsId.data,
    status: (state) => state.request.manufacturer.buds.getManufacturerBudsId.status,
    error: (state) => state.request.manufacturer.buds.getManufacturerBudsId.error,
  },

  patchManufacturerBudsId: {
    data: (state) => state.request.manufacturer.buds.patchManufacturerBudsId.data,
    status: (state) => state.request.manufacturer.buds.patchManufacturerBudsId.status,
    error: (state) => state.request.manufacturer.buds.patchManufacturerBudsId.error,
  },
};

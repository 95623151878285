import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { usePageUp, useChangeEffect } from '../../../hooks';

import Header from '../../../components/header';
import Tabs from '../../../components/UI/tabs';
import EditContent from './tabs/editContent';
import MailTemplates from './tabs/mailTemplates';
import RecipeSettings from './tabs/recipeSettings';
import Design from './tabs/design';
import Loader from '../../../components/popups/loader';
import { useDefaultPerPage } from '../../../hooks/useDefaultPerPage';

const Settings = () => {
  const dispatch = useDispatch();

  const statusGetMailTemplates = useSelector(reducer.request.mailTemplates.state.getMailTemplates.status);
  const statusGetSettings = useSelector(reducer.request.setting.state.getSettings.status);

  const getMailTemplates = (start, limit) => dispatch(action.request.mailTemplates.getMailTemplates.get(start, limit));
  const getSettings = () => dispatch(action.request.settings.getSettings.get());

  const { contentPerPage: mail } = useDefaultPerPage('superuserMailTemplates');

  const [isShowLoader, setShowLoader] = useState(true);
  const [activeTabID, setActiveTabID] = useState(1);

  const isRequestGETSuccess = statusGetSettings === 'success' && statusGetMailTemplates === 'success';

  const tabsList = [
    {
      id: 1,
      name: 'Inhalte bearbeiten',
    },
    {
      id: 2,
      name: 'E-Mail-Vorlagen',
    },
    {
      id: 3,
      name: 'Rezepteinstellungen',
    },
    {
      id: 4,
      name: 'Design',
    },
  ];

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  useEffect(() => {
    getSettings();
    getMailTemplates(0, mail);
  }, [mail]);

  usePageUp();

  return (
    <>
      <Header />

      <main className={`settings-page page ${!isShowLoader ? 'show' : ''}`}>
        <div className="container">
          <h1 className="page-title">Einstellungen</h1>
          <Tabs list={tabsList} activeTabID={activeTabID} setActiveTabID={setActiveTabID} />

          <EditContent visible={activeTabID === 1} />
          <MailTemplates visible={activeTabID === 2} isShowLoader={isShowLoader} />
          <RecipeSettings visible={activeTabID === 3} />
          <Design visible={activeTabID === 4} />
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default Settings;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import QueryString from 'qs';
import { useMemo } from 'react';

import * as action from '../../store/action';
import * as reducer from '../../store/reducer';

import TemplatePopup from '../templatePopup';
import Button from '../UI/button';
import Loader from '../popups/loader';
import CartInfoRow from '../cartInfoRow';

import './style.scss';
import { useLayoutEffect } from 'react';
import { Portal } from '../../HOC';
import BackgroundPopup from '../templatePopup/backgroundPopup';
import ButtonClear from '../UI/buttonClear';

const CartInfoPopup = ({ isOpenCart, setIsOpenCart }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataProducts = useSelector(reducer.request.pharmacie.products.state.getPharmaciesProducts.data);
  const dataStatus = useSelector(reducer.request.pharmacie.products.state.getPharmaciesProducts.status);
  const dataCart = useSelector(reducer.app.cart.state.items);

  const [separetedProducts, setSeparetedProducts] = useState();

  const params = useMemo(
    () =>
      Object.keys(dataCart)
        .reduce((acc, curr) => {
          acc = [...acc, dataCart[curr].map((item) => ({ id: item.id, type: item.type }))];
          return acc;
        }, [])
        .flat(),
    [dataCart],
  );

  const products = QueryString.stringify({ products: params });

  const getPharmaciesProducts = (params) =>
    isOpenCart && dispatch(action.request.pharmacie.products.getPharmaciesProducts.get(params));

  useLayoutEffect(() => {
    setSeparetedProducts(() => {
      if (dataProducts) {
        return {
          buds: [...dataProducts?.filter((prod) => prod.type === 'bud')]
            .map((item) => {
              const isExist = dataCart['bud'].some((cartItem) => cartItem.id === item.id);

              return (
                isExist && {
                  ...item,
                  selectedManufacturer: { ...dataCart['bud'].find((cartItem) => cartItem.id === item.id) },
                }
              );
            })
            .filter(Boolean),
          extracts: [...dataProducts?.filter((prod) => prod.type === 'extract')]
            .map((item) => {
              const isExist = dataCart['extract'].some((cartItem) => cartItem.id === item.id);

              return (
                isExist && {
                  ...item,
                  selectedManufacturer: { ...dataCart['extract'].find((cartItem) => cartItem.id === item.id) },
                }
              );
            })
            .filter(Boolean),
        };
      }
    });
  }, [dataProducts, dataCart]);

  useLayoutEffect(() => {
    getPharmaciesProducts(products);
  }, [isOpenCart]);

  useEffect(() => {
    isOpenCart &&
      Object.keys(separetedProducts).every((item) => !separetedProducts[item].length) &&
      setIsOpenCart(false);
  }, [separetedProducts]);

  return (
    <>
      {dataStatus === 'pending' && <Loader type="transparent" />}
      {isOpenCart && (
        <Portal>
          <BackgroundPopup onClose={() => setIsOpenCart(false)} />
          <div className="popup-container">
            <section className="popup-section">
              <div className="title-container">
                <ButtonClear onEvent={() => setIsOpenCart(false)} />
              </div>
              <div className="cart-popup-container">
                <div className="cart-popup-header">
                  <h1 className="cart-popup-header-title">Auswahl des Lieferanten</h1>
                  <p className="cart-popup-header-under-title">
                    Das von Ihnen ausgewählte Produkt wird von mehreren Lieferanten vertrieben. Hier können Sie
                    entscheiden, welcher Lieferant Sie beliefern soll. (Mehrfachauswahl möglich)
                  </p>
                </div>
                {!!separetedProducts?.buds.length && (
                  <>
                    <p className="cart-popup-type-tile">Blüten ({separetedProducts?.buds.length})</p>
                    <ul className="cart-popup-items-wrapper">
                      {separetedProducts?.buds.map((item) => (
                        <CartInfoRow key={item?.id} data={item} separetedProducts={separetedProducts} />
                      ))}
                    </ul>
                  </>
                )}
                {!!separetedProducts?.extracts.length && (
                  <>
                    <p className="cart-popup-type-tile">Extrakte ({separetedProducts?.extracts.length})</p>
                    <ul className="cart-popup-items-wrapper">
                      {separetedProducts?.extracts.map((item) => (
                        <CartInfoRow key={item?.id} data={item} separetedProducts={separetedProducts} />
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </section>
            <div className="fixed-element">
              <Button onEvent={() => setIsOpenCart(false)} color="gray" title="Abbrechen" width={135} />
              <Button onEvent={() => navigate('/pre-order')} color="green" title="Bestätigen" width={135} />
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default CartInfoPopup;

import React from 'react';

import './style.scss';

const Switcher = ({ label, name, isChecked, onChange }) => {
  return (
    <div className="default-switcher">
      {label && <p className="label">{label}</p>}
      <label className="switcher">
        <input type="checkbox" name={name} checked={isChecked} onChange={onChange} />
        <span className="slider"></span>
        <p className="variant no">Nein</p>
        <p className="variant yes">Ja</p>
      </label>
    </div>
  );
};

export default Switcher;

import React, { useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './style.scss';

import { prescriptionItems, individualConcentrationPrescriptionItems } from './variables';

import { useOnOutsideClick } from '../../../../../hooks';

import DragAndDropItem from './components/dragAndDropItem';

const PrescriptionItems = ({ label, data, setData, individualConcentration }) => {
  const [showDropdown, setShowDropDown] = useState(false);
  const { innerBorderRef } = useOnOutsideClick(() => setShowDropDown(false));

  const onAdd = (item) => {
    setData((prevData) => ({ ...prevData, prescription: [...prevData.prescription, item] }));
  };

  const renderItem = useCallback((item, index) => {
    return <DragAndDropItem key={index} item={item} index={index} data={data} setData={setData} />;
  }, []);

  return (
    <div className="prescription-items" ref={innerBorderRef}>
      <p className="label">{label}</p>
      <DndProvider backend={HTML5Backend}>
        <div className={`selected-field ${showDropdown ? 'open' : ''}`}>
          {data.prescription.map((item, index) => renderItem(item, index))}
          <button className="dropdown-btn" onClick={() => setShowDropDown(!showDropdown)}></button>
        </div>
      </DndProvider>

      <ul className={`content ${showDropdown ? 'show' : 'hide'}`}>
        {(individualConcentration? individualConcentrationPrescriptionItems : prescriptionItems).map((item) => (
          <li className="item" key={item.title} onClick={() => onAdd(item)}>
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PrescriptionItems;

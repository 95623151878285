import { createAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import * as api from '../../../../../api';
import * as type from '../../../../type';
import * as action from '../../..';

export const postPharmaciesOrders = {
  post: function (navigate, data) {
    return (dispatch) => {
      dispatch(this.postPending());

      api.pharmacie.orders
        .postPharmaciesOrders(data)
        .then((response) => {
          if (!response.ok) {
            return Promise.reject(response);
          }
          response.json().then((json) => {
            dispatch(this.postSuccess(json));
            toast.success('Bestellanfrage erfolgreich erstellt');
            navigate('/orders/' + json.id);
            setTimeout(() => dispatch(action.app.cart.clearCart()), 0);
          });
        })
        .catch((error) => {
          if (error.status === 403 || error.status === 404) {
            window.location = '/';
          }
          if (error.status === 401) {
            dispatch(action.request.auth.getRefresh.get(this.post(data)));
          } else {
            dispatch(this.postFailed(error));
            toast.error('Bestellanfrage konnte nicht erstellt werden');
          }
        });
    };
  },

  postSuccess: createAction(type.request.pharmacie.orders.postPharmaciesOrdersSuccess),
  postPending: createAction(type.request.pharmacie.orders.postPharmaciesOrdersPending),
  postFailed: createAction(type.request.pharmacie.orders.postPharmaciesOrdersFailed),
};

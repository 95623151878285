import React, { useEffect, useState } from 'react';
import Field from '../../../UI/field';

const ProductSelectAmount = (props) => {
  const [amountError, setAmountError] = useState({
    minOrderAmount: null,
    maxOrderAmount: null,
    availableAmount: null,
  });

  useEffect(() => {
    if (parseInt(props?.minOrderAmount) > parseInt(props?.maxOrderAmount)) {
      setAmountError({
        minOrderAmount: 'Mindestmenge ist zu groß',
        maxOrderAmount: 'Maximalmenge ist zu klein',
        availableAmount: null,
      });
      !props?.errorId.some((id) => id === props.id) && props.setErrorId([...props?.errorId, props.id]);
    } else {
      setAmountError({
        minOrderAmount: null,
        maxOrderAmount: null,
        availableAmount: null,
      });
      props?.errorId.some((id) => id === props.id) &&
        props.setErrorId([...props?.errorId.filter((item) => item !== props.id)]);
    }
  }, [props?.id, props?.minOrderAmount, props?.maxOrderAmount, props?.errorId]);

  return (
    <>
      <Field
        error={amountError.minOrderAmount}
        label="Mindestbestellmenge (Verpackungseinheit) (g)"
        type="text"
        name="minOrderAmount"
        placeholder="0.00"
        value={props.minOrderAmount || ''}
        onEvent={(event) => props.onSetValue(props.id, event)}
      />
      <Field
        error={amountError.maxOrderAmount}
        label="Maximalbestellmenge pro Apotheke (g)"
        type="text"
        name="maxOrderAmount"
        placeholder="0.00"
        value={props.maxOrderAmount || ''}
        onEvent={(event) => props.onSetValue(props.id, event)}
      />
      <Field
        error={amountError.availableAmount}
        label="Restmenge (aktuell lieferbar) (g)"
        type="text"
        name="availableAmount"
        placeholder="0.00"
        value={props.availableAmount || ''}
        onEvent={(event) => props.onSetValue(props.id, event)}
      />
    </>
  );
};

export default ProductSelectAmount;

export const terpenes = {
  getTerpenesAllSuccess: 'request/getTerpenesAllSuccess',
  getTerpenesAllPending: 'request/getTerpenesAllPending',
  getTerpenesAllFailed: 'request/getTerpenesAllFailed',

  getTerpeneIdSuccess: 'request/getTerpeneIdSuccess',
  getTerpeneIdPending: 'request/getTerpeneIdPending',
  getTerpeneIdFailed: 'request/getTerpeneIdFailed',

  putTerpeneIdSuccess: 'request/putTerpeneIdSuccess',
  putTerpeneIdPending: 'request/putTerpeneIdPending',
  putTerpeneIdFailed: 'request/putTerpeneIdFailed',

  deleteTerpeneIdSuccess: 'request/deleteTerpeneIdSuccess',
  deleteTerpeneIdPending: 'request/deleteTerpeneIdPending',
  deleteTerpeneIdFailed: 'request/deleteTerpeneIdFailed',

  postTerpenesSuccess: 'request/postTerpenesSuccess',
  postTerpenesPending: 'request/postTerpenesPending',
  postTerpenesFailed: 'request/postTerpenesFailed',

  resetPostData: 'request/resetPostData',
};

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useChangeEffect, useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import TableBuilder from '../../../components/tableBuilder';
import Button from '../../../components/UI/button';
import TemplatePopup from '../../../components/templatePopup';
import DropdownCount from '../../../components/dropdownCount';
import FieldSearch from '../../../components/UI/fieldSearch';
import Loader from '../../../components/popups/loader';
import AddProductPopup from '../../../components/popups/addProductPopup';

import { variables } from './variables';
import { useDefaultPerPage } from '../../../hooks/useDefaultPerPage';

const ManufacturerExtracts = () => {
  const dispatch = useDispatch();
  const isShow = useDelay(100);
  usePageUp();

  const persistName = 'manufacturerExtracts';

  const { contentPerPage, setContentPerPage } = useDefaultPerPage(persistName);

  const [isShowAddPopup, setShowAddPopup] = useState(false);
  const [isShowComplitePopup, setIsShowComplitePopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [valueSearch, setValueSearch] = useState('');
  const [isShowLoader, setShowLoader] = useState(true);

  const statusExtracts = useSelector(reducer.request.manufacturer.extracts.state.getManufacturerExtracts.status);
  const dataExtracts = useSelector(reducer.request.manufacturer.extracts.state.getManufacturerExtracts.data);

  const getManufacturerProduct = (start, limit, search, sort, order) =>
    dispatch(action.request.manufacturer.extracts.getManufacturerExtracts.get(start, limit, search, sort, order));

  const pageCount = Math.ceil(dataExtracts?.total / contentPerPage) || 1;
  const isRequestGETSuccess = statusExtracts === 'success';
  const sortKeys = ['status', 'type'];
  const tableTitles = ['№', 'Extrakte', 'Kultivar / Genetik', 'THC / CBD / CBN (%)', 'type', 'view', 'status'];

  const tableData = useMemo(() => {
    return dataExtracts?.items.reduce((acc, item, index) => {
      acc = [
        ...acc,
        {
          id: item.id,
          number: currentPage !== 1 ? (currentPage - 1) * contentPerPage + index + 1 : index + 1,
          product: item.name,
          'Kultivar / Genetik': item.genetics,
          'THC / CBD / CBN (%)': `${item.thc}/${item.cbd}/${item.cbn}`,
          type: item.exclusive && 'exclusive',
          view: item.imageKey,
          active: item.active,
        },
      ];

      return acc;
    }, []);
  }, [dataExtracts?.items]);

  const onClear = () => {
    setValueSearch('');

    if (currentPage === 1) {
      getManufacturerProduct(0, contentPerPage);
    } else {
      setCurrentPage(1);
    }
  };

  const onGoSearch = () => {
    if (currentPage === 1) {
      getManufacturerProduct(0, contentPerPage, valueSearch);
    } else {
      setCurrentPage(1);
    }
  };

  const onChangeStatus = (active, id) => {
    dispatch(action.request.manufacturer.extracts.patchManufacturerExtractsId.patch(id, { active }));
  };

  const onSortHandler = (key) => {
    const typeOrder = sessionStorage.getItem('typeOrder');
    const firstContentIndex = currentPage ? (currentPage - 1) * contentPerPage : 0;
    getManufacturerProduct(firstContentIndex, contentPerPage, valueSearch, key, typeOrder);
  };

  useEffect(() => {
    const firstContentIndex = currentPage ? (currentPage - 1) * contentPerPage : 0;
    getManufacturerProduct(firstContentIndex, contentPerPage, valueSearch);
  }, [contentPerPage, currentPage]);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  return (
    <>
      <Header />
      <main className={`manufacturers-page page ${isShow ? 'show' : ''}`}>
        <div className="container">
          <h1 className="page-title">Meine Extrakte</h1>

          <TableBuilder
            className="manufacturer-table"
            path="edit"
            contentPerPage={contentPerPage}
            title={tableTitles}
            data={tableData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCount={pageCount}
            isCanSelect={false}
            sortKeys={sortKeys}
            pagination="type2"
            onChangeStatus={onChangeStatus}
            innerTableBtn={
              <Button title="Produkt hinzufügen" onEvent={() => setShowAddPopup(true)} width="173" color="green" />
            }
            valueSearch={valueSearch}
            onSortHandler={onSortHandler}>
            <div className="table-header">
              <ul className="left-side">
                <li className="elem">
                  <FieldSearch
                    value={valueSearch}
                    setValue={(event) => setValueSearch(event.target.value)}
                    onClear={onClear}
                    onGoSearch={onGoSearch}
                  />
                </li>
                <li className="elem">
                  <DropdownCount
                    contentPerPage={contentPerPage}
                    data={variables.dropdownContent}
                    onSetContentPerPage={setContentPerPage}
                    setCurrentPage={() => setCurrentPage(1)}
                    persistName={persistName}
                  />
                </li>
              </ul>

              <ul className="right-side">
                <li className="elem">
                  <Button title="Produkt hinzufügen" onEvent={() => setShowAddPopup(true)} width="173" color="green" />
                </li>
              </ul>
            </div>
          </TableBuilder>
        </div>
      </main>
      {isShowAddPopup && (
        <AddProductPopup
          title="Neue Extrakte anfragen"
          onClose={() => setShowAddPopup(false)}
          setIsShowComplitePopup={setIsShowComplitePopup}
          type="extract"
        />
      )}
      {isShowComplitePopup && (
        <TemplatePopup showCloseButton={true} onClose={() => setIsShowComplitePopup(false)} className="add-new-product">
          <div className="add-new-product-content success">
            <h1 className="add-new-product-title">Danke für Ihre Nachricht</h1>
            <p className="add-new-product-text">Unser Team wird Ihre Produkt schnellstmöglich hinzufügen</p>
          </div>
        </TemplatePopup>
      )}

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default ManufacturerExtracts;

export const buds = {
  getManufacturerBudsSuccess: 'request/getManufacturerBudsSuccess',
  getManufacturerBudsPending: 'request/getManufacturerBudsPending',
  getManufacturerBudsFailed: 'request/getManufacturerBudsFailed',

  getManufacturerBudsIdSuccess: 'request/getManufacturerBudsIdSuccess',
  getManufacturerBudsIdPending: 'request/getManufacturerBudsIdPending',
  getManufacturerBudsIdFailed: 'request/getManufacturerBudsIdFailed',

  patchManufacturerBudsIdSuccess: 'request/patchManufacturerBudsIdSuccess',
  patchManufacturerBudsIdPending: 'request/patchManufacturerBudsIdPending',
  patchManufacturerBudsIdFailed: 'request/patchManufacturerBudsIdFailed',
};

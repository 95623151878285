import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useChangeEffect, useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import Loader from '../../../components/popups/loader';
import Form from '../../../components/UI/form/Form.jsx';
import Button from '../../../components/UI/button';
import FormInput from '../../../components/UI/form/formInput/FormInput';
import Checkbox from '../../../components/UI/checkbox';
import OpeningHours from '../../../components/openingHours';
import UploadImgDragDrop from '../../../components/uploadImgDragDrop';
import UploadFiles from '../../../components/uploadFiles/UploadFiles';

import { PharmacieEditSchema, PharmacieEditFullSchema } from '../../../components/UI/form/validation';

import { getFormatedPharmacieData } from '../../../utils/getFormatedPharmacieData';
import { useLayoutEffect } from 'react';

const PharmacieSettings = () => {
  const dispatch = useDispatch();

  const leftColumnRef = useRef();
  const uploadImgRef = useRef();
  const groupBtnRef = useRef();
  const navigate = useNavigate();
  const isShow = useDelay(100);

  const typeUploadFiles = 'other';

  const statusPharmacie = useSelector(reducer.request.pharmacie.user.state.getPharmaciesUser.status);
  const statusUpload = useSelector(reducer.request.uploads.state.postUploads.status);
  const statusDelete = useSelector(reducer.request.uploads.state.deleteUploads.status);

  const dataPharmacie = useSelector(reducer.request.pharmacie.user.state.getPharmaciesUser.data);
  const dataUpload = useSelector(reducer.request.uploads.state.postUploads.data);
  const isDataChages = useSelector(reducer.app.common.state.isDataChages);

  const setDataChage = (isChange) => dispatch(action.app.common.isDataChages(isChange));
  const getPharmaciesUser = () => dispatch(action.request.pharmacie.user.getPharmaciesUser.get());
  const patchPharmaciesUser = (data) => dispatch(action.request.pharmacie.user.patchPharmaciesUser.patch(data));
  const postUploads = (data) => dispatch(action.request.uploads.postUploads.post(data));
  const resetUploadData = () => dispatch(action.request.uploads.resetUploadData());
  const deleteUpload = (filesIdsArray) => dispatch(action.request.uploads.deleteUploads.delete(filesIdsArray));

  usePageUp();

  const [newData, setNewData] = useState();
  const [attachment, setAttachment] = useState([]);
  const [logo, setLogo] = useState();
  const [isSameAddresses, setIsSameAddresses] = useState();
  const [hours, setHours] = useState({
    openingHours: {
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
    },
  });
  const [isShowLoader, setShowLoader] = useState(true);

  const isRequestGETSuccess = statusPharmacie === 'success';

  const onAttachmentHandler = (type, file) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', type === 'logo' ? 'other' : typeUploadFiles);

    postUploads(formData);
  };

  const onDeleteAttachmentHandler = (type, key) => {
    if (window.confirm('Möchten Sie diese Datei wirklich löschen?')) {
      if (type === 'logo') {
        setLogo(null);
      } else {
        setAttachment(attachment.filter((item) => item.key !== key));
      }
      deleteUpload({ fileIds: [key] });
    }
  };

  const onCancel = () => {
    if (isDataChages) {
      if (window.confirm('Bestätigen Sie die Aktion auf der Seite. Die Änderungen zurücksetzen?')) {
        setDataChage(false);
        navigate('/buds');
      }
    } else {
      navigate('/buds');
    }
  };

  const onSubmit = useCallback(
    (data) => {
      patchPharmaciesUser({
        name: data.name,
        billingAddress: {
          zip: data.billingZip,
          city: data.billingCity,
          street: data.billingStreet,
          house: data.billingHouse,
        },
        deliveryAddress: {
          zip: !isSameAddresses ? data.deliveryZip : data.billingZip,
          city: !isSameAddresses ? data.deliveryCity : data.billingCity,
          street: !isSameAddresses ? data.deliveryStreet : data.billingStreet,
          house: !isSameAddresses ? data.deliveryHouse : data.billingHouse,
        },
        isSameAddresses,
        contactName: data.contactName,
        contactEmail: data.contactEmail,
        contactPhone: data.contactPhone,
        contactFax: data.contactFax,
        contactWeb: data.contactWeb,
        contactAddress: data.contactAddress,
        openingHours: hours.openingHours,
        files: attachment.map((item) => item.key),
        logo,
      });
      setDataChage(false);
      navigate('/dashboard');
    },
    [isSameAddresses, attachment, hours, logo],
  );

  useEffect(() => {
    getPharmaciesUser();
    setDataChage(false);
  }, []);

  useEffect(() => {
    if (dataPharmacie) {
      if (dataPharmacie.isSameAddresses !== isSameAddresses) {
        setDataChage(true);
        return;
      }
      if (newData && !_.isEqual(getFormatedPharmacieData(dataPharmacie, isSameAddresses), newData)) {
        setDataChage(true);
        return;
      }
      if (!_.isEqual(dataPharmacie?.openingHours, hours.openingHours)) {
        setDataChage(true);
        return;
      }
      if (!_.isEqual(attachment, dataPharmacie.files)) {
        setDataChage(true);
        return;
      }
      if (!_.isEqual(logo, dataPharmacie.logo)) {
        setDataChage(true);
        return;
      }
      setDataChage(false);

    }
  }, [newData, dataPharmacie, isSameAddresses, hours, attachment, logo]);

  useLayoutEffect(() => {
    if (dataPharmacie && isRequestGETSuccess) {
      setAttachment(dataPharmacie?.files);
      setLogo(dataPharmacie?.logo);
      setHours({ openingHours: { ...dataPharmacie?.openingHours } });
      setIsSameAddresses(!!dataPharmacie?.isSameAddresses);
    }
  }, [dataPharmacie, isRequestGETSuccess]);

  useLayoutEffect(() => {
    if (dataUpload) {
      const typeFile = dataUpload.split('.').pop();

      if (typeFile === 'jpg' || typeFile === 'jpeg' || typeFile === 'png') {
        setLogo(dataUpload);
      }
      resetUploadData();
    }
  }, [dataUpload]);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess, statusPharmacie]);

  return (
    <>
      <Header />
      <main className={`profile-edit-page page pharmacie-settings ${isShow ? 'show' : ''}`}>
        <div className="container">
          {!isShowLoader && (
            <Form
              defaultValues={getFormatedPharmacieData(dataPharmacie, isSameAddresses)}
              className="add-edit profile-form"
              onSubmit={onSubmit}
              resolver={yupResolver(isSameAddresses ? PharmacieEditSchema : PharmacieEditFullSchema)}
              onChange={(data) => setNewData({ ...data })}>
              <div className="left-side">
                <section className="section-wrapper" ref={leftColumnRef}>
                  <h2 className="title">
                    Apothekendetails<sup>*</sup>
                    <span>(Pflichtfeld)</span>
                  </h2>
                  <ul className="field-list-billing">
                    <li>Rechnungsadresse</li>
                    <li>
                      <FormInput name="name" type="text" label="Name" isRequired={true} />
                    </li>
                    <li>
                      <FormInput name="billingZip" type="number" label="PLZ" isRequired={true} />
                      <FormInput name="billingCity" type="text" label="Stadt" isRequired={true} />
                    </li>
                    <li>
                      <FormInput name="billingStreet" type="text" label="Straße" isRequired={true} />
                      <FormInput name="billingHouse" type="text" label="Hausnummer" isRequired={true} />
                    </li>
                  </ul>
                  <ul className="field-list-same-address">
                    <Checkbox
                      label="Rechnungs- und Lieferadresse sind gleich"
                      isChecked={isSameAddresses}
                      onChange={() => setIsSameAddresses(!isSameAddresses)}
                    />
                  </ul>
                  <ul className={`field-list-delivery ${isSameAddresses ? 'hide-field' : 'show-field'}`}>
                    <li>Lieferadresse</li>
                    <li className="s">
                      <FormInput name="deliveryZip" type="text" label="PLZ" isRequired={true} />
                      <FormInput name="deliveryCity" type="text" label="Stadt" isRequired={true} />
                    </li>
                    <li className="address-info-two">
                      <FormInput name="deliveryStreet" type="text" label="Straße" isRequired={true} />
                      <FormInput name="deliveryHouse" type="text" label="Hausnummer" isRequired={true} />
                    </li>
                  </ul>
                  <ul className="field-list-contact pharmacie-contact">
                    <li>Ansprechpartner</li>
                    <li>
                      <FormInput name="contactName" type="text" label="Name" />
                      <FormInput name="contactEmail" type="text" label="E-Mail" />
                    </li>
                    <li>
                      <FormInput name="contactPhone" type="text" label="Telefon" />
                      <FormInput name="contactFax" type="text" label="Fax-Nr." />
                    </li>
                    <li>
                      <FormInput name="contactWeb" type="text" label="Web" />
                      <FormInput name="contactAddress" type="text" label="Adresse" />
                    </li>
                  </ul>
                  <ul className="field-list-contact contact-hours">
                    <li>Informationen</li>
                    <li>
                      <OpeningHours data={hours} setData={setHours} />
                    </li>
                  </ul>
                  <UploadFiles title="Dateien" attachment={attachment} setAttachment={setAttachment} />
                  <ul>
                    <li className="doc-check-input">
                      <FormInput name="docCheckId" type="text" label="DocCheck Token ID" disabled={true} />
                    </li>
                  </ul>
                </section>
              </div>
              <aside className="right-side">
                <p className="title">Logo Apotheke</p>
                <div ref={uploadImgRef} className="upload-img-wrapper">
                  <UploadImgDragDrop
                    type="one"
                    data={logo}
                    isDefaultImg={true}
                    isSingeImg={true}
                    onChange={(file) => onAttachmentHandler('logo', file)}
                    onDelete={(key) => onDeleteAttachmentHandler('logo', key)}
                    buttonName="hochladen"
                    infoText={false}
                    status={statusUpload !== 'pending' && statusDelete !== 'pending'}
                  />
                </div>
                <div className="group-btn" ref={groupBtnRef}>
                  <Button title="Abbrechen und zurück" onEvent={onCancel} width="201" color="transparent" />
                  <Button
                    disabled={!isDataChages}
                    title="Speichern und weiter"
                    type="submit"
                    width="201"
                    color="green"
                  />
                </div>
              </aside>
            </Form>
          )}
        </div>
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default PharmacieSettings;

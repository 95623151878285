import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../../../store/reducer';
import * as action from '../../../../../../store/action';

import { useChangeEffect } from '../../../../../../hooks';
import { TabContent } from '../../../../../../HOC';
import { variables } from './variables';

import CardBuilder from '../../../../../../components/cardBuilder';
import DropdownCount from '../../../../../../components/dropdownCount';
import Pagination from '../../../../../../components/UI/pagination';
import Button from '../../../../../../components/UI/button';
import CreateShapePopup from '../../../../../../components/popups/createShapePopup';
import Loader from '../../../../../../components/popups/loader';
import { useDefaultPerPage } from '../../../../../../hooks/useDefaultPerPage';

const Delivery = ({ visible, isShowLoader }) => {
  const dispatch = useDispatch();

  const dataForms = useSelector(reducer.request.form.state.getForms.data);

  const statusPostForms = useSelector(reducer.request.form.state.postForms.status);
  const statusPutFormId = useSelector(reducer.request.form.state.putFormId.status);
  const statusDeleteFormId = useSelector(reducer.request.form.state.deleteFormId.status);
  const statusGetForms = useSelector(reducer.request.form.state.getForms.status);

  const postForms = (data) => dispatch(action.request.forms.postForms.post(data));
  const putFormId = (id, data) => dispatch(action.request.forms.putFormId.put(id, data));
  const deleteFormId = (id) => dispatch(action.request.forms.deleteFormId.delete(id));
  const getForms = (start, limit) => dispatch(action.request.forms.getForms.get(start, limit));

  const [isShowCreateShapePopup, setShowCreateShapePopup] = useState(false);

  const persistName = 'superuserDelivery';

  const { contentPerPage, setContentPerPage } = useDefaultPerPage(persistName);
  const [currentPage, setCurrentPage] = useState(1);

  const pageCount = Math.ceil(dataForms?.total / contentPerPage) || 1;

  // get new data after delete item
  useChangeEffect(() => {
    if (statusDeleteFormId === 'success') getData();
  }, [statusDeleteFormId]);

  // get new data after change card
  useChangeEffect(() => {
    if (statusPutFormId === 'success') getData();
  }, [statusPutFormId]);

  // get new data after create new card
  useChangeEffect(() => {
    if (statusPostForms === 'success') {
      setShowCreateShapePopup(false);
      getData();
    }
  }, [statusPostForms]);

  // get new data after change page
  useChangeEffect(() => {
    getData();
  }, [currentPage, contentPerPage]);

  const getData = () => {
    const firstContentIndex = (currentPage - 1) * contentPerPage;

    getForms(firstContentIndex, contentPerPage, 'bud');
  };

  return (
    <>
      <TabContent visible={visible}>
        <CardBuilder
          type="forms"
          className="delivery-content"
          data={dataForms?.forms}
          onDelete={deleteFormId}
          onPut={putFormId}
          statusPut={statusPutFormId}
          statusDelete={statusDeleteFormId}>
          <div className="card-header">
            <ul className="left-side">
              <li className="elem">
                <DropdownCount
                  contentPerPage={contentPerPage}
                  data={variables.dropdownContent}
                  onSetContentPerPage={setContentPerPage}
                  setCurrentPage={() => setCurrentPage(1)}
                  persistName={persistName}
                />
              </li>
              <li className="elem">
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
              </li>
            </ul>

            <Button
              title="Neue Abgabeform hinzufügen"
              onEvent={() => setShowCreateShapePopup(true)}
              width="220"
              color="green"
            />
          </div>
        </CardBuilder>

        {isShowCreateShapePopup && (
          <CreateShapePopup onSave={postForms} onClose={() => setShowCreateShapePopup(false)} />
        )}
      </TabContent>

      {!isShowLoader &&
        (statusGetForms === 'pending' ||
          statusDeleteFormId === 'pending' ||
          statusPutFormId === 'pending' ||
          statusPostForms === 'pending') && <Loader type="transparent" />}
    </>
  );
};

export default Delivery;

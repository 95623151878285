import React from 'react';

import './style.scss';

import { Portal } from '../../HOC';

import BackgroundPopup from './backgroundPopup';
import Button from '../UI/button';
import ButtonClear from '../UI/buttonClear';

const TemplatePopup = ({ children, onClose, title, className = '', showCloseButton = false, fixedElement }) => {
  return (
    <Portal>
      <BackgroundPopup onClose={onClose} />
      <div className="popup-container">
        <section className={`template-popup ${className}`}>
          <div className="title-container">
            {title && <h2 className="title">{title}</h2>}
            {showCloseButton && <ButtonClear onEvent={onClose} />}
          </div>
          {children}
        </section>
        {fixedElement && <div className="fixed-element">{fixedElement}</div>}
      </div>
    </Portal>
  );
};

export default TemplatePopup;

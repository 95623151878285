import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';

import * as reducer from './store/reducer';

import SignInPage from './pages/common/signInPage';
import SingInDocCheck from './pages/common/singInDocCheck';
import ForgotPasswordPage from './pages/common/forgotPasswordPage';
import ErrorPage from './pages/common/errorPage';

import SuperuserDashboard from './pages/superuser/dashboard';
import Buds from './pages/superuser/buds/main';
import BudAddEdit from './pages/superuser/buds/addEdit';
import Extracts from './pages/superuser/extracts/main';
import ExtractAddEdit from './pages/superuser/extracts/addEdit';
import Manufacturers from './pages/superuser/manufacturers/main';
import ManufacturerAddEdit from './pages/superuser/manufacturers/addEdit';
import Pharmacies from './pages/superuser/pharmacies/main';
import PharmacyProfile from './pages/superuser/pharmacies/profile';
import Orders from './pages/superuser/orders/main';
import OrderEdit from './pages/superuser/orders/edit';
import Settings from './pages/superuser/settings';
import EditMailTemplates from './pages/superuser/settings/tabs/mailTemplates/editMailTemplates';

import ManufaturerDashboard from './pages/manufacturer/dashboard';
import ManufacturerBuds from './pages/manufacturer/manufacturerBuds/ManufacturerBuds';
import ManufacturerBudEdit from './pages/manufacturer/manufacturerBudEdit';
import ManufacturerExtracts from './pages/manufacturer/manufacturerExtracts/ManufacturerExtracts';
import ManufacturerExtractEdit from './pages/manufacturer/manufacturerExtractEdit';
import ManufacturerOrderEdit from './pages/manufacturer/manufacturerOrderEdit';
import ManufacturerPharmacie from './pages/manufacturer/manufacturerPharmacie';
import ManufacturerProfile from './pages/manufacturer/manufacturerProfile';
import ManufaturerOrders from './pages/manufacturer/manufaturerOrders';

import PharmacieDashboard from './pages/pharmacie/dashboard';
import PharmacieSettings from './pages/pharmacie/pharmacieSettings';
import ApproveAwait from './pages/pharmacie/dashboard/approveAwait';
import PharmacieBuds from './pages/pharmacie/pharmacieBuds/PharmacieBuds';
import PharmacieBudsInfo from './pages/pharmacie/pharmacieBudsInfo';
import PharmacieExtracts from './pages/pharmacie/pharmacieExtracts';
import PharmacieExtractsInfo from './pages/pharmacie/pharmacieExtractsInfo';
import PharmaciePreOrder from './pages/pharmacie/pharmaciePreOrder/PharmaciePreOrder';
import PharmacieOrders from './pages/pharmacie/pharmacieOrders';
import PharmacieOrderInfo from './pages/pharmacie/pharmacieOrderInfo';
import SettingForm from './pages/pharmacie/pharmacieSettings/settingForm/SettingForm';

import DoctorDashboard from './pages/doctor/dashboard';
import DoctorBuds from './pages/doctor/doctorBuds';
import DoctorBudsInfo from './pages/doctor/doctorBudsInfo';
import DoctorExtracts from './pages/doctor/doctorExtracts';
import DoctorExtractsInfo from './pages/doctor/doctorExtractsInfo';
import DoctorPrescription from './pages/doctor/doctorPrescription';

const Router = () => {
  const role = useSelector(reducer.app.common.state.role);

  if (role === 'admin') {
    return (
      <Routes>
        <Route path="/dashboard" element={<SuperuserDashboard />} />

        <Route path="/buds" element={<Buds />} />
        <Route path="/buds/add" element={<BudAddEdit />} />
        <Route path="/buds/edit/:budID" element={<BudAddEdit />} />

        <Route path="/extracts" element={<Extracts />} />
        <Route path="/extracts/add" element={<ExtractAddEdit />} />
        <Route path="/extracts/edit/:extractID" element={<ExtractAddEdit />} />

        <Route path="/manufacturers" element={<Manufacturers />} />
        <Route path="/manufacturers/add" element={<ManufacturerAddEdit />} />
        <Route path="/manufacturers/edit/:manufacturerID" element={<ManufacturerAddEdit />} />

        <Route path="/pharmacies" element={<Pharmacies />} />
        <Route path="/pharmacies/profile/:pharmacyID" element={<PharmacyProfile />} />

        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/edit/:orderID" element={<OrderEdit />} />

        <Route path="/settings" element={<Settings />} />
        <Route path="/settings/email-templates/edit/:ID" element={<EditMailTemplates />} />

        <Route path="/login" element={<SignInPage />} />

        <Route path="/doc-check/:role" element={<SingInDocCheck />} />

        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    );
  }

  if (role === 'manufacturer') {
    return (
      <Routes>
        <Route path="/dashboard" element={<ManufaturerDashboard />} />

        <Route path="/buds" element={<ManufacturerBuds />} />
        <Route path="/buds/edit/:id" element={<ManufacturerBudEdit />} />

        <Route path="/extracts" element={<ManufacturerExtracts />} />
        <Route path="/extracts/edit/:id" element={<ManufacturerExtractEdit />} />

        <Route path="/orders" element={<ManufaturerOrders />} />
        <Route path="/orders/edit/:id" element={<ManufacturerOrderEdit />} />

        <Route path="/pharmacie/:id" element={<ManufacturerPharmacie />} />

        <Route path="/settings" element={<ManufacturerProfile />} />

        <Route path="/login" element={<SignInPage />} />

        <Route path="/doc-check/:role" element={<SingInDocCheck />} />

        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    );
  }

  if (role === 'pharmacy') {
    return (
      <Routes>
        <Route path="/dashboard" element={<PharmacieDashboard />} />

        <Route path="/buds" element={<PharmacieBuds />} />
        <Route path="/buds/:id" element={<PharmacieBudsInfo />} />

        <Route path="/extracts" element={<PharmacieExtracts />} />
        <Route path="/extracts/:id" element={<PharmacieExtractsInfo />} />

        <Route path="/pre-order" element={<PharmaciePreOrder />} />

        <Route path="/orders" element={<PharmacieOrders />} />
        <Route path="/orders/:id" element={<PharmacieOrderInfo />} />

        <Route path="/settings" element={<PharmacieSettings />} />

        <Route path="/login" element={<SignInPage />} />

        <Route path="/doc-check/:role" element={<SingInDocCheck />} />

        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    );
  }

  if (role === 'disactivated_pharmacy') {
    return (
      <Routes>
        <Route path="/dashboard" element={<PharmacieDashboard />} />

        <Route path="/pharmacie-approve" element={<ApproveAwait />} />

        <Route path="/disactivated-settings" element={<SettingForm />} />

        <Route path="/login" element={<SignInPage />} />

        <Route path="/doc-check/:role" element={<SingInDocCheck />} />

        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    );
  }

  if (role === 'doctor') {
    return (
      <Routes>
        <Route path="/dashboard" element={<DoctorDashboard />} />

        <Route path="/buds" element={<DoctorBuds />} />
        <Route path="/buds/:id" element={<DoctorBudsInfo />} />

        <Route path="/extracts" element={<DoctorExtracts />} />
        <Route path="/extracts/:id" element={<DoctorExtractsInfo />} />

        <Route path="/prescription" element={<DoctorPrescription />} />

        <Route path="/login" element={<SignInPage />} />

        <Route path="/doc-check/:role" element={<SingInDocCheck />} />

        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<SignInPage />} />

      <Route path="/login" element={<SignInPage />} />

      <Route path="/doc-check/:role" element={<SingInDocCheck />} />

      <Route path="/forgot-password" element={<ForgotPasswordPage />} />

      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default Router;

import React from 'react';

import * as variables from '../../variables';

const DocCheckFrame = () => {
  return (
    <iframe
      align="left"
      frameBorder="0"
      scrolling="no"
      width="311"
      height="163"
      name="dc_login_iframe"
      id="dc_login_iframe"
      src={`https://login.doccheck.com/code/de/${variables.loginId}/login_m/`}>
      <a href={`https://login.doccheck.com/code/de/${variables.loginId}/login_m/`} target="_blank" rel="noreferrer">
        LOGIN
      </a>
    </iframe>
  );
};

export default DocCheckFrame;

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.dosages.getDosagesAll.getSuccess, ({ getDosagesAll }, action) => {
      getDosagesAll.status = 'success';
      getDosagesAll.data = action.payload;
    })
    .addCase(action.request.dosages.getDosagesAll.getPending, ({ getDosagesAll }, action) => {
      getDosagesAll.status = 'pending';
    })
    .addCase(action.request.dosages.getDosagesAll.getFailed, ({ getDosagesAll }, action) => {
      getDosagesAll.status = 'failed';
      getDosagesAll.error = action.payload;
      getDosagesAll.data = null;
    });

  builder
    .addCase(action.request.dosages.getDosageId.getSuccess, ({ getDosageId }, action) => {
      getDosageId.status = 'success';
      getDosageId.data = action.payload;
    })
    .addCase(action.request.dosages.getDosageId.getPending, ({ getDosageId }, action) => {
      getDosageId.status = 'pending';
    })
    .addCase(action.request.dosages.getDosageId.getFailed, ({ getDosageId }, action) => {
      getDosageId.status = 'failed';
      getDosageId.error = action.payload;
      getDosageId.data = null;
    });

  builder
    .addCase(action.request.dosages.putDosageId.putSuccess, ({ putDosageId }, action) => {
      putDosageId.status = 'success';
      putDosageId.data = action.payload;
    })
    .addCase(action.request.dosages.putDosageId.putPending, ({ putDosageId }, action) => {
      putDosageId.status = 'pending';
    })
    .addCase(action.request.dosages.putDosageId.putFailed, ({ putDosageId }, action) => {
      putDosageId.status = 'failed';
      putDosageId.error = action.payload;
      putDosageId.data = null;
    });

  builder
    .addCase(action.request.dosages.deleteDosageId.deleteSuccess, ({ deleteDosageId }, action) => {
      deleteDosageId.status = 'success';
      deleteDosageId.data = action.payload;
    })
    .addCase(action.request.dosages.deleteDosageId.deletePending, ({ deleteDosageId }, action) => {
      deleteDosageId.status = 'pending';
    })
    .addCase(action.request.dosages.deleteDosageId.deleteFailed, ({ deleteDosageId }, action) => {
      deleteDosageId.status = 'failed';
      deleteDosageId.error = action.payload;
      deleteDosageId.data = null;
    });

  builder
    .addCase(action.request.dosages.getDosages.getSuccess, ({ getDosages }, action) => {
      getDosages.status = 'success';
      getDosages.data = action.payload;
    })
    .addCase(action.request.dosages.getDosages.getPending, ({ getDosages }, action) => {
      getDosages.status = 'pending';
    })
    .addCase(action.request.dosages.getDosages.getFailed, ({ getDosages }, action) => {
      getDosages.status = 'failed';
      getDosages.exrror = action.payload;
      getDosages.data = null;
    });

  builder
    .addCase(action.request.dosages.postDosages.postSuccess, ({ postDosages }, action) => {
      postDosages.status = 'success';
      postDosages.data = action.payload;
    })
    .addCase(action.request.dosages.postDosages.postPending, ({ postDosages }, action) => {
      postDosages.status = 'pending';
    })
    .addCase(action.request.dosages.postDosages.postFailed, ({ postDosages }, action) => {
      postDosages.status = 'failed';
      postDosages.error = action.payload;
      postDosages.data = null;
    });
});

export const state = {
  getCultivarsAll: {
    data: (state) => state.request.cultivars.getCultivarsAll.data,
    status: (state) => state.request.cultivars.getCultivarsAll.status,
    error: (state) => state.request.cultivars.getCultivarsAll.error,
  },
  postCultivars: {
    data: (state) => state.request.cultivars.postCultivars.data,
    status: (state) => state.request.cultivars.postCultivars.status,
    error: (state) => state.request.cultivars.postCultivars.error,
  },
};

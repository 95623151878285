import React, { useState } from 'react';

import './style.scss';

import Table from './table';
import Pagination from '../../components/UI/pagination';
import Button from '../UI/button';
import FieldPagination from '../UI/fieldPagination/';
import ImagePopup from '../popups/imagePopup';

const TableBuilder = ({
  className,
  path,
  contentPerPage,
  title,
  data,
  isCanSelect = true,
  currentPage,
  setCurrentPage,
  pageCount,
  selectedIDs,
  setSelectedIDs,
  children,
  totalItems,
  sortKeys,
  isWithNavigation = true,
  pagination = 'type1',
  onChangeStatus,
  innerTableBtn,
  valueSearch,
  onSortHandler,
  onAddRemoveHandler,
}) => {
  const [productImagePopup, setProductImagePopup] = useState({ img: null, active: false });

  const countSelectedRows = selectedIDs?.length;
  const dataLength = data?.length;
  const selectedOneOrMore = countSelectedRows >= 1;
  const isSelectAll =
    contentPerPage > dataLength ? dataLength === countSelectedRows : Number(contentPerPage) === countSelectedRows;

  return (
    <>
      <section className={`table-builder ${className}`}>
        {children}

        <div className="table">
          <div className="table-scroll">
            <Table
              path={path}
              title={title}
              data={data}
              totalItems={totalItems}
              selectedOneOrMore={selectedOneOrMore}
              isSelectAll={isSelectAll}
              isCanSelect={isCanSelect}
              selectedIDs={selectedIDs}
              setSelectedIDs={setSelectedIDs}
              setProductImagePopup={setProductImagePopup}
              headerButton={children}
              sortKeys={sortKeys}
              onChangeStatus={onChangeStatus}
              innerTableBtn={innerTableBtn}
              valueSearch={valueSearch}
              onSortHandler={onSortHandler}
              onAddRemoveHandler={onAddRemoveHandler}
            />
          </div>

          {pagination === 'type1' && isWithNavigation && (
            <div className="table-footer">
              <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
            </div>
          )}
        </div>
        {pagination === 'type2' && isWithNavigation && data?.length !== 0 && data && (
          <div className="separeate-pagination">
            <div className="pagination-buttons">
              <Button
                title="Vorherige Seite"
                onEvent={() => setCurrentPage((prev) => (prev !== 1 ? prev - 1 : 1))}
                width="173"
                color="transparent"
                disabled={currentPage === 1}
              />
              <Button
                title="Nächste Seite"
                onEvent={() => setCurrentPage((prev) => (prev !== pageCount ? prev + 1 : pageCount))}
                width="173"
                color="green"
                disabled={currentPage === pageCount}
              />
            </div>
            <div className="pagination-navigation">
              <FieldPagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
            </div>
          </div>
        )}
      </section>

      {productImagePopup.active && (
        <ImagePopup
          img={productImagePopup.img}
          onClose={() => setProductImagePopup({ ...productImagePopup, active: false })}
        />
      )}
    </>
  );
};

export default TableBuilder;

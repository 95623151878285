import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useChangeEffect, useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import Button from '../../../components/UI/button';
import Loader from '../../../components/popups/loader';
import CartInfoRow from '../../../components/cartInfoRow';

import { getFormateTableDate } from '../../../utils';

const PharmacieOrderInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const isShow = useDelay(100);
  usePageUp();

  const [separetedProducts, setSeparetedProducts] = useState();
  const [isShowLoader, setShowLoader] = useState(true);

  const dataOrder = useSelector(reducer.request.pharmacie.orders.state.getPharmaciesOrdersId.data);
  const dataStatus = useSelector(reducer.request.pharmacie.orders.state.getPharmaciesOrdersId.status);

  const getPharmaciesOrdersId = (id) => dispatch(action.request.pharmacie.orders.getPharmaciesOrdersId.get(id));

  const isRequestGETSuccess = dataStatus === 'success';

  useEffect(() => {
    setSeparetedProducts(() => {
      if (dataOrder) {
        return {
          buds: [...dataOrder.products?.filter((prod) => prod.type === 'bud')],
          extracts: [...dataOrder.products?.filter((prod) => prod.type === 'extract')],
        };
      }
    });
  }, [dataOrder]);

  useEffect(() => {
    getPharmaciesOrdersId(id);
  }, []);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  return (
    <>
      <Header />
      <main className={`pre-order-page page ${isShow ? 'show' : ''}`}>
        {!isShowLoader && (
          <div className="container">
            <div className="left-side-container">
              <h1 className="pre-order-title"> Bestellanfragen №{dataOrder?.id}</h1>
              <div className="left-side">
                <section className="section-wrapper">
                  <div className="cart-popup-container">
                    {!!separetedProducts?.buds.length && (
                      <>
                        <p className="cart-popup-type-tile">Blüten ({separetedProducts?.buds.length})</p>
                        <ul className="cart-popup-items-wrapper">
                          {separetedProducts?.buds.map((item) => (
                            <CartInfoRow
                              key={item?.id}
                              data={item}
                              separetedProducts={separetedProducts}
                              isOrderInfo={true}
                            />
                          ))}
                        </ul>
                      </>
                    )}
                    {!!separetedProducts?.extracts.length && (
                      <>
                        <p className="cart-popup-type-tile">Extrakte ({separetedProducts?.extracts.length})</p>
                        <ul className="cart-popup-items-wrapper">
                          {separetedProducts?.extracts.map((item) => (
                            <CartInfoRow
                              key={item?.id}
                              data={item}
                              separetedProducts={separetedProducts}
                              isOrderInfo={true}
                            />
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </section>
              </div>
            </div>
            <aside className="right-side complited">
              <div className="right-side-info">
                <h1 className="right-side-info-title">Vielen Dank, die Bestellanfrage ist aufgegeben!</h1>
                <p className="under-text">
                  №{dataOrder?.id} vom {getFormateTableDate(dataOrder?.date, false)}
                </p>
              </div>
              <div className="btn-group">
                <Button onEvent={() => navigate('/orders')} color="green" title="Bestellanfragen" width={152} />
                <Button onEvent={() => navigate('/dashboard')} color="gray" title="Startseite" width={142} />
              </div>
            </aside>
          </div>
        )}
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default PharmacieOrderInfo;

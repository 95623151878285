import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.manufacturer.user.getManufacturer.getSuccess, ({ getManufacturer }, action) => {
      getManufacturer.status = 'success';
      getManufacturer.data = action.payload;
    })
    .addCase(action.request.manufacturer.user.getManufacturer.getPending, ({ getManufacturer }, action) => {
      getManufacturer.status = 'pending';
    })
    .addCase(action.request.manufacturer.user.getManufacturer.getFailed, ({ getManufacturer }, action) => {
      getManufacturer.status = 'failed';
      getManufacturer.error = action.payload;
      getManufacturer.data = null;
    });
    
  builder
    .addCase(action.request.manufacturer.user.postManufacturerMail.postSuccess, ({ postManufacturerMail }, action) => {
      postManufacturerMail.status = 'success';
      postManufacturerMail.data = action.payload;
    })
    .addCase(action.request.manufacturer.user.postManufacturerMail.postPending, ({ postManufacturerMail }, action) => {
      postManufacturerMail.status = 'pending';
    })
    .addCase(action.request.manufacturer.user.postManufacturerMail.postFailed, ({ postManufacturerMail }, action) => {
      postManufacturerMail.status = 'failed';
      postManufacturerMail.error = action.payload;
      postManufacturerMail.data = null;
    });

  builder
    .addCase(action.request.manufacturer.user.putManufacturer.putSuccess, ({ putManufacturer }, action) => {
      putManufacturer.status = 'success';
      putManufacturer.data = action.payload;
    })
    .addCase(action.request.manufacturer.user.putManufacturer.putPending, ({ putManufacturer }, action) => {
      putManufacturer.status = 'pending';
    })
    .addCase(action.request.manufacturer.user.putManufacturer.putFailed, ({ putManufacturer }, action) => {
      putManufacturer.status = 'failed';
      putManufacturer.error = action.payload;
      putManufacturer.data = null;
    });
});

import React from 'react';
import { useNavigate } from 'react-router-dom';

import './style.scss';

import Button from '../UI/button';

const Error404 = () => {
  const navigate = useNavigate();

  const onGoHomePage = () => {
    navigate('/dashboard');
  };

  return (
    <section className="error-404">
      <h1 className="title">
        404 <span>Seite nicht gefunden</span>
      </h1>
      <p className="message">Adresse fehlerhaft oder Seite wurde gelöscht</p>
      <Button title="Home page" width="151" color="green" onEvent={onGoHomePage} />
    </section>
  );
};

export default Error404;

export const state = {
  getManufacturerExtracts: {
    data: (state) => state.request.manufacturer.extracts.getManufacturerExtracts.data,
    status: (state) => state.request.manufacturer.extracts.getManufacturerExtracts.status,
    error: (state) => state.request.manufacturer.extracts.getManufacturerExtracts.error,
  },
  getManufacturerExtractsId: {
    data: (state) => state.request.manufacturer.extracts.getManufacturerExtractsId.data,
    status: (state) => state.request.manufacturer.extracts.getManufacturerExtractsId.status,
    error: (state) => state.request.manufacturer.extracts.getManufacturerExtractsId.error,
  },

  patchManufacturerExtractsId: {
    data: (state) => state.request.manufacturer.extracts.patchManufacturerExtractsId.data,
    status: (state) => state.request.manufacturer.extracts.patchManufacturerExtractsId.status,
    error: (state) => state.request.manufacturer.extracts.patchManufacturerExtractsId.error,
  },
};

export const state = {
  getManufacturersAll: {
    data: (state) => state.request.admin.manufacturers.getManufacturersAll.data,
    status: (state) => state.request.admin.manufacturers.getManufacturersAll.status,
    error: (state) => state.request.admin.manufacturers.getManufacturersAll.error,
  },

  getManufactureId: {
    data: (state) => state.request.admin.manufacturers.getManufactureId.data,
    status: (state) => state.request.admin.manufacturers.getManufactureId.status,
    error: (state) => state.request.admin.manufacturers.getManufactureId.error,
  },

  putManufactureId: {
    data: (state) => state.request.admin.manufacturers.putManufactureId.data,
    status: (state) => state.request.admin.manufacturers.putManufactureId.status,
    error: (state) => state.request.admin.manufacturers.putManufactureId.error,
  },

  deleteManufactureId: {
    data: (state) => state.request.admin.manufacturers.deleteManufactureId.data,
    status: (state) => state.request.admin.manufacturers.deleteManufactureId.status,
    error: (state) => state.request.admin.manufacturers.deleteManufactureId.error,
  },

  getManufacturers: {
    data: (state) => state.request.admin.manufacturers.getManufacturers.data,
    status: (state) => state.request.admin.manufacturers.getManufacturers.status,
    error: (state) => state.request.admin.manufacturers.getManufacturers.error,
  },

  postManufacturers: {
    data: (state) => state.request.admin.manufacturers.postManufacturers.data,
    status: (state) => state.request.admin.manufacturers.postManufacturers.status,
    error: (state) => state.request.admin.manufacturers.postManufacturers.error,
  },

  deleteManufacturers: {
    data: (state) => state.request.admin.manufacturers.deleteManufacturers.data,
    status: (state) => state.request.admin.manufacturers.deleteManufacturers.status,
    error: (state) => state.request.admin.manufacturers.deleteManufacturers.error,
  },
};

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.admin.extracts.getExtractsAll.getSuccess, ({ getExtractsAll }, action) => {
      getExtractsAll.status = 'success';
      getExtractsAll.data = action.payload;
    })
    .addCase(action.request.admin.extracts.getExtractsAll.getPending, ({ getExtractsAll }, action) => {
      getExtractsAll.status = 'pending';
    })
    .addCase(action.request.admin.extracts.getExtractsAll.getFailed, ({ getExtractsAll }, action) => {
      getExtractsAll.status = 'failed';
      getExtractsAll.error = action.payload;
      getExtractsAll.data = null;
    });

  builder
    .addCase(action.request.admin.extracts.getExtractId.getSuccess, ({ getExtractId }, action) => {
      getExtractId.status = 'success';
      getExtractId.data = action.payload;
    })
    .addCase(action.request.admin.extracts.getExtractId.getPending, ({ getExtractId }, action) => {
      getExtractId.status = 'pending';
    })
    .addCase(action.request.admin.extracts.getExtractId.getFailed, ({ getExtractId }, action) => {
      getExtractId.status = 'failed';
      getExtractId.error = action.payload;
      getExtractId.data = null;
    });

  builder
    .addCase(action.request.admin.extracts.patchExtractId.patchSuccess, ({ patchExtractId }, action) => {
      patchExtractId.status = 'success';
      patchExtractId.data = action.payload;
    })
    .addCase(action.request.admin.extracts.patchExtractId.patchPending, ({ patchExtractId }, action) => {
      patchExtractId.status = 'pending';
    })
    .addCase(action.request.admin.extracts.patchExtractId.patchFailed, ({ patchExtractId }, action) => {
      patchExtractId.status = 'failed';
      patchExtractId.error = action.payload;
      patchExtractId.data = null;
    });

  builder
    .addCase(action.request.admin.extracts.deleteExtractId.deleteSuccess, ({ deleteExtractId }, action) => {
      deleteExtractId.status = 'success';
      deleteExtractId.data = action.payload;
    })
    .addCase(action.request.admin.extracts.deleteExtractId.deletePending, ({ deleteExtractId }, action) => {
      deleteExtractId.status = 'pending';
    })
    .addCase(action.request.admin.extracts.deleteExtractId.deleteFailed, ({ deleteExtractId }, action) => {
      deleteExtractId.status = 'failed';
      deleteExtractId.error = action.payload;
      deleteExtractId.data = null;
    });

  builder
    .addCase(action.request.admin.extracts.getExtracts.getSuccess, ({ getExtracts }, action) => {
      getExtracts.status = 'success';
      getExtracts.data = action.payload;
    })
    .addCase(action.request.admin.extracts.getExtracts.getPending, ({ getExtracts }, action) => {
      getExtracts.status = 'pending';
    })
    .addCase(action.request.admin.extracts.getExtracts.getFailed, ({ getExtracts }, action) => {
      getExtracts.status = 'failed';
      getExtracts.error = action.payload;
      getExtracts.data = null;
    });

  builder
    .addCase(action.request.admin.extracts.postExtracts.postSuccess, ({ postExtracts }, action) => {
      postExtracts.status = 'success';
      postExtracts.data = action.payload;
    })
    .addCase(action.request.admin.extracts.postExtracts.postPending, ({ postExtracts }, action) => {
      postExtracts.status = 'pending';
    })
    .addCase(action.request.admin.extracts.postExtracts.postFailed, ({ postExtracts }, action) => {
      postExtracts.status = 'failed';
      postExtracts.error = action.payload;
      postExtracts.data = null;
    });

  builder
    .addCase(action.request.admin.extracts.deleteExtracts.deleteSuccess, ({ deleteExtracts }, action) => {
      deleteExtracts.status = 'success';
      deleteExtracts.data = action.payload;
    })
    .addCase(action.request.admin.extracts.deleteExtracts.deletePending, ({ deleteExtracts }, action) => {
      deleteExtracts.status = 'pending';
    })
    .addCase(action.request.admin.extracts.deleteExtracts.deleteFailed, ({ deleteExtracts }, action) => {
      deleteExtracts.status = 'failed';
      deleteExtracts.error = action.payload;
      deleteExtracts.data = null;
    });
});

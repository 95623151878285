const dateNow = new Date();

export const initialState = {
  patientInfo: {
    patientDay: '01',
    patientMonth: '1',
    patientYear: '2000',
    dateDay: dateNow.getDate(),
    dateMonth: dateNow.getMonth() + 1,
    dateYear: dateNow.getFullYear(),
  },
  items: [],
};

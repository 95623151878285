import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';

import * as action from '../../../store/action';

import Loader from '../../../components/popups/loader';

const SingInDocCheck = () => {
  const { role } = useParams();
  const [docCheckId] = useQueryParam('uniquekey', StringParam);
  const [secret] = useQueryParam('secret', StringParam);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const postLoginDocCheck = (values) => {
    dispatch(action.request.auth.postLoginDocCheck.post(values, navigate));
  };

  useEffect(() => {
    if (role && docCheckId && secret) postLoginDocCheck({ role, docCheckId, secret });
  }, [role, docCheckId, secret]);

  return <Loader />;
};

export default SingInDocCheck;

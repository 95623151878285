export const state = {
  getOrderId: {
    data: (state) => state.request.admin.orders.getOrderId.data,
    status: (state) => state.request.admin.orders.getOrderId.status,
    error: (state) => state.request.admin.orders.getOrderId.error,
  },

  putOrderId: {
    data: (state) => state.request.admin.orders.putOrderId.data,
    status: (state) => state.request.admin.orders.putOrderId.status,
    error: (state) => state.request.admin.orders.putOrderId.error,
  },

  deleteOrderId: {
    data: (state) => state.request.admin.orders.deleteOrderId.data,
    status: (state) => state.request.admin.orders.deleteOrderId.status,
    error: (state) => state.request.admin.orders.deleteOrderId.error,
  },

  getOrders: {
    data: (state) => state.request.admin.orders.getOrders.data,
    status: (state) => state.request.admin.orders.getOrders.status,
    error: (state) => state.request.admin.orders.getOrders.error,
  },
};

export const variables = {
  status: [
    {
      id: 1,
      name: 'neu',
    },
    {
      id: 2,
      name: 'abgeschlossen',
    },
  ],

  status2: [
    {
      id: 1,
      name: 'offen',
    },
    {
      id: 2,
      name: 'bestätigt',
    },
    {
      id: 3,
      name: 'versendet',
    },
    {
      id: 4,
      name: 'abgelehnt',
    },
  ],
};

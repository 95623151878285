import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      action.request.manufacturer.pharmacies.getManufacturerPharmaciesId.getSuccess,
      ({ getManufacturerPharmaciesId }, action) => {
        getManufacturerPharmaciesId.status = 'success';
        getManufacturerPharmaciesId.data = action.payload;
      },
    )
    .addCase(
      action.request.manufacturer.pharmacies.getManufacturerPharmaciesId.getPending,
      ({ getManufacturerPharmaciesId }, action) => {
        getManufacturerPharmaciesId.status = 'pending';
      },
    )
    .addCase(
      action.request.manufacturer.pharmacies.getManufacturerPharmaciesId.getFailed,
      ({ getManufacturerPharmaciesId }, action) => {
        getManufacturerPharmaciesId.status = 'failed';
        getManufacturerPharmaciesId.error = action.payload;
        getManufacturerPharmaciesId.data = null;
      },
    );

  builder
    .addCase(
      action.request.manufacturer.pharmacies.putManufacturerPharmaciesId.putSuccess,
      ({ putManufacturerPharmaciesId }, action) => {
        putManufacturerPharmaciesId.status = 'success';
        putManufacturerPharmaciesId.data = action.payload;
      },
    )
    .addCase(
      action.request.manufacturer.pharmacies.putManufacturerPharmaciesId.putPending,
      ({ putManufacturerPharmaciesId }, action) => {
        putManufacturerPharmaciesId.status = 'pending';
      },
    )
    .addCase(
      action.request.manufacturer.pharmacies.putManufacturerPharmaciesId.putFailed,
      ({ putManufacturerPharmaciesId }, action) => {
        putManufacturerPharmaciesId.status = 'failed';
        putManufacturerPharmaciesId.error = action.payload;
        putManufacturerPharmaciesId.data = null;
      },
    );
});

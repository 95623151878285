import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../../store/reducer';
import * as action from '../../../../../store/action';

import { TabContent } from '../../../../../HOC';

import Tabs from '../../../../../components/UI/tabs';
import UploadImg from '../../../../../components/uploadImg';
import Button from '../../../../../components/UI/button';
import Textarea from '../../../../../components/UI/textarea';
import Loader from '../../../../../components/popups/loader';

const EditContent = ({ visible }) => {
  const dispatch = useDispatch();

  const dataSetting = useSelector(reducer.request.setting.state.getSettings.data);
  const dataUpload = useSelector(reducer.request.uploads.state.postUploads.data);

  const statusPostUpload = useSelector(reducer.request.uploads.state.postUploads.status);
  const statusPatchSettings = useSelector(reducer.request.setting.state.patchSettings.status);

  const postUploads = (data) => dispatch(action.request.uploads.postUploads.post(data));
  const patchSettings = (data) => dispatch(action.request.settings.patchSettings.patch(data));

  const [imgKey, setImgKey] = useState('');
  const [activeTabID, setActiveTabID] = useState(1);
  const [data, setData] = useState({
    budsPrescriptionAmount: 1,
    css: '',
    doctorDescription: '',
    doctorLogo: null,
    extractsPrescriptionAmount: 1,
    logo: null,
    manufacturerDescription: '',
    manufacturerLogo: null,
    pharmacyDescription: '',
    pharmacyLogo: null,
  });

  const tabsList = [
    {
      id: 1,
      name: 'Pharmacist’s UI',
    },
    {
      id: 2,
      name: 'UI Lieferanten',
    },
    {
      id: 3,
      name: 'UI Verordnende',
    },
  ];

  useEffect(() => {
    if (dataSetting) setData(dataSetting);
  }, [dataSetting]);

  useEffect(() => {
    if (statusPostUpload === 'success') setData({ ...data, [imgKey]: dataUpload });
  }, [statusPostUpload]);

  const onSave = () => {
    patchSettings(data);
  };

  const onUploadImg = (name, data) => {
    setImgKey(name);
    postUploads(data);
  };

  return (
    <>
      <TabContent visible={visible}>
        <div className='edit-content'>
          <section className='left-column'>
            <div className='content'>
              <Tabs
                list={tabsList}
                activeTabID={activeTabID}
                setActiveTabID={setActiveTabID}
              />

              <Message
                visible={activeTabID === 1}
                message={data.pharmacyDescription}
                setData={(value) => setData({ ...data, pharmacyDescription: value })}
              />
              <Message
                visible={activeTabID === 2}
                message={data.manufacturerDescription}
                setData={(value) => setData({ ...data, manufacturerDescription: value })}
              />
              <Message
                visible={activeTabID === 3}
                message={data.doctorDescription}
                setData={(value) => setData({ ...data, doctorDescription: value })}
              />
            </div>

            <Button
              title='Speichern und weiter'
              width='206'
              color='green'
              onEvent={onSave}
            />
          </section>

          <aside className='right-column'>
            <TabContent visible={activeTabID === 1}>
              <UploadImg
                title='UI Logo Apotheken'
                view='second'
                type='other'
                img={data.pharmacyLogo}
                onUploadImg={(data) => onUploadImg('pharmacyLogo', data)}
                onSelectImg={(img) => setData({ ...data, pharmacyLogo: img })}
                placeholder='Bildgröße 200x200px'
              />
            </TabContent>

            <TabContent visible={activeTabID === 2}>
              <UploadImg
                title='UI Lieferanten logo'
                view='second'
                type='other'
                img={data.manufacturerLogo}
                onUploadImg={(data) => onUploadImg('manufacturerLogo', data)}
                onSelectImg={(img) => setData({ ...data, manufacturerLogo: img })}
                placeholder='Bildgröße 200x200px'
              />
            </TabContent>

            <TabContent visible={activeTabID === 3}>
              <UploadImg
                title='UI Verordnende logo'
                view='second'
                type='other'
                img={data.doctorLogo}
                onUploadImg={(data) => onUploadImg('doctorLogo', data)}
                onSelectImg={(img) => setData({ ...data, doctorLogo: img })}
                placeholder='Bildgröße 200x200px'
              />
            </TabContent>
          </aside>
        </div>
      </TabContent>

      {visible && (statusPatchSettings === 'pending' || statusPostUpload === 'pending') && (
        <Loader type='transparent' />
      )}
    </>
  );
};

export default EditContent;

const Message = ({ visible, message, setData }) => {
  return (
    <TabContent visible={visible}>
      <Textarea
        label='Produktbeschreibung'
        value={message}
        onChange={(event) => setData(event.target.value)}
      />
    </TabContent>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as reducer from '../../../store/reducer';

import './style.scss';

const EmptyTable = ({ children, valueSearch }) => {
  const location = useLocation();
  const role = useSelector(reducer.app.common.state.role);

  if (valueSearch) {
    return (
      <tr>
        <td colSpan="1000" className="empty-table-container">
          <div className="empty-table">
            <h1 className="empty-table-title">Es wurden keine Ergebnisse für Ihre Suche gefunden</h1>
            <p className="empty-table-text">
              Versuchen Sie, Ihre Suche zu verfeinern, indem Sie die Filtereinstellungen ändern
            </p>
          </div>
        </td>
      </tr>
    );
  }

  if (location.pathname === '/buds' || location.pathname === '/extracts') {
    return (
      role === 'manufacturer' && (
        <tr>
          <td colSpan="1000">
            <div className="empty-table">
              <h1 className="empty-table-title">Sie haben keine Produkte hinzugefügt</h1>
              <p className="empty-table-text">
                Bitte geben Sie die Produkte ein, die Sie verkaufen möchten, und unser Team wird diese hinzufügen
              </p>
              {children}
            </div>
          </td>
        </tr>
      )
    );
  }

  if (location.pathname === '/orders') {
    return (
      role === 'manufacturer' && (
        <tr>
          <td colSpan="1000">
            <div className="empty-table">
              <h1 className="empty-table-title">Zu diesem Suchbergriff wurde kein Ergebnis gefunden</h1>
            </div>
          </td>
        </tr>
      )
    );
  }
};

export default EmptyTable;

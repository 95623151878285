import Cookies from 'js-cookie';

import * as variable from '../../variables';

export const user = {
  getPharmaciesUser: () => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/pharmacies`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  patchPharmaciesUser: (data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/pharmacies`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { ToastContainer, Flip } from 'react-toastify';

import { Provider } from 'react-redux';

import App from './App';
import store, { persistor } from './store/configureStore';

import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <PersistGate loading={null} persistor={persistor}>
    <Provider store={store}>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <App />
          <ToastContainer
            bodyClassName={() => 'toast'}
            position="top-right"
            autoClose={2000}
            hideProgressBar
            transition={Flip}
            style={{ width: 'auto' }}
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </QueryParamProvider>
      </BrowserRouter>
    </Provider>
  </PersistGate>,
);

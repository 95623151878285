import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../store/reducer';
import * as action from '../../../../store/action';
import * as util from '../../../../utils';

import { useChangeEffect } from '../../../../hooks';
import { variables } from './variables';

import TableBuilder from '../../../../components/tableBuilder';
import FieldSearch from '../../../../components/UI/fieldSearch';
import DropdownCount from '../../../../components/dropdownCount';
import Pagination from '../../../../components/UI/pagination';
import Selected from '../../../../components/selected';
import Loader from '../../../../components/popups/loader';
import { useDefaultPerPage } from '../../../../hooks/useDefaultPerPage';

const Orders = ({ isShowLoader }) => {
  const dispatch = useDispatch();

  const statusGetOrders = useSelector(reducer.request.admin.order.state.getOrders.status);
  const dataOrders = useSelector(reducer.request.admin.order.state.getOrders.data);

  const getOrders = (params) => dispatch(action.request.admin.orders.getOrders.get(params));

  const [valueSearch, setValueSearch] = useState('');

  const persistName = 'superuserDashboardOrders';

  const { contentPerPage, setContentPerPage } = useDefaultPerPage(persistName);

  const [currentPage, setCurrentPage] = useState(1);

  const [selectedIDs, setSelectedIDs] = useState([]);

  const countSelectedRows = selectedIDs.length;
  const pageCount = Math.ceil(dataOrders?.total / contentPerPage) || 1;

  const tableTitles = ['№', 'Datum', 'Apotheken', 'Lieferanten', 'Status'];
  const tableData = dataOrders?.orders.reduce((acc, item, index) => {
    acc = [
      ...acc,
      {
        id: item.id,
        number: currentPage !== 1 ? (currentPage - 1) * contentPerPage + index + 1 : index + 1,
        datum: new Date(item.date).toLocaleString(),
        apotheken: item.pharmacyName,
        herstellern: item.products.map((product) => product.manufacturerName).join(', '),
        status: util.getStatusOrder.de(item.status),
      },
    ];

    return acc;
  }, []);

  useChangeEffect(() => {
    getData();
  }, [currentPage, contentPerPage]);

  const onGoSearch = () => {
    if (currentPage === 1) {
      getOrders({ start: 0, limit: contentPerPage, search: valueSearch });
    } else {
      setCurrentPage(1);
    }
  };

  const onClear = () => {
    setValueSearch('');

    if (currentPage === 1) {
      getOrders({ start: 0, limit: contentPerPage });
    } else {
      setCurrentPage(1);
    }
  };

  const getData = () => {
    const firstContentIndex = (currentPage - 1) * contentPerPage;
    getOrders({ start: firstContentIndex, limit: contentPerPage, search: valueSearch });
  };

  return (
    <>
      <section className="dashboard-orders ">
        <h2 className="title">
          Bestellanfragen <sup className="count">{dataOrders?.total}</sup>
        </h2>

        <TableBuilder
          className="dashboard-table"
          path="/orders/edit"
          contentPerPage={contentPerPage}
          title={tableTitles}
          data={tableData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageCount={pageCount}
          selectedIDs={selectedIDs}
          setSelectedIDs={setSelectedIDs}
          valueSearch={valueSearch}
          isCanSelect={false}>
          <div className="table-header">
            <ul className="left-side">
              <li className="elem">
                <FieldSearch
                  value={valueSearch}
                  setValue={(event) => setValueSearch(event.target.value)}
                  onClear={onClear}
                  onGoSearch={onGoSearch}
                />
              </li>
            </ul>

            <ul className="right-side">
              <li className="elem">
                <DropdownCount
                  contentPerPage={contentPerPage}
                  data={variables.dropdownContent}
                  onSetContentPerPage={setContentPerPage}
                  setCurrentPage={() => setCurrentPage(1)}
                  persistName={persistName}
                />
              </li>
              <li className="elem">
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
              </li>
            </ul>
          </div>
        </TableBuilder>
      </section>

      {!isShowLoader && statusGetOrders === 'pending' && <Loader type="transparent" />}
    </>
  );
};

export default Orders;

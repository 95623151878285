import { combineReducers } from 'redux';

import * as reducer from './reducer';

export const rootReducer = combineReducers({
  request: combineReducers({
    auth: reducer.request.auth.reducer,
    uploads: reducer.request.uploads.reducer,
    admin: combineReducers({
      buds: reducer.request.admin.bud.reducer,
      extracts: reducer.request.admin.extract.reducer,
      manufacturers: reducer.request.admin.manufacture.reducer,
      orders: reducer.request.admin.order.reducer,
      pharmacies: reducer.request.admin.pharmacy.reducer,
    }),
    manufacturer: combineReducers({
      buds: reducer.request.manufacturer.buds.reducer,
      extracts: reducer.request.manufacturer.extracts.reducer,
      orders: reducer.request.manufacturer.orders.reducer,
      pharmacies: reducer.request.manufacturer.pharmacies.reducer,
      user: reducer.request.manufacturer.user.reducer,
    }),
    pharmacie: combineReducers({
      buds: reducer.request.pharmacie.buds.reducer,
      extracts: reducer.request.pharmacie.extracts.reducer,
      orders: reducer.request.pharmacie.orders.reducer,
      user: reducer.request.pharmacie.user.reducer,
      products: reducer.request.pharmacie.products.reducer,
    }),
    doctor: combineReducers({
      buds: reducer.request.doctor.buds.reducer,
      extracts: reducer.request.doctor.extracts.reducer,
    }),
    cultivars: reducer.request.cultivar.reducer,
    terpenes: reducer.request.terpene.reducer,
    settings: reducer.request.setting.reducer,
    dosages: reducer.request.dosage.reducer,
    forms: reducer.request.form.reducer,
    mailTemplates: reducer.request.mailTemplates.reducer,
  }),
  app: combineReducers({
    common: reducer.app.common.reducer,
    cart: reducer.app.cart.reducer,
    prescription: reducer.app.prescription.reducer,
  }),
});

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { usePageUp } from '../../../hooks';

import Main from './main/Main';
import Loader from '../../../components/popups/loader';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  usePageUp();

  const statusPharmacie = useSelector(reducer.request.pharmacie.user.state.getPharmaciesUser.status) || 'pending';
  const dataPharmacie = useSelector(reducer.request.pharmacie.user.state.getPharmaciesUser.data);
  const role = useSelector(reducer.app.common.state.role);

  const isRequestGETSuccess = statusPharmacie === 'success';

  const getPharmaciesUser = () => dispatch(action.request.pharmacie.user.getPharmaciesUser.get());

  useEffect(() => {
    getPharmaciesUser();
  }, []);

  useEffect(() => {
    if (!isRequestGETSuccess || !dataPharmacie || role !== 'disactivated_pharmacy') return;
    const isEmptyFields = Object.keys(dataPharmacie).some((key) => key !== 'logo' && dataPharmacie[key] === null);
    if (!isEmptyFields) {
      navigate('/pharmacie-approve');
      return;
    }
    navigate('/disactivated-settings');
  }, [role, dataPharmacie, isRequestGETSuccess]);

  if (!isRequestGETSuccess) return <Loader type="transparent" />;

  if (role === 'pharmacy') return <Main />;
};

export default Dashboard;

import React, { useState } from 'react';

import './style.scss';

const Switcher2 = ({ count, isChecked, onChange, withSymbols = true }) => {
  return (
    <label className="switcher2">
      <input type="checkbox" checked={isChecked} onChange={onChange} />
      <span className="slider"></span>
      {withSymbols && (
        <>
          <b className="left">=</b>
          <b className="rigth">+{count}</b>
        </>
      )}
    </label>
  );
};

export default Switcher2;

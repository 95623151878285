import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      action.request.manufacturer.orders.getManufacturerOrdersId.getSuccess,
      ({ getManufacturerOrdersId }, action) => {
        getManufacturerOrdersId.status = 'success';
        getManufacturerOrdersId.data = action.payload;
      },
    )
    .addCase(
      action.request.manufacturer.orders.getManufacturerOrdersId.getPending,
      ({ getManufacturerOrdersId }, action) => {
        getManufacturerOrdersId.status = 'pending';
      },
    )
    .addCase(
      action.request.manufacturer.orders.getManufacturerOrdersId.getFailed,
      ({ getManufacturerOrdersId }, action) => {
        getManufacturerOrdersId.status = 'failed';
        getManufacturerOrdersId.error = action.payload;
        getManufacturerOrdersId.data = null;
      },
    );

  builder
    .addCase(
      action.request.manufacturer.orders.getManufacturerOrders.getSuccess,
      ({ getManufacturerOrders }, action) => {
        getManufacturerOrders.status = 'success';
        getManufacturerOrders.data = action.payload;
      },
    )
    .addCase(
      action.request.manufacturer.orders.getManufacturerOrders.getPending,
      ({ getManufacturerOrders }, action) => {
        getManufacturerOrders.status = 'pending';
      },
    )
    .addCase(
      action.request.manufacturer.orders.getManufacturerOrders.getFailed,
      ({ getManufacturerOrders }, action) => {
        getManufacturerOrders.status = 'failed';
        getManufacturerOrders.error = action.payload;
        getManufacturerOrders.data = null;
      },
    );

  builder
    .addCase(action.request.manufacturer.orders.putManufacturerOrdersId.putSuccess, ({ putManufacturerOrdersId }, action) => {
      putManufacturerOrdersId.status = 'success';
      putManufacturerOrdersId.data = action.payload;
    })
    .addCase(action.request.manufacturer.orders.putManufacturerOrdersId.putPending, ({ putManufacturerOrdersId }, action) => {
      putManufacturerOrdersId.status = 'pending';
    })
    .addCase(action.request.manufacturer.orders.putManufacturerOrdersId.putFailed, ({ putManufacturerOrdersId }, action) => {
      putManufacturerOrdersId.status = 'failed';
      putManufacturerOrdersId.error = action.payload;
      putManufacturerOrdersId.data = null;
    });
});

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../../store/reducer';
import * as action from '../../../../store/action';
import * as util from '../../../../utils';

import { useChangeEffect, usePageUp } from '../../../../hooks';
import { variables } from './variables';

import Header from '../../../../components/header';
import TableBuilder from '../../../../components/tableBuilder';
import FieldSearch from '../../../../components/UI/fieldSearch';
import DropdownCount from '../../../../components/dropdownCount';
import Pagination from '../../../../components/UI/pagination';
import Selected from '../../../../components/selected';
import Button from '../../../../components/UI/button';
import Loader from '../../../../components/popups/loader';
import DeletePopup from '../../../../components/popups/deletePopup';
import { useDefaultPerPage } from '../../../../hooks/useDefaultPerPage';

const Manufacturers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataManufacturers = useSelector(reducer.request.admin.manufacture.state.getManufacturers.data);

  const statusDeleteManufacturers = useSelector(reducer.request.admin.manufacture.state.deleteManufacturers.status);
  const statusGetManufacturers = useSelector(reducer.request.admin.manufacture.state.getManufacturers.status);

  const deleteManufacturers = (data) => dispatch(action.request.admin.manufacturers.deleteManufacturers.delete(data));
  const getManufacturers = (start, limit, search) =>
    dispatch(action.request.admin.manufacturers.getManufacturers.get(start, limit, search));

  const [isShowLoader, setShowLoader] = useState(true);

  const [valueSearch, setValueSearch] = useState('');

  const persistName = 'superuserManufacturerMain';

  const { contentPerPage, setContentPerPage } = useDefaultPerPage(persistName);

  const [currentPage, setCurrentPage] = useState(1);

  const [isShowDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);

  const countSelectedRows = selectedIDs.length;
  const pageCount = Math.ceil(dataManufacturers?.total / contentPerPage) || 1;

  const tableTitles = ['№', 'Unternehmen', 'Email', 'Status'];
  const tableData = dataManufacturers?.manufacturers.reduce((acc, item, index) => {
    acc = [
      ...acc,
      {
        id: item.id,
        number: currentPage !== 1 ? (currentPage - 1) * contentPerPage + index + 1 : index + 1,
        name: item.name,
        email: item.email,
        status: util.getStatusManufacture(item.active),
      },
    ];

    return acc;
  }, []);

  const isRequestGETSuccess = statusGetManufacturers === 'success';

  usePageUp();

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  useEffect(() => {
    getManufacturers(0, contentPerPage);
  }, []);

  // get new data after delete item
  useChangeEffect(() => {
    if (statusDeleteManufacturers === 'success') {
      setShowDeletePopup(false);
      setSelectedIDs([]);
      getData();
    }
  }, [statusDeleteManufacturers]);

  // get new data when change page or search
  useChangeEffect(() => {
    getData();
  }, [currentPage, contentPerPage]);

  const onGoSearch = () => {
    if (currentPage === 1) {
      getManufacturers(0, contentPerPage, valueSearch);
    } else {
      setCurrentPage(1);
    }
  };

  const onClear = () => {
    setValueSearch('');

    if (currentPage === 1) {
      getManufacturers(0, contentPerPage);
    } else {
      setCurrentPage(1);
    }
  };

  const onDelete = () => {
    deleteManufacturers({
      manufacturersIds: selectedIDs,
    });
  };

  const getData = () => {
    const firstContentIndex = (currentPage - 1) * contentPerPage;
    getManufacturers(firstContentIndex, contentPerPage, valueSearch);
  };

  return (
    <>
      <Header />

      <main className={`manufacturers-page page ${!isShowLoader ? 'show' : ''}`}>
        <div className="container">
          <h1 className="page-title">
            Lieferanten <sup>{dataManufacturers?.total}</sup>
          </h1>

          <TableBuilder
            className="manufacturer-table"
            path="edit"
            contentPerPage={contentPerPage}
            title={tableTitles}
            data={tableData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCount={pageCount}
            selectedIDs={selectedIDs}
            setSelectedIDs={setSelectedIDs}
            valueSearch={valueSearch}>
            <div className="table-header">
              <ul className="left-side">
                <li className="elem">
                  <FieldSearch
                    value={valueSearch}
                    setValue={(event) => setValueSearch(event.target.value)}
                    onClear={onClear}
                    onGoSearch={onGoSearch}
                  />
                </li>
                <li className="elem">
                  <DropdownCount
                    contentPerPage={contentPerPage}
                    data={variables.dropdownContent}
                    onSetContentPerPage={setContentPerPage}
                    setCurrentPage={() => setCurrentPage(1)}
                    persistName={persistName}
                  />
                </li>
                <li className="elem">
                  <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
                </li>
              </ul>

              <ul className="right-side">
                {!!countSelectedRows && (
                  <li className="elem">
                    <Selected count={countSelectedRows} onTrash={() => setShowDeletePopup(true)} />
                  </li>
                )}
                <li className="elem">
                  <Button title="Lieferanten hinzufügen" onEvent={() => navigate('add')} width="173" color="green" />
                </li>
              </ul>
            </div>
          </TableBuilder>
        </div>
      </main>

      {isShowDeletePopup && <DeletePopup onDelete={onDelete} onClose={() => setShowDeletePopup(false)} />}

      {isShowLoader && <Loader type="transparent" />}

      {!isShowLoader && (statusGetManufacturers === 'pending' || statusDeleteManufacturers === 'pending') && (
        <Loader type="transparent" />
      )}
    </>
  );
};

export default Manufacturers;

export const state = {
  getDoctorsExtracts: {
    data: (state) => state.request.doctor.extracts.getDoctorsExtracts.data,
    status: (state) => state.request.doctor.extracts.getDoctorsExtracts.status,
    error: (state) => state.request.doctor.extracts.getDoctorsExtracts.error,
  },

  getDoctorsExtractsId: {
    data: (state) => state.request.doctor.extracts.getDoctorsExtractsId.data,
    status: (state) => state.request.doctor.extracts.getDoctorsExtractsId.status,
    error: (state) => state.request.doctor.extracts.getDoctorsExtractsId.error,
  },
};

import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from '../../../action';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.request.forms.getFormsAll.getSuccess, ({ getFormsAll }, action) => {
      getFormsAll.status = 'success';
      getFormsAll.data = action.payload;
    })
    .addCase(action.request.forms.getFormsAll.getPending, ({ getFormsAll }, action) => {
      getFormsAll.status = 'pending';
    })
    .addCase(action.request.forms.getFormsAll.getFailed, ({ getFormsAll }, action) => {
      getFormsAll.status = 'failed';
      getFormsAll.error = action.payload;
      getFormsAll.data = null;
    });

  builder
    .addCase(action.request.forms.getFormId.getSuccess, ({ getFormId }, action) => {
      getFormId.status = 'success';
      getFormId.data = action.payload;
    })
    .addCase(action.request.forms.getFormId.getPending, ({ getFormId }, action) => {
      getFormId.status = 'pending';
    })
    .addCase(action.request.forms.getFormId.getFailed, ({ getFormId }, action) => {
      getFormId.status = 'failed';
      getFormId.error = action.payload;
      getFormId.data = null;
    });

  builder
    .addCase(action.request.forms.putFormId.putSuccess, ({ putFormId }, action) => {
      putFormId.status = 'success';
      putFormId.data = action.payload;
    })
    .addCase(action.request.forms.putFormId.putPending, ({ putFormId }, action) => {
      putFormId.status = 'pending';
    })
    .addCase(action.request.forms.putFormId.putFailed, ({ putFormId }, action) => {
      putFormId.status = 'failed';
      putFormId.error = action.payload;
      putFormId.data = null;
    });

  builder
    .addCase(action.request.forms.deleteFormId.deleteSuccess, ({ deleteFormId }, action) => {
      deleteFormId.status = 'success';
      deleteFormId.data = action.payload;
    })
    .addCase(action.request.forms.deleteFormId.deletePending, ({ deleteFormId }, action) => {
      deleteFormId.status = 'pending';
    })
    .addCase(action.request.forms.deleteFormId.deleteFailed, ({ deleteFormId }, action) => {
      deleteFormId.status = 'failed';
      deleteFormId.error = action.payload;
      deleteFormId.data = null;
    });

  builder
    .addCase(action.request.forms.getForms.getSuccess, ({ getForms }, action) => {
      getForms.status = 'success';
      getForms.data = action.payload;
    })
    .addCase(action.request.forms.getForms.getPending, ({ getForms }, action) => {
      getForms.status = 'pending';
    })
    .addCase(action.request.forms.getForms.getFailed, ({ getForms }, action) => {
      getForms.status = 'failed';
      getForms.error = action.payload;
      getForms.data = null;
    });

  builder
    .addCase(action.request.forms.postForms.postSuccess, ({ postForms }, action) => {
      postForms.status = 'success';
      postForms.data = action.payload;
    })
    .addCase(action.request.forms.postForms.postPending, ({ postForms }, action) => {
      postForms.status = 'pending';
    })
    .addCase(action.request.forms.postForms.postFailed, ({ postForms }, action) => {
      postForms.status = 'failed';
      postForms.error = action.payload;
      postForms.data = null;
    });
});

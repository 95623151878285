import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.scss';

import * as reducer from '../../../store/reducer';
import * as action from '../../../store/action';

import { useChangeEffect, useDelay, usePageUp } from '../../../hooks';

import Header from '../../../components/header';
import Button from '../../../components/UI/button';
import DropdownCount from '../../../components/dropdownCount';
import FieldSearch from '../../../components/UI/fieldSearch';
import Loader from '../../../components/popups/loader';
import ProductCard from '../../../components/productCard';
import FieldPagination from '../../../components/UI/fieldPagination';

import { variables } from './variables';
import TableBuilder from '../../../components/tableBuilder';
import { useDefaultPerPage } from '../../../hooks/useDefaultPerPage';

const DoctorExtracts = () => {
  const dispatch = useDispatch();
  const isShow = useDelay(100);
  usePageUp();

  const persistName = 'doctorExtracts';

  const { contentPerPage, setContentPerPage } = useDefaultPerPage(persistName);

  const [currentPage, setCurrentPage] = useState(1);
  const [valueSearch, setValueSearch] = useState('');
  const [isTableVersion, setIsTableVersion] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [isShowLoader, setShowLoader] = useState(true);

  const statusExtracts = useSelector(reducer.request.doctor.extracts.state.getDoctorsExtracts.status);
  const prescriptioItems = useSelector(reducer.app.prescription.state.items);

  const dataExtracts = useSelector(reducer.request.doctor.extracts.state.getDoctorsExtracts.data);
  const getDoctorExtracts = (start, limit, search) =>
    dispatch(action.request.doctor.extracts.getDoctorsExtracts.get(start, limit, search));

  const addToPrescription = (data) => dispatch(action.app.prescription.addToPrescription(data));
  const removeFromPrescription = (id) =>
    dispatch(action.app.prescription.removeFromPrescription({ id, type: 'extract' }));

  const isRequestGETSuccess = statusExtracts === 'success';
  const pageCount = Math.ceil(dataExtracts?.total / contentPerPage) || 1;

  const tableTitles = ['№', 'Blütenname', 'Kultivar / Genetik', 'THC / CBD / CBN (%)', '	Unternehmen', ' '];
  const tableData = useMemo(() => {
    return dataExtracts?.items.reduce((acc, item, index) => {
      acc = [
        ...acc,
        {
          id: item.id,
          number: currentPage !== 1 ? (currentPage - 1) * contentPerPage + index + 1 : index + 1,
          product: item.name,
          'Kultivar / Genetik': item.genetics,
          'THC / CBD / CBN (%)': `${item.thc}/${item.cbd}/${item.cbn}`,
          unternehmen: item.companyName,
          editCart: [
            item,
            prescriptioItems.some(({ id }) => item.id === id),
            item.manufacturers.filter(
              (item) => item.maxOrderAmount >= item.minOrderAmount && item.active && !!item.availableAmount,
            ).length,
          ],
        },
      ];

      return acc;
    }, []);
  }, [dataExtracts?.items, prescriptioItems]);

  usePageUp();

  const onAddRemoveHandler = (data, isAdded) => {
    isAdded ? removeFromPrescription(data.id) : addToPrescription(data);
  };

  const severalAddHandler = () => {
    selectedIDs.forEach((id) => {
      if (!prescriptioItems.some(({ id: itemId }) => id === itemId)) {
        addToPrescription(dataExtracts.items.find((item) => item.id === id));
      } else {
        removeFromPrescription(id);
      }
    });
  };

  const onClear = () => {
    setValueSearch('');

    if (currentPage === 1) {
      getDoctorExtracts(0, contentPerPage);
    } else {
      setCurrentPage(1);
    }
  };

  const onGoSearch = () => {
    if (currentPage === 1) {
      getDoctorExtracts(0, contentPerPage, valueSearch);
    } else {
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    const firstContentIndex = currentPage ? (currentPage - 1) * contentPerPage : 0;
    getDoctorExtracts(firstContentIndex, contentPerPage);
  }, [contentPerPage, currentPage]);

  useChangeEffect(() => {
    if (isRequestGETSuccess) setShowLoader(false);
    else setShowLoader(true);
  }, [isRequestGETSuccess]);

  return (
    <>
      <Header />
      <main className={`doctor-page pharmacie-page page ${isShow ? 'show' : ''}`}>
        {!isShowLoader && (
          <div className="container">
            <h1 className="page-title">Extrakte</h1>
            <ul className="page-search-header">
              <li className="elem">
                <FieldSearch
                  value={valueSearch}
                  setValue={(event) => setValueSearch(event.target.value)}
                  onClear={onClear}
                  onGoSearch={onGoSearch}
                />
              </li>
              <div className="right-side">
                {isTableVersion && !!selectedIDs.length && (
                  <button onClick={severalAddHandler} className="several-add-btn prescription">
                    {selectedIDs.length} ausgewählt
                  </button>
                )}
                <li className="elem">
                  <DropdownCount
                    contentPerPage={contentPerPage}
                    data={variables.dropdownContent}
                    onSetContentPerPage={setContentPerPage}
                    setCurrentPage={() => setCurrentPage(1)}
                    persistName={persistName}
                  />
                </li>
                <li className="btn-group">
                  <button
                    onClick={() => setIsTableVersion(false)}
                    className={`btn-cards ${!isTableVersion ? 'active' : ''}`}></button>
                  <button
                    onClick={() => setIsTableVersion(true)}
                    className={`btn-table ${isTableVersion ? 'active' : ''}`}></button>
                </li>
              </div>
            </ul>
            {!isTableVersion ? (
              dataExtracts?.items.length ? (
                <section className="section-wrapper">
                  {dataExtracts.items.map((item) => (
                    <ProductCard
                      key={item.id}
                      {...item}
                      onEvent={onAddRemoveHandler}
                      type="extract"
                      icon="prescription"
                      isAdded={prescriptioItems.some(({ id, type }) => id === item.id && type === 'extract')}
                    />
                  ))}
                </section>
              ) : (
                <section className="empty-search">
                  <h1 className="empty-search-title">Zu diesem Suchbegriff wurde kein Ergebnis gefunden</h1>
                  <p className="empty-search-text">Hier sehen Sie die Liste aller verfügbaren Extrakte.</p>
                </section>
              )
            ) : (
              <TableBuilder
                className="product-table"
                path="/extracts"
                contentPerPage={contentPerPage}
                title={tableTitles}
                data={tableData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                isCanSelect={true}
                pagination="type2"
                valueSearch={valueSearch}
                isWithNavigation={false}
                selectedIDs={selectedIDs}
                setSelectedIDs={setSelectedIDs}
                onAddRemoveHandler={onAddRemoveHandler}></TableBuilder>
            )}

            {dataExtracts?.items.length !== 0 && (
              <div className="separeate-pagination">
                <div className="pagination-buttons">
                  <Button
                    title="Vorherige Seite"
                    onEvent={() => setCurrentPage((prev) => (prev !== 1 ? prev - 1 : 1))}
                    width="173"
                    color="transparent"
                    disabled={currentPage === 1}
                  />
                  <Button
                    title="Nächste Seite"
                    onEvent={() => setCurrentPage((prev) => (prev !== pageCount ? prev + 1 : pageCount))}
                    width="173"
                    color="green"
                    disabled={currentPage === pageCount}
                  />
                </div>
                <div className="pagination-navigation">
                  <FieldPagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} />
                </div>
              </div>
            )}
          </div>
        )}
      </main>

      {isShowLoader && <Loader type="transparent" />}
    </>
  );
};

export default DoctorExtracts;

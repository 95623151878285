import React from 'react';

import './style.scss';

const ConsistencyBar = ({ data }) => {
  return (
    <div className="consistency-bar-wrapper">
      <ul className="consistency-bar">
        {!!data?.thc && <li style={{ width: `${data?.thc <= 1 ? 1 : data?.thc}%` }} title={`THC: ${data?.thc} %`}></li>}
        {!!data?.cbd && <li style={{ width: `${data?.cbd <= 1 ? 1 : data?.cbd}%` }} title={`CBD: ${data?.cbd} %`}></li>}
        {!!data?.cbn && <li style={{ width: `${data?.cbn <= 1 ? 1 : data?.cbn}%` }} title={`CBN: ${data?.cbn} %`}></li>}
      </ul>
      <ul className="consistency-bar-description">
        {!!data?.thc && <li>{`THC: ${data?.thc} %`}</li>}
        {!!data?.cbd && <li>{`CBD: ${data?.cbd} %`}</li>}
        {!!data?.cbn && <li>{`CBN: ${data?.cbn} %`}</li>}
      </ul>
    </div>
  );
};

export default ConsistencyBar;

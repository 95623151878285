import Cookies from 'js-cookie';

import * as variable from '../../variables';

export const orders = {
  getOrderId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/orders/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  putOrderId: (id, data) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/orders/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteOrderId: (id) => {
    const accessToken = Cookies.get('accessToken');

    return fetch(`https://${variable.url}/admin/orders/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getOrders: (params) => {
    const accessToken = Cookies.get('accessToken');
    const { start, limit, pharmacies, manufacturers, products, search, from, to } = params;

    const searchParam = search ? `&search=${search}` : '';
    const pharmaciesParam = pharmacies ? `&${pharmacies}` : '';
    const manufacturersParam = manufacturers ? `&${manufacturers}` : '';
    const productsParam = products ? `&${products}` : '';
    const fromParam = from ? `&from=${from}` : '';
    const toParam = to ? `&to=${to}` : '';

    return fetch(
      `https://${variable.url}/admin/orders?start=${start}&limit=${limit}${searchParam}${pharmaciesParam}${manufacturersParam}${productsParam}${fromParam}${toParam}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  },
};

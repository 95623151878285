import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import './style.scss';


import imageIcon from '../../../assets/icons/icon-image.svg';
import arrowIcon from '../../../assets/icons/icon-arrow.svg';

import Checkbox from '../../UI/checkbox';
import Switcher2 from '../../UI/switcher2';
import EmptyTable from '../emptyTable';
import Cookies from 'js-cookie';

const Table = ({
  path,
  title,
  data,
  selectedOneOrMore,
  isSelectAll,
  selectedIDs,
  setSelectedIDs,
  isCanSelect,
  setProductImagePopup,
  totalItems,
  sortKeys,
  onChangeStatus,
  innerTableBtn,
  valueSearch,
  onSortHandler,
  onAddRemoveHandler,
}) => {
  const navigate = useNavigate();
  const more = useRef(null);
  const typeOrder = sessionStorage.getItem('typeOrder') || 'ASC';

  const role = Cookies.get('role');

  const [copyEmail, setCopyEmail] = useState({ id: 0, status: false, value: '' });
  const [isShowHideLine, setShowHideLine] = useState(0);
  const [heightHideLine, setHeightHideLine] = useState(0);
  const [isAvailable, setIsAvailable] = useState();
  const allSortKeys = ['status', 'datum', 'type'];

  const isSortColumn = (key) => {
    return sortKeys?.some((sortKey) => sortKey === key) && allSortKeys.some((sortKey) => sortKey === key);
  };

  const [sort, setSort] = useState(sortKeys?.reduce((o, key) => Object.assign(o, { [key]: typeOrder === 'ASC' }), {}));

  useEffect(() => {
    if (copyEmail.status) {
      navigator.clipboard.writeText(copyEmail.value);
      setTimeout(() => setCopyEmail(false), 1000);
    }
  }, [copyEmail.status]);

  useEffect(() => {
    setHeightHideLine(more.current?.scrollHeight || 0);
  }, [more, isShowHideLine]);

  const onToggleSort = useCallback(
      (key) => {
        sessionStorage.setItem('typeOrder', typeOrder === 'ASC' ? 'DESC' : 'ASC');
        setSort((prev) => ({ ...prev, [key]: !prev[key] }));
        onSortHandler(key, sort);
      },
      [sort, typeOrder],
  );

  const onToggleLine = (ID) => {
    let modifiedSelectedLine = [];

    if (selectedIDs.includes(ID)) {
      modifiedSelectedLine = selectedIDs.filter((itemID) => itemID !== ID);
    } else {
      modifiedSelectedLine = [...selectedIDs, ID];
    }

    setSelectedIDs(modifiedSelectedLine);
  };

  const onToggleAllLine = (event) => {
    if (event.target.checked) {
      setSelectedIDs(data.map((items) => items.id));
    } else {
      setSelectedIDs([]);
    }
  };

  const onGoToPage = (id) => {
    navigate(`${path}/${id}`);
  };

  const onGoToOrder = () => {
    navigate(`/orders`);
  };

  const onChangeStatusHandler = (id) => {
    setIsAvailable(() => isAvailable.map((prod) => (prod.id === id ? { ...prod, active: !prod.active } : prod)));
    onChangeStatus(!isAvailable?.find((item) => item.id === id).active, id);
  };

  useEffect(() => {
    setIsAvailable(data?.map((item) => ({ id: item.id, active: item.active })));
  }, [data]);

  return (
      <table className="default-table">
        <thead>
        <tr>
          {isCanSelect && (
              <th>
                <Checkbox selectedOneOrMore={selectedOneOrMore} isChecked={isSelectAll} onChange={onToggleAllLine} />
              </th>
          )}

          {title?.map((item, index) => (
              <th
                  key={index}
                  onClick={() => isSortColumn(item) && onToggleSort(item)}
                  className={isSortColumn(item) ? 'th-container' : `table-${item}`}>
                {item}
                {isSortColumn(item) && (
                    <img src={arrowIcon} alt="Arrow" className={sort[item] ? 'arrow-rotate-up' : 'arrow-rotate-down'} />
                )}
              </th>
          ))}
        </tr>
        </thead>

        <tbody>
        {data?.length !== 0 && data ? (
            data?.map((items) => (
                <tr key={items.id}>
                  {isCanSelect && (
                      <td>
                        <p>
                          <Checkbox
                              isChecked={selectedIDs.includes(items.id)}
                              onChange={() => onToggleLine(items.id)}
                              isAvailable={items?.editCart && role !== 'doctor' ? items.editCart[2] : true}
                          />
                        </p>
                      </td>
                  )}

                  {Object.keys(items)
                      .filter((key) => key !== 'id')
                      .map((key, index) => {
                        const value = items[key];

                        // --- all table
                        if (key === 'name') {
                          return (
                              <Td key={index} className="name" onEvent={() => onGoToPage(items.id)}>
                                {value}
                              </Td>
                          );
                        }

                        // --- manufacturers table
                        if (key === 'email') {
                          return (
                              <Td
                                  key={index}
                                  className="email"
                                  onEvent={(event) =>
                                      setCopyEmail({ id: items.id, status: true, value: event.target.innerText })
                                  }>
                                {value}
                                {copyEmail.id === items.id && copyEmail.status && <span className="copy">Kopiert</span>}
                              </Td>
                          );
                        }

                        // --- manufacturers table, pharmacy table
                        if (key === 'status' || key === 'type') {
                          return (
                              <Td key={index}>
                                <span className={`status ${value}`}>{value}</span>
                              </Td>
                          );
                        }

                        if (key === 'view') {
                          return (
                              <Td key={index}>
                                <button onClick={() => setProductImagePopup({ img: value, active: true })}>
                                  <img src={imageIcon} alt="View" />
                                </button>
                              </Td>
                          );
                        }

                        if (key === 'editCart') {
                          return (
                              <Td key={index}>
                                {(!!value[2] || role === 'doctor') && (
                                    <button
                                        className={`product-card-header-add ${
                                            role === 'pharmacy' ? 'cart' : 'prescription'
                                        } table ${value[0].icon} ${value[1] ? 'added' : ''}`}
                                        onClick={() => onAddRemoveHandler(value[0], value[1])}></button>
                                )}
                              </Td>
                          );
                        }

                        if (key === 'active') {
                          return (
                              <Td key={index}>
                                <Switcher2
                                    count=""
                                    isChecked={!!isAvailable?.find((prod) => prod.id === items.id)?.active}
                                    onChange={() => onChangeStatusHandler(items.id)}
                                    withSymbols={false}
                                />
                              </Td>
                          );
                        }

                        // ---  pharmacy table
                        if (key === 'ordersCount') {
                          return (
                              <Td key={index} className="orders-count">
                                {!!value && <span className="order">{value}</span>}
                              </Td>
                          );
                        }

                        if (Array.isArray(value)) {
                          if (key === 'productsName') {
                            return (
                                <td key={index}>
                                  <p key={index} onClick={() => onGoToPage(items.id)}>
                                    {value[0].name}
                                    <span className={`status ${value[0].status}`} onClick={() => onGoToPage(items.id)}>{value[0].status}</span>
                                  </p>

                                  <div
                                      ref={isShowHideLine === items.id ? more : null}
                                      className={`more ${isShowHideLine === items.id ? 'show' : 'hide'}`}
                                      style={{
                                        maxHeight: `${isShowHideLine === items.id ? heightHideLine : 0}px`,
                                      }}>
                                    {value.map((item, index) => {
                                      if (index === 0) return null;

                                      return (
                                          <p key={index}>
                                            {item.name}
                                            <span className={`status ${item.status}`}>{item.status}</span>
                                          </p>
                                      );
                                    })}
                                  </div>
                                </td>
                            );
                          }
                          // ---  pharmacy table
                          if (key === 'ordersCount') {
                            return (
                                <Td key={index} className="orders-count" onEvent={!!value && onGoToOrder}>
                                  {!!value && <span className="order">{value}</span>}
                                </Td>
                            );
                          }

                          return (
                              <td key={index}>
                                <p key={index} onClick={() => onGoToPage(items.id)}>{value[0]}</p>

                                <div
                                    ref={isShowHideLine === items.id ? more : null}
                                    className={`more ${isShowHideLine === items.id ? 'show' : 'hide'}`}
                                    style={{
                                      maxHeight: `${isShowHideLine === items.id ? heightHideLine : 0}px`,
                                    }}>
                                  {value.map((item, index) => {
                                    if (index === 0) return null;

                                    return <p key={index}>{item}</p>;
                                  })}
                                </div>
                              </td>
                          );
                        }

                        if (key === ' ') {
                          return (
                              <Td key={index}>
                                <Switcher2
                                    count=""
                                    isChecked={isShowHideLine === items.id}
                                    onChange={() => setShowHideLine(isShowHideLine === items.id ? 0 : items.id)}
                                />
                              </Td>
                          );
                        }

                        return (
                            <Td
                                key={index}
                                className={key === 'product' || key === 'apotheken' ? 'product-table-name' : ''}
                                onEvent={() => onGoToPage(items.id)}>
                              {value}
                            </Td>
                        );
                      })}
                </tr>
            ))
        ) : (
            <EmptyTable valueSearch={valueSearch}>{innerTableBtn}</EmptyTable>
        )}
        </tbody>
      </table>
  );
};

export default Table;

const Td = ({ className, onEvent, children }) => {
  return (
      <td>
        <p className={className} onClick={onEvent}>
          {children}
        </p>
      </td>
  );
};

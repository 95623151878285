import React, { useEffect, useState } from 'react';

import './style.scss';

import { useOnOutsideClick } from '../../hooks';

const DropdownLines = ({ contentPerPage, data, onSetContentPerPage = () => {}, setCurrentPage }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectDropdown, setSelectDropdown] = useState(contentPerPage);
  const { innerBorderRef } = useOnOutsideClick(() => setShowDropdown(false));

  useEffect(() => {
    onSetContentPerPage(selectDropdown);
  }, [selectDropdown]);

  useEffect(() => {
    setSelectDropdown(contentPerPage);
  }, [contentPerPage]);

  const onSelect = (event) => {
    const { innerText } = event.target;

    setShowDropdown(false);
    setSelectDropdown(innerText);
    setCurrentPage();
  };

  return (
    <div className="dropdown-count" ref={innerBorderRef}>
      <button className="btn" onClick={() => setShowDropdown(!showDropdown)}>
        Zeilen pro Seite <span>{selectDropdown}</span>
      </button>

      {showDropdown && (
        <ul className="content">
          {data?.map((item) => (
            <li className="item" key={item.id}>
              <button onClick={onSelect}>{item.title}</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownLines;
